import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ElementRef , OnDestroy} from '@angular/core';
import { LessonsService } from '../lessons.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { DatatableService } from '@core/datatable/datatable.service';
import { TransactionLog } from '@core/types/models/TransactionLog';
import { FormBuilder } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.css'],
  providers: [DatatableService],
})
export class PendingComponent implements OnInit  {

  @ViewChild('open') open: ElementRef;
  @ViewChild('open1') open1: ElementRef;
  @ViewChild('closeViewPackageDeatailsModal') closeViewPackageDeatailsModal: ElementRef;

  constructor(
    private lessonService: LessonsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public datatable: DatatableService<TransactionLog>,
    private fb: FormBuilder
  ) {

    this.pendingActions$.subscribe(response=>{
      this.actionsData = response;
      if(this.actionsData.length>0){
        this.activeModal();
        this.activeModalRescheduleId();
      }
     });
    this.lessonService.sharedsourcePendingRequest.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    });

    this.lessonService.sharedsourceRequestReschedule.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    });
  }
  public form = this.fb.group({
        tutor: null,
  });
  public pendingActions$ = this.datatable.data$;
  public actionsData;
  public pendingRequests;
  public selectedSourceCode;
  public Timings;
  public bookingRequests;
  public selectedBookingId;
  public rescheduleNote;
  public rescheduleNoteCreatedAt;
  public rescheduleId;
  public reschedule_status;
  public pageParams;
  public rescheduleTime;
  public packageDates;
  public selectedTutor;
  public offeredNewDates;
  public bookingRescheduleSelected;
  public rescheduleBookingID;
  public requestReschedule;
  public offeredNewDatesCount;
  public requestRescheduleCount;
  public selectedTutorId;
  public selectedUserName;
  public selectedReschduleSourceCode;

  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('closeViewRescheduleDeatailsModal') closeViewRescheduleDeatailsModal: ElementRef;
  @ViewChild('closebookingRescheduleModal') closebookingRescheduleModal: ElementRef;

  ngOnInit(): void {

  this.lessonService.sharedtutorName.subscribe(name=>this.selectedUserName = name);

      this.route.params.subscribe( params =>{
        this.selectedTutor = params['id'];
        if(this.selectedTutor){
          this.selectedTutor = params['id'];
          this.setTutorId(this.selectedTutor);
        }else{
          this.setTutorId('');
        }
      });
  
        this.datatable.init({
            uri: LessonsService.PENDINGACTIONS
        });
  }

  public setTutorId(id){
   this.selectedTutor = id;
   this.datatable.addFilter('tutor',id);
  }
  // Schedule Details
  public viewPackageDetails(dates , booking_id){
    this.Timings = dates;
    this.selectedBookingId = booking_id;
  }

  // Close closeViewDetailsModal
  public closeViewDetailsModal(){
   this.Timings='';
   this.selectedBookingId='';
   this.closeViewDeatailsModal.nativeElement.click();
  }

  // Booking Reschedule
  public bookingReschedule(request){
   this.rescheduleBookingID = request.booking_id;
   this.bookingRescheduleSelected = request.booking_reschedule_request;
  }

  public acceptBookingReschedule(operation){
    let data = Object();
    Object.assign(data , {bookingId : this.rescheduleBookingID,  operation:operation});
    this.lessonService.acceptBookingReschedule(data)
        .subscribe(response => {
          if(response.status == 'success'){
          this.datatable.setData(response.pagination.data);
          this.lessonService.pendingActionsCount(response.pagination.data.length);
          this.closebookingRescheduleModal.nativeElement.click();
          this.toastr.success('Booking Reschedule Accepted');
        }else if(response.status == 'failed'){
          this.toastr.error(response.message);
        }
        });
  }

  public rescheduleDetailsModal(data){
    this.offeredNewDates = data.offered_new_date;
    this.offeredNewDatesCount = data.offered_new_date_count;
    this.requestReschedule = data.tutor_request_reschedule;
    this.requestRescheduleCount = data.tutor_request_reschedule_count;
  }

  public bookingRescheduleRequest(data){
    this.bookingRescheduleRequest = data.booking
  }

  public cancelSchedule(booking_dates_id, booking_id) {
    let data = Object();
    Object.assign(data, {booking_dates_id:booking_dates_id, booking_id:booking_id})

    this.lessonService.cancelSchedule(data)
        .subscribe(response => {
        });
  }

  public acceptRescheduleRequest(rescheduleId, operation) {
    let data = Object();
    Object.assign(data, {rescheduleId:rescheduleId, operation:operation})

    this.lessonService.acceptRescheduleRequest(data)
        .subscribe(response => {
          console.log(response.pagination.data);
          this.datatable.setData(response.pagination.data);
          this.closeViewRescheduleDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
    });
  }

  public acceptAssignedDatesForPackage(){
    let data = Object();
    Object.assign(data , {bookingId : this.selectedBookingId});
    this.lessonService.acceptAssignedDatesForPackage(data).subscribe(response => {
          this.datatable.setData(response.pagination.data);
          this.closeViewPackageDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
    });
  }

  public acceptRequestReschedule(rescheduleId, operation) {
    let data = Object();
    Object.assign(data, {rescheduleId:rescheduleId, operation:operation})

    this.lessonService.acceptRequestReschedule(data)
        .subscribe(response => {;
          if(response.status == 'failed'){
           this.toastr.error(response.message);
          }else{
          this.datatable.setData(response.pagination.data);
          this.closeViewRescheduleDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
         }
    });
  }


  public activeModal(){
    $('#'+this.selectedSourceCode).addClass('bg-theme-color');
    this.actionsData.find(x=>{
      if(x.booking_id == this.selectedSourceCode){
        this.bookingReschedule(x);
        this.open.nativeElement.click();
      }
    });
  }

  public activeModalRescheduleId(){
    $('#'+this.selectedReschduleSourceCode).addClass('bg-theme-color');
    this.actionsData.find(x=>{
      if(x.booking_id == this.selectedReschduleSourceCode){
        this.rescheduleDetailsModal(x);
        this.open1.nativeElement.click();
      }
    });
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}
