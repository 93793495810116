<div class="TT-tutorWrapper">
   <div class="TT-templateDraft TT-spacers">
      <div class="row m-auto">
         <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h4 class="TT-AccSettings TT-spacers"><i class="fa fa-usd mr-1" aria-hidden="true"></i>&nbsp;Transactions Log </h4>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
               <div class="d-flex TT-formGroup TT-searchFind pull-right pull-right_mobile">                
                  <span class="TT-csvIcon">
                     <app-transaction-filter (changeSearch)="changeSearchFunction($event)"></app-transaction-filter>
                  </span>
                  <!-- <div class="TT-formGroup">
                     <app-datatable-header-search-input placeholder="Search"></app-datatable-header-search-input>
                     <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
                  </div> -->
                </div>
               </div>
         </div>

      <div class="row m-auto">
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="table-responsive TT-responsiveTable">
               <table class="table table-striped TT-table">
                 <thead> 
                  <tr>
                     <th trans="Amount">Amount</th>
                     <th trans="Purpose">Purpose</th>
                     <th trans="Recipient">Recipient</th>
                     <th trans="Timestamp">Timestamp</th>
                     <th trans="Gateway">Gateway</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let transaction of transactions$ | async">
                     <td>{{selectedCurrency.symbol}}{{transaction.amountConverted}}</td>
                     <td>{{transform(transaction.purpose)}}</td>
                     <td>
                        <span *ngIf="transaction.tutor; else notutor">{{transaction.tutor.username}}</span>
                        <ng-template #notutor>-</ng-template>
                     </td>
                     <td>{{transaction.created_at | date: 'MM-dd-yyyy h:mm a'}}</td>
                     <td>
                        <span *ngIf="transaction.payment_gateway == 'paypal'" trans="PayPal">PayPal</span>
                        <span *ngIf="transaction.payment_gateway == 'stripe'" trans="Stripe">Stripe</span>
                        <p *ngIf="transaction.refund_id"><span class="badge badge-info">Refund On: {{transaction.refund_date | date: 'MM-dd-yyyy h:mm a'}}</span></p>
                     </td>
                  </tr>
                  <tr *ngIf="(transactions$ | async).length == 0">
                    <td class="TT-noRecorded text-center" colspan="6" trans="No Records Found">No Records Found</td>
                  </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </div>
   
   <app-datatable-footer-new *ngIf="(transactions$ | async).length > 0"></app-datatable-footer-new>
</div>