import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { CurrentUser } from '../../auth/current-user';

@Injectable({
    providedIn: 'root',
})

export class StudentGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private currentUser: CurrentUser,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.currentUser.isLoggedIn()){
          return true;
        }else{
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canLoad(route: Route) {
     if(this.currentUser.hasRole('Tutor')){
             return false;
     }else if(this.currentUser.isAdmin()){
             return false;
     }else{
             return true;
     }
    }

    private handle(url: string) {
        if (this.currentUser.isLoggedIn()) {
            return true;
        }

        this.currentUser.redirectUri = url;

        this.router.navigate(['/login']);

        return false;
    }
}
