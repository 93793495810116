<div class="TT-lessonsNavLinkings">
   <ul class="TT-lessonsBar">
      <li (click)="openSchedules()" routerLink="scheduled" routerLinkActive="selectedTeaching" class="TT-lessonsList"
         trans="Schedule">
         <i class="fa fa-calendar TT-fontawaesome" aria-hidden="true"></i>
         <a>Schedule</a>
      </li>
      <li (click)="resetFilter()" routerLink="pending" routerLinkActive="selectedTeaching" class="TT-lessonsList"
         trans="Pending Actions">
         <i class="fa fa-clock-o TT-fontawaesome" aria-hidden="true"></i>
         <a>Pending Actions <span *ngIf="pendingActions" class="pull-right TT-badge">{{pendingActions}}</span></a>
      </li>
      <li (click)="resetFilter()" routerLink="completed" routerLinkActive="selectedTeaching" class="TT-lessonsList"
         trans="completed_lessons">
         <i class="fa fa-check TT-fontawaesome" aria-hidden="true"></i>
         <a>Completed</a>
      </li>
      <li (click)="resetFilter()" routerLink="cancelled" routerLinkActive="selectedTeaching" class="TT-lessonsList"
         trans="cancelled_lessons">
         <i class="fa fa-times TT-fontawaesome" aria-hidden="true"></i>
         <a>Cancelled</a>
      </li>
      <li (click)="resetFilter()" routerLink="overdue" routerLinkActive="selectedTeaching" class="TT-lessonsList"
         trans="overdue_lessons">
         <i class="fa fa-exclamation-triangle TT-fontawaesome" aria-hidden="true"></i>
         <a>Overdue Lessons</a>
      </li>
   </ul>
   <div id="filterTutors" *ngIf="hideSidenav != true">
      <h4 class="TT-AccSettings TT-rightSpacers" trans="Filter by Tutors"> Filter by Tutors </h4>

      <form class="TT-searchFind clearfix">
         <div class="TT-formGroup">
            <input (input)="filterTutorsList($event.target.value)" vtype="text" class="TT-FieldGroup" id="searchValue"
               trans-placeholder="Search">
            <i class="fa fa-search TT-searchIcon" aria-hidden="true"></i>
         </div>
         <p class="TT-filterCleaning pull-right" (click)="clearFilter()" *ngIf="selectedTutor" trans="clear_filter">Clear FIlter</p>
      </form>

<ul class="TT-chatting">
        <li class="TT-chatList" *ngFor="let tutor of tutors" (click)="selectTutor(tutor.tutor_id , tutor['tutor_resume'].display_name)" id="{{tutor.tutor_id}}">
          <a class="TT-chatListing">
           <img *ngIf="tutor['user_avatar']" src="{{tutor['user_avatar']['avatar']}}" class="img-responsive TT-userChat" />
             <span *ngIf="tutor['tutor_resume']" class="TT-ChatText">
               {{tutor['tutor_resume'].display_name}}
             </span>
           </a>
        </li>
      </ul> 
   </div>
</div>

<div id="lessonBoard" *ngIf="hideSidenav">
   <div class="col-sm-12">
      <div class="row">
         
         <h5 class="text-left color2">
            <p><b trans="Chat">
                  Chat
               </b>
            </p>
            <p class="palegreen" trans="leave a comment or upload a document."> leave a comment or upload a document.
            </p>
         </h5>

         <!-- {{Comments|json}} -->
         
         <div class="row clearfix TT-chatFixedvideo">
            <div class="d-flex TT-formGroup TT-searchFind" style="margin: 15px;">
               <button id="staticBackdrop" data-backdrop="static" class="chat__area-media-btn mr-2 btn"
                  data-toggle="modal" data-target="#viewModalpaperclip">
                  <i class="fa fa-paperclip" aria-hidden="true"></i>
               </button>
                <form class="TT-formGroup" style="width:100%;" method="post">
                  <input id="postComment" (keydown)="keyPressHandler($event)" (input)="comentValue($event.target.value)"
                  type="text" class="form-control" placeholder="Type Message..." trans-placeholder="Type Message..." style="border-radius:50px;">
                 <i (click)="postComment()" class="fa fa-paper-plane-o TT-searchIcon" aria-hidden="true"></i>
               </form>
            </div>
         </div>
      </div>
   </div>
   <div class="col-sm-12">
      <!-- main chat -->
      <div class="T_S-chat" id="T_S-chat">
         <div *ngFor="let comment of Comments">
            <h5 *ngIf="userId != comment.from_id" class="text-left color2">
               <p trans="Teacher"><b>
                     Teacher: {{comment.from}}
                  </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
               </p>
               <div class="footer-schedule_Chat chat_chat_bdr">
                  <p class="palegreen"> {{comment.comment}}</p>
                  <br />
                  <p *ngIf="!comment.attachments"><a class="nocolor" ></a></p>
                  <p *ngIf="comment.attachments">
                     <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                        <span data-target="#viewAttachment" data-toggle="modal"
                           (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                     </a>
                  </p>
               </div>
            </h5>
            <h5 *ngIf="userId == comment.from_id" class="text-left color2">
               <p><b>
                     Student: {{studentName}}
                  </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
               </p>
               <div class="footer-schedule_Chat chat_chat_bdr">
                  <p class="palegreen">{{comment.comment}}</p>
                  <br />
                  <p *ngIf="!comment.attachments"><a class="nocolor" ></a></p>

                  <p *ngIf="comment.attachments">
                     <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                        <span data-target="#viewAttachment" data-toggle="modal"
                           (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                     </a>
                  </p>
               </div>
            </h5>
         </div>
      </div>
   </div>
   <div class="modal fade" id="viewAttachment" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog mt-100">
         <div class="modal-content">
            <div class="modal-header">
               <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
                  ×
               </button>
            </div>
            <div class="modal-body M_wrapper">
               <img src="{{attachment}}" width="550">
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog mt-100">
         <div class="modal-content content-new">
            <div class="modal-header">
               <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close"
                  data-dismiss="modal" aria-hidden="true">×
               </button>
               <h4 class="modal-title md-title" id="myModalLabel" trans="File Upload www">
                  File Upload
               </h4>
               <br />
               <form [formGroup]="commentForm">
                  <div class="form-group">
                     <label for="comment" trans="Comment">
                        Comment
                     </label>
                     <input formControlName="commentValue" type="comment" name="comment" class="form-control">
                     <div *ngFor="let error of errorMessages.commentValue">
                        <ng-container *ngIf="commentValue.hasError(error.type) && (commentValue.touched)">
                           <small class="error-message">{{error.message}}</small>
                        </ng-container>
                     </div>
                  </div>
               </form>
               <br />
               <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
                  <ngx-dropzone-label trans="Select a file or drag here">Select a file or drag here</ngx-dropzone-label>
                  <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                     [removable]="true" (removed)="onRemove(f)">
                  </ngx-dropzone-image-preview>
               </ngx-dropzone>
               <br />
               <div class="text-right">
                  <button (click)="sendFile()" class="btn btn-primary text-right" id="upload" trans="Send">
                     Send
                     <app-loader *ngIf="imageLoader$ | async"></app-loader>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
   <app-messagemodal></app-messagemodal>
</div>

<div id="lessonBoard" *ngIf="hideSidenav">