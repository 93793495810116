import {Injectable} from '@angular/core';
import {AppHttpClientService} from '../../core/http/app-http-client.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StudentPaymentService {
    static BASE_URI = 'transactions';
    constructor(private http: AppHttpClientService,) {}

    //Students
    public userTransactionLogs(payload: object){
       return this.http.post('userTransactionLogs', payload);
    }

    public razorPayPayment(payload: object){
       return this.http.post('razorPayWalletPayment', payload);
    }

    public walletDetails(){
       return this.http.get('walletDetails');
    }

    // This Method is used to do stripe razorPayPayment
    public stripeWalletPayment(payload: object){
      return this.http.post('stripeWalletPayment', payload);
    }

    public stripeWalletCustomerPayment(payload: object){
      return this.http.post('stripeWalletCustomerPayment', payload);
    }

    public stripeWalletCustomerPaymentcvc(payload: object){
      return this.http.post('stripeWalletCustomerPaymentcvc', payload);
    }

    public refundRequest(payload: object) {
      return this.http.post('refundRequest', payload);
    }

}
