<div class="modal fade" id="modaldatepicker" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 20px;">
            <div *ngIf="scheduleLoader" class="loader_Main">
               <div class="loader-cal"></div>
            </div>
            <button type="button" class="close" (click)="clearModalData()">
               <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span *ngIf="lessonType == 'trialLesson'" trans="Book a trail lesson">Book a trail lesson</span>
               <span *ngIf="lessonType != 'trialLesson'" trans="Schedule">Schedule</span>
               <span> - {{name}}</span>
            </h5>
            <button type="button" class="close" #closeScheduleModal data-dismiss="modal" aria-label="Close">
            </button>
            <div class="modal-body p-0">
               <div class="container-fluid whiteBg p-0">
                  <ul *ngIf="lessonType != 'trialLesson'" class="nav nav-tabs">
                     <li class="bookSchedule active"><a data-toggle="tab" href="#Schedule">Book From Schedule</a></li>
                     <li class="buyPackage"><a data-toggle="tab" href="#Package" trans="Buy a Package"
                           (click)="clearValues()">Buy a Package</a></li>
                     <li class="buyPackageGroup"><a data-toggle="tab" href="#PackageGroup" trans="Group Class"
                           (click)="clearValues()" style="">Group Class</a></li>
                  </ul>
                  <div class="tab-content">
                     <div id="Schedule" class="tab-pane fade in active">
                        <div class="greyBox">
                           <small> <i class="fa fa-question-circle" aria-hidden="true"></i> <span
                                 trans="The timings are displayed in your local timezone." class="f-10">The timings are
                                 displayed in your local timezone.</span></small>
                        </div>
                        <div class="ReactModal__Content Modal-content-after-open BookingModal-tt" tabindex="-1"
                           role="dialog">
                           <div class="BookingModal-tt ModalCore-c28f0z-0 display-MT">
                              <div class="Wrapper-pro" data-qa-id="wrapper-modal">
                                 <div class="Timetable-CL">
                                    <div class="TimetableHead-CL" data-qa-id="timetable">
                                       <div class="row">

                                          <div class="nxt_frwd">
                                             <div class="col-md-1">
                                                <button *ngIf="reverseButtonAccess()" id="reverseDate"
                                                   (click)="reverseDate()"
                                                   class="NavButton-CL NavButtonDisabled-CL Button-button-CL Button-outlined-CL"
                                                   data-qa-id="previous-timeslots">
                                                   <svg height="12" viewBox="0 0 8 12" width="8"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                         d="M3.268 5.854l4.293 4.292-1.415 1.415L.44 5.854 6.146.146l1.415 1.415z">
                                                      </path>
                                                   </svg>
                                                </button>
                                             </div>
                                             <div class="col-md-1" style="margin-left:-5px;">
                                                <button id="forwardDate" (click)="forwardDate()"
                                                   class="NavButton-CL Button-button-CL Button-outlined-CL"
                                                   data-qa-id="next-timeslots">
                                                   <svg height="12" viewBox="0 0 8 12" width="8"
                                                      xmlns="http://www.w3.org/2000/svg">
                                                      <path
                                                         d="M4.732 5.854L.44 1.56 1.854.146 7.56 5.854 1.854 11.56.439 10.146z">
                                                      </path>
                                                   </svg>
                                                </button>
                                             </div>
                                             <div class="col-md-4 textline2 text-center no_wrap pl-0"
                                                style="padding-top:5px;">
                                                <span>{{month1}}</span>&nbsp;
                                                <span *ngIf="month2">-{{month2}}</span>
                                                <span>{{FirstDate}}-</span>
                                                <span>{{SeventhDate}},</span>
                                                <span>{{currentYear}}</span>
                                             </div>
                                          </div>
                                          <div class="col-md-6">
                                             <input readonly class="form-control text-center"
                                                value="{{timezone}}-{{time1 | date:'HH:mm:ss'}}">
                                          </div>

                                       </div>
                                    </div>
                                    <div>
                                       <div class="DaysHead-CL">
                                          <div class="DayName-CL Day-date-CL" id="FirstDay">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FirstDay}}</p>
                                                <p class="DayDate-CL">{{FirstDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SecondDay}}</p>
                                                <p class="DayDate-CL">{{SecondDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{ThirdDay}}</p>
                                                <p class="DayDate-CL">{{ThirdDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FouthDay}}</p>
                                                <p class="DayDate-CL">{{FourthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FifthDay}}</p>
                                                <p class="DayDate-CL">{{FifthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SixthDay}}</p>
                                                <p class="DayDate-CL">{{SixthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SeventhDay}}</p>
                                                <p class="DayDate-CL">{{SeventhDate}}</p>
                                             </div>
                                          </div>
                                       </div>
                                       <!-- Monday -->
                                       <div class="Calendar-CL">
                                          <div class="Day-date-CL">
                                             <div *ngFor="let firstDateTiming of FirstDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FirstDate}}-{{firstDateTiming}}-{{getMonthForDate(weekFirstDate)}}"
                                                (click)="selectDate( FirstDate , firstDateTiming , weekFirstDate)"
                                                [ngClass]="{'badge' : checkDate(FirstDate, firstDateTiming , getMonthForDate(weekFirstDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFirstDate, firstDateTiming)}">
                                                {{firstDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Tuesaday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let secondDateTiming of SecondDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SecondDate}}-{{secondDateTiming}}-{{getMonthForDate(weekSecondDate)}}"
                                                (click)="selectDate( SecondDate , secondDateTiming , weekSecondDate)"
                                                [ngClass]="{'badge' : checkDate(SecondDate, secondDateTiming , getMonthForDate(weekSecondDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSecondDate, secondDateTiming)}">
                                                {{secondDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Wednesday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let thirdDateTiming of ThirdDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{ThirdDate}}-{{thirdDateTiming}}-{{getMonthForDate(weekThirdDate)}}"
                                                (click)="selectDate( ThirdDate , thirdDateTiming , weekThirdDate)"
                                                [ngClass]="{'badge' : checkDate(ThirdDate, thirdDateTiming, getMonthForDate(weekThirdDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekThirdDate, thirdDateTiming)}">
                                                {{thirdDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Thursday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fourthDateTiming of FourthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FourthDate}}-{{fourthDateTiming}}-{{getMonthForDate(weekFourthDate)}}"
                                                (click)="selectDate( FourthDate , fourthDateTiming , weekFourthDate)"
                                                [ngClass]="{'badge' : checkDate(FourthDate, fourthDateTiming, getMonthForDate(weekFourthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFourthDate, fourthDateTiming)}">
                                                {{fourthDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Friday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fifthDateTiming of FifthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FifthDate}}-{{fifthDateTiming}}-{{getMonthForDate(weekFifthDate)}}"
                                                (click)="selectDate( FifthDate , fifthDateTiming , weekFifthDate)"
                                                [ngClass]="{'badge' : checkDate(FifthDate, fifthDateTiming, getMonthForDate(weekFifthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFifthDate, fifthDateTiming)}">
                                                {{fifthDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Saturday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let sixthDateTiming of SixthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SixthDate}}-{{sixthDateTiming}}-{{getMonthForDate(weekSixthDate)}}"
                                                (click)="selectDate( SixthDate , sixthDateTiming , weekSixthDate)"
                                                [ngClass]="{'badge' : checkDate(SixthDate, sixthDateTiming, getMonthForDate(weekSixthDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSixthDate, sixthDateTiming)}">
                                                {{sixthDateTiming}}
                                             </div>
                                          </div>
                                          <!-- Sunday -->
                                          <div class="Day-date-CL">
                                             <div *ngFor="let seventhDateTiming of SeventhDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SeventhDate}}-{{seventhDateTiming}}-{{getMonthForDate(weekSeventhDate)}}"
                                                (click)="selectDate( SeventhDate , seventhDateTiming , weekSeventhDate)"
                                                [ngClass]="{'badge' : checkDate(SeventhDate, seventhDateTiming, getMonthForDate(weekSeventhDate))}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSeventhDate, seventhDateTiming)}">
                                                {{seventhDateTiming}}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="InstantBookingFooter-CL text-left">
                                 <div class="row">
                                    <div class="col-sm-6">
                                       
                                       <p><span>Price per Lesson:</span>
                                          {{selectedCurrency.symbol}}{{hourlyPrice}}</p>
                                       <p *ngIf="lessonType == 'trialLesson'">
                                          <span *ngIf="adminFirstLessonDiscount>0"><span>Trail
                                                Discount:</span> {{adminFirstLessonDiscount}}%</span>
                                       </p>
                                       <p *ngIf="lessonType != 'trialLesson'">
                                          <span *ngIf="adminWholeDiscount>0"><span>Promotional Discount:</span>
                                             {{adminWholeDiscount}}%</span>
                                       </p>
                                       <p class="fontbold linkLabel"><span trans="Total:">Total:</span>
                                          {{selectedCurrency.symbol}}{{totalPrice}}</p>
                                    </div>

                                    <div *ngIf="selectedSubject && !techingSubjects" class="col-sm-6">
                                       <p class="cc-color"><span>Selected Subject</span> -
                                          <b>{{selectedSubject.subject_name}}</b></p>

                                       <p class="cc-color"><span trans="Booking Type">Booking Type</span> -
                                          <b>{{bookingType_title}}</b></p>

                                    </div>
                                    <div *ngIf="techingSubjects" class="col-sm-6">
                                       <select (input)="selectSubject($event.target.value)" class="form-control">
                                          <option selected [(ngModel)]="selectedSubject" trans="Select Subject"
                                             ngDefaultControl>Select Subject</option>
                                          <option *ngFor="let subject of techingSubjects">
                                             {{subject.active_subject_data.subject_name}}
                                          </option>
                                       </select>
                                    </div>
                                 </div>
                                 <div class="row p-10">
                                    <div *ngIf="this.currentuser.isLoggedIn() && totalPrice > 0"
                                       class="col-sm-12 text-center TT-lessonBooking">
                                       <button [disabled]="selectedSubject == '' || object.length == 0"
                                          *ngIf="selectedSubject" id="staticBackdrop" data-backdrop="static"
                                          tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                          (click)="openCheckoutModal()" href="#modalPayment" data-target="#modalPayment"
                                          data-toggle="modal" class="btn TT-primaryBTN_new"
                                          trans="Complete Booking" >Complete Booking</button>

                                       <button *ngIf="!selectedSubject && !techingSubjects && totalPrice > 0" disabled
                                          (click)="openCheckoutModal()" class="btn TT-primaryBTN_new"
                                          trans="Complete Booking">Complete Booking</button>
                                    </div>
                                    <div *ngIf="!this.currentuser.isLoggedIn() && totalPrice > 0"
                                       class="col-sm-12 text-center TT-lessonBooking">
                                       <button disabled (click)="openCheckoutModal()"
                                          class="btn TT-primaryBTN_new" trans="Complete Booking">Complete
                                          Booking</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div id="Package" class="tab-pane fade">
                        <div class="row">
                           <div class="greyBox">
                              <small> <i class="fa fa-question-circle" aria-hidden="true"></i> <span class="f-10">Tutor will message Student to determine Date/Time for the package of Classes</span></small>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Packages">Packages</p>
                              
                              <select (input)="selectpackage($event.target.value, 'package')" class="form-control"
                                 id="selectPack">
                                 <option value="" trans="Select Package" selected>Select Package</option>
                                 <option value="{{package.no_of_classes}}" *ngFor="let package of packages">
                                    {{package.no_of_classes}} Lessons</option>
                              </select>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Package Price">Package Price</p>
                              <input readonly class="form-control" value="{{packagePrice}}">
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" *ngIf="discountType == 'Percent'">Discount Percent</p>
                              <p class="cc-color" *ngIf="discountType == 'Value'">Discount Value</p>
                              <input readonly class="form-control" value={{discountPrice}}>
                           </div>
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Final Price">Final Price</p>
                              <input readonly class="form-control" value={{packageFinalPriceBeforeDiscount}}>
                           </div>
                        </div>
                        <div class="InstantBookingFooter-CL text-left">
                           <div class="row">
                              <div *ngIf="adminFirstPackageDiscount>0">
                                 <p *ngIf="trailPackageAccess =='yes'" class="fontbold text-center"><span
                                       trans="Trail Package Discount :">Trail Package Discount :</span>
                                    {{adminFirstPackageDiscount}}% </p>
                              </div>
                              <div *ngIf="adminPackageWholeDiscount>0">
                                 <p *ngIf="trailPackageAccess =='no'" class="fontbold text-center"><span
                                       trans="Package Discount :">Package Discount :</span>
                                    {{adminPackageWholeDiscount}}% </p>
                              </div>
                           </div>
                           <div class="row">
                              <p class="fontbold text-center"><span trans="Total to pay :">Total to pay :</span>
                                 {{selectedCurrency.symbol}} {{packageFinalPrice}} </p>
                           </div>
                           
                           <div class="row">
                              <div *ngIf="currentuser.isLoggedIn()" class="col-sm-12 text-center p-10 TT-lessonBooking">
                                 <button (click)="packageCheckOut()" href="#modalPayment" data-target="#modalPayment"
                                    data-toggle="modal" id="packageBtnCheckOut" type="button" disabled
                                    class="btn TT-primaryBTN_new" trans="Complete Booking">Complete Booking</button>
                              </div>
                              <div *ngIf="!currentuser.isLoggedIn()" class="col-sm-12 text-center p-10 TT-lessonBooking">
                                 <button (click)="packageCheckOut()" id="packageBtnCheckOut" type="button" disabled
                                    class="btn TT-primaryBTN_new" trans="Complete Booking">Complete Booking</button>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div id="PackageGroup" class="tab-pane fade">
                        <div class="row">

                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Available Classes">Available Classes</p>
                              <select (input)="selectpackage($event.target.value, 'group')" class="form-control"
                                 id="selectBatch">
                                 <option value="" trans="Select Group Class" selected>Select Group Class</option>
                                 <option value="{{group_class.no_of_classes}}"
                                    *ngFor="let group_class of group_classes">{{group_class.no_of_classes}} Lessons
                                 </option>
                              </select>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Actual Price">Actual Price</p>
                              <input readonly class="form-control" value="{{packagePrice}}">
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" *ngIf="discountType == 'Percent'">Discount Percent</p>
                              <p class="cc-color" *ngIf="discountType == 'Value'">Discount Value</p>
                              <input readonly class="form-control" value={{discountPrice}}>
                           </div>
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Final Price">Final Price</p>
                              <input readonly class="form-control" value={{packageFinalPriceBeforeDiscount}}>
                           </div>
                        </div>

                        <div class="row"
                           *ngIf="selectedPackage && selectedPackage.batches_active && selectedPackage.batches_active.length > 0">
                           
                           <p><small>Select available batch to attend class</small></p>

                           <div class="col-md-12 mb-10" *ngFor="let batch of selectedPackage.batches_active">
                              <label class="control control--checkbox" *ngIf="batch.seats_left > 0">
                                 <input value="{{batch.id}}" type="checkbox" (click)="totalSelected($event)"
                                    class="batchCheckbox">
                                 <div class="control__indicator">
                                 </div>&nbsp;<span class="greyColor">{{batch.class_name}}</span>
                                 <small class="greyColor">
                                    <p><span trans="Class Time">Class Time</span>: {{batch.time_display}}</p>
                                    <p><span trans="Class Days">Class Days</span>: {{batch.class_days}}</p>
                                    <p><span trans="Class Start Date">Class Start Date</span>: {{batch.start_date}}</p>
                                    <!-- <p>End Date: {{batch.end_date}}</p> -->
                                    <p color="red"><span trans="Seats Left">Seats Left</span>: {{batch.seats_left}}
                                       <span trans="Only">Only</span></p>
                                 </small>
                              </label>
                           </div>
                        </div>
                        <div class="InstantBookingFooter-CL text-left">
                           <div class="row">
                              <div *ngIf="adminFirstPackageDiscount>0">
                                 <p *ngIf="trailPackageAccess =='yes'" class="fontbold text-center"><span
                                       trans="Trail Package Discount">Trail Package Discount</span> :
                                    {{adminFirstPackageDiscount}}% </p>
                              </div>
                              <div *ngIf="adminPackageWholeDiscount>0">
                                 <p *ngIf="trailPackageAccess =='no'" class="fontbold text-center"><span
                                       trans="Package Discount">Package Discount</span> : {{adminPackageWholeDiscount}}%
                                 </p>
                              </div>
                           </div>
                           <div class="row">
                              <p class="fontbold text-center">                                 
                                 <span trans="Total to pay :">Total to pay :</span> {{selectedCurrency.symbol}}
                                 {{packageFinalPrice}}
                              </p>
                           </div>
                           <div class="row">
                              <div *ngIf="currentuser.isLoggedIn()" class="col-sm-12 text-center p-10 TT-formGroup">
                                 <button (click)="packageCheckOut()" href="#modalPayment" data-target="#modalPayment"
                                    data-toggle="modal" id="groupBtnCheckOut" type="button" disabled
                                    class="btn TT-primaryBTN" trans="Complete Booking" style="width:auto;">Complete Booking</button>
                              </div>
                              <div *ngIf="!currentuser.isLoggedIn()" class="col-sm-12 text-center p-10 TT-formGroup">
                                 <button (click)="packageCheckOut()" id="groupBtnCheckOut" type="button" disabled
                                    class="btn TT-primaryBTN" trans="Complete Booking" style="width:auto;">Complete Booking</button>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<a #openLoginModal data-toggle="modal" data-target="#loginModal"></a>
<app-login></app-login>