import { ChangeDetectionStrategy , Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LessonsService } from '../lessons.service';
import { ActivatedRoute } from '@angular/router';
import { DatatableService } from '@core/datatable/datatable.service';
import { TransactionLog } from '@core/types/models/TransactionLog';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.css'] ,
  providers: [DatatableService],
})
export class CompletedComponent implements OnInit {
  @ViewChild('closeCompliantModal') closeCompliantModal: ElementRef;
  public form = this.fb.group({
        tutor: null,
  });
  public completed$ = this.datatable.data$;
  constructor(private lessonService: LessonsService,
              private route: ActivatedRoute,
              public datatable: DatatableService<TransactionLog>,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,) {
  this.lessonService.sharedtutorName.subscribe(name=>this.selectedUserName = name);
      this.route.params.subscribe( params =>{
        this.selectedTutor = params['id'];
        if(this.selectedTutor){
          this.selectedTutor = params['id'];
          this.setTutorId(this.selectedTutor);
        }else{
          this.setTutorId('');
        }
      });
  }
  public updateStatusForm = this.fb.group({
      description: ['', Validators.required],
    });
  public errorMessages = {
           description :[{ type:'required',message:'Description is required'}],
       }
  get description(){ return this.updateStatusForm.get('description');}
  public completedClasses;
  public rating;
  public review;
  public selectedTutor;
  public selectedUserName;
  public operation;
  public selectedBookedDateID;
  public bookingId;
  public tutorID;
  public schedules;

  ngOnInit(): void {
        this.datatable.init({
            uri: LessonsService.COMPLETED
        });
  }

  public setTutorId(id){
   this.selectedTutor = id;
   this.datatable.addFilter('tutor',id);
  }

  public closeComplaintModal(){
    $('#complaintDescription').val('');
  }

  public responseHandle(response){
    let data =  JSON.parse(atob(response['data']));
      this.completedClasses = data['completedClasses'];
  }
  public viewFeedBack(rating , review){
   this.rating = rating;
   this.review = review;
  }

  public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   if ( typeof operation === 'undefined' ) {
     this.operation = 'refund';
   } else {
     this.operation = operation;
   }
  }

    public viewScheduleDetails(id , dateId){
    this.router.navigate(['student/lessons/board',{id:id , dId :dateId}]);
  }

  public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closeCompliantModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}
