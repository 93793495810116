import { ChangeDetectionStrategy  , Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LessonsService } from '../lessons.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ScheduleModalComponent } from '../schedule-modal/schedule-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DatatableService } from '@core/datatable/datatable.service';
import { TransactionLog } from '@core/types/models/TransactionLog';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { MessagemodalComponent } from '../../findtutor/messagemodal/messagemodal.component';
declare var $;

@Component({
  selector: 'app-overdue',
  templateUrl: './overdue.component.html',
  styleUrls: ['./overdue.component.css'] ,
  providers: [DatatableService],
})
export class OverdueComponent implements OnInit {
 
 @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
 @ViewChild('closerequestCancellation') closerequestCancellation: ElementRef;
 @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
 @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
 @ViewChild('closeViewModal') closeViewModal: ElementRef;

    public ratingForm = this.fb.group({
        review:['',[Validators.required]]
    });
  public overDue;
  public overDueClass$ = this.datatable.data$;
  public selectedTutor;
  public ratingGiven;
  public selectedTutorIDForRating;
  public selectedBookedDateIDForRating;
  public selectedBookedDateID;
  public bookingId;
  public tutorID;
  public studentID;
  public operation;
  public studentBookings;
  public tutorAvailability;
  public selectedUserName;
  public timezone;
  public selectedTutorProfile:string;
  public sendAsNotification = 'Yes';
    public updateCancellationForm = this.fb.group({
    cancel_description: ['', Validators.required],
    });

    public updateStatusForm = this.fb.group({
        description: ['', Validators.required],
        });
    public errorMessages = {
           description :[{ type:'required',message:'Description is required'}],
           review :[ { type:'required',message:'Review is required'}],
           commentValue :[{type:'required',message:'Comment is required'}],
           cancel_description :[{ type:'required',message:'Description is required'}],
       }
    get description(){ return this.updateStatusForm.get('description');}
    get review(){ return this.ratingForm.get('review');}
       
  constructor(private lessonService: LessonsService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              public datatable: DatatableService<TransactionLog>,
              private localetimezone: LocaleTimeZoneTS) { 
  this.lessonService.sharedtutorName.subscribe(name=>this.selectedUserName = name);
      this.route.params.subscribe( params =>{
        this.selectedTutor = params['id'];
        if(this.selectedTutor){
          this.selectedTutor = params['id'];
          this.setTutorId(this.selectedTutor);
        }else{
          this.setTutorId('');
        }
      });
  this.timezone = this.localetimezone.timezone();
}

  ngOnInit(): void {
        this.datatable.init({
            uri: LessonsService.OVERDUE
        });   
  }

    public setTutorId(id){
   this.selectedTutor = id;
   this.datatable.addFilter('tutor',id);
  }

    //Raise cancellation
  public requestCancellation(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   this.operation = operation;
  }

  public messageModal(resume, userData , bookingDetail , notificationSUbject){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    Object.assign(user,{bookingDateId:bookingDetail});
    this.messageChild.userData(resume, user);
  }


    public responseHandle(response){
          let result = JSON.parse(atob(response['overDueSchedules']));
          this.overDue =  result;
  }

    public closeRating(){
    this.ratingForm.reset();
    this.closeRatingModal.nativeElement.click();
  }

  public rateTutor(value){
   this.ratingGiven = value;
   this.ratingForm.value.rating = value;
  }
   //Mark as done
    public bookingDateId(id , tutorId , tutorProfile){
   this.selectedBookedDateIDForRating = id;
   this.selectedTutorIDForRating = tutorId;
   this.selectedTutorProfile = tutorProfile;
  }

  public submitRating(reBook = true){
   this.ratingForm.markAllAsTouched();
   if(this.ratingForm.valid){
   this.ratingForm.value.selectedBookedDateIDForRating = this.selectedBookedDateIDForRating;
   this.ratingForm.value.selectedTutorIDForRating = this.selectedTutorIDForRating;
   this.lessonService.rateTutor(this.ratingForm.value).subscribe(response => {
     if(response.status == 'success'){
      console.log(response)
      this.toastr.success('Rated Successfully');
     (reBook == true) ? this.router.navigate(['student/profile/'+this.selectedTutorProfile]) : '';
        this.datatable.init({
            uri: LessonsService.OVERDUE
        });  
      this.closeRating();
    }
   });
 }
  }

 public reScheduleRequest(id , data){
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          data.booking_details.overDueReschedule = 'overDueReschedule';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings , packageDetails , data);
    });
 }

  // This Method gets the Reschedule userData
  public reschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
         let responseData =  JSON.parse(atob(response['data']));
         this.overDue = responseData['overDueSchedules'];
        }
    })
  }

   public rescheduledDates(data){
     this.datatable.setData(data.pagination.data);
   }

   public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closeViewModal.nativeElement.click();
        this.closeModals();
        this.toastr.success('Record Updated Successfully');
        let responseData =  JSON.parse(atob(response['data']));
         this.overDue = responseData['overDueSchedules'];
        }
    })
  }

    public raiseCancellation(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {tutor_id: this.tutorID});
    Object.assign(data, {operation: operation});
    $("#cancel_description").val('');
    if ( operation == 'raisecancellation') {
      Object.assign(data, {cancel_description: this.updateCancellationForm.get('cancel_description').value});
    }
    this.lessonService.raiseTutorCancellation(data)
    .subscribe(response => {
        this.closerequestCancellation.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
      if(response.status == 'success'){
        //  let responseData =  JSON.parse(atob(response['data']));
        //  this.schedules = responseData['upcomingClasses'];
        }
    })
  }

  public closeModals(){
    this.updateStatusForm.reset();
    this.updateCancellationForm.reset();
    $("#compliantBtn").prop('disabled',true);
    $("#cancelBtn").prop('disabled',true);
  }

  public messageText(value){
    if(value){
      $("#cancelBtn").prop('disabled',false);
    }else{
       $("#cancelBtn").prop('disabled',true);
    } 
  }

    public compliantComment(value){
    if(value){
      $("#compliantBtn").prop('disabled',false);
    }else{
       $("#compliantBtn").prop('disabled',true);
    } 
  }
  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  

  
}
