<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">

   <div class="TT-schedule clearfix">
      <h4 trans="Cancelled Bookings" class="TT-tableTime pull-left"> Cancelled Bookings
         <span *ngIf="selectedUserName"> with {{selectedUserName}} </span>
      </h4>
   </div>
   <div class="table-responsive TT-responsiveTable">
      <table class="table table-striped TT-table">
         <thead>
            <tr>
               <th trans="Subject">Subject</th>
               <th trans="Tutor">Tutor</th>
               <th trans="Booking Type">Booking Type</th>
               <th trans="Booked Timings">Booked Timings</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngIf="(cancelled | async).length == 0">
               <td class="text_center" colspan="5" align="center" trans="No Records Found">No Records Found
               </td>
            </tr>
            <tr *ngFor="let request of cancelled | async" id="{{request.booking_id}}">
               <td>{{request.subject.subject_name}}</td>
               <td *ngIf="request.tutor_resume">{{request.tutor_resume.display_name}}</td>
               <td *ngIf="!request.tutor_resume">-</td>
               <td>{{transform(request.booking_type)}}</td>
               <td *ngIf="request.booking_dates_rejected.length != 0" class="TT-formGroup">
                  <button (click)="viewDetails(request.booking_dates_rejected , request)" href="#viewDetails"
                     data-target="#viewDetails" data-toggle="modal" type="button" class="TT-primaryBTN"
                     value="Attend" trans="View" style="width: auto;padding: 8px 40px;">View</button>
               </td>
               <td *ngIf="request.booking_dates_rejected.length == 0">{{request.request_status}}</td>
            </tr>
         </tbody>
      </table>
   </div>
   
   <div class="custom-padding">
      <app-datatable-footer-new *ngIf="(cancelled | async).length > 0"></app-datatable-footer-new>
      <span type="button" #open href="#viewDetails" data-target="#viewDetails" data-toggle="modal"></span>
   </div>
</div>
<div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details</h5>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body pl-0 mx_scroll">
               <div class="container-fluid whiteBg pl-0">
                  <div class="tab-content">
                     <table *ngIf="Timings" class="table lesson-table">
                        <tr>
                           <th trans="Date"><b>Date</b></th>
                           <th trans="Timings"><b>Timings</b></th>
                           <th trans="status"><b>Status</b></th>
                           <th trans="action"><b>Action</b></th>
                        </tr>
                        <tr *ngFor="let time of Timings">
                           <td>{{time.slot_date | date : 'MM-dd-yyyy'}}</td>
                           <td>{{time.booked_time}}</td>
                           <td class="white-space">{{transform(time.class_status)}}</td>
                           <td class="pOinter"
                              (click)="viewScheduleDetails(selectedBooking.booking_id , time.booking_dates_id)"
                              trans="Lessons Board">
                              <div class="TT-formGroup">
                                 <button class="TT-primaryBTN" style="width: auto;">Lessons Board</button>
                              </div>
                             </td>
                              
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>