import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ActivationStart } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxPayPalModule } from 'ngx-paypal';
import { StudentRoutingModule } from '@student/student-routing.module';
import { StudentComponent } from '@student/student.component';
import { StudentHmComponent } from '@student/student-hm/student-hm.component';
import { StudentSidenavComponent } from '@student/student-sidenav/student-sidenav.component';
import { FindtutorComponent } from '@student/findtutor/findtutor.component';
import { DemoVideoComponent } from '@student/findtutor/demo-video/demo-video.component';
import { ScheduleComponent } from '@student/findtutor/schedule/schedule.component';
import { MessagemodalComponent } from '@student/findtutor/messagemodal/messagemodal.component';
import { FilterComponent } from '@student/findtutor/filter/filter.component';
import { PriceselectorComponent } from '@student/findtutor/priceselector/priceselector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AthandModule } from '@athand/athand.module';
import { CheckoutComponent } from '@student/findtutor/checkout/checkout.component';
import { LessonsComponent } from '@student/lessons/lessons.component';
import { ScheduledComponent } from '@student/lessons/scheduled/scheduled.component';
import { PendingComponent } from '@student/lessons/pending/pending.component';
import { CompletedComponent } from '@student/lessons/completed/completed.component';
import { CancelledComponent } from '@student/lessons/cancelled/cancelled.component';
import { LessonsSidenavComponent } from '@student/lessons/lessons-sidenav/lessons-sidenav.component';
import { StudentPaymentComponent } from '@student/student-payment/student-payment.component';
import { PaymentSidenavComponent } from '@student/student-payment/payment-sidenav/payment-sidenav.component';
import { StudentTransactionsComponent } from '@student/student-payment/student-transactions/student-transactions.component';
import { NoteModalComponent } from '@student/lessons/note-modal/note-modal.component';
import { StudentChatComponent } from '@student/student-chat/student-chat.component';
import { ChatModule } from '@athand/chat/chat.module';
import { AccountComponent } from './account/account.component';
import { DetailsComponent } from '@student/lessons/scheduled/details/details.component';
import { ScheduleListComponent } from '@student/lessons/scheduled/schedule-list/schedule-list.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ScheduleslotsComponent } from '@student/findtutor/scheduleslots/scheduleslots.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { RatingModule } from 'ng-starrating';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { StripeModule } from "stripe-angular";
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslationsModule } from '../core/translations/translations.module';
import { StudentAlertsComponent } from '@student/student-alerts/student-alerts.component';
import { SuccessModalComponent } from '@student/findtutor/success-modal/success-modal.component';
import { ScheduleModalComponent } from '@student/lessons/schedule-modal/schedule-modal.component';
import { DatatableService } from '@core/datatable/datatable.service';
import { DatatableModule } from '@core/datatable/datatable.module';
import { TransactionFilterComponent } from '@student/student-payment/transaction-filter/transaction-filter.component';
import { OverdueComponent } from '@student/lessons/overdue/overdue.component';
import { ProfileSummaryComponent } from '@student/student-hm/profile-summary/profile-summary.component';
import { UpcomingLessonsComponent } from '@student/student-hm/upcoming-lessons/upcoming-lessons.component';
import { RecentAlertsComponent } from '@student/student-hm/recent-alerts/recent-alerts.component';
import { DownloadComponent } from '@student/student-hm/download/download.component';
import { RecentMessagesComponent } from '@student/student-hm/recent-messages/recent-messages.component';
import { RecentBookingsComponent } from '@student/student-hm/recent-bookings/recent-bookings.component';
import { HmPendingComponent } from '@student/student-hm/hm-pending/hm-pending.component';
import { DocumentationComponent } from '@student/student-hm/documentation/documentation.component';
import { BoardComponent } from '@student/lessons/board/board.component';
import { TutorFullProfileComponent } from '@student/findtutor/tutor-full-profile/tutor-full-profile.component';
import { GoTopComponent } from './findtutor/go-top/go-top.component';
import { TransactionFilterVerticalComponent } from './student-payment/transaction-filter-vertical/transaction-filter-vertical.component';
import { WebsiteModule } from 'app/website/website.module';
import { HeaderComponent } from './header/header.component';
import { StudentHeaderComponent } from './student-header/student-header.component';

@NgModule({
  declarations: [StudentComponent, 
                 StudentHmComponent, 
                 StudentSidenavComponent, 
                 FindtutorComponent, 
                 DemoVideoComponent, 
                 ScheduleComponent, 
                 MessagemodalComponent,
                 FilterComponent,
                 PriceselectorComponent, 
                 CheckoutComponent, 
                 LessonsComponent, 
                 ScheduledComponent, 
                 PendingComponent, 
                 CompletedComponent, 
                 CancelledComponent, 
                 LessonsSidenavComponent, 
                 StudentPaymentComponent, 
                 PaymentSidenavComponent, 
                 StudentTransactionsComponent, 
                 NoteModalComponent, 
                 StudentChatComponent, 
                 AccountComponent,
                 DetailsComponent, 
                 ScheduleListComponent,
                 ScheduleslotsComponent, 
                 StudentAlertsComponent, SuccessModalComponent, ScheduleModalComponent,
                 TransactionFilterComponent, OverdueComponent,
                 ProfileSummaryComponent,
                 UpcomingLessonsComponent, RecentAlertsComponent, DownloadComponent,
                 RecentMessagesComponent, RecentBookingsComponent, HmPendingComponent, DocumentationComponent, 
                 BoardComponent,
                 TutorFullProfileComponent,
                 GoTopComponent,
                 TransactionFilterVerticalComponent, HeaderComponent, StudentHeaderComponent],


  imports: [
  NgSelectModule,
  RatingModule,
  NgxSliderModule,
  TooltipModule,
  NgxPayPalModule,
  NgxDropzoneModule,
  NgMultiSelectDropDownModule,
    CommonModule,
    ChatModule,
    StudentRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AthandModule,
    TranslationsModule,
    DatatableModule,
    WebsiteModule,
    StripeModule.forRoot("")
  ],
  providers: [DatePipe,DatatableService],
  exports:[ScheduleModalComponent]
})
export class StudentModule { }
