import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { LessonsService } from '../../lessons/lessons.service';
import { ScheduleModalComponent } from '../../lessons/schedule-modal/schedule-modal.component';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-recent-bookings',
  templateUrl: './recent-bookings.component.html',
  styleUrls: ['./recent-bookings.component.css']
})
export class RecentBookingsComponent implements OnInit {
  
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  public recent_bookings;
  public recent_bookings_count = 0;
  public loading$ = new BehaviorSubject(false);
  public Timings;
  public selectedBookingId;
  public studentBookings;
  public timezone;
  public tutorAvailability;
  public cancellationId;
  @Input()
  public set recentBookingDetails(value){
    this.recent_bookings = value;
    this.recent_bookings_count = this.recent_bookings?.length;
    console.log(this.recent_bookings_count)
  }

  @Output() messageModalData: EventEmitter<{resume:string, userData:string , bookingDetail:string , notificationSUbject:string}> = new EventEmitter();

  constructor(private router: Router,
              private lessonService: LessonsService) { }

  ngOnInit(): void {
  }

 public viewLessons(){
    this.router.navigate(['student/lessons/scheduled/sch']);
  }

  public cancelCompleteBooking(){
    this.loading$.next(true);
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {from: 'studentDashboard'});
    this.lessonService.cancelDirectBooking(data).subscribe(response => {
       this.recent_bookings = response;
       this.closeCancellation.nativeElement.click();
       this.loading$.next(false);
    });
  }

 public directCancelation(bookingID){
    this.cancellationId = bookingID;
  }

    // Recent Booking 
  public viewDetails(dates ,bookingID){
    this.Timings = dates;
    this.selectedBookingId = bookingID;
  }

  public closeViewDetailsModal(){
   this.Timings='';
   this.selectedBookingId='';
   this.closeViewDeatailsModal.nativeElement.click();
   // this.closeViewRescheduleDeatailsModal.nativeElement.click();
  }


   public rescheduledDates(data){
    let responseData =  JSON.parse(atob(data['data']));
    this.recent_bookings = responseData['pendingApproval'];
  }

    public messageModal(resume, userData , bookingDetail , notificationSUbject , idName="bookingDate"){
    // (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageModalData.emit({resume:resume, userData:userData , bookingDetail:bookingDetail , notificationSUbject:notificationSUbject});
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }

  public humanize(str) {
    if ( ! str ) {
      return;
    }
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
}
