            <div class="row">               
               <div class="col-sm-12 ">
                  <div class="inner-container">
                     <div class="inner-container">
                        <ul class="TT-listOrders" *ngIf="recentNews$ && recentNews$.length > 0">
                           <li class="TT-notify" *ngFor="let news of recentNews$;" >
                              <span class="TT-headlineList" (click)="more(news.page_route)">{{news.title}}</span>
                           </li>

                        </ul>
                        <div *ngIf="recentNews$ && recentNews$.length > 0" (click)="more(recentNews$['0'].page_route)" class="col-sm-12 pull-right text-right smallText">
                           <a class="TT-frameSet" trans="More >>>">More >>></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>