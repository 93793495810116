import { Component, OnInit, ViewChild, ElementRef, Output, Input , EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CheckoutComponent } from '../checkout/checkout.component';
import { LocaleCurrency } from '@auth/localecurrency';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { Currencies } from '@auth/currencies';
import { CurrentUser } from '@auth/current-user';
import { FindTutor } from '../findtutor.service';
import { Settings } from '@core/config/settings.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
declare var $;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  
  @Input() 
  public set removeScheduleData(value){
    this.clearModalData();
  }

  @Output() clearScheduleModalData: EventEmitter<any> = new EventEmitter();
  @Input() lessonsDataSeleted:[];
  @ViewChild('closeScheduleModal') closeScheduleModal: ElementRef;
  @ViewChild('newSchedule') newSchedule: ElementRef;
  @Output() checkOutData: EventEmitter<any> = new EventEmitter();
  @ViewChild('openLoginModal') openLoginModal: ElementRef;
  public tutorResume;
  public tutor_subject;
  public offsetTmz=[];
  public availability;
  public packages;
  public monthValues = [10,11,12];
  public packagePrice;
  public discountPrice;
  public discountType = 'Value';
  public discountPercent;
  public packageFinalPrice;
  public name;
  public currentDate = new Date(this.localetimezone.date());
  public startDay;
  public FirstDay;
  public SecondDay;
  public ThirdDay;
  public FouthDay;
  public FifthDay;
  public SixthDay;
  public SeventhDay;
  public month1;
  public month2;
  public month = new Array();
  public nextMonthValues = [1,2,3,4,5,6,7];
  public FirstDate;
  public SecondDate;
  public ThirdDate;
  public FourthDate;
  public FifthDate;
  public packageFinalPriceBeforeDiscount;
  public SixthDate;
  public SeventhDate;
  public FirstDateTimings = new Array();
  public SecondDateTimings;
  public ThirdDateTimings;
  public FourthDateTimings;
  public FifthDateTimings;
  public SixthDateTimings;
  public adminDiscount;
  public SeventhDateTimings;
  public selectedLessons=0;
  public hourlyPrice;
  public totalPrice;
  public userDetails;
  public currentYear =  this.currentDate.getFullYear();
  public monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
  public nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
  public lessonsData = Object();
  public selectedSubject;
  public selectedCurrency;
  public object = new Array();
  public popularCurrencies;
  public selectedPackage;
  public bookedTimings;
  public month1BookedTimings = new Array();
  public timezone;
  public revDate;
  public scheduleLoader:boolean = false;
  public time1;
  public weekFirstDate;
  public weekSecondDate;
  public weekThirdDate;
  public weekFourthDate;
  public weekFifthDate;
  public weekSixthDate;
  public weekSeventhDate;
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public studentBookings;
  public lessonType;
  public adminWholeDiscount: any;
  public adminFirstLessonDiscount: any;
  public discountedPrice: any;
  public trailPackageAccess;
  public adminPackageWholeDiscount;
  public adminFirstPackageDiscount;
  public discountedPackagePrice;
  public GBP;
  public discountedPercentage:any;
  public mainPrice;
  public techingSubjects:any;

  public bookingType = 'schedule';
  public bookingType_title = 'One to One';
  public packageType = 'package';
  public group_classes = [];
  public total_batches_selected = 0;
  public selected_batches = [];

  constructor(public localecurrency: LocaleCurrency,
              public currencies: Currencies,
              public currentuser: CurrentUser,
              private localetimezone: LocaleTimeZoneTS,
              public service: FindTutor,
              public settings: Settings,
              private toastr: ToastrService,
              private router: Router) {

  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.selectedCurrency = changeCurrency );
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.time1 = changeTime);
  this.timezone = this.localetimezone.timezone();
           }

  ngOnInit(): void {
    $(".BtnCheckOut").prop("disabled", true);
    this.adminWholeDiscount = this.settings.get('admin_discount');
    this.adminFirstLessonDiscount = this.settings.get('first_lesson_discount');
    this.adminPackageWholeDiscount = this.settings.get('package_admin_discount');
    this.adminFirstPackageDiscount = this.settings.get('package_first_lesson_discount');
    this.localetimezone.time();
    this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });
  }

  // Init Availability Data
  public availabilityData(data , bookings, lessonType){
    this.lessonType = lessonType;
    this.currentDate = new Date(this.localetimezone.date());
    this.availability = data;
    this.trailPackageAccess = data.trailPackageAccess; 
    this.calenderDays();
    this.calenderDates();
    this.studentBookings = bookings;
  }

 
  // Booked Dates
  public bookedDates(bookedDates){
    this.bookedTimings = bookedDates;
  }
 

  // Provides User data
  public userData(user){
    this.userDetails = user;
  }

  // Provides Tutor Subject
  public tutorSubject(tutor_subject){
    this.tutor_subject = tutor_subject;
  }


  // Provides Tutor Resume
  public userResume(resume){
    this.hourlyPrice = 0;
    if ( ! resume ) {
      return;
    }
    let price = resume.hourly_rate;
        
    if ( this.bookingType == 'group') {
      if ( this.tutor_subject.hourly_rate_group) {
        price = this.tutor_subject.hourly_rate_group;
        resume.mainHourlyRate = price;
      }
      if ( this.tutor_subject.hourly_rate_GBP_group) {
        resume.hourly_rate_GBP = this.tutor_subject.hourly_rate_GBP_group;
      }
    } else {
      if ( this.tutor_subject.hourly_rate) {
        price = this.tutor_subject.hourly_rate;
        resume.mainHourlyRate = price;
      }
      if ( this.tutor_subject.hourly_rate_GBP) {
        resume.hourly_rate_GBP = this.tutor_subject.hourly_rate_GBP;
      }
    }

    this.hourlyPrice = (Math.round(price * 100) / 100).toFixed(2);
    this.tutorResume = resume;
    this.name = this.tutorResume.display_name;
  }


  // Subject details
  public subjectId(subject){
    this.selectedSubject = subject;
  }

  public subjects(subjects){
    this.techingSubjects = subjects;
  }


 

  // This Methods gets the calenderDayes
  public calenderDays(){
  this.month[0] = "Jan";
  this.month[1] = "Feb";
  this.month[2] = "Mar";
  this.month[3] = "Apr";
  this.month[4] = "May";
  this.month[5] = "June";
  this.month[6] = "July";
  this.month[7] = "Aug";
  this.month[8] = "Sep";
  this.month[9] = "Oct";
  this.month[10] = "Nov";
  this.month[11] = "Dec";

  this.month1 = this.currentDate.getMonth();
  this.month1 = this.month[this.month1];
  if(this.bookedTimings){
  this.bookedTimings.find(x=>{
   this.month1BookedTimings.push(x);
  });
}
  this.calenderWeeklyDates();
  }
 


  // This method gets the Calender Dates
  public calenderDates(){

    this.weekFirstDate = this.currentDate;
 if(this.monthLastDate.getDate() == this.FirstDate){
    this.FirstDate = this.nextMonth.getDate() + 6;
    this.revDate = this.currentDate;
    }else{
    this.FirstDate = this.currentDate.getDate();
    this.revDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
  }
 
  if(this.monthLastDate.getDate() == this.SecondDate){
    this.SecondDate = this.nextMonth.getDate() + 6;
    this.weekSecondDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
    this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
      }  
    }

  if(this.monthLastDate.getDate() == this.ThirdDate){
    this.ThirdDate = this.nextMonth.getDate() + 6;
    this.weekThirdDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FourthDate){
   this.FourthDate = this.nextMonth.getDate() + 6;
    this.weekFourthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FifthDate){
    this.FifthDate = this.nextMonth.getDate() + 6;
    this.weekFifthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  }
 
  if(this.monthLastDate.getDate() == this.SixthDate){
    this.SixthDate = this.nextMonth.getDate() + 6;
    this.weekSixthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
   }else{
    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   }

   if(this.monthLastDate.getDate() == this.SeventhDate){
    this.SeventhDate = this.nextMonth.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
    this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
    }
  }
 

  // Forward Calender Dates
  public forwardDate(){
    this.scheduleLoader = true;
    this.monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
    this.nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.calenderDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }

  public availabilityWeeklyData(){
      let availabilityData = Object();
      let pusheditems = {};
      pusheditems[this.days[this.weekFirstDate.getDay()]] = this.currentYear + "-" + this.sliceValue(this.weekFirstDate.getMonth()+1) + "-" + this.sliceValue(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + this.sliceValue(this.weekSecondDate.getMonth()+1) + "-" + this.sliceValue(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + this.sliceValue(this.weekThirdDate.getMonth()+1) + "-" + this.sliceValue(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + this.sliceValue(this.weekFourthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + this.sliceValue(this.weekFifthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + this.sliceValue(this.weekSixthDate.getMonth()+1) + "-" + this.sliceValue(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + this.sliceValue(this.weekSeventhDate.getMonth()+1) + "-" + this.sliceValue(this.weekSeventhDate.getDate());


    Object.assign(availabilityData , {availabilityInfo: pusheditems});
    Object.assign(availabilityData , {tutorId: this.userDetails.id});
    Object.assign(availabilityData , {timezone: this.timezone});
    $('#forwardDate').prop('disabled' , true);
    $('#reverseDate').prop('disabled' , true);
    this.service.getWeeklyAvailability(availabilityData).subscribe(response => {
      let respnose = JSON.parse(atob(response['data']));
      this.availability = respnose;
      this.calenderWeeklyDates();
      $('#forwardDate').prop('disabled' , false);
      $('#reverseDate').prop('disabled' , false);
      this.scheduleLoader = false;
    });
  }

 public sliceValue(month){
 return ('0' + month).slice(-2)
 }
 

  // Reverse Calender Dated
  public reverseDate(){
    this.scheduleLoader = true;
  if(this.nextMonthValues.includes(this.FirstDate)){
    this.monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() , 0);
  }
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
    this.calenderReverseDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }

getMonthForDate(date){
 let month = date.getMonth();
 return this.month[month];
}

  public calenderReverseDates(){
    this.weekFirstDate = this.currentDate;
    this.FirstDate = this.currentDate.getDate();
    this.revDate = this.currentDate;
    this.weekFirstDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
 

    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
      this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));     
      }  

    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth())); 
  }


    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));   
  }

    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  
 

    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   


    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
     this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
   // console.log(this.weekFirstDate , this.weekSecondDate , this.weekThirdDate , this.weekFourthDate , this.weekFifthDate , this.weekSixthDate , this.weekSeventhDate);
  }

 
  // Reverse Button access
  public reverseButtonAccess(){
    let presentDate = new Date(this.localetimezone.date());
    if(this.revDate){
    if(this.revDate.getTime() > presentDate.getTime()){
      return true;
    }else{
      return false;
    }
   }
}

public dateSelection(date , timeValue , weekDate , lessonType = ''){
  // currency Cron Job is working but the currency is not updating live in frontend
  let data = Object();
  // Object.assign(data,{hourlyPrice_display:this.tutorResume.hourly_rate_original});
  // console.log (this.tutor_subject)
  Object.assign(data,{hourlyPrice_display:this.tutor_subject.hourly_rate});
  Object.assign(data,{user_id:this.userDetails.id});
  Object.assign(data,{selectedCurrency_code:this.selectedCurrency.code}); 
  Object.assign(data,{selectedCurrency_exchange_rate:this.selectedCurrency.exchange_rate}); 

  Object.assign(data,{selected_date:date});
  Object.assign(data,{timeValue:timeValue});
  Object.assign(data,{weekDate:weekDate});
  Object.assign(data,{bookingType:this.bookingType});

  Object.assign(data,{lessonsData:this.object});
  Object.assign(data,{selectedSubject:this.selectedSubject});
  Object.assign(data, {tutor_subject: this.tutor_subject});

  let hourly_rate_front = this.tutor_subject.hourly_rate;
  
  
  this.service.checkCurrencyChange(data)
      .subscribe(response => {

        this.changeBookingType(response.bookingType);
        if ( response.status == 'failed_hourly_rate' &&  hourly_rate_front != response.value ) {
          this.toastr.error(response.message);
          this.tutor_subject.hourly_rate = response.value;
          this.tutorSubject(this.tutor_subject);
          this.userResume(this.tutorResume)
          this.hourlyPrice = response.value;
        }

        if ( response.status == 'failed_exchange_rate' &&  this.selectedCurrency.exchange_rate != response.value) {
          this.toastr.error(response.message);
          this.selectedCurrency.exchange_rate = response.value;
        }

        this.selectedLessons = this.object.length;
        this.mainPrice = this.selectedLessons*this.hourlyPrice;
        let totalPrice = (Math.round((this.selectedLessons*this.hourlyPrice) * 100) / 100).toFixed(2);
        this.calculatePercentage(totalPrice , lessonType);
        if(this.totalPrice!=0){
          $(".BtnCheckOut").prop("disabled", false);
        }else{
          $(".BtnCheckOut").prop("disabled", true);
        }

      }, err => {
          this.toastr.error('Invalid Details');
      });

  let month = weekDate.getMonth();
   let monthName = this.month[month];
   let idBadge = date+'-'+timeValue+'-'+monthName;
  date = ('0' + date).slice(-2)
  if($("[id='" + idBadge + "']").hasClass('badge')){
     $("[id='" + idBadge + "']").removeClass('badge');
     this.object.splice(this.object.findIndex(slot => slot.date === ""+date && slot.time === timeValue && slot.month === monthName),1);
  }else{
    if(lessonType == 'trialLesson'){
     let slotDate = this.currentYear + "-" + (this.getMonth(month+1)) + "-" + date;
     $("[id='" + idBadge + "']").addClass('badge').siblings().removeClass('badge');
     this.object = new Array();
     this.object.push({date:date ,month: monthName ,  time:timeValue,slot_date: slotDate});
     }else{     
      let slotDate = this.currentYear + "-" + (this.getMonth(month+1)) + "-" + date;
     $("[id='" + idBadge + "']").addClass('badge');
     this.object.push({date:date ,month: monthName ,  time:timeValue,slot_date: slotDate});     
    }
  }
   this.selectedLessons = this.object.length;
   this.mainPrice = this.selectedLessons*this.hourlyPrice;
   let totalPrice = (Math.round((this.selectedLessons*this.hourlyPrice) * 100) / 100).toFixed(2);
   this.calculatePercentage(totalPrice , lessonType);
   if(this.totalPrice!=0){
     $(".BtnCheckOut").prop("disabled", false);
   }else{
     $(".BtnCheckOut").prop("disabled", true);
   }
}

public calculatePercentage(totalPrice , lessonType){
   if(lessonType == 'trialLesson'){
    if(this.adminFirstLessonDiscount){
        this.discountedPercentage = this.adminFirstLessonDiscount;
        this.discountedPrice = (Math.round(((this.adminFirstLessonDiscount / 100) * totalPrice) * 100) / 100).toFixed(2);
        this.totalPrice = totalPrice - this.discountedPrice;
        this.totalPrice = (Math.round(this.totalPrice * 100) / 100).toFixed(2);
    }else{
      this.totalPrice = totalPrice;
    }
   }else{
   if(this.adminWholeDiscount){
        this.discountedPercentage = this.adminWholeDiscount;
        this.discountedPrice = (Math.round(((this.adminWholeDiscount / 100) * totalPrice) * 100) / 100).toFixed(2);
        this.totalPrice = totalPrice - this.discountedPrice;
        this.totalPrice = (Math.round(this.totalPrice * 100) / 100).toFixed(2);
     }else{
      this.totalPrice = totalPrice;
    }
   }
}
 

  // Time Selection
  public selectDate(date , timeValue , weekDate){
    if(this.currentuser.isLoggedIn()){
    if(timeValue != 'Booked'){

      if(!this.checkstudentBookings(weekDate , timeValue)){
        if(this.lessonType == 'trialLesson'){
          this.dateSelection(date , timeValue , weekDate , this.lessonType);
        } else { 
          this.dateSelection(date , timeValue , weekDate);
        } 
      }
}
}else{
this.openLoginModal.nativeElement.click();
this.closeModal();
  }
}

public find(needle, haystack) {
    var results = [];
    var idx = haystack.indexOf(needle);
    while (idx != -1) {
        results.push(idx);
        idx = haystack.indexOf(needle, idx + 1);
    }
    return results;
}

public selectSubject(subject){
    let subjectSelected = this.techingSubjects.find(x=>x.active_subject_data.subject_name === subject);
    this.selectedSubject = subjectSelected;
    this.selectedSubject.id = subjectSelected.subject_id;

    this.hourlyPrice = subjectSelected.hourly_rate_GBP
    
    if (this.hourlyPrice) {
     this.mainPrice = this.selectedLessons*this.hourlyPrice;
     let totalPrice = (Math.round((this.selectedLessons*this.hourlyPrice) * 100) / 100).toFixed(2);
     this.calculatePercentage(totalPrice , 'schedule');
    }
    
  if(this.totalPrice!=0){
    $("#BtnCheckOutDetails").prop("disabled", false);
  }else{
    $("#BtnCheckOutDetails").prop("disabled", true);
  }

}

 
  public openCheckoutModal(){
    
    if(this.currentuser.isLoggedIn()){
      if(this.selectedSubject){
    let details = Object();
    Object.assign(details ,{user:this.userDetails.id });
    Object.assign(details ,{userDetails:this.userDetails});
    Object.assign(details ,{resume:this.tutorResume});
    Object.assign(details ,{totalPrice:this.totalPrice});
    Object.assign(details ,{mainPrice:this.mainPrice});
    Object.assign(details ,{discountedPrice:this.discountedPrice});
    Object.assign(details ,{discountedPercentage:this.discountedPercentage});
    Object.assign(details ,{selectedLessons:this.selectedLessons});
    Object.assign(details ,{hourlyRate:this.hourlyPrice});
    Object.assign(details ,{lessonsData:this.object});
    Object.assign(details ,{selectedSubject:this.selectedSubject.subject_name});
    Object.assign(details ,{subjectId:this.selectedSubject.id});
    Object.assign(details ,{bookingType:'schedule'});
    Object.assign(details ,{timezone:this.timezone});
    Object.assign(details ,{tutorTimeZone: this.availability.timeZone});
    Object.assign(details ,{lessonType:this.lessonType});
    Object.assign(details ,{selectedCurrency:this.selectedCurrency.code});
    Object.assign(details ,{tutor_subject:this.tutor_subject});
    this.checkOutData.emit(details);
    
    this.closeModal();
  }else{
    this.toastr.warning('Please Select Subject');
  }
  }else{
    this.openLoginModal.nativeElement.click();
    this.closeModal();
  }
  }

  public closeModal(){
    $('.buyPackage').removeClass('active');
    $('#Package').removeClass('active in');
    $('.bookSchedule').addClass('active');
    $('#Schedule').addClass('active in');
    this.closeScheduleModal.nativeElement.click();
  }

  // Clear All Added Data in closeModal
  public clearModalData(){
    this.closeModal();
    this.object = new Array();
    this.availability = '';
    this.clearScheduleModalData.emit(true);
    $('.TimeSlotAvailable-CL').removeClass('badge');
    $(".BtnCheckOut").prop("disabled", true);
    this.FirstDate = '';
    this.SecondDate = '';
    this.ThirdDate = '';
    this.FourthDate = '';
    this.FifthDate = '';
    this.SixthDate = '';
    this.SeventhDate = '';
    this.packagePrice = '';
    this.discountPrice = '';
    this.packageFinalPrice = '';
    this.lessonType = '';
    this.selectedLessons = 0;
    this.totalPrice = '';
    this.discountedPrice = '';
    this.packageFinalPriceBeforeDiscount = '';
    $('#selectSub').prop('selectedIndex',0);


  }

   
    // Package Currency Converter
   private convertPackageCurrencies(value){
     
      let poundExchnageRate = this.GBP.exchange_rate;
      let amount:any = value.replace(/\,/g,'');
      let currencyInDollars = amount/poundExchnageRate;
      let finalselectedCurrency = currencyInDollars * this.selectedCurrency.exchange_rate;
      let priceConverted = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
      return priceConverted;
  }

  public calenderWeeklyDates(){
   
  this.startDay = this.currentDate.getDay();
  
     switch(this.startDay){

       case 1:
        this.FirstDay   = 'Mon';
        this.SecondDay  = 'Tue';
        this.ThirdDay   = 'Wed';
        this.FouthDay   = 'Thu';
        this.FifthDay   = 'Fri';
        this.SixthDay   = 'Sat';
        this.SeventhDay = 'Sun';

        this.FirstDateTimings    = this.availability.monday;
        this.SecondDateTimings   = this.availability.tuesday;
        this.ThirdDateTimings    = this.availability.wednesday;
        this.FourthDateTimings   = this.availability.thursday;
        this.FifthDateTimings    = this.availability.friday;
        this.SixthDateTimings    = this.availability.saturday;
        this.SeventhDateTimings  = this.availability.sunday;
       break;

       case 2:
       this.FirstDay   = 'Tue';
       this.SecondDay  = 'Wed';
       this.ThirdDay   = 'Thu';
       this.FouthDay   = 'Fri';
       this.FifthDay   = 'Sat';
       this.SixthDay   = 'Sun';
       this.SeventhDay = 'Mon';

       this.FirstDateTimings    = this.availability.tuesday;
       this.SecondDateTimings   = this.availability.wednesday;
       this.ThirdDateTimings    = this.availability.thursday;
       this.FourthDateTimings   = this.availability.friday;
       this.FifthDateTimings    = this.availability.saturday;
       this.SixthDateTimings    = this.availability.sunday;
       this.SeventhDateTimings  = this.availability.monday;
       break;

       case 3:
       this.FirstDay   = 'Wed';
       this.SecondDay  = 'Thu';
       this.ThirdDay   = 'Fri';
       this.FouthDay   = 'Sat';
       this.FifthDay   = 'Sun';
       this.SixthDay   = 'Mon';
       this.SeventhDay = 'Tue';

       this.FirstDateTimings    = this.availability.wednesday;
       this.SecondDateTimings   = this.availability.thursday;
       this.ThirdDateTimings    = this.availability.friday;
       this.FourthDateTimings   = this.availability.saturday;
       this.FifthDateTimings    = this.availability.sunday;
       this.SixthDateTimings    = this.availability.monday;
       this.SeventhDateTimings  = this.availability.tuesday;
       break;

       case 4:
       this.FirstDay   = 'Thu';
       this.SecondDay  = 'Fri';
       this.ThirdDay   = 'Sat';
       this.FouthDay   = 'Sun';
       this.FifthDay   = 'Mon';
       this.SixthDay   = 'Tue';
       this.SeventhDay = 'Wed';

       this.FirstDateTimings    = this.availability.thursday;
       this.SecondDateTimings   = this.availability.friday;
       this.ThirdDateTimings    = this.availability.saturday;
       this.FourthDateTimings   = this.availability.sunday;
       this.FifthDateTimings    = this.availability.monday;
       this.SixthDateTimings    = this.availability.tuesday;
       this.SeventhDateTimings  = this.availability.wednesday;
       break;

       case 5:
       this.FirstDay   = 'Fri';
       this.SecondDay  = 'Sat';
       this.ThirdDay   = 'Sun';
       this.FouthDay   = 'Mon';
       this.FifthDay   = 'Tue';
       this.SixthDay   = 'Wed';
       this.SeventhDay = 'Thu';

       this.FirstDateTimings    = this.availability.friday;
       this.SecondDateTimings   = this.availability.saturday;
       this.ThirdDateTimings    = this.availability.sunday;
       this.FourthDateTimings   = this.availability.monday;
       this.FifthDateTimings    = this.availability.tuesday;
       this.SixthDateTimings    = this.availability.wednesday;
       this.SeventhDateTimings  = this.availability.thursday;
       break;

       case 6:
       this.FirstDay   = 'Sat';
       this.SecondDay  = 'Sun';
       this.ThirdDay   = 'Mon';
       this.FouthDay   = 'Tue';
       this.FifthDay   = 'Wed';
       this.SixthDay   = 'Thu';
       this.SeventhDay = 'Fri';

       this.FirstDateTimings    = this.availability.saturday;
       this.SecondDateTimings   = this.availability.sunday;
       this.ThirdDateTimings    = this.availability.monday;
       this.FourthDateTimings   = this.availability.tuesday;
       this.FifthDateTimings    = this.availability.wednesday;
       this.SixthDateTimings    = this.availability.thursday;
       this.SeventhDateTimings  = this.availability.friday;
       break;


       case 0:
       this.FirstDay   = 'Sun';
       this.SecondDay  = 'Mon';
       this.ThirdDay   = 'Tue';
       this.FouthDay   = 'Wed';
       this.FifthDay   = 'Thu';
       this.SixthDay   = 'Fri';
       this.SeventhDay = 'Sat';
       
       this.FirstDateTimings    = this.availability.sunday;
       this.SecondDateTimings   = this.availability.monday;
       this.ThirdDateTimings    = this.availability.tuesday;
       this.FourthDateTimings   = this.availability.wednesday;
       this.FifthDateTimings    = this.availability.thursday;
       this.SixthDateTimings    = this.availability.friday;
       this.SeventhDateTimings  = this.availability.saturday;
       break;
     }
  }




////////////////////////////////////// Package //////////////////////////////////////


  // Package Checkout
  public packageCheckOut(){
    
    if(this.currentuser.isLoggedIn()){
    let details = Object();
    Object.assign(details ,{userDetails:this.userDetails});
    Object.assign(details ,{user:this.userDetails.id});
    Object.assign(details ,{resume:this.tutorResume});
    Object.assign(details ,{packageLessons:this.selectedPackage.no_of_classes});
    Object.assign(details ,{totalPrice:this.packageFinalPrice});
    Object.assign(details ,{discountedPrice:this.discountPrice});
    Object.assign(details ,{discountedPercentage:this.discountedPercentage});
    Object.assign(details ,{packagePrice:this.packageFinalPriceBeforeDiscount});
    Object.assign(details ,{hourlyRate:this.hourlyPrice});
    Object.assign(details ,{subjectId:this.selectedSubject.subject_id});
    Object.assign(details ,{selectedSubject:this.selectedSubject.subject_name});
    Object.assign(details ,{package:this.selectedPackage.id});
    Object.assign(details ,{bookingType:this.packageType});
    Object.assign(details ,{timezone:this.timezone});
    Object.assign(details ,{tutorTimeZone: this.availability.timeZone});
    Object.assign(details ,{lessonType:this.lessonType});
    Object.assign(details ,{adminDiscount:this.adminDiscount});
    Object.assign(details ,{selectedCurrency:this.selectedCurrency.code});
    Object.assign(details ,{mainPrice:this.packageFinalPriceBeforeDiscount});
    Object.assign(details ,{tutor_subject:this.tutor_subject});
    Object.assign(details ,{total_batches_selected:this.total_batches_selected});
    Object.assign(details ,{selected_batches:this.selected_batches});
    this.checkOutData.emit(details);
   console.log(this.checkOutData.emit(details))
    this.closeModal();
  }else{
    this.openLoginModal.nativeElement.click();
    this.closeModal();  
  }
  }


    // Provides Package Data
  public packagesData(data){
     this.packages = data;
  }

  // Provides Groupclasses Data
  public groupclassesData(data){
    // this.group_classes = data;
    if ( data ) {
      data.forEach(element => {
        if (element.batches_active.length > 0 ) {
          this.group_classes.push(element)
        }
      });
    }
  }

  public calculatePackagePercentage(packageFinalPrice , type){
   if(type == 'yes'){
    if(this.adminFirstPackageDiscount){
        this.discountedPercentage = this.adminFirstPackageDiscount;
        this.discountedPackagePrice = (Math.round(((this.adminFirstPackageDiscount / 100) * packageFinalPrice) * 100) / 100).toFixed(2);
        this.packageFinalPrice = packageFinalPrice - this.discountedPackagePrice;
        this.adminDiscount = this.adminFirstPackageDiscount;
    }else{
      this.packageFinalPrice = packageFinalPrice;
    }
   }else{
   if(this.adminPackageWholeDiscount){
        this.discountedPercentage = this.adminPackageWholeDiscount;
        this.discountedPackagePrice = (Math.round(((this.adminPackageWholeDiscount / 100) * packageFinalPrice) * 100) / 100).toFixed(2);
        this.packageFinalPrice = packageFinalPrice - this.discountedPackagePrice;
        this.adminDiscount = this.adminPackageWholeDiscount;
     }else{
      this.packageFinalPrice = packageFinalPrice;
    }
   }
   
   this.packageFinalPrice = (Math.round((this.packageFinalPrice) * 100) / 100).toFixed(2);
  }
 

  // Package Selection Method
  public selectpackage(packageSelected, packagetype){
    this.packageType = packagetype;
    if ( packagetype == 'group') {
      this.selectedPackage = this.group_classes.find(x=>(x.no_of_classes===packageSelected));      
    } else {
      this.selectedPackage = this.packages.find(x=>(x.no_of_classes===packageSelected));
    }
    console.log(this.selectedPackage)
     if(this.selectedPackage){
      if ( packagetype == 'group') {
        $("#groupBtnCheckOut").prop("disabled", true); // Let us enable it once they select the batch
      } else {
        $("#packageBtnCheckOut").prop("disabled", false);
      }
      this.packagePrice = this.convertPackageCurrencies(this.selectedPackage.price);
      
      this.discountType = this.selectedPackage.discount_type;
      if (this.selectedPackage.discount_type == 'Percent') {
        this.discountPercent = this.selectedPackage.discount_price;
        this.discountPrice = this.selectedPackage.discount_price;
      } else {
        this.discountPrice = this.convertPackageCurrencies(this.selectedPackage.discount_price);
      }
      let packageFinalPrice = this.convertPackageCurrencies(this.selectedPackage.final_price);
      this.packageFinalPriceBeforeDiscount = packageFinalPrice;
      this.calculatePackagePercentage(packageFinalPrice , this.trailPackageAccess);
     } else{
      if ( packagetype == 'group') {
        $("#groupBtnCheckOut").prop("disabled", true);
      } else {
        $("#packageBtnCheckOut").prop("disabled", true);
      }
     this.packagePrice='';
     this.discountPrice='';
     this.packageFinalPrice='';
     this.packageFinalPriceBeforeDiscount = '';
     this.total_batches_selected = 0;
      this.selected_batches = [];
      this.packageType = '';
      
     }
  }



  // This Method gives the exact Month Value
  public getMonth(month){
    if(!this.monthValues.includes(month)){
      month = ('0' + month).slice(-2);
      return month;
    }else{
      return month;
    }
  }


  public checkDate(date , time , weekDate){
    date = ('0' + date).slice(-2)
    return this.checkForBadge(date , time , weekDate);
  }

  public checkForBadge(date , time  , month){ 
        // && Object.keys(this.object).length === 0 && this.object.constructor === Object
    if(this.object.length>0){
      let timeAdded = this.object.filter(
          book => {
            if(book.date === ""+date && book.month === month && book.time === time){
             return book;
            }
          });
      if(timeAdded.length){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  public checkstudentBookings(date , time){
    let dateConverted = this.currentYear + "-" + this.sliceValue(date.getMonth()+1) + "-" + this.sliceValue(date.getDate());
    if(this.studentBookings){
      let timeAccess = this.studentBookings.filter(
         book =>{
          if(book.slot_date === dateConverted && book.booked_time === time){
            return book;
          }
         });
      if(timeAccess.length){
        return true;
      }
    }else{
      return false;
    }
  }

  public changeBookingType(bookingType) {

    if ( this.selectedLessons > 1 && bookingType == 'group' ) {
      if ( ! confirm('Group class will allow one class only. Do you want to procede?') ) {
        this.bookingType = 'schedule'
        return false;
      }
    }
    this.bookingType = bookingType
    if ( bookingType == 'group') {
      this.bookingType_title = 'Group';
    } else {
      this.bookingType_title = 'One to One'
    }
  }

  public checkgroupBooking(timeValue) {
    let parts = timeValue.split('-')   
    if ( parts.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  public totalSelected(obj) {
    let batch = obj.target.value;
    if ( obj.target.checked ) {
      this.total_batches_selected++
      this.selected_batches.push(batch)
    } else {
      if (this.total_batches_selected > 0) {
        this.total_batches_selected--
      }
      if ( this.selected_batches.indexOf(batch) !== -1 ) {
        this.selected_batches.splice(batch, 1);
      }
    }
    this.packageFinalPrice = this.packageFinalPriceBeforeDiscount * this.total_batches_selected;

    if (this.total_batches_selected > 0) {
      $("#groupBtnCheckOut").prop("disabled", false);
    } else {
      $("#groupBtnCheckOut").prop("disabled", true);
    }
  }

  public clearValues() {
    this.packagePrice='';
    this.discountPrice='';
    this.packageFinalPrice='';
    this.packageFinalPriceBeforeDiscount = '';
    this.total_batches_selected = 0;
    this.selected_batches = [];
    this.packageType = '';
    $('#selectPack').val('');
    $('#selectBatch').val('');

  }
}