import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  
  public recentNews$:any;
  public firstPageRoute:any;
  @Input()
  public set recentNews(value:any){
  	this.recentNews$ = value;
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }



  public more(route){
  	console.log(route);
    if(route){
    this.router.navigate(['student/more/'+route]);
    }else{
       this.router.navigate(['more']);
    }
  }

}
