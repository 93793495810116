<section id="tutorDashboard" class="pageSectionBg main" >
  <div class="container-fluid" id="dashboard-content">
    <div id="dashboardContainer">
      <div *ngIf="!isDataLoaded" class="preloading">
         <div class="wrap-preload">
           <div class="cssload-loader"></div>
         </div>
      </div>
      <div *ngIf="bookingCount > 0">
         <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
               <h2 class="TT-mailHeadlineTitle">Student Dashboard</h2> 
             </div>
             <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
              <app-profile-summary></app-profile-summary>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
               <app-recent-messages [recentMessageDetails]="recent_messages"></app-recent-messages>
             </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
              <app-recent-alerts [recentAlertsDetails]="recent_alerts"></app-recent-alerts>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
              <app-upcoming-lessons (messageModalData)="messageModal($event)" [upcoming]="upcoming_lessons"></app-upcoming-lessons>
           </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
              <app-recent-bookings (messageModalData)="messageModal($event)" [recentBookingDetails]="recent_bookings"></app-recent-bookings>
            </div>
            
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
              <app-hm-pending [pendingActionDetails]="pending_actions"></app-hm-pending>
            </div>            
         </div>
      </div>

      <div *ngIf="bookingCount == 0">
        <div class="row">
           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
             <h4 class="TT-mailHeadlineTitle">Start your own success story here</h4> 
             <form class="form-group">
               <div class="row">
                 <div class="col-xl-10 col-lg-10 col-md-10 col-sm-9 col-xs-9 TT-formGroup"> 
                  <input (input)="inputSubject($event.target.value)" class="TT-FieldGroup TT-SelectIcon selectionSubjectHm" type="text" placeholder="what do you want to learn today?" trans-placeholder="what-to-learn-today" />
                  <ul id="selectionSubjectHm" class="TT-resultsDrop results">
                     <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , 'selectionSubjectHm')">
                       <a>{{subject.subject_name}}</a>
                     </li>
                  </ul>
               </div>
               <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 TT-formGroup">
                 <button (click)="findTeacher()" disabled id="findATeacherBtnHm" class="TT-primaryBTN"> Find a Tutor </button>
                </div>
              </div>
            </form>  
         </div>
         <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
           <app-profile-summary></app-profile-summary>
         </div>
         <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
           <app-upcoming-lessons (messageModalData)="messageModal($event)" [upcomingNoBookings]="upcoming_lessons"></app-upcoming-lessons>
         </div>
         <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
          <app-recent-alerts [recentAlertsDetails]="recent_alerts"></app-recent-alerts>
         </div>
         <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 TT-customColumn">
          <app-recent-messages (messageModalData)="messageModal($event)" [recentMessageDetails]="recent_messages"></app-recent-messages>
         </div>
         <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 TT-customColumn">
          <app-hm-pending [pendingActionDetails]="pending_actions"></app-hm-pending>
         </div>
         <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
          <app-recent-bookings (messageModalData)="messageModal($event)" [recentBookingDetails]="recent_bookings"></app-recent-bookings>
         </div>
        </div>
       </div>
      </div>
     </div>
  </section>
<app-messagemodal></app-messagemodal>