<div class="TT-cardds">
  <div class="Table-Height TT-tableHeight">
   <h4 class="TT-title" trans="Pending Actions"> Pending Actions </h4>
   
   <table class="table TT-table TT-autoWidthTable">
      <thead>
         <tr>
            <th trans="Subject">Subject</th>
            <th trans="Tutor">Tutor</th>
            <th trans="Booking Type">Booking Type</th>
            <th trans="Booked Timings">Booked Timings</th>
            <th colspan="5" trans="Status" class="text-center">Status</th>
         </tr>
      </thead>
      <tr *ngIf="pending_actions_count == 0 && packageDates_count == 0">
         <td colspan="9" align="center" class="TT-notify TT-noRecorded">
            <span trans="No Records Found" class="TT-noRecords">
               <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found" class="img-responsive w-100 m-auto TT-noData" /> -->
               <div class="text-center">No Records Found</div>
              </span>
         </td>
      </tr>
      <tbody>
         <tr *ngFor="let request of pending_actions">
            <td>
               {{request.subject.subject_name}}
            </td>
            <td *ngIf="request.tutor_resume">
               {{request.tutor_resume.display_name}}
            </td>
            <td *ngIf="!request.tutor_resume">
               -
            </td>
            <td >{{transform(request.booking_type)}}</td>
            <td *ngIf="request.request_status == 'offered_new_date'">{{transform(request.request_status)}}</td>
            <td *ngIf="request.request_status != 'offered_new_date'">{{transform(request.status)}}</td>
            <td *ngIf="request.booking_type == 'schedule'" class="TT-formGroup">
              <button *ngIf="request.offered_new_date_count > '0' || request.tutor_request_reschedule_count > '0'"  type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(request)" href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal" trans="Request Details"> Request Details </button>
              <button *ngIf="request.status == 'tutor-requested-reschedule'" type="button" class="TT-primaryBTN" (click)="bookingReschedule(request)" href="#bookingReschedule" data-target="#bookingReschedule" data-toggle="modal" trans="Booking Reschedule"> Booking Reschedule </button>
            </td>
            <td *ngIf="request.booking_type == 'package'" class="TT-formGroup">
             <button  *ngIf="request.request_status == 'dates_assigned_for_package'"type="button" class="TT-primaryBTN" (click)="viewPackageDetails(request.packages_dates , request.booking_id)" href="#viewPackageDetails" data-target="#viewPackageDetails" data-toggle="modal" trans="Package Details">
              Package Details
             </button>
             <button *ngIf="request.offered_new_date_count > '0' || request.tutor_request_reschedule_count > '0'"  type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(request)" href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal" trans="Request Details">
              Request Details
             </button>
            </td>
         </tr>
     </tbody>
   </table>

   <div class="TT-innerCover">
     <div (click)="viewPendingActions()" class="TT-clickable">
       <a (click)="viewPendingActions()" class="TT-click" trans="Actions >>>"> Actions >>></a>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="viewPackageDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h4 class="modal-title TT-AccUsers" id="modalLabel" trans="Booking Details"> Booking Details</h4>
            <button type="button" class="close" #closeViewPackageDeatailsModal (click)="closeViewDetailsModal()" data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body p-0">
               
                     <div *ngIf="Timings" class="table-responsive">
                        <table class="table TT-table TT-autoWidthTable">
                           <thead>
                           <tr>
                              <th trans="Date">Date</th>
                              <th trans="Timings">Timings</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr *ngFor="let time of Timings">
                              <td>{{time.slot_date | date: 'MM-dd-yyyy'}}</td>
                              <td>{{time.booked_time}}</td>
                           </tr>
                          </tbody>  
                        </table>
                     </div>
                 
            </div>
            <div class="modal-footer">
               <div class="TT-formGroup">
                  <button (click)="acceptAssignedDatesForPackage()" type="button" class="TT-primaryBTN" trans="Accept Assigned Dates">Accept Assigned Dates</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="rescheduleDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <h4 class="modal-title TT-AccUsers" id="modalLabel" *ngIf="offeredNewDatesCount>'0'" trans="Offered New Date For Requested Reschedule">Offered New Date For Requested Reschedule</h4>
            <h4 class="modal-title TT-AccUsers" id="modalLabel" *ngIf="requestRescheduleCount>'0'" trans="Reschedule Request">Reschedule Request</h4>
            <button type="button" class="close" #closeViewDeatailsModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body p-0" *ngIf="offeredNewDatesCount>'0'">
                  <div class="table-responsive">
                     <table class="table TT-table TT-autoWidthTable">
                       <thead> 
                        <tr>
                           <th trans="Old Date">Old Date</th>
                           <th trans="Old Time">Old Time</th>
                           <th trans="New Offered Date">New Offered Date</th>
                           <th trans="New Offered Time">New Offered Time</th>
                           <th trans="Reject Reason">Reject Reason</th>
                           <th trans="Action">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let newDate of offeredNewDates">
                           <td>{{newDate.slot_date | date :'MM-dd-yyyy'}}</td>
                           <td>{{newDate.slot_time}}</td>
                           <td>{{newDate.new_slot_date | date : 'MM-dd-yyyy'}}</td>
                           <td>{{newDate.new_slot_time}}</td>
                           <td>{{transform(newDate.reject_reason)}}</td>
                           <td class="TT-formGroup">
                             <button (click)="acceptRescheduleRequest(newDate.reschedule_id, 'accept')" type="button" class="TT-primaryBTN" trans="Accept Request">Accept Request</button>
                             <button (click)="acceptRescheduleRequest(newDate.reschedule_id, 'rejectandcancel')" type="button" class="TT-primaryBTN" trans="Reject Request & Cancel">Reject Request & Cancel</button>
                           </td>
                          </tr>
                        </tbody>
                     </table>
                  </div>
            </div>
            <div class="modal-body" *ngIf="requestRescheduleCount>'0'" >
                  <div class="table-responsive">
                     <table class="table TT-table TT-autoWidthTable">
                        <thead>
                          <tr>
                           <th trans="Old Date"> Old Date </th>
                           <th trans="Old Time">Old Time </th>
                           <th trans="Request Date">Request Date</th>
                           <th trans="Request TIme">equest TIme</th>
                           <th trans="Note Given">Note Given</th>
                           <th colspan="2" trans="Action">Action</th>
                         </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let request of requestReschedule">
                           <td>{{request.related_booking_date.slot_date | date : 'MM-dd-yyyy'}}</td>
                           <td>{{request.related_booking_date.booked_time}}</td>
                           <td>{{request.slot_date | date:'MM-dd-yyyy'}}</td>
                           <td>{{request.slot_time}}</td>
                           <td>{{request.note}}</td>
                           <td class="TT-formGroup">
                              <button (click)="acceptRequestReschedule(request.reschedule_id, 'accept')" type="button" class="TT-primaryBTN" trans="accept_request">Accept Request</button>
                            </td>
                           <td class="TT-formGroup">
                              <button (click)="acceptRequestReschedule(request.reschedule_id, 'rejectandcancel')" type="button" class="TT-primaryBTN" trans="reject_request_cancel">Reject Request & Cancel</button>
                           </td>
                        </tr>
                        </tbody>
                     </table>
                  </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="bookingReschedule" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Reschedule Details">Reschedule Details</h5>
            <button type="button" class="close" #closebookingRescheduleModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body p-0">
               <div class="container-fluid whiteBg p-0">
                  <div class="tab-content">
                     <table class="table lesson-table">
                        <tr>
                           <th trans="Booked Date">Booked Date</th>
                           <th trans="Booked Time">Booked Time</th>
                           <th trans="Requested Date">Requested Date</th>
                           <th trans="Request Time">Request Time</th>
                        </tr>
                        <tr *ngFor="let reschedule of bookingRescheduleSelected">
                           <td>{{reschedule['related_booking_date'].slot_date | date : 'MM-dd-yyyy'}}</td>
                           <td>{{reschedule['related_booking_date'].booked_time}}</td>
                           <td>{{reschedule.slot_date | date: "MM-dd-yyyy"}}</td>
                           <td>{{reschedule.slot_time}}</td>
                        </tr>
                     </table>
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <div class="col-sm-12 text-center TT-formGroup">
                  <button (click)="acceptBookingReschedule('accept')" type="button" class="btn TT-primaryBTN" trans="Accept Reschedule Request">Accept Reschedule Request</button>
                  <button (click)="acceptBookingReschedule('rejectandcancel')" type="button" class="btn TT-primaryBTN custom_danger_btn" trans="Reject Reschedule Request">Reject Reschedule Request</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

