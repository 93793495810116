import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { CurrentUser } from '../../auth/current-user';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit { 

  constructor(public auth: AuthService,
    public currentuser: CurrentUser) {
      
    }

  ngOnInit(): void {
    
    $('.collapseToggle').on('click', function() {
      $(".tt-sideArea").toggleClass('sidebar--Collapse');
      $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');	
      $(".tt-sideArea li").click(function(){
        $(".tt-sideArea").removeClass("sidebar--Collapse");
      });
    });    
  }

}