<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
   <div class="TT-schedule clearfix">
      <h4 trans="Overdue Lessons" class="TT-tableTime pull-left"> Overdue Lessons
         <span *ngIf="selectedUserName"> with {{selectedUserName}} </span>
      </h4>
   </div>

   <div class="table-responsive TT-responsiveTable">
      <table class="table lesson-table table-striped TT-table">
         <thead>
         <tr>
            <th trans="Subject">Subject</th>
            <th trans="Tutor">Tutor</th>
            <th trans="Slot Date">Slot Date</th>
            <th trans="Slot Time">Slot Time</th>
            <th trans="Slot Type">Slot Type</th>
            <th trans="My attendence">My attendence</th>
            <th trans="Tutor Attendence">Tutor Attendence</th>
            <th trans="Status">Status</th>
            <th trans="Actions" colspan="2">Actions</th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let request of overDueClass$ | async">
            <td>
               {{request.booking_details.subject.subject_name}}
            </td>
            <td>
               {{request.booking_details.tutor_resume.display_name}}
            </td>
            <td>{{request.slot_date | date:'MM-dd-yyyy'}}</td>
            <td>{{request.booked_time}}</td>
            <td>{{transform(request.booking_details.booking_type)}}</td>
            <td>{{transform(request.student_attendence)}}</td>
            <td>{{transform(request.tutor_attendence)}}</td>
            <td>{{transform(request.booking_dates_status)}}</td>
            <td class="lesson-btn-actions dropdown" class="lesson-btn-actions dropdown" *ngIf="request.status != '5'">
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li *ngIf="request.over_due_status =='reschedule-cancellation'  && request.booking_details?.booking_type != 'group'"
                     (click)="reScheduleRequest(request.booking_details.student_id , request)" href="#studentSchedules"
                     data-target="#studentSchedules" data-toggle="modal" trans="Request Reschedule"><a>Request
                        Reschedule</a></li>
                  <li class="divider"></li>
                  <li *ngIf="request.over_due_status =='reschedule-cancellation' && request.booking_details?.booking_type != 'group'" href="#requestCancellation"
                     data-target="#requestCancellation" data-toggle="modal"
                     (click)="requestCancellation(request.booking_details.tutor_id ,  request.booking_details.booking_id , request.booking_dates_id,'requestcancellation')"
                     trans="Request Cancellation"><a> Request Cancellation</a></li>
                  <li class="divider"></li>
                  <li trans="Raise Complaint"><a class="red" href="#requestRefund" data-target="#requestRefund"
                        data-toggle="modal"
                        (click)="requestCancellation(request.booking_details.tutor_id ,  request.booking_details.booking_id , request.booking_dates_id, 'raisecomplaint')">Raise
                        Complaint</a>
                  </li>
                  <li class="divider"></li>
                  <li (click)="bookingDateId(request.booking_dates_id , request.tutor_id , request.booking_details.tutor.username)"
                     data-target="#DoneModal" data-toggle="modal" trans="Mark Complete"><a>Mark Complete</a></li>
                  <li class="divider"></li>
                  <li (click)="messageModal(request.booking_details.tutor_resume.display_name, request.booking_details.tutor_id , request['booking_dates_id'] , 'over due')"
                     href="#messageModal" data-toggle="modal" trans="Message Tutor"><a>Message Tutor</a></li>
               </ul>
            </td>
            <td *ngIf="request.status == '5'">{{request.booking_dates_status}}</td>
         </tr>
         <tr *ngIf="(overDueClass$ | async).length == 0">
            <td class="text_center" colspan="10" align="center" trans="No Records Found">No Records Found</td>
         </tr>
      </tbody>
      </table>
   </div>
   <app-datatable-footer-new *ngIf="(overDueClass$ | async).length > 0"></app-datatable-footer-new>
</div>

<div class="modal fade" id="DoneModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog topMargin mt-100">
      <form (ngSubmit)="submitRating()" [formGroup]="ratingForm">
         <div class="modal-content">
            <div class="modal-header">
               <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
                  ×
               </button>
               <h4 class="modal-title md-title linkLabel" id="myModalLabel" trans="Thank you!">
                  Thank you!
               </h4>
            </div>
            <div class="modal-body M_wrapper" style="padding:25px;">
               <p>
                  We have marked this lesson as completed. Please rate the Tutor.
               </p>
               <div class="form-group review">
                  <textarea formControlName="review" class='M_textarea' name="the-textarea" id="the-textarea"
                     maxlength="300" placeholder="Type a message..." trans-placeholder="Type a message..."
                     autofocus></textarea>
                  <div *ngFor="let error of errorMessages.review">
                     <ng-container *ngIf="review.hasError(error.type) && (review.dirty || review.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
               
               <div form-group class="star-rating" style="direction: l;">
                  
                  <input id="star-1" type="radio" name="rating" value="star-1" />
                  <label for="star-1" title="1 star">
                     1&nbsp;<i (click)="rateTutor('1')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-2" type="radio" name="rating" value="star-2" />
                  <label for="star-2" title="2 stars">
                     2&nbsp;<i (click)="rateTutor('2')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-3" type="radio" name="rating" value="star-3" />
                  <label for="star-3" title="3 stars">
                     3&nbsp;<i (click)="rateTutor('3')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-4" type="radio" name="rating" value="star-4" />
                  <label for="star-4" title="4 stars">
                     4&nbsp;<i (click)="rateTutor('4')" class="active fa fa-star" aria-hidden="true">4</i>
                  </label>
                  <input id="star-5" type="radio" name="rating" value="star-5" />
                  <label for="star-5" title="5 stars">
                     5&nbsp;<i (click)="rateTutor('5')" class="active fa fa-star" aria-hidden="true">5</i>
                  </label>                  
               </div>
            </div>
            <div class="modal-footer TEXT-center flex TT-formGroup">
               <button type="submit" class="TT-primaryBTN " trans="Submit & Rebook" style="width:auto;padding:8px 35px;margin:10px;">
                  Submit & Rebook
               </button>
               <button (click)="submitRating(false)" type="button" class="TT-primaryBTN"
                  trans="Submit & close" style="width:auto;padding:8px 35px;margin:10px;">
                  Submit & close
               </button>
            </div>
         </div>
      </form>
   </div>
</div>
<div class="modal fade" id="requestRefund" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span *ngIf="operation == 'raisecomplaint'" trans="Raise Compliant">Raise Complaint</span>
            </h5>
            <button (click)="closeModals()" type="button" class="close" #closeViewModal data-dismiss="modal"
               aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p trans="write_description" style="color: #000;" *ngIf="operation == 'raisecomplaint'"
                  trans="Write your description here, to help you out">Write your description here, to help you out</p>
               <form [formGroup]="updateStatusForm" role="form" class="form-horizontal"
                  *ngIf="operation == 'raisecomplaint'">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea (input)="compliantComment($event.target.value)" name="description" id="description"
                           formControlName="description" class="form-control" rows="10" cols="30"></textarea>
                     </div>
                  </div>
                  <div class="TT-formGroup" style="margin-top:20px;">
                     <button id="compliantBtn" disabled type="button" class="btn TT-primaryBTN" trans="submit_complaint"
                     (click)="manageSchedule2('raisecomplaint')" trans="Submit Complaint" style="width:auto;background-color: #5cb85c;padding:8px 35px;">Submit Complaint</button>
                  &nbsp;&nbsp;
                  <button (click)="closeModals()" type="button" class="btn TT-primaryBTN" trans="close"
                     data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close" style="width:auto;padding:8px 35px;">Close</button>
                  </div>
                
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Request Cancellation">Request Cancellation</span>
            </h5>
            <button (click)="closeModals()" type="button" class="close" #closerequestCancellation data-dismiss="modal"
               aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p style="color: #000;" trans="Are you sure you want to cancel the reschedule request?">Are you sure you
                  want to cancel the request?</p>
               <form [formGroup]="updateCancellationForm" role="form" class="form-horizontal">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="cancel_description" formControlName="cancel_description"
                           (input)="messageText($event.target.value)" class="form-control" rows="10"
                           cols="30"></textarea>
                     </div>
                  </div>
                  <div class="TT-formGroup" style="margin-top:15px;">
                     <button disabled type="button" id="cancelBtn" class="TT-primaryBTN"
                     (click)="raiseCancellation('raisecancellation')" trans="Cancel Booking" style="width:auto;padding:8px 35px;">Cancel Booking</button>
                  &nbsp;&nbsp;
                  <button (click)="closeModals()" type="button" class="TT-primaryBTN" trans="close"
                     data-dismiss="modal" aria-label="Close" #closerequestCancellation trans="Close" style="width:auto;padding:8px 35px;">Close</button>
                  </div>
                
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
<app-schedule-modal (rescheduledDates)="rescheduledDates($event)"></app-schedule-modal>