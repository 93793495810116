        <div aria-hidden="true" aria-labelledby="successModallLabel" role="dialog" tabindex="-1" id="successModal" class="modal fade" >
          <div class="modal-dialog mt-200">
            <div class="modal-content bd-25">
              <div class="modal-header">
                <button aria-hidden="true" data-dismiss="modal" class="close" type="button" #closeSuccessModal>×</button>
                <h4 class="modal-title linkLabel-green-dark" trans="Thank You!">Thank You!</h4>
              </div>
              <div class="modal-body padding50 text-center">
                <h4 class="linkLabel"><i class="fa fa-4x fa-check-square green" aria-hidden="true"></i></h4>
                <h4 class="linkLabel" trans="Payment Processed Successfully">Payment Processed Successfully</h4>
                <div class="TT-formGroup">
                  <button (click)="viewSchedules()" type="submit" class="btn TT-primaryBTN" trans="View Schedule">View Schedule</button>

                </div>
              </div>
            </div>
          </div>
        </div>