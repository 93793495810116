import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.css']
})
export class ScheduledComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }



}
