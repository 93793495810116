<div class="TT-cardds">
   <div class="Table-Height TT-tableHeight">
      <h4 class="TT-title" trans="Recent Alerts">Recent Alerts</h4>
   <ul class="TT-listOrders">
      <div *ngFor="let alert of recent_alerts">
         <li class="TT-notify" (click)="viewAlert(alert)">
            <h4 class="TT-headlineList" (click)="viewAlert(alert)">
               {{humanize(alert.purpose)}}
               <span class="TT-innerHeadline">
                  {{alert.created}}
               </span>
            </h4>
            <p class="TT-wrapTexting">
               <small class="TT-limitingText" [innerHtml]="displayTextLimit(alert.notification)">
               </small>
            </p>
         </li>
      </div>
      <li class="TT-notify TT-noRecorded" *ngIf="recent_alerts_count == 0">
       <span trans="No Records Found" class="TT-noRecords">
         <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found" class="img-responsive w-100 m-auto TT-noData" /> -->
         <div class="text-center">No Records Found</div>
       </span>
     </li>
   </ul>

   <div class="TT-innerCover">
      <div class="TT-clickable" id="alerts">
         <a class="TT-click" routerLink="/student/student-alert">Alerts >>></a>
      </div>
   </div>
</div>
<div aria-hidden="true" aria-labelledby="alertModalLabel" role="dialog" #closeModal tabindex="-1" id="alertModal1" class="modal fade" style="display: none;">
   <div class="modal-dialog modal-lg" style="margin-top: 100px;">
      <div class="modal-content" style="border-radius: 25px;">
         <div class="modal-header border0">
            <button aria-hidden="true" data-dismiss="modal" class="close"  type="button">×</button>
            <h6>Booking Details - My Tutor Platform
               <span>{{bookingDateId}}</span>
            </h6>
            <h4 *ngIf="alert">
               <span>{{alert.purpose}}</span>
            </h4>
         </div>
         <div *ngIf="!isDataLoaded" class="preloading">
            <div class="wrap-preload">
               <div class="cssload-loader"></div>
            </div>
         </div>
         <div *ngIf="isDataLoaded"  class="modal-body paddingTop0">
            <div class='row' *ngIf="bookingDate">
               <div class='col-md-7'>
                  <div class=''>
                     <div class=''>
                        <table class="table lesson-table topSec">
                           <tbody>
                              <tr>
                                 <th trans="Date">Date</th>
                                 <th trans="Time">Time</th>
                              </tr>
                              <tr>
                                 <td>{{bookingDate.slot_date | date:'MM-dd-yyyy'}}</td>
                                 <td>{{bookingDate.booked_time}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <div class="row">
                        <h5 class="text-left color2">
                           <p><b trans="Lesson Wall">
                              Lesson Wall
                              </b>
                           </p>
                           <p class="palegreen" trans="leave a comment or upload a document."> leave a comment or upload a document.</p>
                        </h5>
                        <div class="greyColor footer-schedule_Chat chat_schedule_area-text">
                           <div class="col-md-1">
                              <button class="chat__area-media-btn btn" data-toggle="modal" data-target="#viewModalpaperclip">
                              <i class="fa fa-paperclip" aria-hidden="true"></i>
                              </button>
                           </div>
                           <div class="col-md-11 chat_pos" style="padding:0px;">
                              <form method="post" class="search_chat enterCol TWC-control">
                                 <input id="postComment" (keydown)="keyPressHandler($event)" (input)="comentValue($event.target.value)" type="text" class="form-control" placeholder="Type Message..." trans-placeholder="Type Message...">
                                 <i (click) = "postComment()" class="fa fa-paper-plane-o chat__area-send-btn" aria-hidden="true"></i>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <!-- main chat -->
                     <div class="T_S-chat" id="T_S-chat">
                        <div *ngFor="let comment of Comments">
                           <h5 *ngIf="userId != comment.from_id" class="text-left color2">
                              <p trans="Teacher"><b>
                                 Teacher: {{comment.from}}
                                 </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                              </p>
                              <div class="footer-schedule_Chat chat_chat_bdr">
                                 <p class="palegreen"> {{comment.comment}}</p>
                                 <br/>
                                 <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                                 <p *ngIf="comment.attachments">
                                    <a *ngFor= "let attachment of comment.attachments; let i = index" class="nocolor" >
                                    <span data-target="#viewAttachment" data-toggle="modal" (click)="viewAttachmentImage(attachment)" >Attachment {{i+1}} </span>
                                    </a>
                                 </p>
                              </div>
                           </h5>
                           <h5 *ngIf="userId == comment.from_id" class="text-left color2">
                              <p trans=" Student:"><b>
                                 Student: {{studentName}}
                                 </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                              </p>
                              <div class="footer-schedule_Chat chat_chat_bdr">
                                 <p class="palegreen">{{comment.comment}}</p>
                                 <br/>
                                 <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                                 <p *ngIf="comment.attachments">
                                    <a *ngFor= "let attachment of comment.attachments; let i = index" class="nocolor" >
                                    <span data-target="#viewAttachment" data-toggle="modal" (click)="viewAttachmentImage(attachment)" >Attachment {{i+1}} </span>
                                    </a>
                                 </p>
                              </div>
                           </h5>
                        </div>
                     </div>
                  </div>
               </div>
               <div class='col-md-5'>
                  <div class='padding20 paddingTop0'>
                     <div class="media">
                        <div  class="media-left">
                          <p> <span class="flot-left" trans="Form">From:</span>
                           <span class="float-right">{{tutor.display_name}}</span></p>

                           <p><span class="float-left" trans="Subject">Subject:</span>
                           <span class="float-right">{{subject.subject_name}}</span></p>

                           <p><span class="float-left" trans="Booking Type:">Booking Type:</span>
                           <span class="float-right">{{bookingDetails.booking_type}}</span></p>

                         <p>  <span class="float-left" trans="Status:">Status:</span>
                           <span class="float-right">{{bookingDate.class_status}}</span></p>
                           <div>
                           </div>
                        </div>
                        <div  class="media-body">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>