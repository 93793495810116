import { Component, OnInit , Input } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-demo-video',
  templateUrl: './demo-video.component.html',
  styleUrls: ['./demo-video.component.css']
})
export class DemoVideoComponent implements OnInit {
  
  public demoLink;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  public link(link){
  	this.demoLink = this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  public closeVideoModal(){
    this.demoLink = '';
  }
}
