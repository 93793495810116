import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
import { CurrentUser } from '../../auth/current-user';
import { LessonsService } from '../lessons/lessons.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProfileService } from '@athand/profile/profile.service';
import { LocaleTimeZoneTS } from '../../auth/localetimezone';
import { MessagemodalComponent } from './../findtutor/messagemodal/messagemodal.component';
import { ScheduleModalComponent } from '../lessons/schedule-modal/schedule-modal.component';
import { ActiveSubjects } from '../../auth/active-subjects';
import { SubjectTS } from '../../auth/subject';
import { LocaleCurrency } from '../../auth/localecurrency';
import { Currencies } from '../../auth/currencies';
import { DatePipe } from '@angular/common';

declare var $;

@Component({
  selector: 'app-student-hm',
  templateUrl: './student-hm.component.html',
  styleUrls: ['./student-hm.component.css']
})
export class StudentHmComponent implements OnInit , AfterViewInit{

  public dashboard;
  public Loader: boolean = false;
  public upcoming_lessons;
  public bookingCount = 0;
  public upcoming_lessons_count;
  public wallet;
  public orginalWallet;
  public recent_alerts;
  public recent_messages;
  public pending_actions;
  public pending_actions_count;
  public pendingRequests_count = 0;
  public recent_bookings;
  public recent_bookings_count;
  public Timings;
  public GBP;
  public selectedBookingId;
  public timezone;
  public studentBookings;
  public tutorAvailability;
  public cancellationId;
  public pendingRequests;
  public packageDates;
  public rescheduleNote;
  public rescheduleNoteCreatedAt;
  public rescheduleTime;
  public rescheduleId;
  public reschedule_status;
  public selectedBookedDateID;
  public bookingId;
  public tutorID;
  public operation;
  public rescheduleBookingID;
  public bookingRescheduleSelected;
  public popularCurrencies;
  public bookingDateId;
  public isDataLoaded:boolean;
  public selectedCurrency;
  public documentation:any;
  public recentNews:any;
  public now;

  public activeSubjects = new Array();
  public updateStatusForm = this.fb.group({
    description: ['', Validators.required],
    });
  public errorMessages = {
           description :[{ type:'required',message:'Description is required'}],
       }
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('closeViewRescheduleDeatailsModal') closeViewRescheduleDeatailsModal: ElementRef;
  @ViewChild('closeViewModal') closeViewModal: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closebookingRescheduleModal') closebookingRescheduleModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  constructor(public auth: AuthService,
              private lessonService: LessonsService,
  	          public currentuser: CurrentUser,
              private localetimezone: LocaleTimeZoneTS,
  	          private router: Router,
              private toastr: ToastrService,
              public currencies: Currencies,
              private fb: FormBuilder,
              private profileService: ProfileService,
              public activesubjects: ActiveSubjects,
              public subjectselected: SubjectTS,
              public localecurrency: LocaleCurrency,
              private datePipe: DatePipe) { 
  this.timezone = this.localetimezone.timezone();
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
}

  ngAfterViewInit() {
    $('.results').css('display','none');
        $('.selectionSubjectHm').click(function(e) {
         $('#selectionSubjectHm').css('display','inherit');
    });

  $(document).on('click', function (event) {
  if (!$(event.target).closest('.selectionSubjectHm').length) {
    // ... clicked on the 'body', but not inside of #menutop
    $('#selectionSubjectHm').css('display','none');
  }
});
    }

  ngOnInit(): void {
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
    var userTime = new Date().toLocaleTimeString('en-US', { timeZone: this.timezone});
    var userDate = new Date().toLocaleDateString('en-US', { timeZone: this.timezone});
    let userData = Object();
    Object.assign(userData , {time:userTime});
    Object.assign(userData , {timeZone: this.timezone});
    Object.assign(userData , {date: userDate}); 
    this.profileService.Dashboard()
        .subscribe(response => {
          this.responseHandle(response);
        });

        $(document).on("click", ".collapseToggle", function(){
          $(".tt-sideArea").toggleClass('sidebar--Collapse');
          $('.pageSectionBg').toggleClass('pageSectionBg-collapsable');	
          $(".tt-sideArea li").click(function(){
            $(".tt-sideArea").removeClass("sidebar--Collapse");
          });
        });
  }

  public responseHandle(response){
           
          this.dashboard =  JSON.parse(atob(response['dashboard']));
           
           this.wallet = this.dashboard.wallet_summary;
           this.orginalWallet = this.dashboard.wallet_summary;
           this.recent_alerts = this.dashboard.recent_alerts;
           this.recent_messages = this.dashboard.recent_messages;
           this.upcoming_lessons = this.todayClassesLoop(this.dashboard.upcoming_lessons);
           this.pending_actions = this.dashboard.pending_actions;
           this.recent_bookings = this.dashboard.recent_bookings;
           this.bookingCount = this.dashboard.bookingCount;
           this.upcoming_lessons_count = this.upcoming_lessons.length;
           this.pending_actions_count = this.pending_actions.length;
           this.recent_bookings_count = this.recent_bookings.length;
           this.recentNews = this.dashboard.recentNews;
           this.documentation = this.dashboard.documentation;
           this.isDataLoaded = true;
           if(this.upcoming_lessons_count == 0 && this.pending_actions_count == 0 && this.recent_bookings_count == 0){
            this.upcoming_lessons = 'No Bookings';
           }
  }

    public inputSubject(value){
    if(value){
           $('#findATeacherBtnHm').prop('disabled', true);
    }
    this.initializeSubjects();
    
    this.activeSubjects = this.activeSubjects.filter(subject => {
      
      if (subject.subject_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
         $('.results').css('display', 'inherit');
        return subject;
      }else{
        //  $('.results').css('display', 'none');
      // return false;
    }
  });
  }

public initializeSubjects(){
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
  }


 public openScheduleDates(id , data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    if(data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.tutor_id});
      bookingType = 'directCancelation';
    }else{
      Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
      bookingType = 'requestReschedule'; 
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data, bookingType);
    });
 }

  
  // This Method gets the Reschedule userData
  public requestReschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {operation: 'overDueCancelleation'});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
         let responseData =  JSON.parse(atob(response['data']));
         this.upcoming_lessons = responseData['upcomingClasses'];
        }
    })
  }
  
  public rescheduledDates(data){
    let responseData =  JSON.parse(atob(data['data']));
    this.recent_bookings = responseData['pendingApproval'];
  }


  //End Recent Booking

  //Upcoming lessons
   public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
     this.selectedBookedDateID = bookingRequestId;
     this.bookingId = bookingId;
     this.tutorID = tutorid;
     if ( typeof operation === 'undefined' ) {
       this.operation = 'refund';
     } else {
       this.operation = operation;
     }
   }

  // This Method gets the Reschedule userData
  public reschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
        }
    })
  }

  public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Record Updated Successfully');
 
        }
    })
  }
   
  //End Upcoming lessons
   public reschedulePendingDetailsModal(data){
    // console.log(data)
   if ( data.booking_reschedule_requests.status == 11) { // Tutor Reschedule
     this.rescheduleNote = data.booking_reschedule_requests.reject_reason
     this.rescheduleNoteCreatedAt = data.booking_reschedule_requests.new_slot_date;
     this.rescheduleTime = data.booking_reschedule_requests.new_slot_time;
   } else {
     this.rescheduleNote = data.booking_reschedule_requests.reject_reason
     this.rescheduleNoteCreatedAt = data.booking_reschedule_requests.new_slot_date;
     this.rescheduleTime = data.booking_reschedule_requests.new_slot_time;
   }
   this.rescheduleId = data.booking_reschedule_requests.reschedule_id;
   this.reschedule_status = data.booking_reschedule_requests.reject_reason;
   }

  public acceptAssignedDatesForPackage(){
    let data = Object();
    Object.assign(data , {bookingId : this.selectedBookingId});
    this.lessonService.acceptAssignedDatesForPackage(data).subscribe(response => {
          let result = JSON.parse(atob(response['data']));
          this.pendingRequests =  result['recheduleActions'];
          this.packageDates = result['packageOfferedDates'];
          this.toastr.success('Record Updated Successfully');
          this.closeViewDetailsModal();
    });
  }


  public closeViewDetailsModal(){
   this.Timings='';
   this.selectedBookingId='';
   this.closeViewDeatailsModal.nativeElement.click();
   this.closeViewRescheduleDeatailsModal.nativeElement.click();
  }

  public landingPage(){
  	this.router.navigateByUrl('/online-tutoring');
  }
  
  public attendClass(bookingId) {
    this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
  }

    public viewAlert(purpose , notificationId , source_data){
   if(purpose=="New Message"){
     this.router.navigate(['student/student-chat']);
   } else if(purpose == "New booking Accepted"){
     this.lessonService.bookingAccepted(source_data);
     this.router.navigate(['student/lessons/scheduled/sch']); 
   } else if(purpose == "New booking Rejected"){
     this.lessonService.sourceCanceled1(source_data);
     this.router.navigate(['student/lessons/cancelled']);
   }else if(purpose == "Direct Cancellation"){
     this.lessonService.sourceCanceled1(source_data);
     this.router.navigate(['student/lessons/cancelled']);
   } else if(purpose == "Reschedule booking"){
     this.lessonService.sourcePendingRequest1(source_data);
     this.router.navigate(['student/lessons/pending']);
   } else if(purpose == "Request Reschedule"){
     this.lessonService.sourceRequestReschedule1(source_data);
     this.router.navigate(['student/lessons/pending']);
   } else if(purpose == "Reschedule Accept"){
     this.lessonService.bookingAccepted(source_data);
     this.router.navigate(['student/lessons/scheduled/sch']);
   }
   if(notificationId != 'read'){
      //   this.service.readNotification(notificationId).subscribe(response=>{
      //   this.usernotifications =JSON.parse(atob(response['notifications']));
      //   this.usernotifications_read = this.usernotifications.is_read;
      //   this.usernotifications_read_count = this.usernotifications.is_read.length;
      //   this.usernotifications_unread = this.usernotifications.is_unread;
      //   this.usernotifications_unread_count = this.usernotifications.is_unread.length;
      // });
      }
  }


    // Booking Reschedule
  public bookingReschedule(request){
   this.rescheduleBookingID = request.booking_id;
   this.bookingRescheduleSelected = request.booking_reschedule_request;
  }


  public acceptBookingReschedule(operation){
    let data = Object();
    Object.assign(data , {bookingId : this.rescheduleBookingID,  operation:operation});
    this.lessonService.acceptBookingReschedule(data)
        .subscribe(response => {
          if(response.status == 'success'){
      this.profileService.Dashboard()
        .subscribe(response => {
          this.responseHandle(response);
        });
      this.closebookingRescheduleModal.nativeElement.click();
        }
        });
  }



  // SELECT SUBJECT
  public selectSubject(subject , className){
    console.log(subject) 
    let subjectSelected = this.activeSubjects.find(x=>x.subject_name === subject);
     this.subjectselected.init(subjectSelected);
     localStorage.setItem('subject' , JSON.stringify(subjectSelected));
     $('.results').css('display','none');
     $('.'+className).val(subject);
     $('#findATeacherBtnHm').prop('disabled', false);
  }

public findTeacher(){
    this.router.navigate(['/student/find-tutor']);
  }


    public messageModal($event){
      console.log($event);
    let user = Object();
    Object.assign(user,{id:$event.userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:$event.notificationSUbject});
    ($event.idName == 'bookingDate') ? Object.assign(user,{bookingDateId:$event.bookingDetail}) : Object.assign(user,{bookingId:$event.bookingDetail});
    this.messageChild.userData($event.resume, user);
  }


  public todayClassesLoop(upcoming_lessons){
    
    var runningClasses = [];
    for (var tutor of upcoming_lessons) {
      
      let bookedTime = tutor.booked_time;
      let time = this.datePipe.transform(this.now , 'H:mm:ss');
      let statusRequired;
      if ( tutor.display_type == 'today' ) {
        let status = this.calculateTime(bookedTime , time , statusRequired , tutor.rating_status);
        tutor.status = status;
      }
      runningClasses.push(tutor);
    }
    return runningClasses;
  }

  public AMPMTOTIME(time){
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    let time1 = sHours + ":" + sMinutes +  ":" + "00";
    // console.log(time1)
    return time1;
  }

  public reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
  }

  public calculateTime(classTime, now, statusRequired = '', rating_status){
    
    let classTimeConverted = this.AMPMTOTIME(classTime);
    let time   = classTimeConverted.split(":");
    let hour   = Number(time[0]) * 60 * 60 * 1000;
    let minute = Number(time[1]) * 60 * 1000;
    let second = Number(time[2]) * 1000;
    let result = hour + minute + second;
    
    let currenttime   = now.split(":");
    
    let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
    let currentminute = Number(currenttime[1]) * 60 * 1000;
    let currentsecond = Number(currenttime[2]) * 1000;
    let currentresult = currenthour + currentminute + currentsecond;
    
    let graceHour = +time[0]+1;
    let grace = Number(graceHour) * 60 * 60 * 1000;
    let graceTime = grace + minute + second;
    
    if(currentresult <= graceTime){
    if(result <= currentresult && result <= graceTime){
        return 3;  /*2=> CLASS RUNNING */
       }else{
              var Difference_In_Time = result - currentresult;
                   if(Difference_In_Time == 1000){
                    this.reloadComponent();
                   }else{
                    return Difference_In_Time;
                   }
       }
    }else{
      if(rating_status > 0){
        return 4; /*4=> Rated */
      }else{
        return 2;
        }  /*2=> CLASS COMPLETED */
       }
     }
}
