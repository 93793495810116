<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">

   <div class="TT-schedule clearfix">
      <h4 trans="Pending Actions" class="TT-tableTime pull-left"> Pending Action
         <span *ngIf="selectedUserName"> with {{selectedUserName}} </span>
      </h4>
   </div>

   <div class="table-responsive TT-responsiveTable">
      <table class="table TT-table TT-autoWidthTable">
         <thead>
            <tr>
               <th trans="Subject">Subject</th>
               <th trans="Tutor">Tutor</th>
               <th trans="Slot Type">Slot Type</th>
               <th trans="Status">Status</th>
               <th colspan="4" trans="action">Action</th>
            </tr>
         </thead>
         <tbody>
            <tr *ngIf="(pendingActions$ | async).length == 0">
               <td class="text_center" colspan="8" align="center" trans="No Records Found">No Records Found
               </td>
            </tr>
            <tr *ngFor="let request of pendingActions$ | async" id="{{request.booking_id}}">
               <td>
                  {{request.subject.subject_name}}
               </td>
               <td *ngIf="request.tutor_resume">
                  {{request.tutor_resume.display_name}}
               </td>
               <td *ngIf="!request.tutor_resume">
                  -
               </td>
               <td>{{transform(request.booking_type)}}</td>
               <td *ngIf="request.request_status == 'offered_new_date'">{{transform(request.request_status)}} </td>
               <td *ngIf="request.request_status != 'offered_new_date'">{{transform(request.status)}}</td>
               <td *ngIf="request.booking_type == 'schedule'">
                  <div class="TT-formGroup">
                     <button
                        *ngIf="request.offered_new_date_count > '0' || request.tutor_request_reschedule_count > '0'"
                        type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(request)"
                        href="#rescheduleDetails" data-target="#rescheduleDetails" trans="Request Details"
                        data-toggle="modal" style="width: auto;padding: 8px 35px;">
                        Request Details
                     </button>
                  </div>

                  <button *ngIf="request.status == 'booking_reschedule_from_tutor'" type="button"
                     class="btn btn-primary" (click)="bookingReschedule(request)" href="#bookingReschedule"
                     data-target="#bookingReschedule" data-toggle="modal" trans="Booking Reschedule" style="width: auto;padding: 8px 35px;">
                     Booking Reschedule
                  </button>
               </td>
               <td *ngIf="request.booking_type == 'package' || request.booking_type == 'group'"
                  class="white-space TT-formGroup">
                  <button *ngIf="request.request_status == 'dates_assigned_for_package'" type="button"
                     class="TT-primaryBTN" (click)="viewPackageDetails(request.packages_dates , request.booking_id)"
                     href="#viewPackageDetails" data-target="#viewPackageDetails" data-toggle="modal"
                     trans="Package Details" style="width: auto;padding: 8px 35px;">
                     Package Details
                  </button>
                  <button *ngIf="request.offered_new_date_count > '0' || request.tutor_request_reschedule_count > '0'"
                     type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(request)"
                     href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal"
                     trans="Request Details" style="width: auto;">
                     Request Details
                  </button>
               </td>
            </tr>
         </tbody>
      </table>
      <app-datatable-footer-new *ngIf="(pendingActions$ | async).length > 0"></app-datatable-footer-new>
      <span type="button" #open href="#bookingReschedule" data-target="#bookingReschedule" data-toggle="modal"></span>
      <span type="button" #open1 href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal"></span>
   </div>
   <div class="modal fade" id="viewPackageDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog-centered TT-modalMainArea" role="document">
         <div class="modal-content TT-modelCover">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
               <h4 class="modal-title TT-AccUsers" id="modalLabel" trans="Booking Details"> Booking Details
               </h4>
               <button type="button" class="close" #closeViewPackageDeatailsModal (click)="closeViewDetailsModal()"
                  data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body">
                  <div *ngIf="Timings" class="table-responsive">
                     <table class="table TT-table TT-autoWidthTable">
                        <thead>
                        <tr>
                           <th trans="Date">Date</th>
                           <th trans="Timings">Timings</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let time of Timings">
                           <td>{{time.slot_date | date:'MM-dd-yyyy'}}</td>
                           <td>{{time.booked_time}}</td>
                        </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div class="modal-footer">
                 <div class="TT-formGroup">
                   <button (click)="acceptAssignedDatesForPackage()" type="button" class="TT-primaryBTN" trans="Accept Assigned Dates"> Accept Assigned Dates </button>
                 </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="rescheduleDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog-centered TT-modalMainArea" role="document">

         <div class="modal-content TT-modelCover popup_mobile_res">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
               <h4 class="modal-title TT-AccUsers" id="modalLabel" *ngIf="offeredNewDatesCount>'0'"
                  trans="Offered New Date For Requested Reschedule">Offered New Date For Requested Reschedule</h4>
                  <h4 class="modal-title TT-AccUsers" id="modalLabel" *ngIf="requestRescheduleCount>'0'"
                  trans="reschedule_request">Reschedule Request</h4>
               <button type="button" class="close" #closeViewRescheduleDeatailsModal data-dismiss="modal"
                  aria-label="Close" style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body" *ngIf="offeredNewDatesCount>'0'">
                     <div class="table-responsive">
                        <table class="table TT-table TT-autoWidthTable">
                           <tr>
                              <th trans="Old Date">Old Date</th>
                              <th trans="Old Time">Old Time</th>
                              <th trans="New Offered Date">New Offered Date</th>
                              <th trans="New Offered Time">New Offered Time</th>
                              <th trans="Reject Reason">Reject Reason</th>
                              <th trans="Action">Action</th>
                           </tr>
                           <tr *ngFor="let newDate of offeredNewDates">
                              <td>{{newDate.slot_date | date:'MM-dd-yyyy'}}</td>
                              <td>{{newDate.slot_time}}</td>
                              <td>{{newDate.new_slot_date | date:'MM-dd-yyyy'}}</td>
                              <td>{{newDate.new_slot_time}}</td>
                              <td>{{transform(newDate.reject_reason)}}</td>
                              <td>
                                 <button (click)="acceptRescheduleRequest(newDate.reschedule_id, 'accept')"
                                    type="button" class="btn btn-success new" trans="Accept Request">Accept
                                    Request</button>
                                 <button (click)="acceptRescheduleRequest(newDate.reschedule_id, 'rejectandcancel')"
                                    type="button" class="btn new custom_danger_btn"
                                    trans="Reject Request & Cancel">Reject
                                    Request & Cancel</button>
                              </td>
                           </tr>
                        </table>
                     </div>
               </div>
               <div class="modal-body pl-0" *ngIf="requestRescheduleCount>'0'">
                
                     <div class="table-responsive">
                        <table class="table TT-table TT-autoWidthTable">
                           <thead>
                              <tr>
                                 <th trans="Old Date">Old Date</th>
                                 <th trans="Old Time">Old Time</th>
                                 <th trans="Request Date">Request Date</th>
                                 <th trans="Request TIme">Request TIme</th>
                                 <th trans="Note Given">Note Given</th>
                                 <th trans="Action" class="padding_left">Action</th>
                              </tr>
                           </thead>
                          <tbody>
                           <tr *ngFor="let request of requestReschedule">
                              <td>{{request.related_booking_date.slot_date | date : 'MM-dd-yyyy'}}</td>
                              <td>{{request.related_booking_date.booked_time}}</td>
                              <td>{{request.slot_date | date: 'MM-dd-yyyy'}}</td>
                              <td>{{request.slot_time}}</td>
                              <td>{{request.note}}</td>
                              <td class="TT-formGroup">
                                 <button (click)="acceptRequestReschedule(request.reschedule_id, 'accept')"
                                    type="button" class="TT-primaryBTN" trans="Accept Request"
                                    style="background-color: #5cb85c; width:auto;padding:8px 35px;margin:5px;">Accept
                                    Request</button>
                                 <button (click)="acceptRequestReschedule(request.reschedule_id, 'rejectandcancel')"
                                    type="button" class="TT-primaryBTN" trans="reject_request_cancel"
                                    style="width:auto;padding:8px 35px;margin:5px;">Reject
                                    Request & Cancel</button>
                              </td>
                           </tr>
                          </tbody>
                         
                        </table>
                     </div>
               
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="modal fade" id="bookingReschedule" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
      <div class="modal-dialog mt-100" role="document">
         <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
               <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Reschedule Details">Reschedule
                  Details
               </h5>
               <button type="button" class="close" #closebookingRescheduleModal data-dismiss="modal" aria-label="Close"
                  style="margin-top:-30px;">
                  <span aria-hidden="true">&times;</span>
               </button>
               <div class="modal-body pl-0 mx_scroll">
                  <div class="container-fluid whiteBg pl-0">
                     <div class="tab-content">
                        <table class="table TT-table TT-autoWidthTable">
                           <tr>
                              <th trans="Booked Date">Booked Date</th>
                              <th trans="Booked Time">Booked Time</th>
                              <th trans="Requested Date">Requested Date</th>
                              <th trans="Request Time">Request Time</th>
                           </tr>
                           <tr *ngFor="let reschedule of bookingRescheduleSelected">
                              <td>{{reschedule['related_booking_date'].slot_date}}</td>
                              <td>{{reschedule['related_booking_date'].booked_time}}</td>
                              <td>{{reschedule.slot_date}}</td>
                              <td>{{reschedule.slot_time}}</td>
                           </tr>
                        </table>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <div class="TT-formGroup">
                     <button (click)="acceptBookingReschedule('accept')" type="button" class="TT-primaryBTN"
                        trans="Accept Reschedule Request">Accept Reschedule Request</button>
                     <button (click)="acceptBookingReschedule('rejectandcancel')" type="button"
                        class="TT-primaryBTN" trans="Reject Reschedule Request">Reject Reschedule
                        Request</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>