<div class="left-container">
    <div class="new-localization" >
       <h5><b trans="Filters">Filters</b> 
       </h5>
    </div>
    <ul class="lesson-list" >
        <li role="presentation" trans="Credits Purchase"><a role="menuitem" tabindex="-1" (click)="selectSearch('credit_purchased')"  >Credits Purchase</a></li>
        <li role="presentation" trans="Lessons Purchase"><a role="menuitem" tabindex="-1" (click)="selectSearch('lesson_purchase')"  >Lessons Purchase</a></li>
        <li role="presentation" trans="Student Direct Cancelled"><a role="menuitem" tabindex="-1" (click)="selectSearch('student_direct_cancelled')" >Student Direct Cancelled</a></li>
        <li role="presentation" trans="Tutor Direct Cancelled"><a role="menuitem" tabindex="-1" (click)="selectSearch('tutor_direct_cancelled_class')">Tutor Direct Cancelled</a></li>
        <li role="presentation" trans="Tutor Not Responded"><a role="menuitem" tabindex="-1" (click)="selectSearch('tutor_not_responded')">Tutor Not Responded</a></li>
        <li role="presentation" trans="Request Reschedule Rejected By Tutor"><a role="menuitem" tabindex="-1" (click)="selectSearch('request_reschedule_rejected_by_tutor')">Request Reschedule Rejected By Tutor</a></li>
        <li role="presentation" trans="IN"><a role="menuitem" tabindex="-1" (click)="selectSearch('IN')">IN</a></li>
        <li role="presentation" trans="OUT"><a role="menuitem" tabindex="-1" (click)="selectSearch('OUT')">OUT</a></li>
    </ul>
</div>