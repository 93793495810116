<!-- <div *ngIf="!subjectName">
   <div class="col-md-8 text-center" style="margin:auto;">
      <div class="No_subject"> -->
         <div *ngIf="!subjectName" class="container-fluid padding0 TT-wrappers" class="">
            <section class="pageSectionBg">
               <div class="TT-outlineLayers new_TT-outlineLayers">
         <form [formGroup]="searchTutorForm" class="searchBar searchDD" style="display: flex;">
            <input formControlName="subjectName" class="form-control form-control-find-tuter subject" type="text"
               placeholder="what do you want to learn today?" trans-placeholder="what do you want to learn today">
            <ul id="Results" class="results" style="">
               <li *ngFor="let subject of activeSubjects" (click)="selectSubject(subject.subject_name , subject.id)">
                  <a>{{subject.subject_name}}</a>
               </li>
            </ul>
            <button (click)="findTeacher()" disabled id="findATeacherBtn" class="btn btn-primary searchBtn">
               <span trans="Find a Tutor">Find a Tutor </span>
            </button>
         </form>
      </div>
      </section>
   </div>
<!-- </div> -->
<div *ngIf="subjectName" class="container-fluid padding0">
   <section [ngClass]="{'left0': !this.currentuser.isLoggedIn()}"class="pageSectionBg">
      <div class="TT-outlineLayers">
         <div class="row m-auto">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
               <h2 class="TT-mailHeadlineTitle">Find Tutors</h2>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
               <app-filter [displayNames]='tutorNames' (overlayStatus)="overlayStatus($event)"
                  (searchName)="tutorSearch($event)" [loader]="activateLoader" (filterData)="filter($event)">
               </app-filter>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
               <div class="d-flex TT-flexy">
                  <div class="TT-TutorSubjects">
                     <h4 class="TT-subjectName"> {{subjectName}}
                        <span class="TT-tutorOnline">Tutors Online</span>
                        <lable class="TT-"> &nbsp; {{tutorsCount | async}} &nbsp;
                           <small> tutors available</small>
                        </lable>
                     </h4>
                  </div>
                  <div class="TT-filterClear">
                     <span (click)="clearAllFilters()" class="clr_btn" trans="clear_filter"
                        style="color:#198fd9;cursor: pointer;">
                        <i class="fa fa-times" aria-hidden="true"></i> Clear Filter</span>
                  </div>
               </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn" style="margin-top:50px;">
               <div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
                  <div class="wrap-preload">
                     <div class="cssload-loader"></div>
                  </div>
               </div>

               <div class="row" *ngFor="let tutor of tutors$ | async">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 TT-customColumn">
                     <div class="TT-wraperClearCover">
                        <div class="row">
                           <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 TT-customColumn">
                              <div class="media d-flex TT-media">
                                 <div class="TT-contentMedia">
                                    <a class="TT-profileStudent" (click)="tutorProfile(tutor['user_data']['username'])">
                                       <img src="{{tutor['user_data']['avatar']}}" class="img-responsive TT-studentPic"
                                          alt="" />
                                    </a>
                                    <ul class="TT-widgetFavourateChat d-flex">
                                       <li *ngIf="currentuser.isLoggedIn()">
                                          <span id=heart *ngIf="tutor['availability']['is_favourite']=='yes'"
                                             class="TT-heart">
                                             <i id="{{tutor['user_data']['id']}}"
                                                (click)="makeFavourite(tutor['user_data']['id'])" class="fa fa-heart"
                                                aria-hidden="true"> </i>
                                          </span>
                                          <span id=heart *ngIf="tutor['availability']['is_favourite']=='no'"
                                             class="TT-heart">
                                             <i id="{{tutor['user_data']['id']}}"
                                                (click)="makeFavourite(tutor['user_data']['id'])" class="fa fa-heart-o"
                                                aria-hidden="true"> </i>
                                          </span>
                                       </li>
                                       <li (click)="modalSession('favourite' , tutor['user_data']['id'])"
                                          *ngIf="!this.currentuser.isLoggedIn()">
                                          <span id=heart class="TT-heart">
                                             <i data-toggle="modal" data-target="#loginModal" class="fa fa-heart-o"
                                                aria-hidden="true"> </i>
                                          </span>
                                       </li>
                                       <li *ngIf="this.currentuser.isLoggedIn()">
                                          <a (click)="messageModal(tutor['tutor_resume'], tutor['user_data'])"
                                             #openMessageModal href="#messageModal" data-toggle="modal">
                                             <span class="TT-heart">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                             </span>
                                          </a>
                                       </li>
                                       <li (click)="modalSession('message' , tutor['user_data']['id'])"
                                          *ngIf="!this.currentuser.isLoggedIn()">
                                          <a id="staticBackdrop" data-backdrop="static" tabindex="-1"
                                             aria-labelledby="staticBackdropLabel" data-toggle="modal"
                                             data-target="#loginModal">
                                             <span class="TT-heart">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                             </span>
                                          </a>
                                       </li>
                                       <li>
                                          <a id="staticBackdrop" data-backdrop="static" tabindex="-1"
                                             aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                             (click)="tutorSchedule(tutor,tutor['tutorBookingDates'], tutor['tutor_packages'] , tutor['user_data'] , tutor['tutor_resume'] , tutor['subject_data'] , tutor['availability'], 'schedule')"
                                             href="#modaldatepicker" data-target="#modaldatepicker" data-toggle="modal">
                                             <span class="TT-heart">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                             </span>
                                          </a>
                                       </li>
                                       <a (click)="modalSession('schedule')" id="staticBackdrop" data-backdrop="static"
                                          tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                          #openSchedules href="#modaldatepicker" data-target="#modaldatepicker"
                                          data-toggle="modal"> </a>
                                    </ul>
                                 </div>

                                 <div class="media-body TT-mediaBody">
                                    <p *ngIf="tutor['user_data']['isOnlineCheck']" class="New_Green_Led"> </p>
                                    <h4 (click)="tutorProfile(tutor['user_data']['username'])"
                                       routerLink="tutor-details" class="TT-headlineCard">
                                       {{tutor['tutor_resume'].display_name}}
                                    </h4>
                                    <div class="TT-activeCourse">
                                       <label class="TT-name"> {{tutor['students_count']}} &nbsp;</label>
                                       <span class="TT-active">&nbsp; Active Students &nbsp;</span>
                                       <lable class="TT-lessonsTutor">&nbsp; {{tutor['lessons_count']}} &nbsp;</lable>
                                       <span class="TT-lessons">&nbsp; Lessons &nbsp;</span>
                                    </div>
                                    <div class="TT-languages" *ngIf="tutor['languages'].length > 0">
                                       <div style="display: initial;margin-bottom: 10px;">
                                          <h4 trans="Speaks:" class="TT-headline"> Speaks: </h4>
                                          <div class="TT-languageCover"
                                             *ngFor="let lan of tutor['languages']; last as isLast">
                                             <small class="TT-language"> {{lan.language}} </small>
                                             <label *ngIf="lan.level_name && lan.level_name.language_level=='Native'"
                                                class="TT-greenLanguage">
                                                {{lan.level_name.language_level}}
                                             </label>
                                             <label *ngIf="lan.level_name && lan.level_name.language_level!='Native'"
                                                class="TT-blueLanguage">
                                                {{lan.level_name.language_level}}
                                             </label>
                                             <small class="TT-language"> {{isLast?'':', '}} </small>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="TT-priceing">
                                       <div class="TT-layerPrice">
                                          <lable class="TT-dollorIcon"> {{selectedCurrency.symbol}} </lable>
                                          <span class="TT-hourlyRate" *ngIf="tutor.hourly_rate; else defaultprice">
                                             {{tutor.hourly_rate}} </span>
                                          <ng-template #defaultprice>
                                             <span class="TT-tutorRate"> {{tutor['tutor_resume'].hourly_rate}} </span>
                                          </ng-template>
                                          <small class="TT-perHour" trans="Per Hour"> Per Hour </small>
                                       </div>
                                    </div>
                                    <div *ngIf="tutor['availability']['trailLessonAccess'] == 'yes'"
                                       class="TT-lessonBooking">
                                       <a class="TT-bookNow" id="staticBackdrop" data-backdrop="static" tabindex="-1"
                                          aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                          (click)="tutorSchedule(tutor,tutor['tutorBookingDates'], tutor['tutor_packages'] , tutor['user_data'] , tutor['tutor_resume'] , tutor['subject_data'] , tutor['availability'] , 'trialLesson')"
                                          href="#modaldatepicker" data-target="#modaldatepicker" data-toggle="modal"
                                          trans="Book Trial Lesson">
                                          Book Trial Lesson
                                       </a>
                                    </div>
                                    <div *ngIf="tutor['availability']['trailLessonAccess'] == 'no'"
                                       class="TT-lessonBooking">
                                       <a class="TT-bookNow" id="staticBackdrop" data-backdrop="static" tabindex="-1"
                                          aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                          (click)="tutorSchedule(tutor,tutor['tutorBookingDates'], tutor['tutor_packages'] , tutor['user_data'] , tutor['tutor_resume'] , tutor['subject_data'] , tutor['availability'] , 'schedule')"
                                          href="#modaldatepicker" data-target="#modaldatepicker" data-toggle="modal"
                                          trans="Book A Lesson" style="width: fit-content;padding: 8px 35px; ">
                                          Book A Lesson
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 TT-customColumn">
                              <div class="media d-flex TT-media">
                                 <div class="media-body TT-mediaBody">
                                    <h4 class="TT-stroyHeading" title="{{tutor['tutor_resume'].highlight}}">
                                       <label [innerHtml]="tutor['tutor_resume'].highlight"> </label>
                                    </h4>
                                    <p class="TT-storyText"> {{tutor['tutor_resume'].descriptionTags}}</p>
                                    <a (click)="tutorProfile(tutor['user_data']['username'])" class="TT-linkTWC"
                                       trans="View Full Profile"> View Full Profile </a>
                                 </div>

                                 <div class="TT-videoFrame">
                                    <div id="{{tutor['user_data']['id']}}_video" class="TT-frame"></div>
                                    <img id="{{tutor['user_data']['id']}}_thumbnail"
                                       (click)="playVideo(tutor['user_data']['id'] , tutor['video_presentation']['video_URL'])"
                                       src="assets/images/video.png" class="img-responsive TT-playOption">
                                    <img alt="" *ngIf="tutor['video_presentation']['thumbnaillink']"
                                       id="{{tutor['user_data']['id']}}_thumbnail1"
                                       src="{{tutor['video_presentation']['thumbnaillink']}}" width="100%" height="200"
                                       class="TT-Thumb">
                                    <img alt="" *ngIf="!tutor['video_presentation']['thumbnaillink']"
                                       id="{{tutor['user_data']['id']}}_thumbnail1" src="assets/images/youtube.jpg"
                                       width="100%" height="170" class="TT-Thumb">
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 TT-customColumn">
                     <app-datatable-footer-new *ngIf="(tutors$ | async).length > 0"></app-datatable-footer-new>
                  </div>
               </div>
            </div>
            <app-checkout (scheduleModal)="openscheduleModal($event)"
               (removeDataFromScheduleModal)="removeDataFromScheduleModal($event)"
               [clearCheckOutModalData]="clearCheckOutModalData" (bookingCompleted)="bookingCompleted($event)"
               [checkOutDataMain]="checkOutFinalData"></app-checkout>
         </div>
      </div>
   </section>
</div>
<app-schedule (checkOutData)="getCheckoutDetails($event)"></app-schedule>
<a #successclild href="#successModallLabel" data-target="#successModal" data-toggle="modal"></a>
<app-messagemodal></app-messagemodal>
<app-demo-video></app-demo-video>
<app-success-modal></app-success-modal>
<app-go-top></app-go-top>