import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentHmComponent } from '@student/student-hm/student-hm.component';
import { FindtutorComponent } from '@student/findtutor/findtutor.component';
import { LessonsComponent } from '@student/lessons/lessons.component';
import { ScheduledComponent } from '@student/lessons/scheduled/scheduled.component';
import { PendingComponent } from '@student/lessons/pending/pending.component';
import { CompletedComponent } from '@student/lessons/completed/completed.component';
import { CancelledComponent } from '@student/lessons/cancelled/cancelled.component';
import { StudentPaymentComponent } from '@student/student-payment/student-payment.component';
import { StudentTransactionsComponent } from '@student/student-payment/student-transactions/student-transactions.component';
import { StudentChatComponent } from '@student/student-chat/student-chat.component';
import { StudentGuard } from '@athand/guards/student-guard.service';
import { AccountComponent } from '@student/account/account.component';
import { DetailsComponent } from '@student/lessons/scheduled/details/details.component';
import { ScheduleListComponent } from '@student/lessons/scheduled/schedule-list/schedule-list.component';
import { VideocallComponent } from '@athand/videocall/videocall.component';
import { ProfileEditComponent } from '@athand/profile/profile-edit/profile-edit.component';
import { PaymentMethodsComponent } from '@athand/profile/payment-methods/payment-methods.component';
import { AutoConfirmationComponent } from '@athand/profile/auto-confirmation/auto-confirmation.component';
import { CalenderComponent } from '@athand/profile/calender/calender.component';
import { StudentAlertsComponent } from '@student/student-alerts/student-alerts.component';
import { OverdueComponent } from '@student/lessons/overdue/overdue.component';
import { BoardComponent } from '@student/lessons/board/board.component';
import { TutorFullProfileComponent } from '@student/findtutor/tutor-full-profile/tutor-full-profile.component';
import { AlertGuard } from '@athand/guards/alert.guard';

import { NotificationsComponent } from '@athand/profile/notifications/notifications.component';

const routes: Routes = [

              /*
              Web Routes
              */
              { path: '', redirectTo: 'home', pathMatch: 'full' },              
              { path:'find-tutor', component: FindtutorComponent },
              { path:'profile/:userName' , component: TutorFullProfileComponent },

              { path:'home',component: StudentHmComponent , canActivate: [StudentGuard]},

             // LESSONS MODULE
              { 
                path:'lessons',
                component: LessonsComponent, canActivate: [StudentGuard],
                children:[
                  { path: '', redirectTo:'scheduled', component:ScheduledComponent, pathMatch: 'full'},
                  { path:'scheduled', component:ScheduledComponent,
                    children:[
                        {  path:'', redirectTo:'sch' , component:ScheduleListComponent, pathMatch: 'full'},
                        {  path:'sch' ,  component:ScheduleListComponent },
                        {  path:'details',component:DetailsComponent },
                        {  path: 'videoCall/:id', component: VideocallComponent,canDeactivate: [AlertGuard]  }
                    ]
                  },
                  { path:'pending', component:PendingComponent},
                  { path:'completed', component:CompletedComponent},
                  { path:'cancelled', component:CancelledComponent},
                  { path:'overdue' , component:OverdueComponent},
                  { path:'board',component:BoardComponent },
                ]
              },

              // ACCOUNT SETTINGS
              { path: 'student-account',
                component: AccountComponent, canActivate: [StudentGuard],
                children:[
                { path:'', redirectTo: 'settings' , pathMatch: 'full' },
                { path:'settings',component: ProfileEditComponent },
                { path:'payment-methods',component: PaymentMethodsComponent },
                { path:'auto-confirmation',component: AutoConfirmationComponent },
                { path:'calendar',component: CalenderComponent },
                { path:'notifications',component: NotificationsComponent},
                ]},

              // WALLET MODULE
              { path:'student-payment',
                component: StudentPaymentComponent, canActivate: [StudentGuard],
                children:[
                {path: '', redirectTo:'transactions', component:StudentTransactionsComponent, pathMatch: 'full'},
                {path:'transactions', component:StudentTransactionsComponent}
                ]
              },

              // CHAT MODULE
              { path:'student-chat',
                component: StudentChatComponent , canActivate: [StudentGuard] },

              // ALERTS MODULE
              { path:'student-alert', component: StudentAlertsComponent, canActivate: [StudentGuard] },

            ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
