import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from '@athand/chat/chat.service';
import { LessonsService } from '../../lessons/lessons.service';

@Component({
  selector: 'app-recent-messages',
  templateUrl: './recent-messages.component.html',
  styleUrls: ['./recent-messages.component.css']
})
export class RecentMessagesComponent implements OnInit {
  
  public recent_messages;
  public recent_messages_count = 0;
  public selectUser;

  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count;
  public usernotifications_unread;
  public usernotifications_unread_count = 0;
  public usermessages_unread_count;
  
  @Input()
  public set recentMessageDetails(value){
  this.recent_messages = value;
  if(this.recent_messages){
  this.recent_messages_count = this.recent_messages.length;
    }
  }

  constructor(private router: Router,
              public chatService: ChatService,
              private lessonService: LessonsService) { }

  ngOnInit(): void {
  }


  public viewChat(){
    this.router.navigate(['student/student-chat']);
  }

  public userChat(user){
  this.userChat = user;
  this.chatService.selectedUserChat(user);
  this.router.navigate(['student/student-chat']);
  }
}
