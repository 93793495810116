import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { LessonsService } from '../../lessons/lessons.service';
import { ScheduleModalComponent } from '../../lessons/schedule-modal/schedule-modal.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
declare var $;

@Component({
  selector: 'app-upcoming-lessons',
  templateUrl: './upcoming-lessons.component.html',
  styleUrls: ['./upcoming-lessons.component.css']
})
export class UpcomingLessonsComponent implements OnInit {
  
  @ViewChild('closeCompliantModal') closeCompliantModal: ElementRef;
  @ViewChild('closerequestCancellation') closerequestCancellation: ElementRef;
  @ViewChild('closeSingleCancellation') closeSingleCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  public upcoming_lessons_count = 0;
  public upcoming_lessons;
  public selectedBookedDateID;
  public bookingId;
  public studentBookings;
  public timezone;
  public tutorAvailability;
  public tutorID;
  public operation;
  public cancellationId;
  public bookingDateId;
  public now;

  @Output() messageModalData: EventEmitter<{resume:string, userData:string , bookingDetail:string , notificationSUbject:string, idName:string}> = new EventEmitter();
  @Input()
  public set upcoming(value){
   this.upcoming_lessons = value;
   this.upcoming_lessons_count = this.upcoming_lessons.length;
  }
  
  @Input()
  public set upcomingNoBookings(value){
   this.upcoming_lessons = value;
  }

    public updateStatusForm = this.fb.group({
    description: ['', Validators.required],
    });

  public updateCancellationForm = this.fb.group({
    cancel_description: ['', Validators.required],
    });
  get cancel_description(){ return this.updateCancellationForm.get('cancel_description');}
  constructor(private router: Router,
              private lessonService: LessonsService,
              private toastr: ToastrService,
              private fb: FormBuilder,
              private localetimezone: LocaleTimeZoneTS) {
                this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
              }

  ngOnInit(): void {
  }

  /*
  Selected Booking Lesson Board
  */
  public viewScheduleDetails(id , dateId){
    this.router.navigate(['student/lessons/scheduled/details',{id:id , dId :dateId}]);
  }

  public viewLessons(){
    this.router.navigate(['student/lessons/scheduled/sch']);
  }

    public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
     this.selectedBookedDateID = bookingRequestId;
     this.bookingId = bookingId;
     this.tutorID = tutorid;
     if ( typeof operation === 'undefined' ) {
       this.operation = 'refund';
     } else {
       this.operation = operation;
     }
   }

    public openScheduleDates(id , data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    if(data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.tutor_id});
      bookingType = 'directCancelation';
    }else{
      Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
      bookingType = 'requestReschedule'; 
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data, bookingType);
    });
 }

   // This Method gets the Reschedule userData
  public requestReschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {operation: 'overDueCancelleation'});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
         let responseData =  JSON.parse(atob(response['data']));
         this.upcoming_lessons = responseData['upcomingClasses'];
         this.scheduleChild.closeScheduleChildModal();
        }
    })
  }

    public messageModal(resume, userData , bookingDetail , notificationSUbject , id){
    this.messageModalData.emit({resume:resume, userData:userData , bookingDetail:bookingDetail , notificationSUbject:notificationSUbject , idName:id});
  }



  public directSingleCancellation(bookingID,bookingDateId){
    this.cancellationId = bookingID;
    this.bookingDateId = bookingDateId;
  } 

  public cancelSingleBooking(){
    let data = Object(); 
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDateId : this.bookingDateId});
    this.lessonService.cancelSingleStudentBooking(data).subscribe(response => {
        let responseData =  JSON.parse(atob(response['data']));
        this.upcoming_lessons = responseData['upcomingClasses'];
        this.toastr.success('Lesson Cancelled');
        this.closeSingleCancellation.nativeElement.click();
    });
  }

      //Raise cancellation
  public requestCancellation(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   this.operation = operation;
  }

  public messageText(value){
    if(value){
      $("#cancelBtn").prop('disabled',false);
    }else{
       $("#cancelBtn").prop('disabled',true);
    } 
  }

    public raiseCancellation(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecancellation') {
      Object.assign(data, {cancel_description: this.updateCancellationForm.get('cancel_description').value});
    }
    this.lessonService.raiseTutorCancellation(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closerequestCancellation.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.upcoming_lessons = responseData['upcomingClasses'];
        }
    })
  }

  public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
      Object.assign(data, {from: 'studentDashboardUpcoming'});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
        this.closeCompliantModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
        this.upcoming_lessons = response;
    });
  }
  
  public AMPMTOTIME(time){
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    let time1 = sHours + ":" + sMinutes +  ":" + "00";
    // console.log(time1)
    return time1;
  }

  public reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
  }

  public calculateTime(classTime, now, statusRequired = '', rating_status){
    
    let classTimeConverted = this.AMPMTOTIME(classTime);
    let time   = classTimeConverted.split(":");
    let hour   = Number(time[0]) * 60 * 60 * 1000;
    let minute = Number(time[1]) * 60 * 1000;
    let second = Number(time[2]) * 1000;
    let result = hour + minute + second;
    
    let currenttime   = now.split(":");
    
    let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
    let currentminute = Number(currenttime[1]) * 60 * 1000;
    let currentsecond = Number(currenttime[2]) * 1000;
    let currentresult = currenthour + currentminute + currentsecond;
    
    let graceHour = +time[0]+1;
    let grace = Number(graceHour) * 60 * 60 * 1000;
    let graceTime = grace + minute + second;
    
    if(currentresult <= graceTime){
    if(result <= currentresult && result <= graceTime){
        return 3;  /*2=> CLASS RUNNING */
       }else{
              var Difference_In_Time = result - currentresult;
                   if(Difference_In_Time == 1000){
                    this.reloadComponent();
                   }else{
                    return Difference_In_Time;
                   }
       }
    }else{
      if(rating_status > 0){
        return 4; /*4=> Rated */
      }else{
        return 2;
        }  /*2=> CLASS COMPLETED */
       }
  }

  public attendClass(bookingId,lesson) {
    this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
    this.lessonService.onVideo(lesson);
  }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}
