import { Component, OnInit, ViewChild, EventEmitter, ElementRef, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LessonsService } from '../../lessons.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MessagemodalComponent } from '@student/findtutor/messagemodal/messagemodal.component';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ScheduleModalComponent } from '../../schedule-modal/schedule-modal.component';
import { BehaviorSubject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.css']
})
export class ScheduleListComponent implements OnInit {

  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild('closeSingleCancellation') closeSingleCancellation: ElementRef;
  @ViewChild('closerequestCancellation') closerequestCancellation: ElementRef;
  @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
  @ViewChild('closeViewRescheduleDeatailsModal') closeViewRescheduleDeatailsModal: ElementRef;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild('closeViewModal') closeViewModal: ElementRef;
  @ViewChild('closeCompliantModal') closeCompliantModal: ElementRef;


  constructor(private lessonService: LessonsService,
              private toastr: ToastrService,
              private router: Router,
              private fb: FormBuilder,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private route: ActivatedRoute) {
  this.lessonService.sharedtutorName.subscribe(name=>this.selectedUserName = name);
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
  this.timezone = this.localetimezone.timezone();
    this.lessonService.sharedBookings.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    this.activeModalId(this.selectedSourceCode);
    });
}

  public ratingForm = this.fb.group({
        review:['',[Validators.required]]
    });
  public selectedTutorId;
  public now;
  public selectedBookingId;
  public Timings;
  public timezone;
  public bookingId;
  public schedules;
  public selectedBookedDateID;
  public tutorID;
  public operation;
  public pendingApprovals;
  public todayClasses;
  public currentDate = new Date(this.localetimezone.date());
  public runningClasses = new Array();
  public ratingGiven;
  public selectedTutorIDForRating;
  public selectedBookedDateIDForRating;
  public selectedTutor;
  public schedules_count;
  public pendingApprovals_count;
  public todayClasses_count;
  public selectedUserName;
  public cancellationId;
  public studentBookings;
  public selectedSourceCode;
  public tutorAvailability;
  public loading$ = new BehaviorSubject(false);
  public activeList :string = 'allLessonsFilterBtn';

  public updateStatusForm = this.fb.group({
    description: ['', Validators.required],
    });
  public updateCancellationForm = this.fb.group({
    cancel_description: ['', Validators.required],
    });
  public errorMessages = {
           description :[{ type:'required',message:'Description is required'}],
           review :[ { type:'required',message:'Review is required'}],
           commentValue :[{type:'required',message:'Comment is required'}],
           cancel_description :[{ type:'required',message:'Description is required'}],
       }

get description(){ return this.updateStatusForm.get('description');}
get review(){ return this.ratingForm.get('review');}
get cancel_description(){ return this.updateCancellationForm.get('cancel_description');}

  ngOnInit(): void {
    var userTime = new Date().toLocaleTimeString('en-US', { timeZone: this.timezone});
    var userDate = new Date().toLocaleDateString('en-US', { timeZone: this.timezone});
    let userData = Object();
    Object.assign(userData , {time:userTime});
    Object.assign(userData , {timeZone: this.timezone});
    Object.assign(userData , {date: userDate});
      this.route.params.subscribe( params =>{
        this.selectedTutor = params['id'];
        if(this.selectedTutor){
          Object.assign(userData , {tutorID: this.selectedTutor});
        }
  	  	this.lessonService.today_UpcomingLessons(this.selectedTutor)
        .subscribe(response => {
         this.responseHandle(response);
        });
      });
  }

  public rescheduledDates(data){
  this.responseHandle(data);
  }


 public openScheduleDates(id , data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    if(data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.tutor_id});
      bookingType = 'directCancelation';
    }else{
      Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
      bookingType = 'requestReschedule';
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data, bookingType);
    });
 }

  public responseHandle(response){
    this.runningClasses = [];
    let responseData =  JSON.parse(atob(response['data'])); 
    console.log(responseData)
    this.schedules = responseData['upcomingClasses'];
    this.schedules_count = this.schedules.length;
    this.pendingApprovals = responseData['pendingApproval'];
    this.pendingApprovals_count = this.pendingApprovals.length;
         this.todayClasses = responseData['todayClasses'];
         this.todayClasses_count = this.todayClasses.length;
         this.todayClassesLoop();
  }

  public todayClassesLoop(){
         for (var tutor of this.todayClasses) {
         let bookedTime = tutor.booked_time;
         let time = this.datePipe.transform(this.now , 'H:mm:ss');
         let statusRequired;
         let status = this.calculateTime(bookedTime , time , statusRequired , tutor.rating_status);
         tutor.status = status;
         this.runningClasses.push(tutor);
      }
  }

  public closeComplaintModal(){
    $('#complaintDescription').val('');
  }

  public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   if ( typeof operation === 'undefined' ) {
     this.operation = 'refund';
   } else {
     this.operation = operation;
   }
  }

  public viewScheduleDetails(id , dateId){
    this.router.navigate(['student/lessons/scheduled/details',{id:id , dId :dateId}]);
  }

  public messageModal(resume, userData , bookingDetail , notificationSUbject, idName = 'bookingDate'){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    (idName == 'bookingDate') ? Object.assign(user,{bookingDateId:bookingDetail}) : Object.assign(user,{bookingId:bookingDetail});
    this.messageChild.userData(resume, user);
  }


  // This Method gets the Reschedule userData
  public requestReschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {operation: 'overDueCancelleation'});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
         this.scheduleChild.closeScheduleChildModal();
        }else if(response.status == 'failed'){
          this.scheduleChild.closeScheduleChildModal();
          this.toastr.error(response.message);
        }
    })
  }

  public attendClass(bookingId,schedule) {
    if(schedule.v_preferred_type == "custom_link")
    {
      window.open(schedule.custom_link);
    }
    else{
      // this.router.navigate(['tutor/tutor-lessons/videoCall', bookingId]);
      this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
    }
    // this.lessonService.onVideo(schedule);
  }

  public manageSchedule(booking_dates_id, booked_id, operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: booking_dates_id});
    Object.assign(data, {booked_id: booked_id});
    Object.assign(data, {operation: operation});
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Schedule Cancelled');
        this.schedules =  JSON.parse(atob(response['schedules']));
        }
    })
  }

  public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closeCompliantModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }
  //Raise cancellation
  public requestCancellation(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   this.operation = operation;
  }

  public raiseCancellation(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecancellation') {
      Object.assign(data, {cancel_description: this.updateCancellationForm.get('cancel_description').value});
    }
    this.lessonService.raiseTutorCancellation(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closerequestCancellation.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }

  public messageText(value){
    if(value){
      $("#cancelBtn").prop('disabled',false);
    }else{
       $("#cancelBtn").prop('disabled',true);
    }
  }

  //End Cancellation
  public calcTime(city, offset) {
    // create Date object for current location
    var d = new Date();

    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000*offset));

    // return time as a string
    return "The local time for city"+ city +" is "+ nd.toLocaleString();
// alert(calcTime('Bombay', '+5.5'));
}

  // Schedule Details
  public viewDetails(dates ,bookingID){
    this.Timings = dates;
    this.selectedBookingId = bookingID;
  }

    // Close closeViewDetailsModal
  public closeViewDetailsModal(){
    this.Timings='';
    this.selectedBookingId='';
    this.closeViewDeatailsModal.nativeElement.click();
  }

  public lessonFilter(id){
    this.activeFilterButton(id);
    this.activeList = id;
  }

  public activeFilterButton(id){
    $('#'+id).addClass('activeFilter').siblings().removeClass('activeFilter')
  }

  public calculateTime(classTime , now , statusRequired = '' , rating_status){
let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*3=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
 }

  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }


public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  public checkTime(){

  }

  public rateTutor(value){

   this.ratingGiven = parseInt(value);
  }

  public clearRatingModal(){
    this.ratingForm.reset();
    $('.star-rating input[type="radio"]').each(function(){
      $(this).prop('checked', false);
  });
  }


  public closeRating(){
    this.ratingForm.reset();
    this.closeRatingModal.nativeElement.click();
  }

  public submitRating(){
   this.ratingForm.markAllAsTouched();
   if(this.ratingForm.valid){
    this.loading$.next(true);
   this.ratingForm.value.rating = this.ratingGiven;
   this.ratingForm.value.selectedBookedDateIDForRating = this.selectedBookedDateIDForRating;
   this.ratingForm.value.selectedTutorIDForRating = this.selectedTutorIDForRating;
  
   this.lessonService.rateTutor(this.ratingForm.value).subscribe(response => {
       this.responseHandle(response);
       this.closeRating();
       this.loading$.next(false);
       this.toastr.success('Rated Successfully');
   });
 }
  }

  public bookingDateId(id , tutorId){
   this.selectedBookedDateIDForRating = id;
   this.selectedTutorIDForRating = tutorId;
  }

  public tutorFilter(name){
   this.selectedUserName = name;
  }

  public cancelCompleteBooking(){
    this.loading$.next(true);
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {from: 'student'});
    this.lessonService.cancelDirectBooking(data).subscribe(response => {
       this.loading$.next(false);
       console.log(response)
       if(response.status == 'failed'){
       this.toastr.error(response.message);
       }else{
       this.toastr.success('Booking Cancelled');
       this.responseHandle(response);
     }
       setTimeout(() => {
       this.closeCancellation.nativeElement.click();
       },100);
    });
  }

  public directCancelation(bookingID){
    this.cancellationId = bookingID;
  }

  public directSingleCancellation(bookingID,bookingDateId){
    this.cancellationId = bookingID;
    this.bookingDateId = bookingDateId;
  }

  public cancelSingleBooking(){
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDateId : this.bookingDateId});
    this.lessonService.cancelSingleStudentBooking(data).subscribe(response => {
      if(response.status == 'failed'){
          this.toastr.error(response.message);
        }else{
        let responseData =  JSON.parse(atob(response['data']));
        this.schedules = responseData['upcomingClasses'];
        this.closeSingleCancellation.nativeElement.click();
        this.toastr.success('Lesson Cancelled');
      }
    });
  }

    public activeModalId(id){
      if(id){
    this.selectedSourceCode = id;
    this.activeModal();
    }
  }

  public activeModal(){
    let sourceCodes = JSON.parse(this.selectedSourceCode);
    for(let code of sourceCodes){
    setTimeout(() =>
      $('#'+code).addClass('bg-theme-color').siblings()
    , 1000);
    }
  }

 

  public transform(value) {  
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
}  
}
