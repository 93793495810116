import { Component, OnInit , ElementRef , ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LessonsService } from '../lessons.service';
import { CurrentUser } from '../../../auth/current-user'
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { MessagemodalComponent } from '../../findtutor/messagemodal/messagemodal.component';
import { ScheduleModalComponent } from '../schedule-modal/schedule-modal.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $;
import { Location } from '@angular/common';
import { PusherService } from 'app/pusher.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  public attachment;
  public commentForm = this.fb.group({
    commentValue: ['',Validators.required]
    });
  get commentValue(){ return this.commentForm.get('commentValue');}
  public now;
  public ratingForm = this.fb.group({
        review:['',[Validators.required]],
        rating: ['', Validators.required],
    });
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeFileUploader') closeFileUploader: ElementRef;
  @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  files: File[] = [];
  formData = new FormData();
  public Comments = new Array();
  public lessonDetails;
  public selectedBoooking;
  public selectedBookingDateId;
  public comment;
  public userId;
  public studentName;
  public operation;
  public bookingId;
  public schedules;
  public selectedBookedDateID;
  public tutorID;
  public date;
  public studentBookings;
  public tutorAvailability;
  public timezone;
  public allowedActions;
  rating3 = 0;
  public errorMessages = {
  review :[ { type:'required',message:'Review is required'}],
  commentValue :[{type:'required',message:'Comment is required'}],
}

get review(){ return this.ratingForm.get('review');}


user:String;
room:String;
messagesText:String;
messageArray:Array<{user:String,message:String}> = [];

  constructor(private router: Router,
  	          private activatedRoute: ActivatedRoute,
  	          private lessonService: LessonsService,
              public currentUser: CurrentUser,
              private fb: FormBuilder,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private _location: Location,
              private toastr: ToastrService,
              private pusherService:PusherService) {
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
  this.date = this.localetimezone.onlyDate();
    
}

  ngOnInit(): void {
    this.userId = this.currentUser.userId();
    this.studentName = this.currentUser.userName();
  	this.selectedBoooking = this.activatedRoute.snapshot.params['id'];
  	this.selectedBookingDateId = this.activatedRoute.snapshot.params['dId'];
     console.log(this.selectedBoooking);
    this.lessonSelected();
  }


  public bind(){
     
    this.pusherService.channel.bind('tutor.message', res => {
      console.log('bind from student lesson board')
      //console.log( res,'asdasdaaaaaaaaaaaaaa');
      //console.log(res[0].original.data,'asdasdaaaaaaaaaaaaaa');
      let data =  JSON.parse(atob(res[0].original.data));
      this.Comments = data['comments'];
      localStorage.setItem('Comments',JSON.stringify(this.Comments));
     // console.log(this.comments);
      $("#postComment").val("");
    });  
  }

  public lessonSelected(){
    console.log('asdasdasdasd');
        let bookingid = Object();
    Object.assign(bookingid , { 'id' : this.selectedBoooking });
    Object.assign(bookingid , { 'did': this.selectedBookingDateId});
    this.lessonService.lessonBoardStudent(bookingid).subscribe(response=>{
    let data =  JSON.parse(atob(response['data']));
     console.log(data);
     this.pusherService.createChannel(this.currentUser.userId(),data.bookingDetails.booking_details.tutor.id);
     setTimeout(()=>{                           
      this.bind();
      }, 5000);
     this.Comments = data['comments'];
     this.lessonDetails = data['bookingDetails'];
     this.allowedActions = this.lessonDetails['student_actions'];
     console.log(this.allowedActions);
         let bookedTime = this.lessonDetails.booked_time;
         let time = this.datePipe.transform(this.now , 'H:mm:ss');
         let statusRequired;
         let status;
         if(data['bookingDetails']['slot_date'] > this.date){
          status = 5;
         }else{
          status = this.calculateTime(bookedTime , time , statusRequired ,  this.lessonDetails.rating_status);
         }
         this.lessonDetails.status = status;
    });
  }

 public backClicked() {
      this._location.back();
      $('.container-fluid.tutorChat.lesson .leftCol').show();
    }

 public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 0){
                 return true;
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}

  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }



  public comentValue(value){
  this.comment = value;
  }

  public postComment(){
      if(this.comment){
      let val = this.comment;
      this.comment = "";
    let commentData = Object();
    Object.assign(commentData,{commentValue:    val});
    Object.assign(commentData,{to_id:           this.lessonDetails.booking_details.tutor_id});
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.lessonDetails.booking_details.booking_id});
    Object.assign(commentData,{booking_dates_id:this.lessonDetails.booking_dates_id});
    Object.assign(commentData,{group_chat_id:this.lessonDetails.group_chat_id});
    this.lessonService.postStudentComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      $("#postComment").val("");
      
    });
    }
  }

  public cancelSchedule(booking_dates_id, booking_id) {

  }

  public closeRating(){
    this.closeRatingModal.nativeElement.click();
  }

  public submitRating(){
   this.ratingForm.markAllAsTouched();
  }

     // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
      }

       // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }

    public removeAllFiles(){
      this.files = [];
      this.commentForm.reset();
      this.formData.delete("file[]");
    }

      public attendClass(bookingId) {
    this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
  }

        // This Method is used to upload the Files in the chat
    public sendFile(){
      this.commentForm.markAllAsTouched();
      if(this.commentForm.valid){
        $('#upload').prop("disabled", true);
        this.formData.append("commentValue", this.commentForm.value.commentValue);
        this.formData.append("booked_id", this.lessonDetails.booking_details.booking_id);
        this.formData.append("booking_dates_id", this.lessonDetails.booking_dates_id);
        this.formData.append("to_id" , this.lessonDetails.booking_details.tutor_id);
        this.formData.append("from_id" , this.currentUser.userId().toString());
        this.formData.append('group_chat_id',this.lessonDetails.group_chat_id);
      this.lessonService.UploadFiles(this.formData).subscribe(response => {
      if(response['status'] == 'success'){
      this.closeFileUploader.nativeElement.click();
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      $('#upload').prop("disabled", false);
        }
      })
    }
    }

        // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }


    public filesSendMessage(file){


    }

    public viewAttachmentImage(image){
     this.attachment = image;
    }

    public closeFileUploaderModal(){
      this.removeAllFiles();
    }

  public messageModal(resume, userData , bookingDetail , notificationSUbject){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    Object.assign(user,{bookingDateId:bookingDetail});
    this.messageChild.userData(resume, user);
  }


   public openScheduleDates(id , data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    if(data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.tutor_id});
      bookingType = 'directCancelation';
    }else{
      Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
      bookingType = 'requestReschedule';
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data, bookingType);
    });
 }

   public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   if ( typeof operation === 'undefined' ) {
     this.operation = 'refund';
   } else {
     this.operation = operation;
   }
  }

  // This Method gets the Reschedule userData
  public requestReschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {operation: 'overDueCancelleation'});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
        this.lessonSelected();
        }
    })
  }

  public keyPressHandler(e) {
    //
    if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
      this.postComment()
      e.preventDefault();
    }
  }

  // socket services
  // join(){
  //   this._socketService.joinRoom({user:this.studentName, room:this.selectedBoooking});
  // }

  // leave(){
  //     this._socketService.leaveRoom({user:this.studentName, room:this.selectedBoooking});
  // }

  // sendMessage()
  // {
  //     this._socketService.sendMessage({user:this.studentName, room:this.selectedBoooking, message:this.messagesText});
  // }
  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}

