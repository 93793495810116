import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from '../../../auth/current-user'
import { LessonsService } from '../../lessons/lessons.service';
declare var $;

@Component({
  selector: 'app-recent-alerts',
  templateUrl: './recent-alerts.component.html',
  styleUrls: ['./recent-alerts.component.css']
})
export class RecentAlertsComponent implements OnInit {
  
  public alert;
  public recent_alerts;
  public recent_alerts_count = 0;
  public bookingDateId;
  public isDataLoaded = false;
  public tutor;
  public subject;
  public bookingDetails;
  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count;
  public usernotifications_unread;
  public usernotifications_unread_count;
  public usermessages_unread_count;
  public bookingDate;
  public Comments;
  public comment;
  @Input()
  public set recentAlertsDetails(value){
  this.recent_alerts = value;
  this.recent_alerts_count = value?.length
  }
  constructor(private router: Router,
              public service: LessonsService,
              public currentUser: CurrentUser,
              private lessonService: LessonsService) { }

  ngOnInit(): void {
  }

  // public openAlerts(){
  //   this.router.navigate(['student/student-alert']);
  // }

  public openAlertModal(alert){
    this.alert = alert;
    this.bookingDateId = this.alert.source_data;
    this.alertBooking();
  }

  public alertBooking(){
    this.service.alertBooking(this.bookingDateId).subscribe(response =>{
      if(response['status'] == 'success'){
      let resposne =  JSON.parse(atob(response['booking']));
      this.bookingDate = resposne;
      this.tutor = this.bookingDate.booking_details.tutor_resume;
      this.subject = this.bookingDate.booking_details.subject;
      this.bookingDetails = this.bookingDate.booking_details;
      this.Comments = this.bookingDate.comments;
      this.isDataLoaded = true;
     }
   }, err => {
              console.log(err);
              // this.loading$.next(false);
              // this.toastr.error(err.status, 'Invalid details');/
            });
  }

    public keyPressHandler(e) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.postComment()
     e.preventDefault();
  }
}

  public postComment(){
    let commentData = Object();
    Object.assign(commentData,{commentValue:    this.comment});
    Object.assign(commentData,{to_id:           this.bookingDetails.tutor_id});
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.bookingDetails.booking_id});
    Object.assign(commentData,{booking_dates_id:this.bookingDate.booking_dates_id});
    this.lessonService.postStudentComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      $("#postComment").val("");
    });
  }

    public comentValue(value){
  this.comment = value;
  }

    public viewAlert(notification){

      console.log(notification)
     this.router.navigate([notification.redirect_url]);
      if(notification.is_read == 0){
        this.service.readNotification(notification.id).subscribe(response=>{
      });
      }
  }

  // alerts
  public openAlerts(){
    // this.alertModal.nativeElement.click();
        if($("#alert-box-alerts").hasClass("activeAlert")){
          $("#alert-box-alerts").removeClass("activeAlert");
        }else{
          $("#alert-box-alerts").addClass("activeAlert");
        }

       this.getNotifications();
  }

  public getNotifications(){
    this.lessonService.getNotification().subscribe(response=>{

    if ( response['status'] == 'authfailed' ) {
      location.reload();
    } else {
      let data =JSON.parse(atob(response['notifications']));

      this.usernotifications = data['notifications'];

      this.usernotifications_read = this.usernotifications.is_read;
      this.usernotifications_read_count = this.usernotifications.is_read.length;
      
      this.usernotifications_unread = this.usernotifications.is_unread;
      this.usernotifications_unread_count = this.usernotifications.is_unread.length;

      this.usermessages_unread_count = data['inboxCount'];
    }

    });
  }

  public humanize(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }
  
  public displayTextLimit(text) {
    if ( text.length > 150 ) {
      return text.substring(0, 150) + '...';
    } else {
      return text;
    }
  }


}
