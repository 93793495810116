<div class="TT-cardds">
   <div class="Table-Height TT-tableHeight">
      <h4 class="TT-title">Upcoming Lessons</h4>

         <table *ngIf="upcoming_lessons != 'No Bookings'" class="table TT-table TT-autoWidthTable">
            <thead>
               <tr>
                  <th trans="Subject">Subject</th>
                  <th trans="Tutor">Tutor</th>
                  <th trans="Date">Date</th>
                  <th trans="Time">Time</th>
                  <th trans="Status">Status</th>
                  <th trans="Countdown">Countdown</th>
                  <th colspan="2" trans="Actions" class="text-center">Actions</th>
               </tr>
            </thead>
            <tr *ngIf="upcoming_lessons_count == 0" class="TT-notify TT-noRecorded">
               <td colspan="8" align="center" trans="No Records Found" class="TT-noRecords">
                  <div class="text-center">Not Found Lessons</div>
               </td>
            </tr>
            <tbody>
               <tr *ngFor="let lesson of upcoming_lessons">
                  <td>{{lesson['booking_details']['subject'].subject_name}}</td>
                  <td>{{lesson['booking_details']['tutor_resume'].display_name}}</td>
                  <td>{{lesson.slot_date | date: 'MM-dd-yyyy'}}</td>
                  <td>{{lesson.booked_time}}</td>
                  <td>{{transform(lesson.booking_dates_status)}}</td>
                  <td>
                     <span
                        *ngIf="lesson.status!= '2' && lesson.status!= '3' && lesson.status!= '4' && lesson.display_type == 'today'">
                        {{calculateTime(lesson.booked_time
                        , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}
                     </span>
                     <span *ngIf="lesson.status== '3'" trans="CLASS RUNNING">Class Running</span>
                     <span *ngIf="lesson.status== '2'" trans="CLASS COMPLETED">Class Completed</span>
                     <span *ngIf="lesson.display_type == 'upcoming'">N/A</span>
                  </td>
                  <td class="TT-tableDropDownMenuList" >
                    <div class="TT-formGroup"> 
                      <button *ngIf="lesson.status== '3'" type="button" class="TT-primaryBTN" value="Attend" (click)="attendClass(lesson.booking_dates_id,lesson)" trans="Attend">
                       Attend
                      </button>
                     </div>                    
                  </td>
                  <td class="dropdown TT-tableDropDownMenuList">
                     <i class="fa fa-ellipsis-v data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                     <ul  class="TT-dropDownMenu dropdown-menu dropdown-new" style="min-width: 50px;">
                        <li class="TT-optionsList" (click)="viewScheduleDetails(lesson['booking_details'].booking_id , lesson.booking_dates_id)">
                          <a class="TT-frameSet" trans="View Details">View Details</a></li>
                        <li class="TT-optionsList" (click)="messageModal(lesson['booking_details']['tutor_resume'].display_name, lesson['booking_details'].tutor_id ,lesson.booking_dates_id, 'upcoming schedule','bookingDate')" href="#messageModal" data-toggle="modal">
                          <a class="TT-frameSet" trans="Message Tutor">Message Tutor</a></li>
                        <li class="TT-optionsList" *ngIf="lesson.status !='3' && lesson.booking_details?.booking_type != 'group'" (click)="openScheduleDates(lesson.booking_details.student_id , lesson)" href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal">
                          <a class="TT-frameSet" trans="Request reschedule" (click)="reScheduleRequest(lesson['booking_details'].tutor_id , lesson['booking_details'].booking_id , lesson.booking_dates_id)">
                           Request Reschedule
                          </a>
                        </li>
                        <li class="TT-optionsList" *ngIf="lesson.cancel_status == 'askreschedule_sendtutorcancellationrequest' && lesson.booking_details?.booking_type != 'group'">
                          <a class="TT-frameSet" href="#requestCancellation" data-target="#requestCancellation" data-toggle="modal" (click)="requestCancellation(lesson['booking_details'].tutor_id ,  lesson['booking_details'].booking_id , lesson.booking_dates_id,'requestcancellation')" trans="request_cancellation">
                           Request Cancellation
                          </a>
                        </li>
                        <li class="TT-optionsList" *ngIf="lesson.cancel_status == 'ask_opencomplaint'">
                          <a class="TT-frameSet" href="#requestRefund" data-target="#requestRefund" data-toggle="modal" (click)="reScheduleRequest(lesson['booking_details'].tutor_id , lesson['booking_details'].booking_id , lesson.booking_dates_id ,'raisecomplaint')" trans="raise_complaint">Raise Complaint</a>
                        </li>
                        <li class="TT-optionsList" *ngIf="lesson.cancel_status == 'directCancellation' && lesson.booking_details?.booking_type != 'group'" href="#directSingleCancellation"
                           data-target="#directSingleCancellation" data-toggle="modal"
                           (click)="directSingleCancellation(lesson['booking_details'].booking_id , lesson.booking_dates_id)"
                           trans="direct_cancellation">
                           <a class="TT-frameSet">Direct Cancellation</a>
                        </li>
                     </ul>
                  </td>
               </tr>
            </tbody>
         </table>
      
      <div class="TT-noBookings" *ngIf="upcoming_lessons == 'No Bookings'">

         <p class="TT-middleHighlite">
            <span class="TT-wrapCoverLife">
               <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
               <span trans="You don't have any booked lessons.Please use the search tool to find a teacher">
                  You don't have any booked lessons.Please use the search tool to find a teacher
               </span>
            </span>
         </p>

         <div class="TT-innerCover">
            <div class="TT-clickable TT-toppers" *ngIf="upcoming_lessons != 'No Bookings'">
               <a (click)="viewLessons()" class="TT-click">
                  <span trans="Update >>>">Update >>></span>
               </a>
            </div>
         </div>
      </div>
   </div>
      <div class="modal fade" id="directSingleCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
         #closeSingleCancellation>
         <div class="modal-dialog mt-100" role="document">
            <div class="modal-content">
               <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
                  <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                     <span trans="Direct Cancellation">Direct Cancellation</span>
                  </h5>
                  <button type="button" class="close" #closeSingleCancellation data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body TT-formGroup">
                     <p style="color: #000;"
                        trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you
                        sure you want to cancel this booking and refund the amount to wallet?</p>
                     <button type="button" class="TT-primaryBTN" (click)="cancelSingleBooking()"
                        trans="Cancel Booking" style="background-color: #d43f3a;width:auto;padding:8px 35px
                     ">Cancel Booking</button>
                     &nbsp;&nbsp;
                     <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal"
                        aria-label="Close" #closeViewModal trans="Close" style="width:auto;padding:8px 35px
                        ">Close</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
         <div class="modal-dialog mt-100" role="document">
            <div class="modal-content">
               <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
                  <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                     <span trans="Request Cancellation">Request Cancellation</span>
                  </h5>
                  <button type="button" class="close" #closerequestCancellation data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                     <p style="color: #000;" trans="Are you sure you want to cancel the reschedule request?">Are you
                        sure you want to cancellation request for this booking?</p>
                     <form [formGroup]="updateCancellationForm" role="form" class="form-horizontal TT-formGroup">
                        <div class="form-group">
                           <p class="greycolor" trans="Description">
                              Description
                           </p>
                           <div class="col-sm-12">
                              <textarea name="description" id="description" formControlName="cancel_description"
                                 (input)="messageText($event.target.value)" class="form-control" rows="10"
                                 cols="30"></textarea>
                           </div>
                        </div>
                        <button disabled type="button" id="cancelBtn" class="btn TT-primaryBTN"
                           (click)="raiseCancellation('raisecancellation')">Cancel Booking</button>
                        &nbsp;&nbsp;
                        <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                           aria-label="Close" #closerequestCancellation trans="Close">Close</button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!--Upcoming Lessons Request Refund Details  -->
      <div class="modal fade" id="requestRefund" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
         <div class="modal-dialog mt-100" role="document">
            <div class="modal-content">
               <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
                  <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
                     <span *ngIf="operation == 'refund'" trans="Refund Schedule">Refund Schedule</span>
                     <span *ngIf="operation == 'raisecomplaint'" trans="Raise Compliant">Raise Compliant</span>
                  </h5>
                  <button type="button" class="close" #closeCompliantModal data-dismiss="modal" aria-label="Close"
                     style="margin-top:-30px;">
                     <span aria-hidden="true">&times;</span>
                  </button>
                  <div class="modal-body">
                     <p trans="sure_refund_amount" style="color: #000;" *ngIf="operation == 'refund'"
                        trans="Are you sure you want to cancel schedule and refund the amount to wallet?">Are you sure
                        you want to cancel schedule and refund the amount to wallet?</p>
                     <p trans="write_description" style="color: #000;" *ngIf="operation == 'raisecomplaint'"
                        trans="Write your description here, to help you out">Write your description here, to help you
                        out</p>
                     <form [formGroup]="updateStatusForm" role="form" class="form-horizontal"
                        *ngIf="operation == 'raisecomplaint'">
                        <div class="form-group">
                           <p class="greycolor" trans="Description">
                              Description
                           </p>
                           <div class="col-sm-12">
                              <textarea name="description" id="description" formControlName="description"
                                 class="form-control" rows="10" cols="30"></textarea>
                           </div>
                        </div>
                        <button type="button" class="btn btn-success new" trans="submit_complaint"
                           (click)="manageSchedule2('raisecomplaint')" trans="Submit Complaint">Submit
                           Complaint</button>
                        &nbsp;&nbsp;
                        <button type="button" class="btn btn-primary new" trans="close" data-dismiss="modal"
                           aria-label="Close" trans="Close">Close</button>
                     </form>
                     <span *ngIf="operation == 'refund'" class="TT-formGroup">
                        <button type="button" class="btn TT-primaryBTN" trans="refund"
                           (click)="manageSchedule2('refund')" trans="Refund">Refund</button>
                        &nbsp;&nbsp;
                        <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                           aria-label="Close" #closeViewModal trans="Close">Close</button>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <app-schedule-modal (rescheduleData)="requestReschedule($event)"></app-schedule-modal>
      </div>