<div class="d-flex" trans="Filter">
   <div class="TT-TransFilter" *ngIf="selectedFilterName">
      <h4>{{selectedFilterName}} 
        <span (click)="removeWalletFilter()">&times;</span>
      </h4>
    </div>
    
    <div class="dropdown TT-dropdownns">
      <button id="imageDropdown" class="btn btn-primary TT-secondaryBTN dropdown-toggle" type="button" data-toggle="dropdown">
         Filter
        <i class="fa fa-filter" aria-hidden="true"></i>
      </button>
      <ul class="dropdown-menu TT-menuDropdown TT-menuDropdown_font">
        <li role="presentation">
         <a role="menuitem" tabindex="-1" (click)="selectSearch('PayPal')" trans="PayPal"> PayPal </a>
        </li>
        <li role="presentation">
         <a role="menuitem" tabindex="-1" (click)="selectSearch('Stripe')" trans="Stripe">Stripe</a>
        </li>
      </ul>
    </div>
</div>