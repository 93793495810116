import { Component, OnInit } from '@angular/core';
import { WebSocketService } from '../../core/services/web-socket.service';
import { ChatService } from '../../athand/chat/chat.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-chat',
  templateUrl: './student-chat.component.html',
  styleUrls: ['./student-chat.component.css']
})
export class StudentChatComponent implements OnInit {
  
  public conversationLists;
  constructor(public chat: ChatService,
  	private bpo: BreakpointObserver,
  	private router: Router) { }

  ngOnInit(): void {
  }
   
}
