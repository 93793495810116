import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.css']
})
export class NoteModalComponent implements OnInit {
  
  @ViewChild('closeNoteModal') closeNoteModal: ElementRef;
  @Output() noteEmitter = new EventEmitter<string>();
  public noteForm = this.fb.group({
        note: ['',[Validators.required]]
  });

  get note(){ return this.noteForm.get('note');}
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  public noteEvent(){
    this.noteEmitter.emit(this.noteForm.value.note);
  }

  public closeModal(){
  this.closeNoteModal.nativeElement.click();
  }

}
