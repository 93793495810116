
<div class="TT-cardds">
   <div class="Table-Height TT-tableHeight">
   <h4 class="TT-title" trans="Profile Summary">Profile Summary <span class="led-Green"></span></h4>
     <div class="TT-innerCover">
       <div class="TT-overlayLayer">
       <h4 class="TT-title1 TT-areaTitles clearfix">
        <b class="TT-textHeadline pull-left">{{currentuser.fullName()}}</b>
        <span class="TT-tagLineText pull-right">
          <span style="position:relative;top:-10px;"> </span>
        </span>
      </h4>
       </div>
       <div class="TT-overlayLayer">
       <div class="TT-title1 TT-titleExtra">
       <span trans="From : " class="TT-tagLineText"> From:</span>
       <b class="TT-textHeadline">&nbsp; {{currentuser.country()}} </b>
       </div>
       </div>
       <div class="TT-overlayLayer">
        <div class="TT-title1 TT-titleExtra">
         <span trans="Email : " class="TT-tagLineText"> Email : </span>
         <b class="TT-textHeadline">&nbsp;{{currentuser.userEmail()}}</b>
        </div>
       </div>
       
       <div (click)="editProfile()" class="TT-clickable TT-toppers">
         <a class="TT-click" trans="Edit">Edit</a> |
         <a class="TT-click" trans="Full Profile >>>">Full Profile >>></a>
       </div>
    </div>
   </div>
 </div>