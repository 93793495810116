import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TransactionLog} from '../core/types/models/TransactionLog';

@Injectable({
    providedIn: 'root'
})
export class Transactions {
	public transaction$ = new BehaviorSubject<Partial<TransactionLog[]>>([]);
	changeTransaction$ = this.transaction$.asObservable();

	 public init(params) {
        this.transaction$.next(params);
    }

    public currencies(){
    	return this.transaction$.value;
    }

}