import {Component, OnInit, ChangeDetectionStrategy, Input , Injectable , Injector} from '@angular/core';
import {DatatableService} from '@core/datatable/datatable.service';
import {Model} from '@core/types/models/Model';
import {FormBuilder} from '@angular/forms';
import { Translations } from '@core/translations/translations.service';

@Component({
  selector: 'app-transaction-filter',
  templateUrl: './transaction-filter.component.html',
  styleUrls: ['./transaction-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionFilterComponent implements OnInit {
  public form = this.fb.group({
        filter: null,
  });
  public search;
  public selectedFilter;
  public selectedFilterName;
  constructor(private datable: DatatableService<Model>,
  	          private fb: FormBuilder,
              protected injector: Injector,
              protected i18n: Translations) { 
  this.i18n = this.injector.get(Translations);
  this.form.patchValue(this.datable.filters$.value);
  }

  ngOnInit(): void {
        this.form.valueChanges.subscribe(value => {
            this.datable.filters$.next(value);
        });
  }

  public selectSearch(search) {
    this.selectedFilter = search;
    this.selectedFilterName = (this.i18n['localization']['lines'][this.selectedFilter]) ? this.i18n['localization']['lines'][this.selectedFilter] : this.selectedFilter;
    this.search = search;
    this.form.patchValue({
        filter:this.search,
    });
  }

  public removeWalletFilter(){
    this.selectedFilter = '';
    this.selectedFilterName = '';
    this.form.patchValue({
        filter: null,
    });
  }
}
