import { Component, OnChanges , Output , EventEmitter, Input } from '@angular/core';
import { Options, LabelType } from "@angular-slider/ngx-slider";
declare var $;

@Component({
  selector: 'app-priceselector',
  templateUrl: './priceselector.component.html',
  styleUrls: ['./priceselector.component.css']
})
export class PriceselectorComponent implements OnChanges {
  @Input() currency;
  @Output() minRange = new EventEmitter();
  @Output() maxRange = new EventEmitter();
	minValue: number = 0;
  maxValue: number = 0;
  options;
  constructor() { }
  
  ngOnChanges(): void {
    this.options = {
          floor: 0,
          ceil: 500,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.currency + value;
        case LabelType.High:
          return this.currency + value;
        default:
          return '' + value;
      }
    }
    }
  }

  public minValueChange(value){
    this.minRange.emit(value);
  }

  public maxValueChange(value){
    this.maxRange.emit(value);
  }



}
