import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../../auth/current-user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.css']
})
export class ProfileSummaryComponent implements OnInit {

  constructor(public currentuser: CurrentUser,
  	          public router: Router) { }

  ngOnInit(): void {
  }

  public editProfile(){
    this.router.navigate(['student/student-account/settings']);
  }

}
