import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  
  @ViewChild('closeSuccessModal') closeSuccessModal: ElementRef;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public viewSchedules(){
  	this.closeSuccessModal.nativeElement.click();
  	this.router.navigate(['student/lessons']);
  }

}
