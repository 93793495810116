import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';
import { WebSocketService } from '@core/services/web-socket.service';
import { FindTutor } from '../findtutor.service';
import { CurrentUser } from '@auth/current-user';
import { randomString } from '@core/utils/random-string';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-messagemodal',
  templateUrl: './messagemodal.component.html',
  styleUrls: ['./messagemodal.component.css']
})

export class MessagemodalComponent implements OnInit {
   @ViewChild('closeModal') closeModal: ElementRef;
   @ViewChild('openLoginModal') openLoginModal: ElementRef;
   get messageValue(){ return this.messageForm.get('messageValue');}

  constructor(private websocket: WebSocketService,
  	          public findTutorSerivice:FindTutor,
  	          public currentuser: CurrentUser,
              private fb: FormBuilder,
              private toastr: ToastrService) {

              this.findTutorSerivice.shareddisplayNameData.subscribe(displayname=>{
                if( displayname ) {
                  this.selectedTutorName = displayname;
                }
              }); 

              this.findTutorSerivice.shareduserData.subscribe(userdataonly=>{
                if( userdataonly ) {
                  this.selecteduserData = userdataonly;
                }
              }); 

            }

  public messageForm = this.fb.group({
     messageValue:   ['',[ Validators.required,
                        Validators.minLength(180), 
                        Validators.maxLength(300)]]                  
    });
  public messageLimit :number = 300;
  public charactersCount: number = 0;
  public selectedTutorName;
  public selecteduserData; 
  public message;
  ngOnInit(): void {
  }

  public userData(name, userData){
   this.selectedTutorName = name;
   this.selecteduserData = userData;
  }

  public messageText(value){
    this.charactersCount = value.length; 
  	if(value){
    this.message = value;
  		$("#sendBtn").prop('disabled',false);
  	}else{
      $("#sendBtn").prop('disabled',true);
  	} 
  }

  public sendMessage(){
    if(this.currentuser.isLoggedIn()){
      if ( this.message != '' ) {
        let time = new Date().getTime();
        let messageData = Object();
        Object.assign(messageData , {to_id:''+this.selecteduserData.id});
        Object.assign(messageData , {user:this.selecteduserData});
        Object.assign(messageData , {message:this.message});
        Object.assign(time , {time:time});
        Object.assign(messageData , {senderName:this.currentuser.userName()});
        Object.assign(messageData , {randomMsgId : randomString(13)});
        if(this.selecteduserData.sendAsNotification){
        Object.assign(messageData , {sendAsNotification: this.selecteduserData.sendAsNotification});
        }
        this.findTutorSerivice.sendMessage(messageData).subscribe(response=>{
          $('#message').val('');
          if(response.status == 'success'){
                this.toastr.success('Message Sent Successfully');
                this.closeModal.nativeElement.click();
                this.message = '';
                this.messageForm.reset();
                this.charactersCount = 0;
              }
        })
      } else {
        this.toastr.error("Please fill the Message field");
      }
  	
  }else{
    this.openLoginModal.nativeElement.click();
  }
}


}
