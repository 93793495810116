import { Component, OnInit, Input, Output, ViewChild,  EventEmitter , ElementRef, AfterViewChecked, OnChanges, ChangeDetectorRef , Injectable, Injector } from '@angular/core';
import { FindTutor } from '../findtutor.service';
import { LocaleCurrency } from '@auth/localecurrency';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { WalletAmountTS } from '@auth/walletamount';
import { Currencies } from '@auth/currencies';
import { NgForm } from '@angular/forms';
import { CurrentUser } from '@auth/current-user';
import { ProfileService } from '@athand/profile/profile.service';
import { StudentPaymentService } from '@student/student-payment/student-payment.service';
import { PaymentDetail } from '@auth/paymentdetail';
import { Translations } from '@core/translations/translations.service';
import { finalize } from 'rxjs/operators';
declare var paypal;
declare var $;
declare var elements;
declare var stripe;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})

export class CheckoutComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('closeCheckoutModal') closeCheckoutModal: ElementRef;
  @Input()
  public set clearCheckOutModalData(val){
    this.closeModal();
  }

  @Input()
  public set checkOutDataMain(val){
    $("#new-card-button-container").attr("disabled",false);
    this.checkOutData = val;
    if(val){
    this.slotsSelected = val['lessonsData'];
    this.checkOutData.lessonsData = this.slotsSelected;
    this.showOnePayment();
    this.convertCurrency(this.checkOutData['totalPrice'] , 'totalPrice');
    this.convertCurrency(this.checkOutData['discountedPrice'] , 'discountedPrice');
    this.walletAmount = this.wallet.wallet();
    this.walletStatus = this.wallet.wallet();
    this.resetSwitchPayment()
    }
  }
  @Output() removeDataFromScheduleModal = new EventEmitter();
  @Output() scheduleModal = new EventEmitter();
  @Output() bookingCompleted = new EventEmitter();

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  public finalPrice: number;
  public selectedLessons;
  public userDetails;
  public discountPriceInpounds;
  public singleLessonPrice;
  public selectedCurrency;
  public currentDate = new Date().toDateString();
  public transactionDetails:any;
  public walletStatus;
  public walletAmount;
  public popularCurrencies;
  public GBP;
  public finalPriceInpounds;
  public slotsSelected;
  public checkOutData;
  public paypalRendred;
  public payment_methods;
  public selectedCard;
  public paypal_address;
  public loading$ = new BehaviorSubject(false);
  public loadingpaypal$ = new BehaviorSubject(false);
  public selectedPaymenttype = 'stripe';

  constructor(public findTutorSerivice:FindTutor,
              public localecurrency: LocaleCurrency,
              private toastr: ToastrService,
              private wallet: WalletAmountTS,
              public currencies: Currencies,
              protected i18n: Translations,
              private cd: ChangeDetectorRef,
              protected injector: Injector,
              public element: ElementRef,
              public currentuser: CurrentUser,
              public profileService: ProfileService,
              public service: StudentPaymentService,
              public payment: PaymentDetail) {

    this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
     });

    this.i18n = this.injector.get(Translations);

  this.payment.changepaymentDetails$.subscribe(response=>{
   this.payment_methods = response;
  });

}

ngAfterViewInit() {
this.initiateCardElement();
}

initiateCardElement() {
        // Giving a base style here, but most of the style is in scss file
        const cardStyle = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };
        this.card = elements.create('card', {cardStyle});
        if (typeof this.card !== 'undefined') {
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
        this.card.addEventListener('change', function(event){
                    $("#new-card-button-container").css("display", "inline-block");
                    $("#existing-card-button-container").css("display", "none");
                    $("#paypal-button-container").css("display", "none");
                    $("#wallet-button-container").css("display", "none");
                    $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
                    $("#card1").prop('checked',true);
        });
        }
    }


ngOnDestroy() {
        if (typeof this.card !== 'undefined' && this.card) {
            this.card.destroy();
        }
    }


 onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }




ngOnChanges(){
 
  if(this.checkOutData){
    this.finalPrice = this.checkOutData['totalPrice'];
    this.popularCurrencies = this.currencies.currencies();
    this.convertCurrency(this.finalPrice);
    
   if(!this.paypalRendred){
        // Render the PayPal button into #paypal-button-container
        paypal.Buttons({
            // Set up the transaction
            createOrder: (data, actions ) => {
              // console.log(this.checkOutData['userDetails']['connected_stripe_id'])
              return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency: 'USD',
                            value: this.finalPriceInpounds,
                        },
                        payee: {
                          email_address: this.checkOutData['userDetails']['paypal_iban'] // https://developer.paypal.com/docs/checkout/standard/customize/pay-another-account/
                        }
                    }]
                });
            },

            // Finalize the transaction
            onApprove: async (data, actions)=>{
             const order = await actions.order.capture();
             this.transactionDetails = order;
             this.transactionDetails['payment_gateway'] = 'paypal';
             this.transactionDetails['actions'] = actions;
                   
             this.bookLesson(this.transactionDetails);
            },
            onError: err =>{
              console.log(err);
            }
        }).render('#paypal-button-container');
    this.paypalRendred = true;
  }
  }
}

  ngOnInit(): void {
    this.walletStatus = this.wallet.wallet();
    
    this.showOnePayment(); 
  }

  public switchPayment(value){
    console.log('afsdfasdf')
    this.selectedPaymenttype = value;

    if (value === 'paymentMethod') {
                    $("#existing-card-button-container").css("display", "inline-block");
                    $("#paypal-button-container").css("display", "none");
                    $("#wallet-button-container").css("display", "none");
                    $("#new-card-button-container").css("display", "none");
                    $("#stripeExistingCard").addClass("activerow").siblings().removeClass("activerow");
                    $("#payment_methods").prop('checked', true);
                }

                if (value === 'paypal') {
                 
                    $("#new-card-button-container").css("display", "none");
                    $("#existing-card-button-container").css("display", "none");
                    $("#wallet-button-container").css("display", "none");
                    $("#paypalCard").addClass("activerow").siblings().removeClass("activerow");
                    $("#card12").prop('checked', true);
                    if(this.checkOutData['bookingType'] == 'schedule'){
                    let data = Object();
                    console.log(data)
                    Object.assign(data,{scheduleDetails: this.checkOutData['lessonsData']});
                    Object.assign(data,{tutor_id : this.checkOutData['userDetails']['id']});
                    this.findTutorSerivice.validateLesson(data)
                    .pipe(finalize(() => this.loading$.next(false)))
                    .subscribe(response=>{
                          if(response['status'] == 'success'){
                            $("#paypal-button-container").css("display", "inline-block");
                          }else if(response.status == 'failed'){
                           this.toastr.error(response.message);
                          }
                     });
                  }else{
                     $("#paypal-button-container").css("display", "inline-block");
                  }
                }

                // If Card is selected, show the standard continue button
                if (value === 'card') {
                    $("#new-card-button-container").css("display", "inline-block");
                    $("#existing-card-button-container").css("display", "none");
                    $("#paypal-button-container").css("display", "none");
                    $("#wallet-button-container").css("display", "none");
                    $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
                    $("#card1").prop('checked',true);
                }

  }

public allowWallet(){
  if(this.checkWalletAccess()){
    if ( typeof this.card !== 'undefined' ) {
      this.card.clear();
    }
    $("#new-card-button-container").css("display", "none");
    $("#existing-card-button-container").css("display", "none");
    $("#paypal-button-container").css("display", "none");
    $("#wallet-button-container").css("display", "inline-block");
    $("#walletCard").addClass("activerow").siblings().removeClass("activerow");
    $("#card13").prop('checked',true);
    }
  }

  public showOnePayment(){
            // Hide Non-PayPal button by default
        $("#new-card-button-container").css("display", "inline-block");
        $("#paypal-button-container").css("display", "none");
        $("#wallet-button-container").css("display", "none");
        $("#existing-card-button-container").css("display", "none");
  }

  public bookLesson(paymentDetails:''){
    console.log('aaaaaaaaaaaaa')
    this.loadingpaypal$.next(true);      
    // $('#paypal-button-container').prop('disabled', true);
    $('#paypal-button-container').hide();
    let BookingData = Object();
    Object.assign(BookingData,{scheduleDetails: this.checkOutData});
    Object.assign(BookingData,{paymentDetails: paymentDetails});
    this.findTutorSerivice.bookLesson(BookingData)
       .pipe(finalize(() => this.loadingpaypal$.next(false)))
      .subscribe(response=>{
      if(response.status == 'success'){
       this.toastr.success('Lessons Booked Successfully');
       this.bookingCompleted.emit('true');
       this.closeCheckoutModal.nativeElement.click();
      }else if(response.status == 'failed'){
        this.toastr.error(response.message);
      }
    });
  }

  public bookLessonWithWallet(){
    this.loading$.next(true);
    let BookingData = Object();
    let paymentDetails = Object();
    Object.assign(BookingData,{scheduleDetails: this.checkOutData});
    Object.assign(BookingData,{amount: this.finalPriceInpounds * 100});
    Object.assign(BookingData,{discountedAmount: this.discountPriceInpounds * 100});
    Object.assign(paymentDetails,{update_time:new Date()});
    this.findTutorSerivice.bookLessonwithWallet(BookingData)
      .pipe(finalize(() => this.loading$.next(false)))
      .subscribe(response=>{
      if(response.status == 'success'){
       this.walletAmount =  JSON.parse(atob(response['booking']));
       this.wallet.init(this.walletAmount);
       this.toastr.success('Lessons Booked Successfully');
       this.bookingCompleted.emit('true');
       this.closeCheckoutModal.nativeElement.click();
      }else if(response.status == 'failed'){
        this.toastr.error(response.message);
      }
    });
  }

  public closeModal(){
    if(this.card){
    this.card.clear();
  }
    if(this.checkOutData){
    this.checkOutData['lessonsData'] = [];
    this.removeDataFromScheduleModal.emit(true);
    this.slotsSelected = '';
   }
  }

  public convertCurrency(finalPrice , price = ''){
    if(this.checkOutData){
       this.popularCurrencies.find(x => {
       if(x.code == this.selectedCurrency.code){
         let poundExchnageRate = x.exchange_rate;
         let currencyInDollars = finalPrice/poundExchnageRate;
         let finalselectedCurrency = currencyInDollars * this.GBP.exchange_rate; // This is USD exchange rate. For the sake of back compatibility we used this variable name!
         let value = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
         if(price == 'discountedPrice'){
          this.discountPriceInpounds = value;
         }else {
          this.finalPriceInpounds = value;
         if(parseFloat(this.finalPriceInpounds) > parseFloat(this.walletAmount)){
           this.walletStatus = 'Insufficent Amount In Wallet';
           $('#wallet-button-container').prop('disabled', true);
         }else{
            $('#wallet-button-container').prop('disabled', false);
         }
         }
       }
     });
     }
  }

  public currency(changeCurrency){
      this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });
      this.selectedCurrency = changeCurrency;
      this.finalPriceInpounds = this.convertCurrency(this.finalPrice);
    }

       /*get Latest Wallet Balance*/
   getWalletBalance(currency , symbol){
    this.findTutorSerivice.userWalletBalance(currency , symbol).subscribe(response =>{ 
      if(response['balance']){
       let balance = response['balance'].replace(/\,/g,'');
       balance = (Math.round(balance * 100) / 100).toFixed(2);
       this.wallet.init(balance);
      }
    });
   }

    public openScheduleModal(){
        this.card.clear();
        let data = Object();
        Object.assign(data ,{lessonData: this.checkOutData['lessonData']});
        this.scheduleModal.emit(data);
        this.closeCheckoutModal.nativeElement.click();
    }

    public checkWalletAccess(){
      if(this.checkOutData){
        if(Math.round(parseFloat(this.walletAmount)*100)/100 >= Math.round(parseFloat(this.checkOutData.totalPrice)*100)/100){
          return true;
        }else{
          return false;
        }
      }
    }

    async payWithCard(){
      console.log(this.loading$)
      this.loading$.next(true);
     const { token, error } = await stripe.createToken(this.card);
    if (error) {
     console.log('Something is wrong:', error);
     this.loading$.next(false);
   } else {
     console.log('Success!', token);
     this.payWithStripeToken(token);
     // <span class="code-annotation">// ...send the token to the your backend to process the charge</span>
      }
    }

  public payWithStripeToken(token){
   this.checkOutData.email = this.currentuser.userEmail();
   let data = Object();
   Object.assign(data,{token: token});
   Object.assign(data,{amount: this.finalPriceInpounds * 100});
   Object.assign(data,{discountedAmount: this.discountPriceInpounds * 100});
   Object.assign(data ,{gateway:'stripe'});
   Object.assign(data,{scheduleDetails: this.checkOutData});
   this.findTutorSerivice.bookWithStripe(data)
    .pipe(finalize(() => this.loading$.next(false)))
    .subscribe(response => {
      if(response.status == 'success'){
       this.toastr.success('Lessons Booked Successfully');
       this.bookingCompleted.emit('true');
       this.card.clear();
       this.closeCheckoutModal.nativeElement.click();
     }else if(response.status == 'failed'){
       this.toastr.error(response.message);
     } 
   });
  }

  public payWithExistingCard() {
    this.selectedCard = $('#payWithExistingCard').val();
    console.log(this.selectedCard)
    this.loading$.next(true);
     this.checkOutData.email = this.currentuser.userEmail();
     let data = Object();
     Object.assign(data,{selectedCard: this.selectedCard});
     Object.assign(data,{amount: this.finalPriceInpounds * 100});
     Object.assign(data ,{gateway:'stripe'});
     Object.assign(data,{scheduleDetails: this.checkOutData});
     this.findTutorSerivice.bookWithStripe(data)
       .pipe(finalize(() => this.loading$.next(false)))
       .subscribe(response => {
      if(response.status == 'success'){
       this.toastr.success('Lessons Booked Successfully');
       this.bookingCompleted.emit('true');
       this.card.clear();
       this.closeCheckoutModal.nativeElement.click();
     }else if(response.status == 'failed'){
       this.toastr.error(response.message);
     } 
     });
  }

  /*Reset Selected Payment*/
  resetSwitchPayment(){
                    $("#new-card-button-container").css("display", "inline-block");
                    $("#existing-card-button-container").css("display", "none");
                    $("#paypal-button-container").css("display", "none");
                    $("#wallet-button-container").css("display", "none");
                    $("#stripeCard").addClass("activerow").siblings().removeClass("activerow");
                    $("#card1").prop('checked',true);
  }

}