<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
  <div class="TT-schedule clearfix">
   <h4 trans="Today's Schedule" class="TT-tableTime pull-left"> Today's Schedule 
     <span *ngIf="selectedUserName"> with {{selectedUserName}} </span>
   </h4>
   <h4 class="TT-dateTime pull-right">{{currentDate | date}}</h4>
  </div>

  <div class="table-responsive TT-responsiveTable">
   <table class="table TT-table TT-autoWidthTable">
      <thead>
         <tr>
            <th trans="Subject">Subject</th>
            <th trans="Tutor">Tutor</th>
            <th trans="Time">Time</th>
            <th trans="Class Status">Class Status</th>
            <th trans="Countdown">Countdown</th>
            <th colspan="2" trans="Actions" class="text-center">Actions</th>
         </tr>
      </thead>
      <tbody>
         <tr *ngIf="todayClasses_count == 0">
            <td class="text_center" colspan="7" align="center" trans="No Records Found">No Records Found
            </td>
         </tr>
         <tr *ngFor="let schedule of runningClasses">
            <td>{{schedule['booking_details']['subject'].subject_name}}</td>
            <td class="white-space word_break"><span
                  *ngIf="schedule['booking_details']['tutor_resume']">{{schedule['booking_details']['tutor_resume'].display_name}}</span>
            </td>
            <td>{{schedule.booked_time}}</td>
            <td class="white-space word_break">{{transform(schedule.booking_dates_status)}}</td>
            <td>
               <span
                  *ngIf="schedule.status!= '2' && schedule.status!= '3' && schedule.status!= '4'">{{calculateTime(schedule.booked_time
                  , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}</span>
               <span *ngIf="schedule.status== '3'" trans="CLASS RUNNING">Class Running</span>
               <span *ngIf="schedule.status== '2'" trans="CLASS COMPLETED">Class Completed</span>
               <span>&nbsp;</span>
            </td>
            <td class="lesson-btn-actions dropdown text-right TT-formGroup">
               <button *ngIf="schedule.status== '3'" type="button" class="TT-primaryBTN" value="Attend"
                  (click)="attendClass(schedule.booking_dates_id,schedule)" trans="Attend" style="width:auto;">Attend</button>
               <button *ngIf="schedule.status== '2'"
                  (click)="bookingDateId(schedule.booking_dates_id , schedule.booking_details.tutor_id)"
                  type="button" class="TT-primaryBTN" data-target="#DoneModal" data-toggle="modal"
                  trans="Done">Done</button>
               <button *ngIf="schedule.status== '4'" class="TT-primaryBTN">Rated</button>
            </td>
            <td class="lesson-btn-actions dropdown">

<!-- ========== Today's Schedule ============== -->

               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                  aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new" style="left: -174%;
               top: -81px !important;">
                  <li (click)="viewScheduleDetails(schedule['booking_details'].booking_id , schedule.booking_dates_id)"
                     trans="Lesson Board"><a>Lesson Board</a></li>
                  <li class="divider"></li>
                  <li (click)="messageModal(schedule['booking_details']['tutor_resume'].display_name, schedule['booking_details'].tutor_id , schedule['booking_dates_id'] , 'todays schedule' ,'bookingDate')"
                     href="#messageModal" data-toggle="modal"><a trans="Message Tutor">Message Tutor</a>
                  </li>
                  <li *ngIf="schedule.status !='3' && schedule['booking_details'].booking_type != 'group'"
                     (click)="openScheduleDates(schedule.booking_details.student_id , schedule)"
                     href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal"
                     class="white-space word_break" data-backdrop="static">
                     <a (click)="reScheduleRequest(schedule['booking_details'].tutor_id , schedule['booking_details'].booking_id , schedule.booking_dates_id)"
                        trans="Request Reschedule">Request Reschedule</a>
                  </li>
                  <li *ngIf="schedule.cancel_status == 'askreschedule_sendtutorcancellationrequest'"><a
                        href="#requestCancellation" data-target="#requestCancellation" data-toggle="modal"
                        (click)="requestCancellation(schedule['booking_details'].tutor_id ,  schedule['booking_details'].booking_id , schedule.booking_dates_id,'requestcancellation')"
                        trans="Request Cancellation">Request Cancellation</a></li>
                  <li *ngIf="schedule.cancel_status == 'ask_opencomplaint'"><a class="red"
                        href="#requestRefund" data-target="#requestRefund" data-toggle="modal"
                        (click)="reScheduleRequest(schedule.tutor_id , schedule['booking_details'].booking_id , schedule.booking_dates_id ,'raisecomplaint')"
                        trans="Raise Complaint">Raise Complaint</a>
                  </li>
                  <li *ngIf="schedule.cancel_status == 'directCancellation'"
                     href="#directSingleCancellation" data-target="#directSingleCancellation"
                     data-toggle="modal"
                     (click)="directSingleCancellation(schedule['booking_details'].booking_id , schedule.booking_dates_id)">
                     <a trans="Direct Cancellation">Direct Cancellation</a>
                  </li>
               </ul>
             <!-- ========== Today's Schedule ============== -->  
               </td>
         </tr>
      </tbody>
   </table>
  </div>

  <!-- Upcomming Lessons  -->

  <div class="TT-schedule clearfix">
   <h4 trans="Upcoming Lessons" class="TT-tableTime pull-left"> Upcoming Lessons </h4>
  </div>
  
  <div class="table-responsive TT-responsiveTable">
   <table class="table TT-table TT-autoWidthTable">
      <thead>
         <tr>
            <th trans="Subject">Subject</th>
            <th trans="Tutor">Tutor</th>
            <th trans="Date">Date</th>
            <th trans="Booking Type">Booking Type</th>
            <th trans="Booked Timings">Booked Timings</th>
            <th trans="Status">Status</th>
            <th trans="Actions">Actions</th>
         </tr>
      </thead>
      <tbody>
       <tr *ngIf="schedules_count == 0 && pendingApprovals_count == 0">
         <td class="text_center" colspan="8" align="center" trans="No Records Found"> No Records Found </td>
       </tr>
            <tr *ngFor="let request of pendingApprovals">
               <td>{{request['subject'].subject_name}}</td>
               <td class="white-space word_break"><span
                     *ngIf="request['tutor_resume']">{{request['tutor_resume'].display_name}}</span></td>
               <td>{{request.created_at | date: 'MM-dd-yyyy'}}</td>
               <td>{{transform(request.booking_type)}}</td>
               <td class="lesson-btn-actions white-space word_break">
                  <span *ngIf="request.status == 'Pending tutor approval'">
                     <button *ngIf="request.booking_type == 'schedule'"
                        (click)="viewDetails(request['booking_dates_pending_approval'] , request.booking_id)"
                        href="#viewDetails" data-target="#viewDetails" data-toggle="modal" type="button"
                        class="btn btn-primary" value="Attend" trans="View Details">View Details</button>
                     <span *ngIf="request.booking_type == 'package'" trans="Schedule Not Set">Schedule
                        Not Set</span>
                  </span>
               </td>
               <td class="white-space word_break">
                  <span *ngIf="request.status == 'Pending tutor approval'">
                     {{transform(request.booking_status)}}
                  </span>

                  <span *ngIf="request.request_status == 3 || request.request_status == 'offered_new_date'">
                     <button type="button" class="btn btn-primary"
                        (click)="rescheduleDetailsModal(request['rescheduleData'])"
                        href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal">
                        <span trans="Request Details">Request Details </span>
                     </button>
                  </span>

                  <span *ngIf="request.request_status == 3 || request.request_status == 'offered_new_date'"
                     class="white-space word_break">
                     <span *ngIf="request.request_status == 3"
                        trans="Awaiting Teacher approval for Reschedule">Awaiting Teacher approval for
                        Reschedule</span>
                     <span *ngIf="request.request_status == 'offered_new_date'"
                        trans="Teacher has given new date">Teacher has given new date</span>
                  </span>
               </td>               
               
               <td class="lesson-btn-actions dropdown">
                  <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                     aria-hidden="true"></i>
                  <ul class="dropdown-menu dropdown-new ">
                     <li *ngIf="request.booking_type != 'group'"
                        (click)="openScheduleDates(request.student_id , request)"
                        href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal"><a
                           trans="Direct Reschedule">Direct Reschedule</a></li>
                     <li class="divider"></li>
                     <li *ngIf="request.booking_type != 'group'" data-backdrop="static" href="#directCancellation"
                        data-target="#directCancellation" data-toggle="modal"
                        (click)="directCancelation(request.booking_id)"><a
                           rans="Direct Cancellation">Direct Cancellation</a></li>
                     <li (click)="messageModal(request['tutor_resume'].display_name, request.tutor_id ,request.booking_id , 'pending approval' ,'booking')"
                        href="#messageModal" data-toggle="modal"><a trans="Message Tutor">Message
                           Tutor</a>
                     </li>
                  </ul>
               </td>
            </tr>
            <tr *ngFor="let schedule of schedules">
               <td>{{schedule['booking_details']['subject'].subject_name}}</td>
               <td>{{schedule['booking_details']['tutor_resume'].display_name}}</td>
               <td>{{schedule.slot_date | date: 'MM-dd-yyyy'}}</td>
               <td>{{transform(schedule['booking_details'].booking_type)}}</td>
               <td>{{schedule.booked_time}}</td>
               <td class="white-space word_break">{{transform(schedule.booking_dates_status)}}</td>
               <td class="lesson-btn-actions dropdown" >
                  <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown"
                     aria-hidden="true"></i>
                  <ul class="dropdown-menu dropdown-new">
                     <li
                        (click)="viewScheduleDetails(schedule['booking_details'].booking_id , schedule.booking_dates_id)">
                        <a trans="View Details">View Details</a>
                     </li>
                     <li *ngIf="schedule.cancel_status == 'askreschedule_sendtutorcancellationrequest' && schedule['booking_details'].booking_type != 'group'">
                        <a href="#requestCancellation" data-target="#requestCancellation"
                           data-toggle="modal"
                           (click)="requestCancellation(schedule['booking_details'].tutor_id ,  schedule['booking_details'].booking_id , schedule.booking_dates_id,'requestcancellation')"
                           trans="Request Cancellation">Request Cancellation</a>
                     </li>
                     <li *ngIf="schedule.cancel_status == 'ask_opencomplaint'"><a class="red"
                           href="#requestRefund" data-target="#requestRefund" data-toggle="modal"
                           (click)="reScheduleRequest(schedule.tutor_id , schedule['booking_details'].booking_id , schedule.booking_dates_id ,'raisecomplaint')"
                           trans="Raise Complaint">Raise Complaint</a>
                     </li>
                     <li (click)="messageModal(schedule['booking_details']['tutor_resume'].display_name, schedule['booking_details'].tutor_id ,schedule.booking_dates_id, 'upcoming schedule','bookingDate')"
                        href="#messageModal" data-toggle="modal"><a trans="Message Tutor">Message
                           Tutor</a>
                     </li>
                     <li *ngIf="schedule.status !='3' && schedule['booking_details'].booking_type != 'group'"
                        (click)="openScheduleDates(schedule.booking_details.student_id , schedule)"
                        href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal"
                        class="white-space word_break" data-backdrop="static">
                        <a (click)="reScheduleRequest(schedule['booking_details'].tutor_id , schedule['booking_details'].booking_id , schedule.booking_dates_id)"
                           trasn="Request Reschedule">Request Reschedule</a>
                     </li>
                     <li *ngIf="schedule.cancel_status == 'directCancellation' && schedule['booking_details'].booking_type != 'group'"
                        href="#directSingleCancellation" data-target="#directSingleCancellation"
                        data-toggle="modal"
                        (click)="directSingleCancellation(schedule['booking_details'].booking_id , schedule.booking_dates_id)">
                        <a trans="Direct Cancellation">Direct Cancellation</a>
                     </li>
                    
                  </ul>
               </td>
            </tr>
      </tbody>
   </table>
</div>

</div>


<div class="modal fade" id="viewDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">

   <div class="modal-dialog mt-100" role="document">

      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel"> </h5>
            <button type="button" class="close mt-22" #closeViewDeatailsModal (click)="closeViewDetailsModal()"
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <div class="container-fluid whiteBg">
                  <div class="tab-content">
                     <table class="table lesson-table Auto_block">
                        <tbody class="Auto_block">
                           <tr class="Auto_block">
                              <th trans="Date">Date</th>
                              <th trans="Month">Month</th>
                              <th trans="Timings">Timings</th>
                           </tr>
                           <div *ngIf="Timings">
                              <tr *ngFor="let time of Timings" class="Auto_block">
                                 <td>{{time.slot_date | date : 'MM-dd-yyyy'}}</td>
                                 <td>{{time.booked_month}}</td>
                                 <td>{{time.booked_time}}</td>
                              </tr>
                           </div>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="DoneModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog topMargin m-0">
      <form (ngSubmit)="submitRating()" [formGroup]="ratingForm">
         <div class="modal-content">
            <div class="modal-header">
               <button (click)="clearRatingModal()" type="button" class="close" data-dismiss="modal" #closeRatingModal
                  aria-hidden="true">
                  ×
               </button>
               <h4 trans="thank_you!" class="modal-title md-title linkLabel" id="myModalLabel">
                  Thank you!
               </h4>
            </div>
            <div class="modal-body M_wrapper">
               <p>
                  We have marked this lesson as completed. Please rate the tutor.
               </p>
               <div class="form-group review">
                  <textarea formControlName="review" class='M_textarea' name="the-textarea" id="the-textarea"
                     maxlength="300" placeholder="Type a message..." autofocus></textarea>
                  <div *ngFor="let error of errorMessages.review">
                     <ng-container *ngIf="review.hasError(error.type) && (review.dirty || review.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
              
               <div form-group class="star-rating" style="display: inline-flex;">
                  <input id="star-1" type="radio" name="rating" value="star-1">
                  <label for="star-1" title="1 star" class="star_rating_satisfaction">
                     1&nbsp;<i (click)="rateTutor('1')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-2" type="radio" name="rating" value="star-2">
                  <label for="star-2" title="2 stars" class="star_rating_satisfaction">
                     2&nbsp;<i (click)="rateTutor('2')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-3" type="radio" name="rating" value="star-3">
                  <label for="star-3" title="3 stars" class="star_rating_satisfaction">
                     3&nbsp;<i (click)="rateTutor('3')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-4" type="radio" name="rating" value="star-4" >
                  <label for="star-4" title="4 stars" class="star_rating_satisfaction">
                     4&nbsp;<i (click)="rateTutor('4')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
                  <input id="star-5" type="radio" name="rating" value="star-5" />
                  <label for="star-5" title="5 stars" class="star_rating_satisfaction">
                     5&nbsp;<i (click)="rateTutor('5')" class="active fa fa-star" aria-hidden="true"></i>
                  </label>
               </div>
            </div>
            <div class="modal-footer TEXT-center TT-formGroup">
               <button trans="submit_rating" [disabled]="loading$ | async" type="submit"
                  class="btn TT-primaryBTN TT-topSpaceZero">
                  Submit Rating
               </button>
            </div>
         </div>
      </form>
   </div>
</div>
<div class="modal fade" id="requestRefund" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span *ngIf="operation == 'refund'" trans="Refund Schedule">Refund Schedule</span>
               <span *ngIf="operation == 'raisecomplaint'" trans="Raise Compliant">Raise Complaint</span>
            </h5>
            <button (click)="closeComplaintModal()" type="button" class="close" #closeCompliantModal
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p trans="sure_refund_amount" style="color: #000;" *ngIf="operation == 'refund'"
                  trans="Are you sure you want to cancel schedule and refund the amount to wallet?">Are you
                  sure you
                  want to cancel schedule and refund the amount to wallet?</p>
               <p trans="write_description" style="color: #000;" *ngIf="operation == 'raisecomplaint'"
                  trans="Write your description here, to help you out">Write your description here, to help you
                  out</p>
               <form [formGroup]="updateStatusForm" role="form" class="form-horizontal"
                  *ngIf="operation == 'raisecomplaint'">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="complaintDescription" formControlName="description"
                           class="form-control" rows="10" cols="30"></textarea>
                     </div>
                  </div>
                  <button type="button" class="btn btn-success new" trans="submit_complaint"
                     (click)="manageSchedule2('raisecomplaint')" trans="Submit Complaint">Submit
                     Complaint</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-primary new" trans="close" data-dismiss="modal"
                     aria-label="Close" #closeViewModal trans="Close">Close</button>
               </form>
               <span *ngIf="operation == 'refund'" class="TT-formGroup">
                  <button type="button" class="btn TT-primaryBTN" trans="refund" (click)="manageSchedule2('refund')"
                     trans="Refund">Refund</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                     aria-label="Close" #closeViewModal trans="Close">Close</button>
               </span>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span>Direct Cancellation</span>
            </h5>
            <button #closeCancellation [disabled]="loading$ | async" type="button" class="close mt-22"
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p style="color: #000;">Are you sure you want to cancel this complete booking and refund the
                  amount to
                  wallet?</p>
               <div class="text_center TT-formGroup">
                  <button [disabled]="loading$ | async" type="button" class="btn TT-primaryBTN"
                     (click)="cancelCompleteBooking()">Cancel Booking
                     <app-loader *ngIf="loading$ | async"></app-loader>
                  </button>
                  &nbsp;&nbsp;
                  <button [disabled]="loading$ | async" type="button" class="btn TT-primaryBTN" trans="close"
                     data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span>Request Cancellation</span>
            </h5>
            <button type="button" class="close" #closerequestCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p style="color: #000;" trans="Are you sure you want to cancel the reschedule request?">Are you
                  sure you
                  want to cancellation request for this booking?</p>
               <form [formGroup]="updateCancellationForm" role="form" class="form-horizontal">
                  <div class="form-group TT-formGroup">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="description" formControlName="cancel_description"
                           (input)="messageText($event.target.value)" class="form-control" rows="10"
                           cols="30"></textarea>
                     </div>
                  </div>
                  <button disabled type="button" id="cancelBtn" class="btn TT-primaryBTN"
                     (click)="raiseCancellation('raisecancellation')">Cancel Booking</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                     aria-label="Close" #closerequestCancellation trans="Close">Close</button>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="directSingleCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
   #closeSingleCancellation>
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span>Direct Cancellation</span>
            </h5>
            <button type="button" class="close" #closeSingleCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup">
               <p style="color: #000;">Are you sure you want to cancel this booking and refund the amount to
                  wallet?</p>
               <button type="button" class="TT-primaryBTN" (click)="cancelSingleBooking()" style="width:auto;padding:8px 35px">Cancel
                  Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close"
                  #closeViewModal trans="Close" style="width:auto;padding:8px 35px">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
<app-schedule-modal (rescheduleData)="requestReschedule($event)" (rescheduledDates)="rescheduledDates($event)">
</app-schedule-modal>