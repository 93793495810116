import { Component, OnInit , ElementRef , ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LessonsService } from '../../lessons.service';
import { CurrentUser } from '../../../../auth/current-user'
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LocaleTimeZoneTS } from '../../../../auth/localetimezone';
import { MessagemodalComponent } from '../../../findtutor/messagemodal/messagemodal.component';
import { ScheduleModalComponent } from '../../schedule-modal/schedule-modal.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $;
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { PusherService } from 'app/pusher.service';
// import { SocketService } from '@services/socket.service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  public attachment;
  public commentForm = this.fb.group({
    commentValue: ['',Validators.required]
    });
  get commentValue(){ return this.commentForm.get('commentValue');}
  public now;
  public ratingForm = this.fb.group({
        review:['',[Validators.required]],
        rating: ['', Validators.required],
    });

  @ViewChild('closeSingleCancellation') closeSingleCancellation: ElementRef;
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeFileUploader') closeFileUploader: ElementRef;
  @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild('closeCompliantModal') closeCompliantModal: ElementRef;
  @ViewChild('closerequestCancellation') closerequestCancellation: ElementRef;
  files: File[] = [];
  formData = new FormData();
  public Comments = new Array();
  public lessonDetails;
  public selectedBoooking;
  public selectedBookingDateId;
  public comment;
  public userId;
  public studentName;
  public operation;
  public bookingId;
  public schedules;
  public selectedTutorIDForRating;
  public selectedBookedDateIDForRating;
  public cancellationId;
  public selectedBookedDateID;
  public tutorID;
  public date;
  public studentBookings;
  public tutorAvailability;
  public timezone;
  public allowedActions :any;
  public imageLoader$ = new BehaviorSubject(false);
  rating3 = 0;
  public updateCancellationForm = this.fb.group({
    cancel_description: ['', Validators.required],
    });


  public updateStatusForm = this.fb.group({
    description: ['', Validators.required],
    });
  public errorMessages = {
  review :[ { type:'required',message:'Review is required'}],
  commentValue :[{type:'required',message:'Comment is required'}],
}

user:String;
room:String;
messagesText:String;
messageArray:Array<{user:String,message:String}> = [];

get review(){ return this.ratingForm.get('review');}


  constructor(private router: Router,
  	          private activatedRoute: ActivatedRoute,
  	          private lessonService: LessonsService,
              public currentUser: CurrentUser,
              private fb: FormBuilder,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private _location: Location,
              // private _socketService: SocketService,
              private pusherService:PusherService,
              private toastr: ToastrService) { 
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
  this.date = this.localetimezone.onlyDate();
  // chat service
  // this._socketService.newUserJoined()
  // .subscribe(data=> this.messageArray.push(data));

  // this._socketService.userLeftRoom()
  // .subscribe(data=>this.messageArray.push(data));

  // this._socketService.newMessageReceived()
  // .subscribe((data)=>{
  //   console.log("In constructor"+data);
  //   this.messageArray.push(data)
  //   this.lessonSelected();
  // });

}

  ngOnInit(): void {
    
    // this.socketService.setupSocketConnection();
    this.userId = this.currentUser.userId();
    this.studentName = this.currentUser.userName();
  	this.selectedBoooking = this.activatedRoute.snapshot.params['id'];
  	this.selectedBookingDateId = this.activatedRoute.snapshot.params['dId'];
    this.lessonSelected();
    $('#upload').prop("disabled", true);
    console.log("This is your market");
    // this.join();
    // this.socketService.setupSocketConnection();
  }

  public bind(){
     
    this.pusherService.channel.bind('tutor.message', res => {
      console.log('Bind from tuboard')
      //console.log( res,'asdasdaaaaaaaaaaaaaa');
      //console.log(res[0].original.data,'asdasdaaaaaaaaaaaaaa');
      let data =  JSON.parse(atob(res[0].original.data));
      this.Comments = data['comments'];
      localStorage.setItem('Comments',JSON.stringify(this.Comments));
     // console.log(this.comments);
      $("#postComment").val("");
    });  
  }

  public lessonSelected(){
    console.log('asdasdasd');
        let bookingid = Object();
    Object.assign(bookingid , { 'id' : this.selectedBoooking });
    Object.assign(bookingid , { 'did': this.selectedBookingDateId});
        this.lessonService.selectedBookingDetails(bookingid).subscribe(response=>{
          let data =  JSON.parse(atob(response['data']));
          this.Comments = data['comments'];
          //  console.log("commentsss");
          //  console.log(this.Comments);
          this.lessonDetails = data['bookingDetails'];
          console.log(data['bookingDetails'])
          this.pusherService.createCommonChannel(this.lessonDetails.booking_details.tutor.username);
          setTimeout(()=>{                           
            this.bind();
            }, 5000);
          this.allowedActions = this.lessonDetails['student_actions'];
          //  console.log(this.allowedActions);
          let bookedTime = this.lessonDetails.booked_time;
          let time = this.datePipe.transform(this.now , 'H:mm:ss');
          let statusRequired;
         let status;
         if(data['bookingDetails']['slot_date'] < this.date || data['bookingDetails']['slot_date'] > this.date){
          status = 5;
         }else{
          status = this.calculateTime(bookedTime , time , statusRequired ,  this.lessonDetails.rating_status);
         }
         this.lessonDetails.status = status;
    });
  }

    public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closeCompliantModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }

  public closeComplaintModal(){
    $('#complaintDescription').val('');
  }

 public backClicked() {
      this._location.back();
      $('.container-fluid.tutorChat.lesson .leftCol').show();
    }

 public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                 this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}

public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }



  public comentValue(value){
  this.comment = value;
  }

  public postComment(){
    if(this.comment){
      let val = this.comment;
      this.comment = "";
    let commentData = Object();
    Object.assign(commentData,{commentValue:    val});
    Object.assign(commentData,{to_id:           this.lessonDetails.booking_details.tutor_id});
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.lessonDetails.booking_details.booking_id});
    Object.assign(commentData,{booking_dates_id:this.lessonDetails.booking_dates_id});
    Object.assign(commentData,{group_chat_id:this.lessonDetails.booking_details.group_chat_id});

    console.log(this.lessonDetails);
    this.lessonService.postStudentComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      console.log(commentData);
      $("#postComment").val("");
      // sending the message using the socket
      // this._socketService.sendMessage({user:this.studentName, room:this.selectedBoooking, message:val});
    });
   }
  }

  // socket methods
  // join(){
  //   this._socketService.joinRoom({user:this.studentName, room:this.selectedBoooking});
  // }

  // leave(){
  //     this._socketService.leaveRoom({user:this.studentName, room:this.selectedBoooking});
  // }

  // sendMessage()
  // {
  //     this._socketService.sendMessage({user:this.studentName, room:this.selectedBoooking, message:this.messagesText});
  // }

  public cancelSchedule(booking_dates_id, booking_id) {

  }

  public closeRating(){
    this.closeRatingModal.nativeElement.click();
  }

  public submitRating(){
   this.ratingForm.markAllAsTouched();
  }

     // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
      }

       // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }

    public removeAllFiles(){
      this.files = [];
      this.commentForm.reset();
      this.formData.delete("file[]");
    }

      public attendClass(bookingId,schedule) {
        console.log('in details component page',bookingId,schedule)
        if(schedule.v_preferred_type == "custom_link")
    {
      window.open(schedule.custom_link);
    }
    else{
      this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
    }
    this.lessonService.onVideo(schedule);
    // this.router.navigate(['student/lessons/scheduled/videoCall', bookingId]);
  }

        // This Method is used to upload the Files in the chat
    public sendFile(){
      this.commentForm.markAllAsTouched();
      if(this.commentForm.valid){
        this.formData.append("commentValue", this.commentForm.value.commentValue);
        this.formData.append("booked_id", this.lessonDetails.booking_details.booking_id);
        this.formData.append("booking_dates_id", this.lessonDetails.booking_dates_id);
        this.formData.append("to_id" , this.lessonDetails.booking_details.tutor_id);
        this.formData.append("from_id" , this.currentUser.userId().toString());
        this.formData.append("group_chat_id" , this.lessonDetails.booking_details.group_chat_id);
      this.imageLoader$.next(true);
      this.lessonService.UploadFiles(this.formData).subscribe(response => {
      if(response['status'] == 'success'){
      this.closeFileUploader.nativeElement.click();
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      this.imageLoader$.next(false);
        }
      })
    }
    }

        // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }


    public filesSendMessage(file){


    }

    public viewAttachmentImage(image){
     this.attachment = image;
    }

    public closeFileUploaderModal(){
      this.removeAllFiles();
    }

  public messageModal(resume, userData , bookingDetail , notificationSUbject){
    let user = Object();
    Object.assign(user,{id:userData});
    Object.assign(user,{sendAsNotification:'Yes'});
    Object.assign(user,{notificationSubject:notificationSUbject});
    Object.assign(user,{bookingDateId:bookingDetail});
    this.messageChild.userData(resume, user);
  }

 public bookingDateId(id , tutorId){
   this.selectedBookedDateIDForRating = id;
   this.selectedTutorIDForRating = tutorId;
  }


   public openScheduleDates(id , data){
    let bookingType;
    let availabilityData = Object();
    Object.assign(availabilityData , {availabilityInfo: this.lessonService.currentWeekDates()});
    Object.assign(availabilityData , {studentId: id});
    if(data.tutor_id){
      Object.assign(availabilityData , {tutorId : data.tutor_id});
      bookingType = 'directCancelation';
    }else{
      Object.assign(availabilityData , {tutorId : data.booking_details.tutor_id});
      bookingType = 'requestReschedule';
    }
    Object.assign(availabilityData , {timezone: this.timezone});
    this.lessonService.getStudentBooking(availabilityData).subscribe(response =>{
          let resposne =  JSON.parse(atob(response['studentBookings']));
          this.studentBookings = resposne['studentDates'];
          this.tutorAvailability = resposne['avaialbleTimings'];
          let packageDetails = '';
          this.scheduleChild.bookedDates(this.tutorAvailability , this.studentBookings ,packageDetails, data, bookingType);
    });
 }

   public reScheduleRequest(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   if ( typeof operation === 'undefined' ) {
     this.operation = 'refund';
   } else {
     this.operation = operation;
   }
  }

  // This Method gets the Reschedule userData
  public requestReschedule(event){
    let reScheduleData = Object();
    Object.assign(reScheduleData, {bookingId: this.bookingId});
    Object.assign(reScheduleData, {bookingDateId: this.selectedBookedDateID});
    Object.assign(reScheduleData, {operation: 'overDueCancelleation'});
    Object.assign(reScheduleData, {reScheduleValues: event});
    this.lessonService.rescheduleRequest(reScheduleData)
    .subscribe(response => {
      if(response.status == 'success'){
        this.toastr.success('Reschedule Requested');
        this.scheduleChild.closeScheduleChildModal();
        this.lessonSelected();
        }
    })
  }


public raiseCancellation(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecancellation') {
      Object.assign(data, {cancel_description: this.updateCancellationForm.get('cancel_description').value});
    }
    this.lessonService.raiseTutorCancellation(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closerequestCancellation.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }

    //Raise cancellation
  public requestCancellation(tutorid, bookingId , bookingRequestId, operation){
   this.selectedBookedDateID = bookingRequestId;
   this.bookingId = bookingId;
   this.tutorID = tutorid;
   this.operation = operation;
  }

  public keyPressHandler(e) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.postComment()
     e.preventDefault();
  }
}

  public cancelSingleBooking(){
    let data = Object();
    Object.assign(data , {bookingId : this.cancellationId});
    Object.assign(data , {bookingDateId : this.bookingDateId});
    this.lessonService.cancelSingleStudentBooking(data).subscribe(response => {
      if(response.status == 'failed'){
          this.toastr.error(response.message);
        }else{
        let responseData =  JSON.parse(atob(response['data']));
        this.schedules = responseData['upcomingClasses'];
        this.closeSingleCancellation.nativeElement.click();
        this.toastr.success('Lesson Cancelled');
      }
    });
  }

    public directSingleCancellation(bookingID,bookingDateId){
    this.cancellationId = bookingID;
    this.bookingDateId = bookingDateId;
  }


  public messageText(value){
    if(value){
      $("#cancelBtn").prop('disabled',false);
    }else{
       $("#cancelBtn").prop('disabled',true);
    }
  }

  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
}
