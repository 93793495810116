<div class="TT-cardds">
   <div class="Table-Height TT-tableHeight">
      <h4 class="TT-title" trans="Recent Messages">Recent Messages</h4>
      <div class="contact-area inner-Padd Table-Height_scroll">
         <!-- chat 1 start -->
         <div *ngFor="let messages of recent_messages"
            class="chat__person-box chat__person-box--active " data-id="2" data-is_group="0"
            id="user-2" data-is_my_contact="1">
            <div class="position-relative chat__person-box-status-wrapper">
               <div
                  [ngClass]="{'true' : 'chat__person-box-status--online','false' :'chat__person-box-status--offline'}[messages.user.isOnlineCheck]"
                  class="chat__person-box-status">
               </div>
               <div class="chat__person-box-avtar chat__person-box-avtar--active">
                  <img *ngIf="messages.user" [src]="messages.user.avatar" class="user-avatar-img">
               </div>
            </div>
            <div (click)="userChat(messages.user)" class="chat__person-box-detail">
               <h5 class="mb-1 chat__person-box-name contact-name">
                  {{messages.displayName}}
                  <span class="contact-status">
                     <i class="nav-icon user-status-icon" data-toggle="tooltip" data-placement="top"
                        title="" data-original-title="ghljghj">
                     </i>
                  </span>
               </h5>
            </div>
            <div class="chat__person-box-msg-time">
               <div class="chat__person-box-time">
                  {{messages.user.last_seen|date : 'HH:mm'}}
               </div>
               <div class="chat__person-box-count" *ngIf="messages.unread_count != 0">
                  <span>{{messages.unread_count}}</span>
               </div>
            </div>
         </div>
         <div class="TT-notify TT-noRecorded" *ngIf="recent_messages_count == 0">
           <td colspan="6" align="center">
            <span trans="No Records Found" class="TT-noRecords">
            <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found" class="img-responsive w-100 m-auto TT-noData" /> -->
            <div class="text-center">No Records Found</div>
           </span>
           </td>
         </div>
         <!-- chat 1 end -->
      </div>
      <!-- chat content end -->
      <div class="TT-innerCover">
         <div (click)="viewChat()" class="TT-clickable">
            <a class="TT-click" trans="Messages >>>">Messages >>></a>
         </div>
      </div>
   </div>
</div>