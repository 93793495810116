<div class="TT-cardds">
   <div class="Table-Height TT-tableHeight">
      <h4 class="TT-title" trans="Recent Bookings"> Recent Bookings </h4>


      <table class="table TT-table TT-autoWidthTable">
         <thead>
            <tr>
               <th trans="Subject">Subject</th>
               <th trans="Tutor">Tutor</th>
               <th trans="Booking Type">Booking Type</th>
               <th trans="Booked Timings">Booked Timings</th>
               <th trans="Status" class="text-center">Status</th>
               <th trans="Actions" class="text-center">Actions</th>
            </tr>
         </thead>
         <tr *ngIf="recent_bookings_count == 0">
            <td colspan="6" align="center" class="TT-notify TT-noRecorded">
               <span trans="No Records Found" class="TT-noRecords">
                  <div class="text-center">No Records Found</div>
               </span>
            </td>
         </tr>
         <tbody>
            <tr *ngFor="let lesson of recent_bookings">
               <td>{{lesson['subject'].subject_name}}</td>
               <td>{{lesson['tutor_resume']?.display_name}}</td>
               <td>{{transform(lesson.booking_type)}}</td>
               <td class="TT-formGroup">
                  <span *ngIf="lesson.status == 'pending_tutor_approval'">
                  <button *ngIf="lesson.booking_type == 'schedule' || lesson.booking_type == 'group'"
                     (click)="viewDetails(lesson['booking_dates_all'] , lesson.booking_id)"
                     href="#viewBookingDetails" data-target="#viewBookingDetails" data-toggle="modal" type="button"
                     class="TT-primaryBTN" value="Attend" trans="View Details">View Details</button>
                  <span *ngIf="lesson.booking_type == 'package'" trans="Schedule Not Set"> Schedule Not Set</span>
                  </span>
                  <span *ngIf="lesson.status == 'booking_approved'">
                     <button (click)="viewDetails(lesson['booking_dates_all'] , lesson.booking_id)"
                     href="#viewBookingDetails" data-target="#viewBookingDetails" data-toggle="modal" type="button" class="TT-primaryBTN" value="Attend" trans="View Details">View Details</button>
                  </span>
                  
                  <span *ngIf="lesson.request_status == 3 || lesson.request_status == 'offered_new_date'">
                     <button type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(lesson['rescheduleData'])"
                        href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal">
                        <span trans="Request Details" class="TT-primaryBTN">Request Details </span>
                     </button>

                     <span *ngIf="lesson.request_status == 3" trans="Awaiting Teacher approval for Reschedule">Awaiting
                        Teacher approval for Reschedule</span>
                     <span *ngIf="lesson.request_status == 'offered_new_date'" trans="Teacher has given new date">Teacher
                        has given new date</span>
                  </span>
               </td>
               <td>{{transform(lesson.status)}}</td>
               <!-- <td *ngIf="lesson.request_status == 3 || lesson.request_status == 'offered_new_date'">
                  <button type="button" class="TT-primaryBTN" (click)="rescheduleDetailsModal(lesson['rescheduleData'])"
                     href="#rescheduleDetails" data-target="#rescheduleDetails" data-toggle="modal">
                     <span trans="Request Details" class="TT-primaryBTN">Request Details </span>
                  </button>
               </td>
               <td *ngIf="lesson.request_status == 3 || lesson.request_status == 'offered_new_date'">
                  <span *ngIf="lesson.request_status == 3" trans="Awaiting Teacher approval for Reschedule">Awaiting
                     Teacher approval for Reschedule</span>
                  <span *ngIf="lesson.request_status == 'offered_new_date'" trans="Teacher has given new date">Teacher
                     has given new date</span>
               </td> -->
               <td class="dropdown TT-tableDropDownMenuList">
                  <i class="fa fa-ellipsis-v data-toggle" data-toggle="dropdown" aria-hidden="true" style="text-align: center;
                  margin: auto;display: block;"></i>
                  <ul class="dropdown-menu TT-dropDownMenu">
                     <li class="TT-optionsList" href="#directCancellation" data-target="#directCancellation"
                        data-toggle="modal" (click)="directCancelation(lesson.booking_id)" trans="Direct Cancellation" *ngIf="lesson.booking_type != 'group'">
                        <a class="TT-frameSet">Direct Cancellation</a>
                     </li>
                     <li class="divider"></li>
                     <li class="TT-optionsList"
                        (click)="messageModal(lesson['tutor_resume'].display_name, lesson.tutor_id , lesson.booking_id, 'recent bookings','Booking')"
                        href="#messageModal" data-toggle="modal" trans="Message Tutor">
                        <a class="TT-frameSet">Message Tutor</a>
                     </li>
                  </ul>
               </td>
            </tr>
         </tbody>
      </table>
      <div class="TT-innerCover">
         <div (click)="viewLessons()" class="TT-clickable">
            <a class="TT-click" trans="Lessons >>>">Lessons >>></a>
         </div>
      </div>
   </div>
</div>

<!-- Recent Booking View Details -->
<div class="modal fade" id="viewBookingDetails" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel" trans="Booking Details"> Booking Details</h5>
            <button type="button" class="close" #closeViewDeatailsModal (click)="closeViewDetailsModal()"
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <div class="container-fluid whiteBg">
                  <div class="tab-content">
                     <table class="table lesson-table table-responsive bd-none">
                        <thead>
                           <tr>
                              <th trans="Date" style="width: 33%;">Date</th>
                              <th trans="Month" style="width: 33%;">Month</th>
                              <th trans="Timings" style="width: 33%;">Timings</th>
                           </tr>
                        </thead>
                        <tbody *ngIf="Timings">
                           <!-- <div *ngIf="Timings"class="table-width"> -->
                           <tr *ngFor="let time of Timings">
                              <td style="width: 33%;">{{time.slot_date | date:'MM-dd-yyyy'}}</td>
                              <td style="width: 33%;">{{time.booked_month}}</td>
                              <td style="width: 33%;">{{time.booked_time}}</td>
                           </tr>
                           <!-- </div> -->
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="directCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1" #closeCancellation>
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <h4 class="modal-title TT-AccUsers" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h4>
            <button type="button" class="close" #closeViewModal data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p trans="Are you sure you want to cancel this complete booking and refund the amount to wallet?">Are you
                  sure you want to cancel this complete booking and refund the amount to wallet?</p>
                
                  <div class="row">
                     <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">&nbsp;</div>
                     <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 clearfix">
                        <div class="TT-formGroup d-flex pull-right">
                           <button [disabled]="loading$ | async" type="button" class="TT-primaryBTN"  (click)="cancelCompleteBooking()" trans="Cancel Booking">Cancel Booking</button>
                        &nbsp;&nbsp;
                        <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close"
                           #closeViewModal trans="Close">Close</button>
                           </div>
                     </div>
                   </div>
                  
            </div>
         </div>
      </div>
   </div>
</div>