import { Component, OnInit, Input, ViewChild , ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LessonsService } from '../../lessons/lessons.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hm-pending',
  templateUrl: './hm-pending.component.html',
  styleUrls: ['./hm-pending.component.css']
})
export class HmPendingComponent implements OnInit {
  
  public pending_actions;
  public pending_actions_count = 0;
  public packageDates_count    = 0;
  public Timings;
  public selectedBookingId;
  public offeredNewDates;
  public bookingRescheduleSelected;
  public rescheduleBookingID;
  public requestReschedule;
  public offeredNewDatesCount;
  public requestRescheduleCount;
  public timezone;
  @Input()
  public set pendingActionDetails	(value){
  this.pending_actions = value;
  this.pending_actions_count = this.pending_actions?.length;
  }
  @ViewChild('closeViewPackageDeatailsModal') closeViewPackageDeatailsModal: ElementRef;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('closebookingRescheduleModal') closebookingRescheduleModal: ElementRef;
  constructor(public router: Router,
    private lessonService: LessonsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

    // Booking Reschedule
  public bookingReschedule(request){
   this.rescheduleBookingID = request.booking_id;
   this.bookingRescheduleSelected = request.booking_reschedule_request;
  }

       // Schedule Details
  public viewPendingDetails(dates , booking_id){
    this.Timings = dates;
    this.selectedBookingId = booking_id;
  }
  

  public viewPendingActions(){
    this.router.navigate(['student/lessons/pending']);
  }

  public rescheduleDetailsModal(data){
    this.offeredNewDates = data.offered_new_date;
    this.offeredNewDatesCount = data.offered_new_date_count;
    this.requestReschedule = data.tutor_request_reschedule;
    this.requestRescheduleCount = data.tutor_request_reschedule_count;
  }

    public acceptRescheduleRequest(rescheduleId, operation) {
    let data = Object();
    Object.assign(data, {rescheduleId:rescheduleId, operation:operation});
    Object.assign(data, {from : 'studentDashboard'});
    this.lessonService.acceptRescheduleRequest(data)
        .subscribe(response => {
          this.pending_actions = response;
          this.closeViewDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
    });
  }

    public acceptBookingReschedule(operation){
    let data = Object();
    Object.assign(data , {bookingId : this.rescheduleBookingID,  operation:operation});
    Object.assign(data , {from:'studentDashboard'});
    this.lessonService.acceptBookingReschedule(data)
        .subscribe(response => {
         this.pending_actions = response;
         this.closebookingRescheduleModal.nativeElement.click();
         this.toastr.success('Booking Reschedule Accepted');
        });
  }

    // Close closeViewDetailsModal
  public closeViewDetailsModal(){
   this.Timings='';
   this.selectedBookingId='';
   this.closeViewDeatailsModal.nativeElement.click();
  }

    public acceptAssignedDatesForPackage(){
    let data = Object();
    Object.assign(data , {bookingId : this.selectedBookingId});
    Object.assign(data , {from : 'studentDashboard'});
    this.lessonService.acceptAssignedDatesForPackage(data).subscribe(response => {
          this.pending_actions = response;
          this.closeViewPackageDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
    });
  }

    public acceptRequestReschedule(rescheduleId, operation) {
    let data = Object();
    Object.assign(data, {rescheduleId:rescheduleId, operation:operation})
    Object.assign(data ,{from: 'studentDashboard'});
    this.lessonService.acceptRequestReschedule(data)
        .subscribe(response => {
          this.pending_actions = response;
          this.closeViewPackageDeatailsModal.nativeElement.click();
          this.toastr.success('Record Updated Successfully');
    });
  }


    // Schedule Details
  public viewPackageDetails(dates , booking_id){
    this.Timings = dates;
    this.selectedBookingId = booking_id;
  }

  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }


}
