<div *ngIf="datatable.paginator && datatable.paginator.loading$ | async" class="preloading">
   <div class="wrap-preload">
      <div class="cssload-loader"></div>
   </div>
</div>
<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/3.2.1/css/font-awesome.css" /> -->
<div class="container-fluid padding0">
   <div class="pageSectionBg">
      <div class="TT-outlineLayers">
         <div class="TT-tutorWrapper">
            <div class="TT-templateDraft TT-spacers">
               <div class="row m-auto">
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 clearfix">
                   <div class="pull-left">
                     <div *ngIf="tutor" class="TT-overwiew">                 
                     <a class="TT-tileTitle" trans="Find Tutors"> Find Tutors </a>
                        <h3 class="TT-displYName">{{tutor['display_name']}}</h3>
                        <p *ngIf="tutor['user_data']['isOnlineCheck']" class="TT-lineCheck"></p>
                        <p class="TT-lineCheck"> {{tutor['students_count']}}
                           <span>Active Students</span> 
                           {{tutor['lessons_count']}}
                           <span>Lessons</span>
                        </p>
                     </div>
                   </div>
                   <div class="pull-right">
                     <form class="TT-formGroup">
                       <button type="submit" class="TT-primaryBTN" (click)="backClicked()"> Back </button>
                     </form>
                   </div>
                 </div> 
                 
               </div>
               <div *ngIf="tutor" class="row m-auto">
                  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xl-12">
                     <div class="media d-flex TT-media">
                        <div class="TT-profileStudent">
                           <img src="{{tutor.user_data.avatar}}" class="media-object media-pic media-pic">
                           <p class="TT-widgetFavourateChat d-flex">
                              <span class="orangeBg" trans="Recommended">Recommended </span>
                              <span *ngIf="currentuser.isLoggedIn()">
                                 <span id=heart class="TT-heart" *ngIf="tutor['availability']['is_favourite']=='yes'"><i
                                       id="{{tutor.user_id}}" (click)="makeFavourite(tutor.user_id)" class="fa fa-heart"
                                       aria-hidden="true"></i></span>
                                 <span id=heart class="TT-heart" *ngIf="tutor['availability']['is_favourite']=='no'"><i
                                       id="{{tutor.user_id}}" (click)="makeFavourite(tutor.user_id)" class="fa fa-heart-o"
                                       aria-hidden="true"></i></span>
                              </span>
                              <span *ngIf="!this.currentuser.isLoggedIn()">
                                 <span id=heart class="TT-heart">
                                    <i data-toggle="modal" data-target="#loginModal" class="fa fa-heart-o" aria-hidden="true"
                                       height="30px">
                                    </i>
                                 </span>
                              </span>
                           </p>
                        </div>
                   
                  <div class="media">
                     <div class="media-body">
                        <div class=" tutor-detail-info">
                           <p class="linkLabel-green"><b [innerHtml]="tutor['highlight']"></b></p>
                           <p *ngIf="tutor.active_subjects">
                             <span trans="Teaches">
                              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                             </span>
                              Teaches:
                              <span *ngFor="let subject of tutor.active_subjects; last as isLast">
                                 <span *ngIf="subject.active_subject_data">
                                    {{subject.active_subject_data.subject_name}}
                                    {{isLast?'':', '}}
                                 </span>
                              </span>
                           </p>
                           <p *ngIf="tutor['languages'].length > 0">
                              <span><i class="fa fa-commenting-o" aria-hidden="true"></i></span>
                              <b trans="Speaks">Speaks:</b>
                              <span *ngFor="let lan of tutor.languages; last as isLast">
                                 {{lan.language}}
                                 <label *ngIf="lan.level_name.language_level=='Native'" class="lite-green" style="color: #198fd9;
                     ">
                                    {{lan.level_name.language_level}}
                                 </label>
                                 <label *ngIf="lan.level_name.language_level!='Native'" class="lite-blue" style="color: #198fd9;
                     ">
                                    {{lan.level_name.language_level}}
                                 </label>
                                 {{isLast?'':', '}}
                              </span>
                           </p>
                        </div>
                     </div>
               </div>  
            </div>  
            <div class="tutor-detail-card">
               <h4 class="linkLabel" trans="About me">About me</h4>
               <div class="desc" [innerHtml]="tutor['description']"></div>
            </div>
            <div class="tutor-detail-card">
               <div class="container-fluid whiteBg">
                  <ul class="nav nav-tabs">
                     <li class="active"><a (click)="resetSchedules()" data-toggle="tab" href="#Schedule"
                           trans="Book from Schedule">Book From Schedule</a></li>
                     <li><a (click)="resetSchedules()" data-toggle="tab" href="#Package"
                           trans="Buy a Package">Buy a
                           Package</a></li>
                     <li><a (click)="resetSchedules()" data-toggle="tab" href="#PackageGroup"
                           trans="Group Class">Group
                           Class</a></li>
                  </ul>
                  <div class="tab-content">
                     <div id="Schedule" class="tab-pane fade in active">
                        <div class="greyBox">
                           <small> <i class="fa fa-question-circle" aria-hidden="true"></i> <span
                                 trans="Choose the time for your first lesson. The timings are displayed in your local timezone.">Choose
                                 the time for your first lesson. The timings are displayed in your local
                                 timezone.</span></small>
                        </div>
                        <div class="ReactModal__Content Modal-content-after-open BookingModal-tt" tabindex="-1"
                           role="dialog">
                           <div class="BookingModal-tt ModalCore-c28f0z-0 display-MT">
                              <div class="Wrapper-pro" data-qa-id="wrapper-modal">
                                 <div class="Timetable-CL">
                                    <div class="TimetableHead-CL" data-qa-id="timetable">
                                       <div class="row">
                                          <div class="col-md-1">
                                             <button *ngIf="reverseButtonAccess()" id="reverseDate"
                                                (click)="reverseDate()"
                                                class="NavButton-CL NavButtonDisabled-CL Button-button-CL Button-outlined-CL"
                                                data-qa-id="previous-timeslots">
                                                <svg height="12" viewBox="0 0 8 12" width="8"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M3.268 5.854l4.293 4.292-1.415 1.415L.44 5.854 6.146.146l1.415 1.415z">
                                                   </path>
                                                </svg>
                                             </button>
                                          </div>
                                          <div class="col-md-1" style="margin-left:-5px;">
                                             <button id="forwardDate" (click)="forwardDate()"
                                                class="NavButton-CL Button-button-CL Button-outlined-CL"
                                                data-qa-id="next-timeslots">
                                                <svg height="12" viewBox="0 0 8 12" width="8"
                                                   xmlns="http://www.w3.org/2000/svg">
                                                   <path
                                                      d="M4.732 5.854L.44 1.56 1.854.146 7.56 5.854 1.854 11.56.439 10.146z">
                                                   </path>
                                                </svg>
                                             </button>
                                          </div>
                                          <div class="col-md-4 textline2 text-center" style="padding-top:5px;">
                                             <span>{{month1}}</span>&nbsp;
                                             <span *ngIf="month2">-{{month2}}</span>
                                             <span>{{FirstDate}}-</span>
                                             <span>{{SeventhDate}},</span>
                                             <span>{{currentYear}}</span>
                                          </div>
                                          <div class="col-md-6">
                                             <input readonly class="form-control" value="{{timezone}}">
                                          </div>
                                       </div>
                                    </div>
                                    <div>
                                       <div class="DaysHead-CL">
                                          <div class="DayName-CL Day-date-CL" id="FirstDay">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FirstDay}}</p>
                                                <p class="DayDate-CL">{{FirstDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SecondDay}}</p>
                                                <p class="DayDate-CL">{{SecondDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{ThirdDay}}</p>
                                                <p class="DayDate-CL">{{ThirdDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FouthDay}}</p>
                                                <p class="DayDate-CL">{{FourthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{FifthDay}}</p>
                                                <p class="DayDate-CL">{{FifthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SixthDay}}</p>
                                                <p class="DayDate-CL">{{SixthDate}}</p>
                                             </div>
                                          </div>
                                          <div class="DayName-CL Day-date-CL">
                                             <div class="DayHead-CL">
                                                <p class="DayTitle-CL">{{SeventhDay}}</p>
                                                <p class="DayDate-CL">{{SeventhDate}}</p>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="Calendar-CL">
                                          <div class="Day-date-CL">
                                             <div *ngFor="let firstDateTiming of FirstDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FirstDate}}-{{firstDateTiming}}-{{getMonthForDate(weekFirstDate)}}"
                                                (click)="selectDate( FirstDate , firstDateTiming , weekFirstDate)"
                                                [ngClass]="{'badge' : checkDate(FirstDate, firstDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFirstDate, firstDateTiming)}">
                                                {{firstDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let secondDateTiming of SecondDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SecondDate}}-{{secondDateTiming}}-{{getMonthForDate(weekSecondDate)}}"
                                                (click)="selectDate( SecondDate , secondDateTiming , weekSecondDate)"
                                                [ngClass]="{'badge' : checkDate(SecondDate, secondDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSecondDate, secondDateTiming)}">
                                                {{secondDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let thirdDateTiming of ThirdDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{ThirdDate}}-{{thirdDateTiming}}-{{getMonthForDate(weekThirdDate)}}"
                                                (click)="selectDate( ThirdDate , thirdDateTiming , weekThirdDate)"
                                                [ngClass]="{'badge' : checkDate(ThirdDate, thirdDateTiming)}"
                                                [ngClass]="{'badge' : checkDate(ThirdDate, thirdDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekThirdDate, thirdDateTiming)}">
                                                {{thirdDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fourthDateTiming of FourthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FourthDate}}-{{fourthDateTiming}}-{{getMonthForDate(weekFourthDate)}}"
                                                (click)="selectDate( FourthDate , fourthDateTiming , weekFourthDate)"
                                                [ngClass]="{'badge' : checkDate(FourthDate, fourthDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFourthDate, fourthDateTiming)}">
                                                {{fourthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let fifthDateTiming of FifthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{FifthDate}}-{{fifthDateTiming}}-{{getMonthForDate(weekFifthDate)}}"
                                                (click)="selectDate( FifthDate , fifthDateTiming , weekFifthDate)"
                                                [ngClass]="{'badge' : checkDate(FifthDate, fifthDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekFifthDate, firstDateTiming)}">
                                                {{fifthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let sixthDateTiming of SixthDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SixthDate}}-{{sixthDateTiming}}-{{getMonthForDate(weekSixthDate)}}"
                                                (click)="selectDate( SixthDate , sixthDateTiming , weekSixthDate)"
                                                [ngClass]="{'badge' : checkDate(SixthDate, sixthDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSixthDate, firstDateTiming)}">
                                                {{sixthDateTiming}}
                                             </div>
                                          </div>
                                          <div class="Day-date-CL">
                                             <div *ngFor="let seventhDateTiming of SeventhDateTimings"
                                                class="TimeSlot-CL TimeSlotAvailable-CL"
                                                id="{{SeventhDate}}-{{seventhDateTiming}}-{{getMonthForDate(weekSeventhDate)}}"
                                                (click)="selectDate( SeventhDate , seventhDateTiming , weekSeventhDate)"
                                                [ngClass]="{'badge' : checkDate(SeventhDate, seventhDateTiming)}"
                                                [ngClass]="{'linkLabel' : checkstudentBookings(weekSeventhDate, firstDateTiming)}">
                                                {{seventhDateTiming}}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="InstantBookingFooter-CL text-left">
                                 <div class="row">
                                    <div class="col-sm-6">
                                       <p><span>Total Lessons Selected:
                                          </span>{{selectedLessons}}</p>
                                       <p><span>Price per Lesson:</span>
                                          {{selectedCurrency.symbol}}{{tutor.hourly_rate}}</p>
                                       <p>
                                          <span *ngIf="adminWholeDiscount>0">Promotional
                                             Discount: {{adminWholeDiscount}}%</span>
                                       </p>
                                       <p class="fontbold linkLabel" trans="Total to pay">Total to pay:
                                          {{selectedCurrency.symbol}}{{totalPrice}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                       <select id="scheduleSubject"
                                          (input)="selectSubject($event.target.value)" class="form-control">
                                          <option value="" hidden>Select Subject</option>
                                          <option *ngFor="let subject of tutor.active_subjects"
                                             value="{{subject.active_subject_data.subject_name}}">
                                             {{subject.active_subject_data.subject_name}}
                                          </option>
                                       </select>
                                    </div>
                                 </div>
                                 <div class="row" *ngIf="showCheck && selectedLessons > 0">
                                    <div *ngIf="this.currentuser.isLoggedIn()" class="col-sm-12 text-center">
                                       <button *ngIf="selectedSubject && selectedLessons > 0"
                                          id="staticBackdrop" data-backdrop="static" tabindex="-1"
                                          aria-labelledby="staticBackdropLabel" aria-hidden="true"
                                          (click)="openCheckoutModal()" href="#modalPayment"
                                          data-target="#modalPayment" data-toggle="modal"
                                          id="BtnCheckOutDetails" class="btn btn-primary new"
                                          trans="Complete Booking">Complete Booking</button>
                                       <button *ngIf="!selectedSubject || selectedLessons == 0" disabled
                                          (click)="openCheckoutModal()" id="BtnCheckOutDetails"
                                          class="btn btn-primary new" trans="Complete Booking">Complete
                                          Booking</button>
                                    </div>
                                    <div *ngIf="!this.currentuser.isLoggedIn()" class="col-sm-12 text-center">
                                       <button disabled id="BtnCheckOutDetails" (click)="openCheckoutModal()"
                                          class="btn btn-primary new" trans="Complete Booking">Complete
                                          Booking</button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="Package" class="tab-pane fade">
                        <div class="row">
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Select Subject">Select Subject</p>

                              <select (input)="packageSubject($event.target.value, 'package')"
                                 class="form-control" id="selectSubject">
                                 <option value="" selected trans="Select Subject">Select Subject</option>
                                 <option *ngFor="let subject of tutor.active_subjects"
                                    value="{{subject.active_subject_data.subject_name}}">
                                    {{subject.active_subject_data.subject_name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Packages">Packages</p>
                              <select (input)="selectpackage($event.target.value, 'package')"
                                 class="form-control" id="selectPack">
                                 <option value="" selected trans="Select Package">Select Package</option>
                                 <option value="{{package.no_of_classes}}" *ngFor="let package of packages">
                                    {{package.no_of_classes}} Lessons</option>
                              </select>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Package Price">Package Price</p>
                              <input readonly class="form-control" value="{{packagePrice}}">
                           </div>
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Discount Price">Discount Price</p>
                              <input readonly class="form-control" value={{discountPrice}}>
                           </div>
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Final Price">Final Price</p>
                              <input readonly class="form-control" value={{packageFinalPrice}}>
                           </div>
                        </div>
                        <div class="InstantBookingFooter-CL text-left">
                           <div class="row">
                              <p class="fontbold text-center" trans="Total to pay :">Total to Pay :
                                 {{selectedCurrency.symbol}} {{packageFinalPrice}} </p>
                           </div>
                           <div class="row">
                              <div *ngIf="currentuser.isLoggedIn()" class="col-sm-12 text-center">
                                 <button (click)="packageCheckOut()" href="#modalPayment"
                                    data-target="#modalPayment" data-toggle="modal" id="packageBtnCheckOut"
                                    type="button" disabled class="btn btn-primary new"
                                    trans="Complete Booking">Complete Booking</button>
                              </div>
                              <div *ngIf="!currentuser.isLoggedIn()" class="col-sm-12 text-center">
                                 <button (click)="packageCheckOut()" id="packageBtnCheckOut" type="button"
                                    disabled class="btn btn-primary new" trans="Complete Booking">Complete
                                    Booking</button>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div id="PackageGroup" class="tab-pane fade">
                        <div class="row">
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Select Subject">Select Subject</p>
                              <select (input)="packageSubject($event.target.value, 'group')"
                                 class="form-control" id="selectPackageSubject">
                                 <option value="" trans="Select Subject" selected>Select Subject</option>
                                 <option *ngFor="let subject of tutor.active_subjects"
                                    value="{{subject.active_subject_data.subject_name}}">
                                    {{subject.active_subject_data.subject_name}}
                                 </option>
                              </select>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Available Classes">Available Classes</p>
                              <select (input)="selectpackage($event.target.value, 'group')"
                                 class="form-control" id="selectBatch">
                                 <option value="" trans="Select Group Class" selected>Select Group Class
                                 </option>
                                 <option value="{{group_class.no_of_classes}}"
                                    *ngFor="let group_class of group_classes">
                                    {{group_class.no_of_classes}} Lessons</option>
                              </select>
                           </div>
                           <div class="col-sm-6 mb-10">
                              <p class="cc-color" trans="Actual Price">Actual Price</p>
                              <input readonly class="form-control" value="{{packagePrice}}">
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Discount Price">Discount Price</p>
                              <input readonly class="form-control" value={{discountPrice}}>
                           </div>
                           <div class="col-md-6 mb-10">
                              <p class="cc-color" trans="Final Price">Final Price</p>
                              <input readonly class="form-control" value={{packageFinalPrice}}>
                           </div>
                        </div>

                        <div class="row"
                           *ngIf="selectedPackage && selectedPackage.batches_active && selectedPackage.batches_active.length > 0">
                           <div class="col-md-12 mb-10" *ngFor="let batch of selectedPackage.batches_active">
                              <label class="control control--checkbox" *ngIf="batch.seats_left > 0">
                                 <input value="{{batch.id}}" type="checkbox" (click)="totalSelected($event)">
                                 <div class="control__indicator">
                                 </div>&nbsp;<span class="greyColor">{{batch.class_name}}</span>
                                 <small class="greyColor">
                                    <p>Class Time: {{batch.time_display}}</p>
                                    <p>Class Days: {{batch.class_days}}</p>
                                    <p>Start Date: {{batch.start_date}}</p>
                                    <p>End Date: {{batch.end_date}}</p>
                                    <p color="red">Seats Left: {{batch.seats_left}} Only</p>
                                 </small>
                              </label>
                           </div>
                        </div>
                        <div class="InstantBookingFooter-CL text-left">
                           <div class="row">
                              <div *ngIf="adminFirstPackageDiscount>0">
                                 <p *ngIf="trailPackageAccess =='yes'" class="fontbold text-center"
                                    trans="Trail Package Discount">Trail Package Discount :
                                    {{adminFirstPackageDiscount}}%
                                 </p>
                              </div>
                              <div *ngIf="adminPackageWholeDiscount>0">
                                 <p *ngIf="trailPackageAccess =='no'" class="fontbold text-center"
                                    trans="Package Discount">Package Discount : {{adminPackageWholeDiscount}}%
                                 </p>
                              </div>
                           </div>
                           <div class="row">
                              <p class="fontbold text-center">
                                 <!-- <span trans="">:</span>{{total_batches_selected}} -->
                                 <span trans="Total to pay :">Total to Pay :</span> {{selectedCurrency.symbol}}
                                 {{packageFinalPrice}}
                              </p>
                           </div>
                           <div class="row">
                              <div *ngIf="currentuser.isLoggedIn()" class="col-sm-12 text-center p-10">
                                 <button (click)="packageCheckOut()" href="#modalPayment"
                                    data-target="#modalPayment" data-toggle="modal" id="groupBtnCheckOut"
                                    type="button" disabled class="btn btn-primary new"
                                    trans="Complete Booking">Complete Booking</button>
                              </div>
                              <div *ngIf="!currentuser.isLoggedIn()" class="col-sm-12 text-center p-10">
                                 <button (click)="packageCheckOut()" id="groupBtnCheckOut" type="button"
                                    disabled class="btn btn-primary new" trans="Complete Booking">Complete
                                    Booking</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div *ngIf="tutor.educations.length > 0 || tutor.certificates.length > 0"
               class="tutor-detail-card">
               <div class="container-fluid whiteBg">
                  <ul class="nav nav-tabs TT-navNavTabb">
                     <li class="active"><a data-toggle="tab" href="#education" trans="Education">Education</a>
                     </li>
                     <li><a data-toggle="tab" href="#certifications" trans="Certifications">Certifications</a>
                     </li>
                  </ul>
                  <div class="tab-content">
                     <div id="education" class="tab-pane fade in active">
                        <span *ngFor="let education of tutor.educations">
                           {{education.from_date | date: 'yyyy'}} - {{education.to_date | date: 'yyyy'}}
                           {{education.degree_name}} - {{education.level_id}}
                           <br>
                        </span>
                     </div>
                     <div id="certifications" class="tab-pane fade">
                        <span *ngFor="let certificate of tutor.certificates">
                           {{certificate.certificate_name}} - {{certificate.level_id}}
                        </span>
                     </div>
                  </div>
               </div>
            </div>           
               </div>
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xl-12">
                  <div class="media TT-card">
                     <div class="teacher-card-video" *ngIf="tutor['bypass']">
                        <iframe width="320" height="195" [src]="tutor['bypass']" frameborder="0" allowfullscreen>
                        </iframe>
                     </div>
                     <span *ngIf="tutor.rating>0">
                        <app-rating [rating]="tutor.rating" [review]="tutor.over_all_reviews"> </app-rating>
                     </span>
                     <div class="TT-mediaBody">
                        <p *ngIf="tutor['availability']['trailLessonAccess'] == 'yes'" #openSchedules id="staticBackdrop" data-backdrop="static" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" (click)="tutorSchedule(tutor['user_data']['id'] ,tutor['tutorBookingDates'], tutor['tutor_packages'] , tutor['user_data'] , tutor , tutor['active_subject_data'] , tutor['availability'] , 'trialLesson')" href="#modaldatepicker" data-target="#modaldatepicker" data-toggle="modal" class="TT-linkTWC" trans="Book Lesson ">Book Lesson</p>
                        <p *ngIf="currentuser.isLoggedIn()" (click)="messageModal(tutor, tutor['user_data'])" #openMessageModal href="#messageModal" data-toggle="modal" class="TT-linkTWC" trans="Send a Message ">Send a Message </p>
                        <p *ngIf="!currentuser.isLoggedIn()" data-toggle="modal" data-target="#loginModal" class="TT-linkTWC" trans="Send a Message "> Send a Message </p>
                        <p><i class="fa fa-circle-o green" aria-hidden="true" trans="Usually responds in 2 hrs"></i> Usually Responds in 2 hrs </p>
                     </div>                   
                  </div>
               </div>
              
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
<app-checkout (scheduleModal)="openscheduleModal($event)"
   (removeDataFromScheduleModal)="removeDataFromScheduleModal($event)" (bookingCompleted)="bookingCompleted($event)"
   [checkOutDataMain]="checkOutFinalData"></app-checkout>
<a #openLoginModal data-toggle="modal" data-target="#loginModal"></a>
<app-login></app-login>
<app-schedule (checkOutData)="getCheckoutDetails($event)"></app-schedule>
<a #successclild href="#successModallLabel" data-target="#successModal" data-toggle="modal"></a>
<app-success-modal></app-success-modal>