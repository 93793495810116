<div aria-hidden="true" aria-labelledby="video-ModalLabel" role="dialog" tabindex="-1" id="video-Modal" class="modal fade" style="display: none;">
   <div class="modal-dialog mt-200">
      <div class="modal-content bd-25">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" (click)="closeVideoModal()" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark" trans="Introduction Video for Tutor">Introduction Video for Tutor</h4>
         </div>
         <div *ngIf="demoLink" class="modal-body">
            <iframe id = "video" width="580" height="300" [src] = "demoLink" frameborder = "0" allowfullscreen></iframe>
            <div id = "lightbox">
               <i id = "close-btn" class="fa fa-times"></i>
               <img src="assets/images/video.png" class="img-video video_Btn">
               <div id = "video-wrapper">
               </div>
            </div>
         </div>
      </div>
   </div>
</div>