<div class="TT-cardds">
  <div class="Table-Height TT-tableHeight">
   <h4 class="TT-title" trans="Documentations"> Documentations </h4>
   <div class="inner-container Table-Height_scroll" *ngIf="documentation$ && documentation$.length > 0">
      <ul class="TT-listOrders">
         <li class="TT-notify" *ngFor="let doc of documentation$" class="cursor">
            <a class="TT-headlineList" (click)="more(doc.page_route)">{{doc.title}}</a>
         </li>
         <li class="TT-notify" *ngIf="documentation$ && documentation$.length == 0">
            <span trans="No Records Found" class="TT-noRecords">
             <!-- <img src="../../../../assets/images/nodata.jpg" alt="no data found" class="img-responsive w-100 m-auto " /> -->
             <div class="text-center TT-noData">No Records Found</div>
            </span>
          </li>
      </ul>
   </div>
   <div class="TT-innerCover">
      <div *ngIf="documentation$ && documentation$.length > 0" (click)="more(documentation$['0'].page_route)" class="TT-clickable" id="alerts">
         <a class="TT-click" trans="More >>>">More >>></a>
      </div>
   </div>
</div>
</div>