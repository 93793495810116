import { Component, OnInit, ViewChild, ElementRef ,ChangeDetectionStrategy, Output,  EventEmitter } from '@angular/core';
import { FindTutor } from './findtutor.service';
import { ActiveSubjects } from '@auth/active-subjects';
import { DemoVideoComponent } from './demo-video/demo-video.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FormBuilder, Validators} from '@angular/forms';
import { LocaleCurrency } from '@auth/localecurrency';
import { Currencies } from '@auth/currencies';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessagemodalComponent } from './messagemodal/messagemodal.component';
import { SubjectTS } from '@auth/subject';
import { CurrentUser } from '@auth/current-user';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { CurrentSessionTS } from '@auth/currentsession';
import { ViewEncapsulation } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CheckoutComponent } from './checkout/checkout.component';
import { Model } from '@core/types/models/Model';
import { WalletAmountTS } from '@auth/walletamount';
import { DatatableService } from '@core/datatable/datatable.service';
import { FilterComponent } from '@student/findtutor/filter/filter.component';
import { GoTopComponent } from './go-top/go-top.component';

declare var $ :any;

@Component({
  selector: 'app-findtutor',
  templateUrl: './findtutor.component.html',
  styleUrls: ['./findtutor.component.css','./findtutor1.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatatableService],
})

export class FindtutorComponent implements OnInit {

  @ViewChild('successclild') successclild: ElementRef;
  @ViewChild(DemoVideoComponent) child: DemoVideoComponent;
  @ViewChild(ScheduleComponent) scheduleChild: ScheduleComponent;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild(CheckoutComponent)checkoutChild:CheckoutComponent;
  @ViewChild('openSchedules') openSchedules: ElementRef;
  @ViewChild(FilterComponent) filterChild: FilterComponent;
  @ViewChild(GoTopComponent) gotop: GoTopComponent;

  public activeSubjects;
  public selectedSubject = new Array();
  public subjectName;
  public subjectId;
  public tutorsCount = this.datatable.totalCount$;
  public Favourite = [];
  public lessonsDataSeleted;
  public activateLoader;
  public studentBookings;
  public prices = [];
  public searchTutorForm = this.fb.group({
    subjectName: ['',Validators.required]
   });
  public selectedSubjectId;
  public availability;
  public sendAsNotification = 'Yes';
  public tutors$ = this.datatable.data$;
  public GBP;
  public previousAppendedId;
  public session;
  public sessiondetails;
  public checkOutFinalData;
  public selectedCurrency;
  public popularCurrencies;
  public timezone;
  public tutorNames = [];
  
  constructor(public findtutor:FindTutor,
  	          public activesubjects: ActiveSubjects,
              private fb: FormBuilder,
              public localecurrency: LocaleCurrency,
              public currencies: Currencies,
              private router: Router,
              private subject: SubjectTS,
              private elementRef: ElementRef,
              public currentuser: CurrentUser,
              private localetimezone: LocaleTimeZoneTS,
              private currentsession: CurrentSessionTS,
              public subjectselected: SubjectTS,
              public sanitizer: DomSanitizer,
              private wallet: WalletAmountTS,
              private toastr: ToastrService,
              public datatable: DatatableService<Model>,
              private route: ActivatedRoute
  	          ) {

  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
  this.timezone = this.localetimezone.timezone();
  this.popularCurrencies = this.currencies.currencies();
  this.GBP = this.popularCurrencies.find(x => {
              if(x.code == 'USD'){
               return x;
               }
          });
  this.tutors$.subscribe(check=>{
   this.convertCurrencies();
   this.convertVideoUrls();
   $('.CC-main').removeClass('overlay');
  });
 }

  ngOnInit(): void {

        this.datatable.destroy();
        this.datatable.init({
            uri: FindTutor.tutors
        });

    this.activeSubjects = this.activesubjects.activeSubjects$.value;
    if(!this.subjectName){
      let subjectDetails = JSON.parse(localStorage.getItem('subject'));
      this.subjectName = subjectDetails['subject_name'];
      this.selectedSubject = [{ subject_name: subjectDetails['subject_name'] }, { id: subjectDetails['id'] }];
    }
  }

 ngOnDestroy() {
        this.datatable.destroy();
    }


    ngAfterViewInit(){
         this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F4F4F4';
    }

  public selectSubject(subject , id){
     localStorage.removeItem('subject');
     this.searchTutorForm.value.subjectName = subject;
     this.selectedSubject = [{ subject_name: subject }, { id: id }];
     let subjectSelected = this.activeSubjects.find(x=>x.subject_name === subject);
     localStorage.setItem('subject' , JSON.stringify(subjectSelected));
     $('.results').css('display','none');
     $('.subject').val(subject);
     $('#findATeacherBtn').prop('disabled', false);
  }

  public filter(event){
    if(event.subjectId != this.selectedSubjectId){
    this.selectedSubjectId = event.subjectId;
    this.subjectName = event.subject;
    this.selectedSubject = [{ subject_name: event.subject }, { id: event.subjectId }];
    }
  }

  public findTeacher(){
    this.subjectName = this.selectedSubject['0']['subject_name'];
        this.datatable.init({
            uri: FindTutor.tutors
        });
  }


  public JSON(languages){
    return JSON.parse(languages);
  }

  public changeCurrency(currency){
    this.selectedCurrency = currency;
    this.convertCurrencies();
  }

  public convertCurrencies(){
      this.prices = [];
      this.tutorNames = [];
     
    if(this.tutors$){
      for(let tutor of this.tutors$.value){
        // console.log(tutor)  
        tutor['tutor_resume']['hourly_rate_original'] = tutor['tutor_resume']['hourly_rate'];  
          // let tutorMainHourlyRate = tutor['tutor_resume']['hourly_rate_GBP'];
          let tutorMainHourlyRate = tutor['hourly_rate_GBP'];
          let tutorCurrencyInDollars = tutorMainHourlyRate/this.GBP.exchange_rate;
          let hourlyRateInSelectedCurrency = tutorCurrencyInDollars * this.selectedCurrency.exchange_rate;
          tutor['tutor_resume']['hourly_rate'] = (Math.round(hourlyRateInSelectedCurrency * 100) / 100).toFixed(2);          
          tutor['hourly_rate'] = (Math.round(hourlyRateInSelectedCurrency * 100) / 100).toFixed(2);          
          this.prices.push(tutor['tutor_resume']['hourly_rate']);
          this.tutorNames.push(tutor['tutor_resume']['display_name']);
      }
    }
    this.getWalletBalance(this.selectedCurrency.code , this.selectedCurrency.symbol);
  }

  public convertVideoUrls(){
    if(this.tutors$){
      for(let tutor of this.tutors$.value){
  tutor['video_presentation']['bypass'] = this.sanitizer.bypassSecurityTrustResourceUrl(tutor['video_presentation']['video_URL']);
     }
    }
  }

   public tutorSchedule(tutorsubject , bookedDates, packages, user, resume, subjectData , availability, lessonType){
   this.availability = availability;
   
    if(this.availability){
      this.scheduleChild.tutorSubject(tutorsubject);
      this.scheduleChild.bookedDates(bookedDates);
      this.scheduleChild.packagesData(packages);
      this.scheduleChild.groupclassesData(tutorsubject.tutor_groupclasses_active);
      this.scheduleChild.userData(user);
      this.scheduleChild.userResume(resume);
      this.scheduleChild.subjectId(subjectData);
      this.scheduleChild.availabilityData(this.availability , this.studentBookings , lessonType);
   }
  }

  public tutorDetails(tutor){
  let tutorDetails = Object();
  Object.assign(tutorDetails , {tutor:tutor});
  Object.assign(tutorDetails , {subject:this.selectedSubject});
  this.findtutor.tutorDetails(tutorDetails);
  }

  public tutorProfile(userName){
  this.router.navigate(['student/profile/'+userName]);
  }

public playVideo(id , videoURL){
let videoURLAU = videoURL + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&autoplay=1';
var e = $('<iframe width="100%" height="170" src="'+videoURLAU+'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
if(this.previousAppendedId){
  $('#'+this.previousAppendedId+'_thumbnail1').show();
  $('#'+this.previousAppendedId+'_thumbnail').show();
  $('#'+this.previousAppendedId+'_video').empty();
  }
$('#'+id+'_video').append(e);    
$('#'+id+'_thumbnail1').hide();
$('#'+id+'_thumbnail').hide();
this.previousAppendedId = id;
}

public modalSession(session , sessionDetails){
  localStorage.removeItem('modalSession');
  localStorage.removeItem('modalSessionDetails');
  localStorage.setItem('modalSession',session);
  localStorage.setItem('modalSessionDetails' , sessionDetails);
}

  public makeFavourite(id){
     if($("#"+id).hasClass('fa-heart-o')){
     this.activateLoader = true;
     $("#"+id).removeClass('fa-heart-o');
     $("#"+id).addClass('fa-heart');
      let favourite_tutors = Object();
      Object.assign(favourite_tutors , {id:id});
      this.findtutor.makeFavourite(favourite_tutors).subscribe(response => {
       let favourite = JSON.parse(atob(response['favourite']));
       this.toastr.success('Added To Favourite');
       this.activateLoader = false;
      })
     }else{
       this.activateLoader = true;
     $("#"+id).addClass('fa-heart-o');
     $("#"+id).removeClass('fa-heart');
      let favourite_tutors = Object();
      Object.assign(favourite_tutors , {id:id});
      this.findtutor.makeFavourite(favourite_tutors).subscribe(response => {
       let favourite = JSON.parse(atob(response['favourite']));
       this.toastr.success('Removed From Favourite');
       this.activateLoader = false;
      })
   }
  }

  public getCheckoutDetails(data){
   this.checkOutFinalData = data;
  }

  public messageModal(resume, userData){
    userData.sendAsNotification = this.sendAsNotification;
    this.messageChild.userData(resume.display_name, userData);
  }
  
  public bookingCompleted(){
    this.openThankYouModal();
    this.datatable.init({
            uri: FindTutor.tutors
        });
    this.scheduleChild.clearModalData();
  }

  public openThankYouModal(){
    this.successclild.nativeElement.click();
    this.getWalletBalance(this.selectedCurrency.code , this.selectedCurrency.symbol);
  }

  public openscheduleModal(lessonData){
    let data = new Array();
    this.lessonsDataSeleted = data.push(lessonData);
    this.openSchedules.nativeElement.click();
  }

  public overlayStatus(overlayStatus){
  if($('.CC-main').hasClass('overlay')){
     $('.CC-main').removeClass('overlay');
   }else{
      $('.CC-main').addClass('overlay');
   }
  }

  public removeDataFromScheduleModal(value){
      this.scheduleChild.clearModalData();
   }

   /*get Latest Wallet Balance*/
   getWalletBalance(currency , symbol){
    this.findtutor.userWalletBalance(currency , symbol).subscribe(response =>{ 
      if(response['balance']){
       let balance = response['balance'].replace(/\,/g,'');
       balance = (Math.round(balance * 100) / 100).toFixed(2);
       this.wallet.init(balance);
      }
    });
   }

   /* CLear All Selected Filter */
   clearAllFilters(){
    // localStorage.clear();
    this.filterChild.resetFilterSelected(this.selectedSubject[1]['id']);
   }
}