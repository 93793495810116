<div aria-hidden="true" aria-labelledby="paymenntModalLabel" role="dialog" tabindex="-1" id="modalPayment"
   class="modal fade">
   <div class="modal-dialog modal-lg mt-100">
      <div class="modal-content bd-25">
         <div class="modal-header border0">
            <div *ngIf="loading$ | async" class="loader_Main">
               <div class="loader-cal"></div>
            </div>
            <button aria-hidden="true" data-dismiss="modal" class="close" (click)="closeModal()"
               type="button">×</button>
            <button aria-hidden="true" data-dismiss="modal" class="close" #closeCheckoutModal type="button"></button>
            <h4 trans="Payment Method">Payment Method</h4>
            <p>
               <i class="fa fa-lock green" aria-hidden="true"></i>
               <small>
                  It's safe to pay on My Tutor Platform. All transactions are protected by SSL encryption</small>

            </p>
         </div>
         <div class="modal-body paddingTop0">
            <div class='row'>
               <div class='col-md-7'>
                  <div class=''>
                     <div class=''>
                        <div (click)="switchPayment('card')" id="stripeCard" class='activerow selectrow marginTop0'>
                           <input type="radio" id="card1" name="radio-group" value="card" checked>
                           <label for="card1"></label>
                           <img src="assets/images/visa.svg" class="CCcards">
                           <img src="assets/images/master.svg" class="CCcards">
                           <img src="assets/images/amex.png" class="CCcards">
                           <img src="assets/images/discover.svg" class="CCcards">
                           <span class="pull-right line-height50">
                              <i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>&nbsp;
                              <i class="fa fa-lock greycolor" aria-hidden="true"></i>
                           </span>
                           <form #checkout="ngForm" class="checkout">
                              <div class="form-row">
                                 <div id="card-info" #cardInfo></div>
                                 <div>{{error}}</div>
                              </div>
                           </form>
                        </div>
                        <div *ngIf="payment_methods && payment_methods.length > 0"
                           (click)="switchPayment('paymentMethod')" id="stripeExistingCard" class='selectrow'>
                           <input type="radio" id="payment_methods" name="radio-group" value="payment_methods">
                           <label for="payment_methods" trans="Pay with Saved Cards">Pay with Saved Cards</label>                           
                           <span class="pull-right line-height50">
                              <i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>&nbsp;
                              <i class="fa fa-lock greycolor" aria-hidden="true"></i>
                           </span>
                           <select class="form-control iconArrowDown margin5 payment_methods" id="payWithExistingCard">
                              <option value="" trans="Please select">Please select</option>
                              <option [value]="method.card_id" *ngFor="let method of payment_methods"
                                 [selected]="method.is_default == 1">{{method.card_mask}}</option>
                           </select>
                        </div>

                        <div (click)="switchPayment('paypal')" id="paypalCard" class='selectrow'
                           *ngIf="checkOutData && checkOutData['userDetails']['paypal_iban']">
                           <input type="radio" id="card12" value="paypal" name="radio-group">
                           <label for="card12"></label>
                           <img src="assets/images/paypal.png" class='margin5' style="width:80px">
                           <span class="pull-right line-height50">
                              <i class="fa fa-paypal fa-2x" aria-hidden="true"></i>&nbsp;
                              <i class="fa fa-lock greycolor" aria-hidden="true"></i>
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class='col-md-5'>
                  <div class='padding20 paddingTop0'>
                     <div class="media">
                        <div *ngIf="checkOutData" class="media-left">

                           <img *ngIf="checkOutData.userDetails && checkOutData.userDetails.user_data; else no_avatar"
                              [src]="checkOutData.userDetails.user_data.avatar" class="media-object circleImg"
                              style="width:60px">
                           <ng-template #no_avatar>
                              <img *ngIf="checkOutData.userDetails" [src]="checkOutData.userDetails.avatar"
                                 class="media-object circleImg" style="width:60px">
                           </ng-template>

                           <span *ngIf="checkOutData.resume.rating>0">
                              <app-rating [rating]="checkOutData.resume.rating"
                                 [review]="checkOutData.resume.over_all_reviews"></app-rating>
                           </span>
                        </div>
                        <div *ngIf="checkOutData" class="media-body">
                           <h4 *ngIf="checkOutData.resume" class="media-heading">{{checkOutData.resume.display_name}}
                              <img src="{{checkOutData.resume.flag}}" width="35" class="resume_flag" />
                           </h4>

                           <p
                              *ngIf="checkOutData.selectedSubject && checkOutData.selectedSubject.active_subject_data; else no_subject">
                              <i class="fa fa-graduation-cap greycolor" aria-hidden="true"></i>
                              {{checkOutData.selectedSubject.active_subject_data.subject_name}}</p>
                           <ng-template #no_subject>
                              <p *ngIf="checkOutData.selectedSubject"><i class="fa fa-graduation-cap greycolor"
                                    aria-hidden="true"></i> {{checkOutData.selectedSubject}}</p>
                           </ng-template>
                        </div>
                     </div>
                     <div *ngIf="checkOutData">
                        <div *ngIf="checkOutData['bookingType']=='schedule'">
                           <hr>
                           <div class="table_height">
                              <table class="scheduleTable">
                                 <thead>
                                    <tr class="TB_width">
                                       <th trans="Date">Date</th>
                                       <th trans="Month">Month</th>
                                       <th trans="Time">Time</th>
                                       <th trans="Slot Date">Slot Date</th>
                                    </tr>
                                 </thead>
                                 <tbody *ngIf="slotsSelected">
                                    <tr *ngFor="let lesson of slotsSelected" class="TB_width">
                                       <td>{{lesson.date}}</td>
                                       <td>{{lesson.month}}</td>
                                       <td>{{lesson.time}}</td>
                                       <td>{{lesson.slot_date | date: "MM-dd-yyyy" }}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                           <hr>
                        </div>
                     </div>
                     <table>
                        <thead>
                           <tr>
                              <th>Service Details</th>
                              <th class="text-right">Price per Hour</th>
                           </tr>
                        </thead>

                        <tbody *ngIf="checkOutData">
                           <tr>
                              <td>
                                 <span trans="1 hour lesson">1 hour lesson</span>
                                 <i tooltip="{{i18n['localization']['lines']['1_hour_lesson']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>

                              </td>
                              <td class="text-right">
                                 <b>{{selectedCurrency.symbol}}{{checkOutData.hourlyRate}}</b>
                              </td>
                           </tr>

                           <!-- SCHEDULE -->
                           <tr *ngIf="checkOutData['bookingType']=='schedule'">
                              <td trans="Lessons Selected">Lessons Selected
                                 <i tooltip="{{i18n['localization']['lines']['lessons_selected']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b>{{checkOutData.selectedLessons}}</b>
                              </td>
                           </tr>
                           <tr *ngIf="checkOutData.discountedPercentage && checkOutData['bookingType']!='package'">
                              <td>
                                 <span *ngIf="checkOutData['lessonType']=='schedule'"
                                    trans="Promotional Discount">Promotional Discount</span>
                                 <span *ngIf="checkOutData['lessonType']=='trialLesson'" trans="Trial Discount">Trial
                                    Discount</span>
                                 <i tooltip="{{i18n['localization']['lines']['trial_promotional_discount']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b>{{checkOutData.discountedPercentage}}%</b>
                              </td>
                           </tr>
                           <!-- SCHEDULE -->

                           <!-- PACKAGE -->

                           <tr *ngIf="checkOutData['bookingType']=='package' || checkOutData['bookingType']=='group'">
                              <td><span trans="Package Lessons">Package Lessons</span>
                                 <i tooltip="{{i18n['localization']['lines']['package_lessons']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b>{{checkOutData.packageLessons}}</b>
                              </td>
                           </tr>
                           <tr *ngIf="checkOutData['bookingType']=='package' || checkOutData['bookingType']=='group'">
                              <td trans="Package Price">Package Price
                                 <i tooltip="{{i18n['localization']['lines']['package_price']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b>{{selectedCurrency.symbol}}{{checkOutData.packagePrice}}</b>
                              </td>
                           </tr>
                           <tr *ngIf="checkOutData['bookingType']=='package' || checkOutData['bookingType']=='group'">
                              <td trans="Admin Discount">Admin Discount
                                 <i tooltip="{{i18n['localization']['lines']['admin_discount']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b>{{checkOutData.adminDiscount}}%</b>
                              </td>
                           </tr>
                           <!-- PACKAGE -->


                           <tr>
                              <td trans="Cancellation">Cancellation
                                 <i tooltip="{{i18n['localization']['lines']['cancellation']}}"
                                    class="fa fa-question-circle-o greycolor" aria-hidden="true"></i>
                              </td>
                              <td class="text-right">
                                 <b trans="Free">Free</b>
                              </td>
                           </tr>
                        </tbody>
                        <tfoot *ngIf="checkOutData">
                           <tr>
                              <td>
                                 <h4 trans="Total">Total</h4>
                              </td>
                              <td class="text-right">

                                 <h4 tooltip="${{finalPriceInpounds}}" placement="bottom">
                                    {{selectedCurrency.symbol}}{{checkOutData.totalPrice}}</h4>
                              </td>
                           </tr>
                        </tfoot>
                     </table>
                  </div>
               </div>
            </div>
         </div>
         <div class="modal-footer">
            <div class="row col-md-12 text-center flex_between TT-formGroup">
               <span class="pull-left TT-formGroup">
                  <button [disabled]="loading$ | async" (click)="openScheduleModal()" type="submit"
                     class="btn TT-primaryBTN" style="padding:8px 35px;"><span trans="Back"><i aria-hidden="true"
                           class="fa fa-arrow-left"></i>&nbsp;Back</span></button></span>
               <div id="paypal-button-container"></div>
               <app-loader *ngIf="loadingpaypal$ | async"></app-loader>

               <button [disabled]="loading$ | async" id="existing-card-button-container" *ngIf="checkOutData" style="display: none" (click)="payWithExistingCard()" class="btn TT-primaryBTN" >
               <span>   Pay &#160;{{selectedCurrency.symbol}}{{checkOutData.totalPrice}}
                  <app-loader *ngIf="loading$ | async"></app-loader></span>
               </button>
               <button [disabled]="loading$ | async" id="new-card-button-container" *ngIf="checkOutData" (click)="payWithCard()" class="btn TT-primaryBTN">
                <span>  Card&#160;{{selectedCurrency.symbol}}{{checkOutData.totalPrice}}
                  <app-loader *ngIf="loading$ | async"></app-loader></span>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>