import { ChangeDetectionStrategy , Component, OnInit , OnDestroy } from '@angular/core';
import { StudentPaymentService } from '../student-payment.service';
import { Transactions } from '../../../auth/transactions';
import { LocaleCurrency } from '../../../auth/localecurrency';
import { ToastrService } from 'ngx-toastr';
import { Currencies } from '../../../auth/currencies';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { DatatableService } from '../../../core/datatable/datatable.service';

@Component({
  selector: 'app-student-transactions',
  templateUrl: './student-transactions.component.html',
  styleUrls: ['./student-transactions.component.css'],
  providers: [DatatableService],
})
export class StudentTransactionsComponent implements OnInit , OnDestroy {
  
  public transactions$ = this.datatable.data$;
  public popularCurrencies;
  public GBP;
  public selectedCurrency;
  public previousSelectedCurrency;
  
  constructor(private service: StudentPaymentService,
              private transactions: Transactions,
              public localecurrency: LocaleCurrency,
              private toastr: ToastrService,
              public currencies: Currencies,
              public datatable: DatatableService<TransactionLog>) {

  this.transactions.changeTransaction$.subscribe(changeTransaction => {
        this.datatable.init({
            uri: StudentPaymentService.BASE_URI
        });
  });

  this.transactions$.subscribe(convertCurrencies =>{
   this.convertCurrencies();
  });
      this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.currency(changeCurrency);
     });

      this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });

  }
  public dataLoaded:boolean;

  ngOnInit(): void {
        this.datatable.init({
            uri: StudentPaymentService.BASE_URI
        });
  }


  ngOnDestroy() {
        this.datatable.destroy();
  }

  public refundRequest(id){
    let refundData = Object();
    Object.assign(refundData,{id: id});

    this.service.refundRequest(refundData)
         .subscribe(response => {
          if ( typeof response.error !== 'undefined' ) {
            this.toastr.error(response.error);
          } else {
            this.toastr.success('Refund initiated successfully');
          }
    });
  }


  public currency(changeCurrency){
      this.selectedCurrency = changeCurrency;
      if(!this.previousSelectedCurrency){
        this.previousSelectedCurrency = this.selectedCurrency;
      }
      this.convertCurrencies();
    }

  public convertCurrencies(){
    if(this.transactions$){
      for(let transaction of this.transactions$.value){
         let poundExchnageRate = this.GBP.exchange_rate;
         let amount:any = transaction['total_amount'];
         let currencyInDollars = amount/poundExchnageRate;
         let finalselectedCurrency = currencyInDollars * this.selectedCurrency.exchange_rate;
         transaction['amountConverted'] = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
               }
         this.previousSelectedCurrency = this.selectedCurrency;
      }
    }

    public changeSearchFunction(search) {
      console.log('changeSearchFunction')
      console.log(search)
    }

    public transform(value) {
      return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');   
    }
}
