import { Component, OnInit, ViewChild , ChangeDetectionStrategy , ElementRef} from '@angular/core';
import { LessonsService } from '../lessons.service';
import { NoteModalComponent } from '../note-modal/note-modal.component'
import { ActivatedRoute } from '@angular/router';
import { DatatableService } from '../../../core/datatable/datatable.service';
import { TransactionLog } from '../../../core/types/models/TransactionLog';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.css'] ,
  providers: [DatatableService],
})
export class CancelledComponent implements OnInit {
  
  public cancelled = this.datatable.data$;
  @ViewChild(NoteModalComponent) noteModal:NoteModalComponent;
  @ViewChild('closeViewDeatailsModal') closeViewDeatailsModal: ElementRef;
  @ViewChild('open') open: ElementRef;
  constructor(private lessonService: LessonsService,
              private route: ActivatedRoute,
              public datatable: DatatableService<TransactionLog>,
              private fb: FormBuilder,
              private router: Router) { 

  this.cancelled.subscribe(response=>{
      this.actionsData = response;
      if(this.actionsData.length>0){
        this.activeModal();
      }
     });
  this.lessonService.sharedsourceCancelled.subscribe(modalId=>{
    this.selectedSourceCode = modalId;
    this.activeModalId(this.selectedSourceCode);
    });
  this.lessonService.sharedtutorName.subscribe(name=>this.selectedUserName = name);
      this.route.params.subscribe( params =>{
        this.selectedTutor = params['id'];
        if(this.selectedTutor){
          this.selectedTutor = params['id'];
          this.setTutorId(this.selectedTutor);
        }else{
          this.setTutorId('');
        }
      });
}
      public form = this.fb.group({
        tutor: null,
  });

  public cancelledClasses;
  public Timings;
  public bookingRequests;
  public selectedBookingId;
  public selectedTutor;
  public selectedUserName;
  public selectedSourceCode;
  public actionsData;
  public selectedBooking;
  ngOnInit(): void {  
        this.datatable.init({
            uri: LessonsService.CANCELLED
        });
  }

    public setTutorId(id){
    this.selectedTutor = id;
    this.datatable.addFilter('tutor',id);
  }
  
   public responseHandle(response){
    let data =  JSON.parse(atob(response['data']));
      this.cancelledClasses = data['cancelledClasses'];
   }
  // Schedule Details
  public viewDetails(bookingDetails , booking){
    this.Timings = bookingDetails; 
    this.selectedBookingId = bookingDetails.booked_id;
    this.selectedBooking = booking;
  }

  // Cancell Booking
  public cancelBooking(id){
     this.selectedBookingId = id;
  } 

   public viewScheduleDetails(id , dateId){
    this.closeViewDeatailsModal.nativeElement.click();
    this.router.navigate(['student/lessons/board',{id:id , dId :dateId}]);
  }


      public activeModalId(id){
    this.selectedSourceCode = id;
    if(this.actionsData.length>0){
      this.activeModal();
    }
  }

  public activeModal(){
    $('#'+this.selectedSourceCode).addClass('bg-theme-color');
    this.actionsData.find(x=>{
      if(x.booking_id == this.selectedSourceCode){
        this.viewDetails(x.booking_dates_rejected , x);
        this.open.nativeElement.click();
      }
    });
  }

  public transform(value) {
    return value.replaceAll('_', ' ').split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');  
  }
  
}
