import { Component, OnInit , Output , ChangeDetectionStrategy, EventEmitter , Input} from '@angular/core';
import { ActiveSubjects } from '@auth/active-subjects';
import { Currencies } from '@auth/currencies';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Options } from "@angular-slider/ngx-slider";
import { CountryListItem, LanguageListItem, ValueLists } from '@core/services/value-lists.service';
import { LocaleCurrency } from '@auth/localecurrency';
import { FindTutor } from '../findtutor.service';
import { Router } from '@angular/router';
import { SubjectTS } from '@auth/subject';
import { CurrentUser } from '@auth/current-user';
import { DatatableService } from '@core/datatable/datatable.service';
import  *  as  countries  from  '@athand/json/countries.json';
import  *  as  languages  from  '@athand/json/languages.json';
import { Model } from '@core/types/models/Model';
declare var $;
import { LessonsService } from '../../lessons/lessons.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {

  public form = this.fb.group({
        sub: null,
        con: [],
        as: [],
        nl: null,
        pt: [],
        pd: [],
        tli :null,
        sn :null,
        min:null,
        max:null,
        orderBy:null,
        orderDir:null,
        search:null,
        age:null,
        fa:null
  });
    @Output() tutorSearch = new EventEmitter();

    @Input()
    public set loader(value){
      this.tutorLoader = value;
    }
    @Input()
    public set displayNames(value){
      this.tutorNames = value;
    }

    @Output() tutorCount = new EventEmitter();
    @Output() filterData = new EventEmitter();
    @Output() overlayStatus = new EventEmitter();
    @Output() filterValues = new EventEmitter();
    public countries$ = new BehaviorSubject<CountryListItem[]>([]);
    public languages$ = new BehaviorSubject<LanguageListItem[]>([]);
    public overlayStatusMain: boolean;
    public minimumPriceRange;
    public maximumPriceRange;
    public countries: any = (countries as any).default;
    public languages: any = (languages as any).default;
    public activeSubjects = new Array();
    public tutorLoader:boolean = false;
    public tutorNames;
    public filterRequests = Object();
    public selectedCurrency;
    public subjectSeleted;
    public selectedTime = new Array();
    public selectedDay = new Array();
    public filterTime = new Array();
    public filterDay = new Array();
    public teachingLevels;
    public popularCurrencies;
    public GBP;
    public USD;
    public minRange: number = 0;
    public maxRange: number = 0;
    public searchName;
    public specialityForThisSubject = [];
    public agesData = [];
    public specialitiesData = [];
    public countriesData = [];
    public languagesData = [];
    public ageData = [];
    public specialityData = [];
    public nativelanguages: any = (languages as any).default;
    public countriesForThisSubject;
    public nativelanguagesForThisSubject;
    public languagesForThisSubject;
    public ageForThisSubject;
    public countryData = [];
    public alsoSpeaksData = [];
    public nativeSpeakersData = [];
    public availabilityInfo;
    public tutorsCount;
    public allAvailabletutors = [];
    public alltutors = [];



    lowValue: any = 0;
    highValue: any = 0;
    dropdownList = [];
    selectedItems = [];
    countriesSettings = {};
    subjectsList = [];
    selectedItems1 = [];
    subjectSettings = {};
    languageSettings = {};
    ageSettings = {};
    alsoSpecksSettings = {};
    specialitiesSettings = {};
    specialities = {};
    options: Options = {
    floor: this.minRange,
    ceil: this.maxRange
  };

  minRangeChange(value){
    this.minimumPriceRange = value;
  }

  maxRangeChange(value){
    this.maximumPriceRange = value;
  }


  constructor(public activesubjects: ActiveSubjects,
  	          public currencies: Currencies,
  	          private fb: FormBuilder,
              private lists: ValueLists,
              public localecurrency: LocaleCurrency,
              public findtutor:FindTutor,
              public router: Router,
              public subjectselected: SubjectTS,
              public currentuser: CurrentUser,
              private datable: DatatableService<Model>,
              public lesson: LessonsService,
              private route: ActivatedRoute) {

    this.form.patchValue(this.datable.filters$.value);
    this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });

  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
  this.currentuser.isLoggedIn$.subscribe(val => {
    (val && this.router.url.split('?')[0] == '/student/find-tutor') ? this.form.patchValue(this.datable.filters$.value) : '';
  });

  }

  ngOnInit(): void {
    $(".Search_filter").hide();
     $(document).on('click', function (event) {
      if (!$(event.target).closest('.overLayId').length) {
              $(".CC-main").removeClass("overlay");
      }
    });

     this.form.valueChanges.subscribe(value => {
            this.datable.filters$.next(value);
            this.datable.sort$.next(value);
        });
       $(document).ready(function(){
        $("#overlay-none").click(function(e) {
          $(".container.CC-main").css("background","white");
      });
        $(".multiselect-dropdown").click(function(){
          $(".container.CC-main").addClass("overlay-add");
      });
    });

    let subjectDetails = JSON.parse(localStorage.getItem('subject'));
    this.subjectSeleted = [ { subject_name : subjectDetails.subject_name, id : subjectDetails.id } ];
    this.form.patchValue({
        sub:subjectDetails.id
    });
    this.findtutor.getTutorsList().subscribe(response=>{
    this.allAvailabletutors = JSON.parse(atob(response['tutors']))
    })
    this.getSubjectData(subjectDetails.id);
    $("#priceRage").mouseup(price => this.priceFilter());

    let subjects = this.activesubjects.activeSubjects$.value;
    console.log(subjects)
    for(var subject of subjects){
      if(subject['subject_name'] != this.subjectSeleted['0']['subject_name']){
        this.activeSubjects.push(subject);
      }
    }

    // console.log(localStorage)

    this.filterSettings();
  }




 onSubjectSelect(item: any) {
    localStorage.removeItem('subject');
    let subjectSelected = this.activeSubjects.find(x=>x.subject_name === item.subject_name);
    localStorage.setItem('subject' , JSON.stringify(subjectSelected));
    this.subjectselected.init(subjectSelected);
    this.activeSubjects = [];
    let subjects = this.activesubjects.activeSubjects$.value;
    // To Remove Select Subjects in the dropdown list
    for(var subject of subjects){
      if(subject['subject_name'] != this.subjectSeleted['0']['subject_name']){
        this.activeSubjects.push(subject);
      }
    }

    // TO get Data inside the filter belonged to selected subject.
    this.getSubjectData(item.id);

    // Send Backend Requests
    this.resetFilterSelected();
    this.form.patchValue({
        sub:item.id
    });

    // To Display Subject name in the main component
   Object.assign( this.filterRequests , {subject: item.subject_name});
   Object.assign( this.filterRequests , {subjectId: item.id});
   this.filterData.emit(this.filterRequests);
  }

  public resetFilterSelected(subId?){
    this.form.reset();

    (subId) ? this.form.patchValue({sub: subId}) : null;
    this.countryData = [];
    this.nativeSpeakersData = [];
    this.alsoSpeaksData = [];
    this.agesData = [];
    this.teachingLevels = [];
    this.specialitiesData = [];

    this.countriesData = [];
    this.languagesData = [];
    this.specialityData = [];
    this.ageData = [];
    this.selectedTime = [];
    this.selectedDay = [];
    this.filterDay = [];
    this.filterTime = [];
    $('#sortBy').val('1');
    $('#text').val('');
    this.highValue = this.maxRange;
    this.lowValue = this.minRange;
    this.changeCurrency(this.selectedCurrency)
  }

  public getSubjectData(id){
    this.findtutor.subjectDetails(id).subscribe(response => {
      if(response){
       let filterData = JSON.parse(atob(response['filtersData']));
      //  console.log(filterData)
       this.tutorsCount = filterData['tutorsCount'];
       this.tutorCount.emit(this.tutorsCount);
       this.setCountriesList(filterData['countries']);
       if(filterData['minPrice'] == 0){
        this.minRange = 1;
       }else{
        this.minRange = filterData['minPrice'];
       }
       this.maxRange = filterData['maxPrice'];
       this.changeCurrency(this.selectedCurrency);
       this.setNativeLanguage(filterData['nativeLanguage']);
       this.specialityForThisSubject = filterData['teachingLevels'];
       this.ageForThisSubject = filterData['ages'];
       this.setAlsoSpeaks(filterData['alsoSpeaks']);
      }
    });
  }

  onCountrySelect(item: any){
    this.countryData.push(item);
    this.countriesData.push(item.name);
    this.form.patchValue({
     con:this.countriesData
    });
  }

onCountryDeSelect(item: any){
  const index = this.countriesData.indexOf(item.name , 0);

if (index > -1) {
  this.countriesData.splice(index, 1);
}
    this.form.patchValue({
     con:this.countriesData
    });
  }

  alsoSpeaks(item: any){
    this.alsoSpeaksData.push(item);
    this.languagesData.push(item.name);
    this.form.patchValue({
     as:this.languagesData
    });
  }

  onAlsoSpeaksDeSelect(item: any){
  const index = this.languagesData.indexOf(item.name, 0);
if (index > -1) {
  this.languagesData.splice(index, 1);
}
    this.form.patchValue({
     as:this.languagesData
    });
  }

  specialitiesSelcted(item: any){
    this.specialitiesData.push(item);
    this.specialityData.push(item.id);
    this.form.patchValue({
     tli:this.specialityData
    });
  }

  onSpecialitiesDeSelect(item: any){
  const index = this.specialityData.indexOf(item.id, 0);
if (index > -1) {
  this.specialityData.splice(index, 1);
}
    this.form.patchValue({
     tli:this.specialityData
    });
  }

  ageSelcted(item: any){
    this.agesData.push(item);
    this.ageData.push(item.id);
    this.form.patchValue({
     age:this.ageData
    });
  }

  onAgeDeSelect(item: any){
  const index = this.ageData.indexOf(item.id, 0);
if (index > -1) {
  this.ageData.splice(index, 1);
}
    this.form.patchValue({
     age:this.ageData
    });
  }

  nativeLanguage(item: any){
    this.nativeSpeakersData.push(item);
    this.form.patchValue({
      nl:item.name
    });
  }

  onNativeLanguageDeSelect(item: any){
    this.form.patchValue({
      nl:''
    });
  }


  onSelectAll(items: any) {
  }

  public onDropDownClose(){
    this.overlay();
  }

  onSubjectDeSelect(item: any){
  }

  public filterSettings(){

    this.specialitiesSettings = {
     singleSelection: false,
     idField: 'id',
     textField: 'level_name',
     allowSearchFilter: true,
     closeDropDownOnSelection: true,
     itemsShowLimit: 1,
     enableCheckAll: false,
    }

    this.ageSettings = {
     singleSelection: false,
     idField: 'id',
     textField: 'student_age',
     allowSearchFilter: true,
     closeDropDownOnSelection: true,
     itemsShowLimit: 1,
     enableCheckAll: false,
    }


    this.subjectSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'subject_name',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: true,
      clearSearchFilter: true
    };

    this.countriesSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      closeDropDownOnSelection: true,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
    };

    this.languageSettings = {
      singleSelection: true,
      idField: 'code',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    }

    this.alsoSpecksSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      closeDropDownOnSelection: false,
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
    }

    this.specialities = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      closeDropDownOnSelection: false,
      itemsShowLimit: 3,
      limitSelection: 3,
      allowSearchFilter: true,
      placeholder: 'Specialities'
    }
  }

  public overlay(){
      this.overlayStatus.emit(true);
  }

  public changeCurrency(currency){
    this.selectedCurrency = currency;
    let minValueInDollars = this.minRange/this.GBP.exchange_rate;
    let maxValueInDollars = this.maxRange/this.GBP.exchange_rate;
    this.setNewFloor(Math.trunc(minValueInDollars * this.selectedCurrency.exchange_rate));
    this.setNewCeil(Math.trunc(maxValueInDollars * this.selectedCurrency.exchange_rate));
    this.lowValue = minValueInDollars * this.selectedCurrency.exchange_rate;
    this.highValue = maxValueInDollars * this.selectedCurrency.exchange_rate;
    console.log('lowValue',this.lowValue,'highValue',this.highValue)
  }

  public setNewFloor(newFloor:number): void {
        // Due to change detection rules in Angular, we need to re-create the options object to apply the change
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = newFloor;
    this.options = newOptions;
  }

    public setNewCeil(newCeil: number): void {
    // Due to change detection rules in Angular, we need to re-create the options object to apply the change
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.ceil = newCeil;
    this.options = newOptions;
  }



  public minValueChange(value){
    // this.lowValue = value;
  }

  public maxValueChange(value){
    this.highValue = value;
  }

  public priceFilter(){
   let inDollarLow = this.lowValue/this.selectedCurrency.exchange_rate;
   let inGBPLow = inDollarLow * this.GBP.exchange_rate;
   let lowValue = (Math.round(inGBPLow * 100) / 100).toFixed(0);
   let inDollarHigh = this.highValue/this.selectedCurrency.exchange_rate;
   let inGBPHigh = inDollarHigh * this.GBP.exchange_rate;
   let highValue = (Math.round(inGBPHigh * 100) / 100).toFixed(0);
   this.form.patchValue({
        min:lowValue,
        max:highValue,
    });
  }

  public selectTime(id , value , startTime , endTime){
    if(this.selectedTime.includes(id)){
      var index = this.selectedTime.indexOf(id);
      this.selectedTime.splice(index, 1);
      let SelectedTimes = this.calculateTime(startTime , endTime);
      for(let time of SelectedTimes){
        var index = this.filterTime.indexOf(time);
        this.filterTime.splice(index , 1);
      }
      $("#"+id).removeClass('timeActive');
    }else{
      $("#"+id).addClass('timeActive');
      this.selectedTime.push(id);
      let SelectedTimes = this.calculateTime(startTime , endTime);
      if(this.filterTime.length >0){
        this.filterTime = this.filterTime.concat(SelectedTimes);
      }else{
       this.filterTime = SelectedTimes;
      }
    }

    this.form.patchValue({
     pt:btoa(this.filterTime+""),
    });
  }

  public selectDay(id , value){
    if(this.selectedDay.includes(id)){
      var index = this.selectedDay.indexOf(id);
      var ValIndex = this.filterDay.indexOf(value);
      this.selectedDay.splice(index , 1);
      this.filterDay.splice(ValIndex , 1);
      $("#"+id).removeClass('DayActive');
    }else{
      $("#"+id).addClass('DayActive');
      this.selectedDay.push(id);
      this.filterDay.push(value);
    }
    this.form.patchValue({
     pd:this.filterDay,
    });
  }

     public calculateTime(fromTime , toTime){
      var timeStart = new Date("12/20/2020 " + fromTime).getHours();
      var timeEnd = new Date("12/20/2020 " + toTime).getHours();
      var hourDifference = timeEnd - timeStart;
      let values = [];
      let mainValues = [];
      for(let i = timeStart,j=0; i < timeEnd; i++,j++){
         var now = new Date();
         let addHour = now.setHours(new Date("12/20/2020 " + fromTime).getHours() + j);
         let addMinute = now.setMinutes(new Date("12/20/2020 " + fromTime).getMinutes());
         // let date = now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
         values.push(this.formatAMPM(now));
      }
      for(let i = timeStart,j=0; i < timeEnd; i++,j++){
         var now = new Date();
         let addHour = now.setHours(new Date("12/20/2020 " + fromTime).getHours() + j);
         let addMinute = now.setMinutes(new Date("12/20/2020 " + fromTime).getMinutes() + 30);
         // let date = now.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
         values.push(this.formatAMPM(now));
      }
      return values;
    }

  public formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  if(hours.toString().length == 1){
   hours = '0'+hours;
   var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
   }else{
   var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
   }
  }

  public sortBy(){
    let orderValue;
    let orderDir;
    let fav;
    if($('#sortBy').val() == 'HighToLow'){
     orderValue = 'hourly_rate_GBP';
     orderDir = 'desc';
    }else if($('#sortBy').val() == 'LowToHigh'){
     orderValue = 'hourly_rate_GBP';
     orderDir = 'asc';
    }else if($('#sortBy').val() == 'Top-Rated'){
     orderValue = 'rating';
     orderDir = 'desc';
    }
    this.form.patchValue({
     orderBy:orderValue,
     orderDir:orderDir
    });

    if($('#sortBy').val() == 'Favourite-Tutor'){
      fav = 'favs';
    }
      this.form.patchValue({
        fa:fav
      });
  }

  public searchByNameInput(value){
    this.searchName = value;
    if(value == ''){
    $(".Search_filter").hide();
    }
    else
    {
      $(".Search_filter").show();
      var input, filter, ul, li, a, i, txtValue;
      input = value;
      filter = input.toUpperCase();
      ul = document.getElementById("myUL");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("a")[0];
          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
      }
    }
    this.tutorSearch.emit(value);
  }

  public searchByName(){
    this.form.patchValue({
     search:this.searchName
    });
  }

  public SetsearchByName(value)
  {
    this.searchName = value;
    this.searchByName()
    $(".Search_filter").hide();
  }

  public openFilter(){
    this.router.navigate(['mobilefilter']);
  }

  public setCountriesList(list){
   let data = [];
   for(let country of list){
    this.countries.find( x =>{
       if(x.name == country){
        data.push(x);
       }
    });
   }
   this.countriesForThisSubject = data;
  }

  public setAlsoSpeaks(languages){
    if(languages){
   let data = [];
   for(let language of languages){
    this.languages.find( x =>{
      if(x.name == language){
        data.push(x);
       }
    });
   }
   this.languagesForThisSubject = data;
  }
}

  public setNativeLanguage(languages){
    if(languages){
       let data = [];
   for(let language of languages){
    this.nativelanguages.find( x =>{
      if(x.name == language){
        data.push(x);
       }
    });
   }
   this.nativelanguagesForThisSubject = data;
  }
}

}
