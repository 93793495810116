<header class="TT-headerWrapper TT-fixedTop">
    <div class="TT-brandIdentity clearfix">
      <div class="TT-brand d-flex">
       <img src="assets/images/main-logo.png" class="TT-LogoDashboard" routerLink="/student/home"/>
         <div class="collapseToggle TT-ToggleFrame">
           <i id="toggleIcon" class="fa fa-bars TT-bars" aria-hidden="true"></i>
         </div>
       </div>
       <a *ngIf="currentuser.isImpersonating()" (click)="auth.backToAdmin()" class="greyText pull-right pOinter">
        <i class="fa fa-arrow-left fa-3x" aria-hidden="true"></i><br>
        <span trans="BACK TO ADMIN">BACK TO ADMIN</span>
        </a>
       <div class="TT-logoutGroup btn-group pull-right">
          <button class="btn btn-default btn-lg dropdown-toggle TT-faultBTN" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="{{currentuser.userAvater()}}" class="TT-userIcon" />
            <label class="TT-System"> {{currentuser.fullName().length > 7 ? currentuser.userName().substring(0,4) + '...' : currentuser.fullName() }} </label>
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu TT-dropDownProfile">
             <li *ngIf="currentuser.hasPermissions(['account.access'])"><a routerLink="account">Profile</a></li>
             <li *ngIf="currentuser.hasPermissions(['account.access'])"><a routerLink="account">Change Password</a></li>
             <li><a (click)="auth.logOut()">Logout</a></li> 
           </ul>
        </div>
    </div>
  </header>
