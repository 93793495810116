<div class='col-sm-9 rightCol'>
   <div class="well well-sm chat-head">
      <h5 class="greyColor">
         <a type="submit" class="closelessonboard" (click)="backClicked()"><i class="fa fa-times pr-2"></i></a>
         <b class="s-lesson">
            Lesson Board
         </b>
      </h5>
   </div>
   <span class="content-container">
      <table class="table lesson-table">
         <tr>
            <th trans="Subject">
               Subject
            </th>
            <th trans="Tutor">
               Tutor
            </th>
            <th trans="Date">
               Date
            </th>
            <th trans="Time">
               Time
            </th>
            <th>
               Status
            </th>
            <th>

            </th>
            <th trans="Actions">
               Actions
            </th>
         </tr>
         <tr *ngIf="lessonDetails">
            <td>{{lessonDetails.booking_details.subject.subject_name}}</td>
            <td>{{lessonDetails.booking_details.tutor_resume.display_name}}</td>
            <td>{{lessonDetails.slot_date | date: 'MM-dd-yyyy'}}</td>
            <td>{{lessonDetails.booked_time}}</td>
            <td>{{transform(lessonDetails.booking_dates_status)}}</td>
            <td
               *ngIf="lessonDetails.status!= '2' && lessonDetails.status!= '3' && lessonDetails.status!= '4' && lessonDetails.status!= '5'">
               {{calculateTime(lessonDetails.booked_time , now | date:'H:mm:ss') | date:'H:mm:ss' : 'UTC'}}</td>
            <td *ngIf="lessonDetails.status== '3'" trans="CLASS RUNNING">Class Running</td>
            <!-- <td *ngIf="lessonDetails.status== '2'" trans="CLASS COMPLETED">CLASS COMPLETED</td> -->
            <td class="lesson-btn-actions dropdown TT-formGroup">

               <button *ngIf="lessonDetails.status== '3'" type="button" class="TT-primaryBTN" value="Attend"
                  (click)="attendClass(lessonDetails.booking_dates_id,lessonDetails)" trans="Attend"
                  style="width:auto;margin: 0 10px;">Attend</button>


               <!-- <button  *ngIf="lessonDetails.status== '2'"  (click)="bookingDateId(lessonDetails.booking_dates_id , lessonDetails.booking_details.tutor_id)" type="button" class="btn btn-primary" data-target="#DoneModal" data-toggle="modal"  trans="Done">Done</button> -->
               <button *ngIf="lessonDetails.status== '4'" class="btn btn-primary" trans="Rated">Rated</button>
               <i class="fa fa-ellipsis-v more data-toggle more" data-toggle="dropdown" aria-hidden="true"></i>
               <ul class="dropdown-menu dropdown-new">
                  <li class="divider"></li>
                  <li (click)="messageModal(lessonDetails['booking_details']['tutor_resume'].display_name, lessonDetails['booking_details'].tutor_id ,lessonDetails.booking_dates_id, 'upcoming lessonDetails')"
                     href="#messageModal" data-toggle="modal" trans="Message Tutor"><a>Message Tutor</a></li>
                  <li class="divider"></li>
                  <li *ngIf="lessonDetails['booking_details'].booking_type != 'group' && (lessonDetails.status !='3' || allowedActions.includes('request_reschedule'))"
                     (click)="openScheduleDates(lessonDetails.booking_details.student_id , lessonDetails)"
                     href="#studentSchedules" data-target="#studentSchedules" data-toggle="modal"
                     class="white-space word_break" data-backdrop="static">
                     <a (click)="reScheduleRequest(lessonDetails['booking_details'].tutor_id , lessonDetails['booking_details'].booking_id , lessonDetails.booking_dates_id)"
                        trans="request_reschedule">Request Reschedule</a>
                  </li>
                  <li class="divider"></li>
                  <li *ngIf="lessonDetails.cancel_status == 'askreschedule_sendtutorcancellationrequest' || allowedActions.includes('request_reschedule')"
                     trans="Request Cancellation"><a href="#requestCancellation" data-target="#requestCancellation"
                        data-toggle="modal"
                        (click)="requestCancellation(lessonDetails['booking_details'].tutor_id ,  lessonDetails['booking_details'].booking_id , lessonDetails.booking_dates_id,'requestcancellation')">Request
                        Cancellation</a></li>
                  <li class="divider"></li>
                  <li *ngIf="lessonDetails.cancel_status == 'ask_opencomplaint' || allowedActions.includes('raise_complaint')"
                     trans="Raise Complaint"><a class="red" href="#requestRefund" data-target="#requestRefund"
                        data-toggle="modal"
                        (click)="reScheduleRequest(lessonDetails.tutor_id , lessonDetails['booking_details'].booking_id , lessonDetails.booking_dates_id ,'raisecomplaint')">Raise
                        Complaint</a>
                  </li>
                  <li class="divider"></li>
                  <li *ngIf="lessonDetails['booking_details'].booking_type != 'group' && (lessonDetails.cancel_status == 'directCancellation' || allowedActions.includes('direct_cancellation'))"
                     href="#directSingleCancellation" data-target="#directSingleCancellation" data-toggle="modal"
                     (click)="directSingleCancellation(lessonDetails['booking_details'].booking_id , lessonDetails.booking_dates_id)"
                     trans="Direct Cancellation"><a>Direct Cancellation</a></li>
               </ul>
            </td>
         </tr>
      </table>
      <div class="col-sm-12">
         <div class="row">
            <h5 class="text-left color2">
               <p><b trans="Lesson Wall">
                     Lesson Wall
                  </b>
               </p>
               <p class="palegreen" trans="leave a comment or upload a document."> leave a comment or upload a document.
               </p>
            </h5>
            <div class="row clearfix TT-chatFixed">
               <div class="d-flex TT-formGroup TT-searchFind" style="margin: 15px;">
                  <button id="staticBackdrop" data-backdrop="static" class="chat__area-media-btn mr-2 btn"
                     data-toggle="modal" data-target="#viewModalpaperclip">
                     <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <form class="TT-formGroup" style="width:100%;" method="post"><input id="postComment"
                        (keydown)="keyPressHandler($event)" (input)="comentValue($event.target.value)" type="text"
                        class="form-control" placeholder="Type Message..." trans-placeholder="Type Message..."
                        style="border-radius: 50px;">
                     <i (click)="postComment()" class="fa fa-paper-plane-o TT-searchIcon" aria-hidden="true"></i>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <div class="col-sm-12">
         <!-- main chat -->
         <div class="T_S-chat" id="T_S-chat">
            <div *ngFor="let comment of Comments">
               <h5 *ngIf="userId != comment.from_id" class="text-left color2">
                  <p><b>
                        Teacher: {{comment.from}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen"> {{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
               <h5 *ngIf="userId == comment.from_id" class="text-left color2">
                  <p><b>
                        Student: {{studentName}}
                     </b><span class="Text-Right"> {{comment.created_at | date: 'MM-dd-yyyy'}}</span>
                  </p>
                  <div class="footer-schedule_Chat chat_chat_bdr">
                     <p class="palegreen">{{comment.comment}}</p>
                     <br />
                     <p *ngIf="!comment.attachments"><a class="nocolor" trans="No Attachment">No Attachment</a></p>
                     <p *ngIf="comment.attachments">
                        <a *ngFor="let attachment of comment.attachments; let i = index" class="nocolor">
                           <span data-target="#viewAttachment" data-toggle="modal"
                              (click)="viewAttachmentImage(attachment)">Attachment {{i+1}} </span>
                        </a>
                     </p>
                  </div>
               </h5>
            </div>
         </div>
      </div>
   </span>
</div>
<div class="modal fade" id="viewAttachment" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog mt-100">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true">
               ×
            </button>
         </div>
         <div class="modal-body M_wrapper">
            <img src="{{attachment}}" width="550">
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="viewModalpaperclip" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <button #closeFileUploader (click)="closeFileUploaderModal()" type="button" class="close"
               data-dismiss="modal" aria-hidden="true">×
            </button>
            <h4 class="modal-title TT-AccUsers" id="myModalLabel" trans="File Upload"> File Upload </h4>
            <form [formGroup]="commentForm">
               <div class="form-group">
                  <label for="comment" trans="Comment">
                     Comment
                  </label>
                  <input formControlName="commentValue" type="comment" name="comment" class="form-control">
                  <div *ngFor="let error of errorMessages.commentValue">
                     <ng-container *ngIf="commentValue.hasError(error.type) && (commentValue.touched)">
                        <small class="error-message">{{error.message}}</small>
                     </ng-container>
                  </div>
               </div>
            </form>
            <ngx-dropzone accept="image/jpeg,image/jpg,image/png,image/gif" (change)="onSelect($event)">
               <ngx-dropzone-label trans="Select a file or drag here" style="padding: 0px 30px;">Select a file or drag
                  here</ngx-dropzone-label>
               <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                  [removable]="true" (removed)="onRemove(f)">
               </ngx-dropzone-image-preview>
            </ngx-dropzone>
            <br />
            <div class="form-group TT-formGroup clearfix" style="padding-right:10px;">
               <button (click)="sendFile()" class="btn TT-primaryBTN TT-autoCustomStyle pull-right" id="upload"
                  trans="Send">Send
                  <app-loader *ngIf="imageLoader$ | async"></app-loader>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Request Cancellation">Request Cancellation</span>
            </h5>
            <button type="button" class="close" #closerequestCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p style="color: #000;" trans="Are you sure you want to cancel the reschedule request?">Are you sure you
                  want to cancellation request for this booking?</p>
               <form [formGroup]="updateCancellationForm" role="form" class="form-horizontal TT-formGroup">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="description" formControlName="cancel_description"
                           (input)="messageText($event.target.value)" class="form-control" rows="10"
                           cols="30"></textarea>
                     </div>
                  </div>
                  <button disabled type="button" id="cancelBtn" class="btn TT-primaryBTN"
                     (click)="raiseCancellation('raisecancellation')" trans="Cancel Booking">Cancel Booking</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                     aria-label="Close" #closerequestCancellation trans="Close">Close</button>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<app-messagemodal></app-messagemodal>
<app-schedule-modal (rescheduleData)="requestReschedule($event)"></app-schedule-modal>
<div class="modal fade" id="directSingleCancellation" role="dialog" aria-labelledby="modalLabel" tabindex="-1"
   #closeSingleCancellation>
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span trans="Direct Cancellation">Direct Cancellation</span>
            </h5>
            <button type="button" class="close" #closeSingleCancellation data-dismiss="modal" aria-label="Close"
               style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body TT-formGroup">
               <p style="color: #000;"
                  trans="Are you sure you want to cancel this booking and refund the amount to wallet?">Are you sure you
                  want to cancel this booking and refund the amount to wallet?</p>
               <button type="button" class="btn TT-primaryBTN" (click)="cancelSingleBooking()"
                  trans="Cancel Booking">Cancel Booking</button>
               &nbsp;&nbsp;
               <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close"
                  #closeViewModal trans="Close">Close</button>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestRefund" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog mt-100" role="document">
      <div class="modal-content">
         <div class="modal-header" style="border-bottom: 1px solid transparent;
            padding-bottom: 0px;">
            <h5 class="modal-title linkLabel-green-dark" id="modalLabel">
               <span *ngIf="operation == 'refund'" trans="Refund Schedule">Refund Schedule</span>
               <span *ngIf="operation == 'raisecomplaint'" trans="Raise Compliant">Raise Complaint</span>
            </h5>
            <button (click)="closeComplaintModal()" type="button" class="close" #closeCompliantModal
               data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
               <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
               <p trans="sure_refund_amount" style="color: #000;" *ngIf="operation == 'refund'"
                  trans="Are you sure you want to cancel schedule and refund the amount to wallet?">Are you sure you
                  want to cancel schedule and refund the amount to wallet?</p>
               <p trans="write_description" style="color: #000;" *ngIf="operation == 'raisecomplaint'"
                  trans="Write your description here, to help you out">Write your description here, to help you out</p>
               <form [formGroup]="updateStatusForm" role="form" class="form-horizontal"
                  *ngIf="operation == 'raisecomplaint'">
                  <div class="form-group">
                     <p class="greycolor" trans="Description">
                        Description
                     </p>
                     <div class="col-sm-12">
                        <textarea name="description" id="complaintDescription" formControlName="description"
                           class="form-control" rows="10" cols="30"></textarea>
                     </div>
                  </div>
                  <button type="button" class="btn btn-success new" trans="submit_complaint"
                     (click)="manageSchedule2('raisecomplaint')" trans="Submit Complaint">Submit Complaint</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-primary new" trans="close" data-dismiss="modal"
                     aria-label="Close" #closeViewModal trans="Close">Close</button>
               </form>
               <span *ngIf="operation == 'refund'" class="TT-formGroup">
                  <button type="button" class="btn TT-primaryBTN" trans="refund" (click)="manageSchedule2('refund')"
                     trans="Refund">Refund</button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn TT-primaryBTN" trans="close" data-dismiss="modal"
                     aria-label="Close" #closeViewModal trans="Close">Close</button>
               </span>
            </div>
         </div>
      </div>
   </div>
</div>