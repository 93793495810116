import { Component, OnInit } from '@angular/core';
declare var $ :any;

@Component({
  selector: 'app-go-top',
  templateUrl: './go-top.component.html',
  styleUrls: ['./go-top.component.css']
})
export class GoTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.movetop();
  }

  public movetop() {
     // 2. fixed navbar
    $(window).on('scroll', function () {
      // checks if window is scrolled more than 500px, adds/removes solid class
      if ($(this).scrollTop() > 0) {
        $('.navbar').addClass('affix');
        $('.scroll-to-target').removeClass('hidescroll');
        $('.scroll-to-target').addClass('open');
      } else {
        $('.navbar').removeClass('affix');
        $('.scroll-to-target').removeClass('open');
      }
      // checks if window is scrolled more than 500px, adds/removes top to target class
      if ($(this).scrollTop() > 500) {
        $('.scroll-to-target').removeClass('hidescroll');
        $('.scroll-to-target').addClass('open');
      } else {
        $('.scroll-to-target').removeClass('open');
      }
    });

    // 3. back to top
    if ($('.scroll-to-target').length) {
      $(".scroll-to-target").on('click', function () {
        var target = $(this).attr('data-target');
        // animate
        $('html, body').animate({
          scrollTop: $(target).offset().top
        }, 500);

      });
    }

  }

}
