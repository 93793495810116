<div class="container-fluid padding0">
   <section id="findTutor-chat" class="pageSectionBg">
      <div class="TT-outlineLayers">
         <div class="row m-auto TT-bottomBorder">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-6">
              <h4 class="TT-AccSettings TT-rightSpacers" trans="Your Lessons"> Your Lessons </h4>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-xs-6 clearfix">
               <div id="access-buttons" class="TT-fullPageScreen pull-right">
                  <!-- <a class="TT-fullscreen" (click)="hideSideMenu()">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </a>
                  <a class="TT-fullscreen" (click)="showSideMenu()">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a> -->
                  <a class="TT-fullscreen" (click)="openFullscreen()">
                    <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                  </a>
                  <a class="TT-fullscreen" (click)="closeFullscreen()">
                    <i class="fa fa-compress" aria-hidden="true"></i>
                  </a>
               </div>
            </div>
          </div>
         
         <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12" >
            <app-lessons-sidenav></app-lessons-sidenav>
         </div>
         <router-outlet></router-outlet>
      </div>
   </section>
</div>