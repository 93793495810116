import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {
  
  public documentation$:any;
  public firstPageRoute:any;
  @Input()
  public set documentation(value:any){
  	this.documentation$ = value;
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }



  public more(route){
    if(route){
    this.router.navigate(['student/more/'+route]);
    }else{
       this.router.navigate(['more']);
    }
  }
}
