   <div class="row m-auto" id="overlay-none">
     <!-- {{subjectSeleted | json}} -->
     <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
       <div class="form-group TT-formGroup">
         <label trans="I want to learn" class="TT-lable"> I want to learn </label>
         <div class="TT-FieldGroup">
         <ng-multiselect-dropdown (click)="overlay()" 
         [settings]="subjectSettings"
         [data]="activeSubjects"
         [(ngModel)]="subjectSeleted"
         (onSelect)="onSubjectSelect($event)"
         (onDeSelect)="onSubjectDeSelect($event)">
         </ng-multiselect-dropdown>
        </div>
       </div>
     </div>
     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
       <div class="form-group TT-formGroup">
         <label trans=" I'm Available" class="TT-lable">  I'm Available </label>
         <div class="TT-FieldGroup">
            <div class="DesktopPanelItemBase DesktopPanelItem ExpFilter_cls">
               <div class="FilterPopupWrapper" data-qa-group="search-desktop-top-filter">
                  <div class="Auto_text_complete">
                     <div class="AutocompleteBody">
                        <div class="dropdown">
                           <button class="btn btn-transparent dropdown-toggle overLayId" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="overlay()" style="margin-top: 5px !important;">
                          <span *ngIf="filterDay.length==0 && filterTime.length==0" trans="Select a Time">Select a Time</span>
                           <div class="Day_time">
                          <div *ngIf="selectedTime" class="timeFilter">
                              <span class="Day_time_color" *ngFor="let time of selectedTime">
                                 {{time}},&nbsp;
                              </span>
                           </div>
                          <div *ngIf="selectedDay" class="DayFilter">
                              <span class="Day_time_color" *ngFor="let day of selectedDay">
                                 {{day}}.&nbsp;
                              </span>
                           </div>
                        </div>
                     </button>
   
                           <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" >
                                 <!-- Date and time start -->
                                 <div class="PopupBase PopupNewDesign">
                                    <div class="AvailabilityFilterPopupWrap">
                                       <div class="TimeFilterBlock">
                                          <div class="FilterLabel">
                                             <span trans="Time of the day">Time of the day</span>
                                          </div>
                                          <div class="BlockMLR" data-qa-id="time-filter-block">
                                             <div (click)="selectTime('6-9' ,'6:00-9:00' , '6:00' ,'9:00')" id="6-9" class="TimeItemBlock" data-qa-id="time-MORNING">
                                                <svg class="TimeIcon" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Morning">
                                                      Morning
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-48 -54)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 26)" id="Block">
                                                               <g id="1-block">
                                                                  <g id="baseline-brightness_7-24px" transform="translate(32 12)">
                                                                     <path d="M5.1 13.2c0-1.988 1.522-3.6 3.4-3.6s3.4 1.612 3.4 3.6H17V15H0v-1.8h5.1zm5.1 0c0-.994-.761-1.8-1.7-1.8s-1.7.806-1.7 1.8h3.4zm2.374-5.586l1.202 1.272-1.275 1.35-1.202-1.272 1.275-1.35zm-9.35 1.272l1.202-1.272 1.275 1.35-1.202 1.272-1.275-1.35zM7.65 6h1.7v1.8h-1.7V6z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                6-9
                                                </span>
                                                <span class="TimeItemText" trans="Morning">
                                                Morning
                                                </span>
                                             </div>
                                             <div (click)="selectTime('9-12','9:00-12:00' ,'9:00' ,'12:00' )" id="9-12"  class="TimeItemBlock" data-qa-id="time-MORNING_LATE">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Late-morning">
                                                      Late-morning
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-129 -52)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 26)" id="Block">
                                                               <g id="2-block-•-Active" transform="translate(82)">
                                                                  <g id="baseline-brightness_7-24px" transform="translate(31 10)">
                                                                     <path d="M5.416 15a5 5 0 1 1 9.168 0H20v2H0v-2h5.416zm6.82 0a3 3 0 1 0-4.473 0h4.474zM9 4h2v2H9V4zm6.793 1.793l1.414 1.414-1.5 1.5-1.414-1.414 1.5-1.5zm-13 1.414l1.414-1.414 1.5 1.5-1.414 1.414-1.5-1.5zM19 11v2h-2v-2h2zM1 13v-2h2v2H1z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                9-12
                                                </span>
                                                <span class="TimeItemText" trans="Late morning">
                                                Late morning
                                                </span>
                                             </div>
                                             <div (click)="selectTime('12-15','12:00-15:00' , '12:00' ,'15:00')" id="12-15" class="TimeItemBlock" data-qa-id="time-AFTERNOON">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Afternoon">
                                                      Afternoon
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-211 -52)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 26)" id="Block">
                                                               <g id="3-block" transform="translate(164)">
                                                                  <g id="baseline-brightness_7-24px" transform="translate(31 10)">
                                                                     <path d="M10 5a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM9 0h2v3H9V0zm0 17h2v3H9v-3zm-9-6V9h3v2H0zm17 0V9h3v2h-3zm-1.293-5.293l-1.414-1.414 2-2 1.414 1.414-2 2zm-1.414 10l1.414-1.414 2 2-1.414 1.414-2-2zm-10-1.414l1.414 1.414-2 2-1.414-1.414 2-2zm1.414-10L4.293 5.707l-2-2 1.414-1.414 2 2z" id="Rectangle-4">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                12-15
                                                </span>
                                                <span class="TimeItemText" trans="Afternoon">
                                                Afternoon
                                                </span>
                                             </div>
                                             <div (click)="selectTime('15-18','15:00-18:00' ,'15:00' , '18:00')" id="15-18" class="TimeItemBlock" data-qa-id="time-AFTERNOON_LATE">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Late-afternoon">
                                                      Late-afternoon
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-292 -52)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 26)" id="Block">
                                                               <g id="4-block" transform="translate(245)">
                                                                  <g id="baseline-brightness_7-24px" transform="translate(31 10)">
                                                                     <path d="M10 5a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM9 1h2v2H9V1zm0 16h2v2H9v-2zm-8-6V9h2v2H1zm16 0V9h2v2h-2zm-1.293-5.293l-1.414-1.414 1.414-1.414 1.414 1.414-1.414 1.414zm-1.414 10l1.414-1.414 1.414 1.414-1.414 1.414-1.414-1.414zm-10-1.414l1.414 1.414-1.414 1.414-1.414-1.414 1.414-1.414zm1.414-10L4.293 5.707 2.879 4.293l1.414-1.414 1.414 1.414z" id="Rectangle-4">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                15-18
                                                </span>
                                                <span class="TimeItemText" trans="Late afternoon">
                                                Late afternoon
                                                </span>
                                             </div>
                                             <div (click)="selectTime('18-21','18:00-21:00' ,'18:00' ,'21:00')" id="18-21" class="TimeItemBlock" data-qa-id="time-EVENING">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Evening">
                                                      Evening
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-48 -131)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 105)" id="Block">
                                                               <g id="1-block">
                                                                  <g id="baseline-brightness_7-24px" transform="translate(32 10)">
                                                                     <path d="M13.465 14A3.998 3.998 0 0 0 10 12c-1.48 0-2.773.804-3.465 2h6.93zM4.34 14a6.002 6.002 0 0 1 11.318 0H20v2H0v-2h4.341zM9 6h2v2H9V6zm6.793 1.793l1.414 1.414-1.5 1.5-1.414-1.414 1.5-1.5zm-13 1.414l1.414-1.414 1.5 1.5-1.414 1.414-1.5-1.5z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                18-21
                                                </span>
                                                <span class="TimeItemText" trans="Evening">
                                                Evening
                                                </span>
                                             </div>
                                             <div (click)="selectTime('21-24','21:00-24:00' , '21:00' ,'23:59')" id="21-24" class="TimeItemBlock" data-qa-id="time-EVENING_LATE">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Late-evening">
                                                      Late-evening
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-129 -131)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 105)" id="Block">
                                                               <g id="2-block-•-Active" transform="translate(82)">
                                                                  <g id="baseline-brightness_2-24px-copy" transform="translate(31 10)">
                                                                     <path d="M19.31 10.53A6.535 6.535 0 0 1 7.09 13.753a7.625 7.625 0 0 0 6.474-9.709 6.536 6.536 0 0 1 5.748 6.488zm-8.471 4.102a4.535 4.535 0 0 0 4.976-7.468 9.634 9.634 0 0 1-4.976 7.468zM10.5 7.896c0 1.53-1.241 2.77-2.77 2.77H3.124a3.125 3.125 0 1 1-.852-6.131 3.652 3.652 0 0 1 6.366.752A2.76 2.76 0 0 1 10.5 7.896zM6.872 6.333a1.653 1.653 0 0 0-3.09-.44l-.248.476L3 6.425a1.123 1.123 0 0 0 .124 2.242h4.604c.425 0 .771-.346.771-.771a.762.762 0 0 0-.716-.76l-.764-.052-.148-.751z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                21-24
                                                </span>
                                                <span class="TimeItemText" trans=" Late evening">
                                                Late evening
                                                </span>
                                             </div>
                                             <div (click)="selectTime('0-3','0:00-3:00' ,'0:00' ,'3:00')" id="0-3" class="TimeItemBlock" data-qa-id="time-NIGHT">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Night">
                                                      Night
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-211 -131)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 105)" id="Block">
                                                               <g id="3-block" transform="translate(164)">
                                                                  <g id="baseline-brightness_2-24px" transform="translate(31 10)">
                                                                     <path d="M2.112 13.989A6.5 6.5 0 0 0 6.534 2.403a8 8 0 1 1-4.422 11.586zM9.048 16a6 6 0 0 0 1.258-11.868 8.501 8.501 0 0 1-4.23 11.081A5.988 5.988 0 0 0 9.048 16z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                0-3
                                                </span>
                                                <span class="TimeItemText" trans="Night">
                                                Night
                                                </span>
                                             </div>
                                             <div (click)="selectTime('3-5','3:00-5:00' ,'3:00' ,'5:00')" id="3-5" class="TimeItemBlock" data-qa-id="time-NIGHT_LATE">
                                                <svg class="TimeIcon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                   <title trans="Late-night">
                                                      Late-night
                                                   </title>
                                                   <g id="Page-1" fill="none" fill-rule="evenodd">
                                                      <g id="Availability-dropdown" transform="translate(-292 -131)" fill="#371084" fill-rule="nonzero">
                                                         <g id="Time" transform="translate(16 16)">
                                                            <g transform="translate(0 105)" id="Block">
                                                               <g id="4-block" transform="translate(245)">
                                                                  <g id="baseline-brightness_2-24px-copy-2" transform="translate(31 10)">
                                                                     <path d="M19.31 11.53A6.535 6.535 0 0 1 7.09 14.753a7.625 7.625 0 0 0 6.474-9.709 6.536 6.536 0 0 1 5.748 6.488zm-8.471 4.102a4.535 4.535 0 0 0 4.976-7.468 9.634 9.634 0 0 1-4.976 7.468zM7.819 2H6V0h4.753L9.182 4H11v2H6.247l1.571-4zm-6 5H0V5h4.753L3.182 9H5v2H.247l1.571-4z" id="Combined-Shape">
                                                                     </path>
                                                                  </g>
                                                               </g>
                                                            </g>
                                                         </g>
                                                      </g>
                                                   </g>
                                                </svg>
                                                <span class="TimeItemLabel">
                                                3-6
                                                </span>
                                                <span class="TimeItemText" trans="Late night">
                                                Late night
                                                </span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="DayFilterBlock">
                                          <div class="FilterLabel">
                                             <span trans="Days of the week">Days of the week</span>
                                          </div>
                                          <div class="BlockHSR" data-qa-id="day-filter-block">
                                             <div id="Sun" (click)="selectDay('Sun' ,  'sunday')" class="DayItem" data-qa-id="day-of-week-sun" trans="Sun">
                                                Sun
                                             </div>
                                             <div id="Mon" (click)="selectDay('Mon' , 'monday')" class="DayItem" data-qa-id="day-of-week-mon" trans="Mon">
                                                Mon
                                             </div>
                                             <div id="Tue" (click)="selectDay('Tue' , 'tuesday')" class="DayItem" data-qa-id="day-of-week-tue" trans="Tue">
                                                Tue
                                             </div>
                                             <div id="Wed" (click)="selectDay('Wed' , 'wednesday')" class="DayItem" data-qa-id="day-of-week-wed" trans="Wed">
                                                Wed
                                             </div>
                                             <div id="Thu" (click)="selectDay('Thu' , 'thursday')" class="DayItem" data-qa-id="day-of-week-thu" trans="Thu">
                                                Thu
                                             </div>
                                             <div id="Fri" (click)="selectDay('Fri' , 'friday')" class="DayItem" data-qa-id="day-of-week-fri" trans="Fri">
                                                Fri
                                             </div>
                                             <div id="Sat" (click)="selectDay('Sat' , 'saturday')" class="DayItem" data-qa-id="day-of-week-sat" trans="Sat">
                                                Sat
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <!-- Date and time end -->
                              </a>
                           </div>
                        </div>
                        <!-- time -->
                     </div>
                  </div>
               </div>
            </div>
        </div>
       </div>
       
     </div>
     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
      <div class="form-group TT-formGroup">
         <label class="TT-lable"> Level </label>
          <div class="TT-FieldGroup">
            <ng-multiselect-dropdown (click)="overlay()" class="overLayId" 
            [placeholder]="'Level'"
            [settings]="ageSettings"
            [data]="ageForThisSubject"
            [(ngModel)]="agesData"
            (onSelect)="ageSelcted($event)"
            (onDeSelect)="onAgeDeSelect($event)" >
            </ng-multiselect-dropdown>
          </div>
       </div>
     </div> 
     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
      <div class="form-group TT-formGroup">
         <label class="TT-lable"> Sort By </label>             
            <select id="sortBy" (input)="sortBy()" class="TT-FieldGroup TT-SelectIcon" (click)="overlay()">
               <option value="1" trans="Sort By">
                 Sort By
               </option>
               <option value="1" trans="Select">
                Select
               </option>
               <option value="LowToHigh" trans=" Low to High (Price)">
                Low to High (Price)
               </option>
               <option value="HighToLow" trans=" High to Low (Price)"> 
                High to Low (Price)
               </option>
               <option value="Top-Rated" trans="Top Rated">
                Top Rated
               </option>
               <option *ngIf="currentuser.isLoggedIn()" value="Favourite-Tutor" trans="Favourite Tutor">
                Favourite Tutor
               </option>
            </select>             
       </div>
     </div>
     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-12">
      <div class="TT-formGroup TT-searchFind form-group TT-formGroup">
         <label class="TT-lable"> Search </label>
          <div class="TT-formGroup">
            <input id="text" (input)="searchByNameInput($event.target.value)" type="text" class="TT-FieldGroup" placeholder="Search by name or keyword" trans-placeholder="Search by name or keyword">               
            <i (click)="searchByName()"  class="fa fa-search TT-searchIcon"> </i>
         
          <div class="Search_filter">
            <ul id="myUL">
               <li *ngFor="let displayName of tutorNames">
                 <a href="javascript:void(0)" (click)="SetsearchByName(displayName)">{{ displayName }}</a>
               </li>
            </ul>
          </div>
          </div>
       </div>
     </div> 
   </div>