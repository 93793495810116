import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { BehaviorSubject } from 'rxjs';
import { ExternalLibraryService } from '../utils';
import { CurrentUser } from '@auth/current-user';
import { StudentPaymentService } from '../student-payment.service';
import { LocaleCurrency } from '@auth/localecurrency';
import { Currencies } from '@auth/currencies';
import { ToastrService } from 'ngx-toastr';
import { Transactions } from '@auth/transactions';
import { StripeCheckoutLoader, StripeCheckoutHandler } from 'ng-stripe-checkout';
import { WalletAmountTS } from '@auth/walletamount';
import { finalize } from 'rxjs/operators';
import { Settings } from '@core/config/settings.service';

declare var $;
declare let Razorpay: any;
declare var StripeCheckout;
declare var stripe; // Not sure about this!
declare var elements;
// var cardCvcElement = elements.create('cardCvc');
// cardCvc.mount('#card-cvc');

@Component({
  selector: 'app-payment-sidenav',
  templateUrl: './payment-sidenav.component.html',
  styleUrls: ['./payment-sidenav.component.css']
})
export class PaymentSidenavComponent implements OnInit {

  public transactionlog = new BehaviorSubject([]);
  public walletdetails;
  public walletAmountdetails;
  public available_balance : any;
  public unavailable_balance : any;
  public total_balance : any;
  public payPalConfig?: IPayPalConfig;
  public paymentGateway;
  public subTotal = 0;
  public showSuccess;
  public selectedCurrency;
  public popularCurrencies;
  public subTotalInpounds;
  public previousCurrency;
  public GBP;
  public savedCards;
  public stripeToken;
  response;
  razorpayResponse;
  showModal = false;
  handler: StripeCheckoutHandler;
  public selectedCard:any;
  public loading$ = new BehaviorSubject(false);

  constructor(private razorpayService: ExternalLibraryService,
  	          public currentuser: CurrentUser,
              public currencies: Currencies,
  	          private cd:  ChangeDetectorRef,
  	          public service: StudentPaymentService,
              public localecurrency: LocaleCurrency,
              private toastr: ToastrService,
              private transactions: Transactions,
              private wallet: WalletAmountTS,
              public settings: Settings) {

  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => {
       this.resetPayment();
       this.currency(changeCurrency);
     });
  }


  ngOnInit(): void {
/*Avoid Test*/
$('.numbers').keyup(function () {
    this.value = this.value.replace(/[^0-9\.]/g,'');
});

    this.selectedPayment('');

  	$("li").click(function() {
    $(this).addClass('active-row').siblings().removeClass('active-row');
    });

    //Avalaible balance
    this.service.walletDetails()
         .subscribe(response => {
          this.walletAmountdetails = JSON.parse(atob(response['walletAmountdetails']));
          this.available_balance = this.walletAmountdetails.available_balance;
          this.unavailable_balance = this.walletAmountdetails.unavailable_balance;
          this.total_balance = this.walletAmountdetails.total_balance;
          this.savedCards = response['payment_gateways'];
          this.convertWalletAmount();
        });
  }

   private initConfig(subTotalInpounds): void {
      this.payPalConfig = {
      currency: 'USD',
      clientId: 'sb',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: subTotalInpounds,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: subTotalInpounds
                }
              }
            },
            items: [
              {
                name: 'Wallet Credit',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: subTotalInpounds,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);

        let PaymentSuccessData = Object();
        Object.assign(PaymentSuccessData ,{transaction_id:data.id}); // Capture ID
        Object.assign(PaymentSuccessData ,{creditedAmount:subTotalInpounds});
        Object.assign(PaymentSuccessData ,{transaction:data});
        Object.assign(PaymentSuccessData ,{gateway:'paypal'});
        Object.assign(PaymentSuccessData ,{purpose:'Wallet Credit'});
        Object.assign(PaymentSuccessData ,{selectedCurrency: this.selectedCurrency.code});
          this.service.razorPayPayment(PaymentSuccessData)
         .subscribe(response => {
           if(response.status == 'success'){
            this.toastr.success('Amount Credited To Wallet');
            this.transactionlog =  JSON.parse(atob(response['transactionDetails']));
            this.transactions.init(this.transactionlog['transactions']);
            this.walletdetails = this.transactionlog['walletdetails'];

         this.walletAmountdetails = this.transactionlog['walletdetails'];
         this.available_balance = this.walletAmountdetails.available_balance;
         this.unavailable_balance = this.walletAmountdetails.unavailable_balance;
        this.wallet.init(this.available_balance);
         this.total_balance = this.walletAmountdetails.total_balance;
         this.resetPayment();
        }
    });
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };


    };

  // This Method is Used to Display the Selected Payment
  public selectedPayment(value){
  	this.paymentGateway = value;
    if(this.paymentGateway == 'paypal' && this.subTotal){
      this.initPaypal();
    }
  }

  public assignSubTotal(value){
  	this.subTotal = value;
  }

  public selectPrice(value){
    this.subTotal = value;
    (this.subTotal == 0) ? this.paymentGateway = '' : null
    this.subTotalInpounds = value;
    this.initConfig(this.subTotalInpounds)
}

  public checkOut(){
    if(this.paymentGateway == 'paypal'){
      this.initConfig(this.subTotalInpounds)
    }
    if(this.paymentGateway == 'skrillEWallet'){

    }
  }

    public razorPaySuccessHandler(response) {
    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
    this.cd.detectChanges();
    document.getElementById('razorpay-response').style.display = 'block';
    let razorPaySuccessData = Object();
    Object.assign(razorPaySuccessData ,{transaction_id:response['razorpay_payment_id']});
    Object.assign(razorPaySuccessData ,{creditedAmount:this.subTotal});
    Object.assign(razorPaySuccessData ,{purpose:'Wallet Credit'});
    Object.assign(razorPaySuccessData ,{gateway:'razorpay'});
    Object.assign(razorPaySuccessData ,{transaction:response});
    this.service.razorPayPayment(razorPaySuccessData)
         .subscribe(response => {
          this.transactionlog =  JSON.parse(atob(response['transactionlog']));
    });
  }

    public convertCurrency(value){
     this.popularCurrencies.find(x => {
       if(x.code == this.selectedCurrency.code){
         let poundExchnageRate = x.exchange_rate;
         let currencyInDollars = value/poundExchnageRate;
         let finalselectedCurrency = currencyInDollars * this.GBP.exchange_rate;
         this.subTotalInpounds = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
       }
     });
    }

    public currency(changeCurrency){
      this.popularCurrencies = this.currencies.currencies();
      this.GBP = this.popularCurrencies.find( x => {
       if(x.code == "USD"){
         return x;
       }
      });
      this.selectedCurrency = changeCurrency;
      this.previousCurrency = changeCurrency;
      this.convertCurrency(this.subTotal);
      this.convertWalletAmount();
    }

    // THis Method is used to open the payPalConfig
    public initPaypal(){
      if(this.paymentGateway == 'paypal'){
        this.convertCurrency(this.subTotalInpounds);
        this.initConfig(this.subTotalInpounds)
       }
    }

    // This Method is used to convert wallet amount
    public convertWalletAmount(){
    if(this.walletAmountdetails){
    this.walletAmountdetails.available_balance = this.walletAmountdetails.available_balance.replace(/\,/g,'');
    let available_balance = (this.walletAmountdetails.available_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.available_balance = this.math(available_balance);
    
    this.walletAmountdetails.unavailable_balance = this.walletAmountdetails.unavailable_balance.replace(/\,/g,'');
    let unavailable_balance = (this.walletAmountdetails.unavailable_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.unavailable_balance = this.math(unavailable_balance);
    
    this.walletAmountdetails.total_balance = this.walletAmountdetails.total_balance.replace(/\,/g,'');
    let total_balance = (this.walletAmountdetails.total_balance/this.GBP.exchange_rate) * this.selectedCurrency.exchange_rate;
    this.total_balance = this.math(total_balance);
   }
    }


  public math(value){
    return (Math.round(value * 100) / 100).toFixed(2);
  }


 pay(amount) {
    this.handler = StripeCheckout.configure({
      key: this.settings.get('stripe_pk_key'),
      locale: 'auto',
      token: async(token) =>{
         this.sendtoken(token);
      }
    });

    this.handler.open({
      name: 'My Tutor Platform',
      description: '2 widgets',
      amount: amount * this.subTotalInpounds,
      currency: this.selectedCurrency.code
    });

  }

  payCustomer(amount) {
    let data = Object();
    Object.assign(data,{amount: this.subTotalInpounds});
    Object.assign(data,{selectedCurrency: this.selectedCurrency.code});
    Object.assign(data,{gateway:'stripe'});

    this.service.stripeWalletCustomerPayment(data).subscribe(response => {
         this.toastr.success('Amount Credited To Wallet');
         this.transactionlog =  JSON.parse(atob(response['transactionDetails']));
         this.transactions.init(this.transactionlog['transactions']);
         this.walletdetails = this.transactionlog['walletdetails'];
         this.wallet.init(this.walletdetails);
         this.walletAmountdetails = this.transactionlog['walletdetails'];
         this.available_balance = this.walletAmountdetails.available_balance;
         this.unavailable_balance = this.walletAmountdetails.unavailable_balance;
         this.total_balance = this.walletAmountdetails.total_balance;
         this.resetPayment();
    });
  }




/*
CVC METHOD
  payCustomercvc(amount) {
    let data = Object();
    Object.assign(data,{amount: this.subTotalInpounds * 100});
    Object.assign(data ,{gateway:'stripe'});
    this.service.stripeWalletCustomerPaymentcvc(data).subscribe(response => {
        console.log(response['client_secret'] , response['payment_method']);

         stripe.confirmCardPayment(response['client_secret'], {
          payment_method: response['payment_method'],
          payment_method_options: {
            card: {
              // cvc: cardCvcElement
            }
          },
        }).then(function(result) {
         console.log(result);
          if (result.error) {
            // Show error to your customer
            console.log(result.error.message);
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              console.log(result.paymentIntent.status);
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
            }
          }
        });

    });
  }
  */

  public resetPayment(){
        this.subTotal = 0;
        $('#paymentSelection').prop('selectedIndex',0);
        $('li.active-row').removeClass('active-row');
        $('#customPrice').val('');
        this.paymentGateway = '';
  }

  // This Method is used to send the Stripe Token
  public sendtoken(token){
   let data = Object();
   Object.assign(data,{token: token});
   Object.assign(data,{amount: this.subTotalInpounds});
   Object.assign(data,{selectedCurrency: this.selectedCurrency.code});
   Object.assign(data ,{gateway:'stripe'});

   this.service.stripeWalletPayment(data).subscribe(response => {
       this.walletData(response);
   });
  }

  /*
  To Buy Credits From Selected Saved Cards
  */
   payWithSavedCards(){
    this.selectedCard = $('#payWithExistingCard').val();
    this.loading$.next(true);
   let data = Object();
   Object.assign(data,{selectedCard: this.selectedCard});
   Object.assign(data,{amount: this.subTotalInpounds});
   Object.assign(data,{gateway:'stripe - saved cards'});
   Object.assign(data,{selectedCurrency: this.selectedCurrency.code});
   this.service.stripeWalletPayment(data)
     .pipe(finalize(() => this.loading$.next(false)))
     .subscribe(response =>{
      console.log(response);
         this.walletData(response);
     });
   }
  
  /*
  Update Wallet Details
  */
  walletData(response){
         this.transactionlog =  JSON.parse(atob(response['transactionDetails']));
         if(this.transactionlog['status'] == 'invalid_selection'){
            this.toastr.error(this.transactionlog['message']);
         }else{
         this.transactions.init(this.transactionlog['transactions']);
         this.walletdetails = this.transactionlog['walletdetails'];
         this.walletAmountdetails = this.transactionlog['walletdetails'];
         this.available_balance = this.walletAmountdetails.available_balance;
         this.unavailable_balance = this.walletAmountdetails.unavailable_balance;
         this.total_balance = this.walletAmountdetails.total_balance;
         this.wallet.init(this.available_balance);
         this.currency(this.selectedCurrency);
         this.resetPayment();
         this.toastr.success('Amount Credited To Wallet');
       }
  }

  public selectSearch2(search) {
    
  }

}

