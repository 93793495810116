<div aria-hidden="true" aria-labelledby="messageModalLabel" role="dialog" #closeModal tabindex="-1" id="messageModal" class="modal fade" style="display: none;">
   <div class="modal-dialog mt-200">
      <div class="modal-content bd-25">
         <div class="modal-header">
            <button aria-hidden="true" data-dismiss="modal" class="close" type="button">×</button>
            <h4 class="modal-title linkLabel-green-dark">Messaging {{selectedTutorName}}</h4>
         </div>
         <div class="modal-body">
            <form [formGroup]="messageForm" role="form" class="form-horizontal">
               <div class="form-group">
                  <p class="greycolor"> &nbsp; <span trans="Your Message">Your Message </span> 300 - ({{charactersCount}}) <span trans="Characters Currently.">Characters Currently.</span> </p>
                  <div class="col-lg-12">
                     <textarea formControlName="messageValue" maxlength="300" id="message" (input)="messageText($event.target.value)" rows="10" cols="30" class="form-control" placeholder="Type Something Here..." trans-placeholder="Type Something Here..."></textarea>
                  </div>
               </div>
               <div class="form-group">
                  <div class="TT-formGroup" style="text-align: center;">
                     <button disabled (click)="sendMessage()" id="sendBtn" class="TT-primaryBTN" type="submit" trans="Send" style="width:auto;padding:8px 35px;margin-top:20px;">Send</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<a #openLoginModal data-toggle="modal" data-target="#loginModal"></a>
<app-login></app-login>