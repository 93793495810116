<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">

   <div class="TT-schedule clearfix">
      <h4 trans="Completed Lessons" class="TT-tableTime pull-left"> Completed Lessons
         <span *ngIf="selectedUserName"> with {{selectedUserName}} </span>
      </h4>
   </div>

   <div class="table-responsive TT-responsiveTable">
      <table class="table lesson-table table-striped TT-table">
         <thead>
         <tr>
            <th trans="Tutor">Tutor</th>
            <th trans="Subject">Subject</th>
            <th trans="Date">Date</th>
            <th trans="Time">Time</th>
            <th trans="Feedback">Feedback</th>
            <th trans="Type">Type</th>
            <th trans="Status">Status</th>
            <th trans="Actions">Actions</th>
         </tr>
         </thead>
         <tbody>
         <tr *ngIf="(completed$ | async).length == 0">
               <td class="text_center" colspan="8" align="center" trans="No Records Found">No Records Found</td>
         </tr>
         <tr *ngFor="let class of completed$ | async">
            <td>{{class.booking_details.tutor_resume.display_name}}</td>
            <td>{{class.booking_details.subject.subject_name}}</td>
            <td>{{class.slot_date | date:'MM-dd-yyyy'}}</td>
            <td>{{class.booked_time}}</td>
            <td (click)="viewFeedBack(class.rating , class.review)" href="#DoneModal" data-target="#DoneModal" data-toggle="modal" trans="Feedback">FeedBack</td>
            <td>{{transform(class.booking_details.booking_type)}}</td>
            <td>{{transform(class.class_status)}}</td>
            <td class="dropdown TT-tableDropDownMenuList">
              <i class="fa fa-ellipsis-v data-toggle" data-toggle="dropdown" aria-hidden="true"></i>
                <ul class="dropdown-menu TT-dropDownMenu dropdown-new" >
                  <li class="TT-optionsList" (click)="viewScheduleDetails(class.booking_details.booking_id , class.booking_dates_id)">
                    <a class="TT-frameSet" trans="Lesson Board">Lesson Board</a>
                  </li>
                  <li class="TT-optionsList">
                    <a class="TT-frameSet" href="#requestRefund" data-target="#requestRefund" data-toggle="modal" (click)="reScheduleRequest(class.tutor_id , class['booking_details'].booking_id , class.booking_dates_id ,'raisecomplaint')" trans="Raise Complaint">Raise Complaint</a>
                  </li>
                     <!-- <li class="TT-optionsList" (click)="viewScheduleDetails(class.booking_details.booking_id , class.booking_dates_id)"><a class="TT-frameSet">Lesson Board</a></li> -->
                  </ul>
            </td>
         </tr>
      </tbody>
      </table>
   </div>

   <app-datatable-footer-new *ngIf="(completed$ | async).length > 0"></app-datatable-footer-new>
</div>

<div  class="modal fade" id="DoneModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
   <div class="modal-dialog-centered TT-modalMainArea">
      <div class="modal-content TT-modelCover">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" #closeRatingModal aria-hidden="true"> × </button>
            <h4 class="modal-title TT-AccUsers" id="myModalLabel" trans="Rating Given is"> Rating Given is {{rating}} </h4>
         </div>
         <div class="modal-body TT-modelBody">
            <div class="form-group TT-formGroup">
               <textarea disabled value="{{review}}" class="TT-areaTexging" name="the-textarea" id="the-textarea" maxlength="300" placeholder="Type a message..."autofocus trans-placeholder="Type a message..."></textarea>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade" id="requestRefund" role="dialog" aria-labelledby="modalLabel" tabindex="-1">
   <div class="modal-dialog-centered TT-modalMainArea" role="document">
      <div class="modal-content TT-modelCover">
         <div class="modal-header" style="border-bottom: 1px solid transparent;padding-bottom: 0px;">
            <h4 class="modal-title TT-AccUsers" id="modalLabel">
               <span *ngIf="operation == 'refund'" trans="Refund Schedule">Refund Schedule</span>
               <span *ngIf="operation == 'raisecomplaint'" trans="Raise Compliant">Raise Complaint</span>
            </h4>
            <button (click)="closeComplaintModal()" type="button" class="close" #closeCompliantModal data-dismiss="modal" aria-label="Close" style="margin-top:-30px;">
            <span aria-hidden="true">&times;</span>
            </button>
           </div>
            <div class="modal-body">
               <p trans="sure_refund_amount" *ngIf="operation == 'refund'" trans="Are you sure you want to cancel schedule and refund the amount to wallet?">Are you sure you want to cancel schedule and refund the amount to wallet?</p>
               <p trans="write_description" *ngIf="operation == 'raisecomplaint'" trans="Write your description here, to help you out">Write your description here, to help you out</p>
               <form [formGroup]="updateStatusForm" role="form" class="form-horizontal" *ngIf="operation == 'raisecomplaint'">
                 <h4 modal-title TT-AccUsers>Description</h4>
                 <textarea name="description" id="complaintDescription" formControlName="description" class="form-control" rows="10" cols="30"></textarea>
                   
               <div class="TT-formGroup" style="margin-top:20px;">
                  <button type="button" class="TT-primaryBTN" trans="submit_complaint" (click)="manageSchedule2('raisecomplaint')" trans="Submit Complaint" style="background-color: #5cb85c;width: auto;padding:8px 35px;">Submit Complaint</button>
                  &nbsp;&nbsp;
                  <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close" style="width: auto;padding:8px 35px;">Close</button>
               </div>
              
               </form>
               <span *ngIf="operation == 'refund'">
               <button type="button" class="TT-primaryBTN" trans="refund" (click)="manageSchedule2('refund')"
                  trans="Refund">Refund</button>
               &nbsp;&nbsp;
               <button type="button" class="TT-primaryBTN" trans="close" data-dismiss="modal" aria-label="Close" #closeViewModal trans="Close">Close</button>
               </span>
            </div>
      </div>
   </div>
</div>