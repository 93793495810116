import { ChangeDetectionStrategy , Component, OnInit, ElementRef , ViewChild } from '@angular/core';
import { LessonsService } from '../lessons.service';
import { Router, Event, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DatatableService } from '@core/datatable/datatable.service';
import { Model } from '@core/types/models/Model';
import { CurrentUser } from '../../../auth/current-user'
import { LocaleTimeZoneTS } from '../../../auth/localetimezone';
import { MessagemodalComponent } from '../../findtutor/messagemodal/messagemodal.component';
import { ScheduleModalComponent } from '../schedule-modal/schedule-modal.component';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $;
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { PusherService } from 'app/pusher.service';

@Component({
  selector: 'app-lessons-sidenav',
  templateUrl: './lessons-sidenav.component.html',
  styleUrls: ['./lessons-sidenav.component.css'] ,
})
export class LessonsSidenavComponent implements OnInit {

  public attachment;
  public commentForm = this.fb.group({
    commentValue: ['',Validators.required]
    });
  get commentValue(){ return this.commentForm.get('commentValue');}
  public now;
  public ratingForm = this.fb.group({
        review:['',[Validators.required]],
        rating: ['', Validators.required],
    });

  @ViewChild('closeSingleCancellation') closeSingleCancellation: ElementRef;
  @ViewChild('closeCancellation') closeCancellation: ElementRef;
  @ViewChild(ScheduleModalComponent) scheduleChild: ScheduleModalComponent;
  @ViewChild('closeFileUploader') closeFileUploader: ElementRef;
  @ViewChild('closeRatingModal') closeRatingModal: ElementRef;
  @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
  @ViewChild('closeCompliantModal') closeCompliantModal: ElementRef;
  @ViewChild('closerequestCancellation') closerequestCancellation: ElementRef;
  files: File[] = [];
  formData = new FormData();

  public pendingActions;
  public tutors;
  public schedule;
  public tutorsLists;
  public currentRoute;
  public selectedTutor;
  public hideSidenav = false;
  public Comments = new Array();
  public lessonDetails;
  public selectedBoooking;
  public selectedBookingDateId;
  public comment;
  public userId;
  public studentName;
  public operation;
  public bookingId;
  public schedules;
  public selectedTutorIDForRating;
  public selectedBookedDateIDForRating;
  public cancellationId;
  public selectedBookedDateID;
  public tutorID;
  public date;
  public studentBookings;
  public tutorAvailability;
  public timezone;
  public allowedActions :any;
  public imageLoader$ = new BehaviorSubject(false);

  public updateCancellationForm = this.fb.group({
    cancel_description: ['', Validators.required],
    });


  public updateStatusForm = this.fb.group({
    description: ['', Validators.required],
    });
  public errorMessages = {
  commentValue :[{type:'required',message:'Comment is required'}],
}
public showCalendar = true;
  constructor(private lessonService: LessonsService,
              private router: Router,
              public currentUser: CurrentUser,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private localetimezone: LocaleTimeZoneTS,
              private datePipe: DatePipe,
              private _location: Location,
              private toastr: ToastrService,
              private pusherService:PusherService,
              private datable: DatatableService<Model>)
  {

    window.onresize = function(){  
      if (window.screen.width <= 767) {
          this.showCalendar = false;
        }
      }

    this.lessonService.bookingData.subscribe(response=>{
     // console.log(response,'sssssssssssssssssssssssssss')
      this.lessonDetails = response;
    })
     this.localetimezone.changelocaleTime$.subscribe(changeTime => this.now = changeTime);
     this.date = this.localetimezone.onlyDate();


      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
            if(event.url.includes('videoCall')){
               this.hideSidenav = true;

             }else{

              this.hideSidenav = false;
            }

        }
      });


     this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(val.urlAfterRedirects);
        const urlSegments = urlTree.root.children['primary'].segments.map(segment => segment.path);
        if(urlSegments[3]){
        this.currentRoute = (`/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}`);
        }else{
        this.currentRoute = (`/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}`);
        }
        if(this.currentRoute == "/student/lessons/scheduled/details" || this.currentRoute == "/student/lessons/board"){
          $("#filterTutors").css('display','none');
        }else{
          $("#filterTutors").css('display','inherit');
        }
      }
    });
     // this.clearFilter();
     this.lessonService.sharedpendingActions.subscribe(length=>this.pendingActions = length);
     
     setTimeout(()=>{                           
      this.bind();
      }, 15000);
     
  }
 

  ngOnInit(): void {
    
    
       let name = '';
       this.lessonService.userFilterName(name);
  	   this.lessonService.studentLessonsData()
        .subscribe(response => {
          //console.log(response,'aswew11111111111111111');
          // if(this.currentUser.hasRole('Student')){
          //   this.pusherService.createChannel(this.currentUser.userId(),response.booking.tutor.id);
          // }else{
          //   this.pusherService.createChannel(response.booking.student.id , this.currentUser.userId());
          // } 
    
          //this.bind();


          let lessons =  JSON.parse(atob(response['data']));
          this.schedule = lessons['todayClassesCount'];
          this.pendingActions = lessons['pendingActionsCount'];
          this.tutors = lessons['studentTutors'];
          this.tutorsLists = this.tutors;
          this.userId = this.currentUser.userId();
          this.studentName = this.currentUser.userName();
          this.selectedBoooking = this.route.snapshot.params['id'];
          this.selectedBookingDateId = this.route.snapshot.params['dId'];
          $('#upload').prop("disabled", true);
          
        });
       // this.Comments = JSON.parse( localStorage.getItem('Comments'));
        

  }

  public bind(){
     
    this.pusherService.channel.bind('tutor.message', res => {
      //console.log( res,'asdasdaaaaaaaaaaaaaa');
     // console.log(res[0].original.data,'asdasdaaaaaaaaaaaaaa');
      let data =  JSON.parse(atob(res[0].original.data));
      //this.Comments.push(data['comments'][0])
      this.Comments = data['comments'];
      //console.log(this.Comments);
     
      $("#postComment").val("");
    });  
  }

public resetFilter(){
    if(this.selectedTutor){
   $('#'+this.selectedTutor).removeClass('active');
   this.selectedTutor = '';
   this.lessonService.userFilterName(null);
   this.lessonService.userFilterId(null);
   }
  }


  public filterTutorsList(searchTerm: string){
    this.initializeItems();
  if (!searchTerm) {
    return;
  }

    this.tutors = this.tutors.filter(currentGoal => {
      if (currentGoal.tutor_resume.display_name && searchTerm) {
        if (currentGoal.tutor_resume.display_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      }
    });
  }

  public initializeItems(){
  this.tutors = this.tutorsLists;
}

  // This Method Selects the Tutor for filter
  public selectTutor(tutorId , name){
    //.log(tutorId, 'tutorIdtutorId')
   if($('#'+tutorId).hasClass('active')){
   this.selectedTutor = '';
    $('#'+tutorId).removeClass('active');
   this.lessonService.userFilterName('');
   this.router.navigate([this.router.url.split(';')[0]]);
   }else{
    this.selectedTutor = tutorId;
   this.lessonService.userFilterName(name);
   this.router.navigate([this.router.url.split(';')[0],{id:tutorId}]);
   $('#'+tutorId).addClass('active').siblings().removeClass('active');
   }
  }

  public clearFilter(){
    this.selectedTutor = '';
    $('.tutor').removeClass('active');
    this.lessonService.userFilterId('');
    this.lessonService.userFilterName('');
    this.router.navigate([this.router.url.split(';')[0]]);
  }

  public openSchedules(){
        let name = '';
       this.lessonService.userFilterName(name);
  }
  public isVideoComponent(value){
    this.hideSidenav = value;

  }
  public lessonSelected(){
    let bookingid = Object();
    Object.assign(bookingid , { 'id' : this.selectedBoooking });
    Object.assign(bookingid , { 'did': this.selectedBookingDateId});
    this.lessonService.selectedBookingDetails(bookingid).subscribe(response=>{
     let data =  JSON.parse(atob(response['data']));
     this.Comments = data['comments'];
     this.lessonDetails = data['bookingDetails'];
     this.allowedActions = this.lessonDetails['student_actions'];
         let bookedTime = this.lessonDetails.booked_time;
         let time = this.datePipe.transform(this.now , 'H:mm:ss');
         let statusRequired;
         let status;
         if(data['bookingDetails']['slot_date'] < this.date || data['bookingDetails']['slot_date'] > this.date){
          status = 5;
         }else{
          status = this.calculateTime(bookedTime , time , statusRequired ,  this.lessonDetails.rating_status);
         }
         this.lessonDetails.status = status;
    });
  }

    public manageSchedule2(operation) {
    let data = Object();
    Object.assign(data, {booking_dates_id: this.selectedBookedDateID});
    Object.assign(data, {booked_id: this.bookingId});
    Object.assign(data, {operation: operation});
    if ( operation == 'raisecomplaint') {
      Object.assign(data, {description: this.updateStatusForm.get('description').value});
    }
    this.lessonService.manageSchedule(data)
    .subscribe(response => {
      if(response.status == 'success'){
        this.closeCompliantModal.nativeElement.click();
        this.toastr.success('Record Updated Successfully');
         let responseData =  JSON.parse(atob(response['data']));
         this.schedules = responseData['upcomingClasses'];
        }
    })
  }

  public closeComplaintModal(){
    $('#complaintDescription').val('');
  }

 public backClicked() {
      this._location.back();
      $('.container-fluid.tutorChat.lesson .leftCol').show();
    }

 public calculateTime(classTime , now , statusRequired = '' , rating_status){
    let classTimeConverted = this.AMPMTOTIME(classTime);
let time   = classTimeConverted.split(":");
let hour   = Number(time[0]) * 60 * 60 * 1000;
let minute = Number(time[1]) * 60 * 1000;
let second = Number(time[2]) * 1000;
let result = hour + minute + second;

let currenttime   = now.split(":");
let currenthour   = Number(currenttime[0]) * 60 * 60 * 1000;
let currentminute = Number(currenttime[1]) * 60 * 1000;
let currentsecond = Number(currenttime[2]) * 1000;
let currentresult = currenthour + currentminute + currentsecond;

let graceHour = +time[0]+1;
let grace = Number(graceHour) * 60 * 60 * 1000;
let graceTime = grace + minute + second;

if(currentresult <= graceTime){
if(result <= currentresult && result <= graceTime){
    return 3;  /*2=> CLASS RUNNING */
   }else{
          var Difference_In_Time = result - currentresult;
               if(Difference_In_Time == 1000){
                 this.reloadComponent();
               }else{
                 return Difference_In_Time;
               }
   }
}else{
  if(rating_status > 0){
    return 4; /*4=> Rated */
  }else{
    return 2;
    }  /*2=> CLASS COMPLETED */
   }
}

public reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }

  public AMPMTOTIME(time){
var hours = Number(time.match(/^(\d+)/)[1]);
var minutes = Number(time.match(/:(\d+)/)[1]);
var AMPM = time.match(/\s(.*)$/)[1];
if(AMPM == "PM" && hours<12) hours = hours+12;
if(AMPM == "AM" && hours==12) hours = hours-12;
var sHours = hours.toString();
var sMinutes = minutes.toString();
if(hours<10) sHours = "0" + sHours;
if(minutes<10) sMinutes = "0" + sMinutes;
let time1 = sHours + ":" + sMinutes +  ":" + "00";
return time1;
  }



  public comentValue(value){
  this.comment = value;
  }

  public postComment(){
    if(this.comment){
      let val = this.comment;
      this.comment = "";
    let commentData = Object();
    Object.assign(commentData,{commentValue:    val});
    //console.log( this.lessonDetails,'LessonsSidenavComponent.postComment');
    Object.assign(commentData,{to_id:           this.lessonDetails.booking.tutor_id});
    Object.assign(commentData,{from_id:         this.currentUser.userId()});
    Object.assign(commentData,{booked_id:       this.lessonDetails.booking.booking_id});
    Object.assign(commentData,{booking_dates_id:this.lessonDetails.booking.booking_dates_id});
    Object.assign(commentData,{group_chat_id:this.lessonDetails.booking.group_chat_id});
    this.lessonService.postStudentComment(commentData).subscribe(response=>{
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      $("#postComment").val("");
    });
   }
  }

       // This Method is used to select the Image is drag and drop
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
      }

       // This Method is used to drop the image selected
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.formData.delete("file[]");
        for (var i = 0; i < this.files.length; i++) {
                   this.formData.append("file[]", this.files[i]);
                   this.disableUpload();
        }
        this.disableUpload();
    }

  public keyPressHandler(e) {
  //
  if ( (e.keyCode === 13) || (27 === e.which) || (13 === e.which) ) {
     this.postComment()
     e.preventDefault();
  }
}


 // This Method is used to upload the Files
    public disableUpload(){
      if(this.files.length>0){
        $('#upload').prop("disabled", false);
      }else{
        $('#upload').prop("disabled", true);
      }
    }

    // This Method send the uploaded files as messages
    public fileUploadMessages(files){
       for(var file of files){
        this.filesSendMessage(file);
       }
    }


    public filesSendMessage(file){


    }

    public viewAttachmentImage(image){
     this.attachment = image;
    }

    public closeFileUploaderModal(){
      this.removeAllFiles();
    }
    public removeAllFiles(){
      this.files = [];
      this.commentForm.reset();
      this.formData.delete("file[]");
    }

    // This Method is used to upload the Files in the chat
    public sendFile(){
      this.commentForm.markAllAsTouched();
      if(this.commentForm.valid){
        this.formData.append("commentValue", this.commentForm.value.commentValue);
        this.formData.append("booked_id", this.lessonDetails.booking.booking_id);
        this.formData.append("booking_dates_id", this.lessonDetails.booking.booking_dates_id);
        this.formData.append("group_chat_id", this.lessonDetails.booking.group_chat_id);
        this.formData.append("to_id" , this.lessonDetails.booking.tutor_id);
        this.formData.append("from_id" ,  this.currentUser.userId().toString());
       
      this.imageLoader$.next(true);
      this.lessonService.UploadFiles(this.formData).subscribe(response => {
      if(response['status'] == 'success'){
      this.closeFileUploader.nativeElement.click();
      let data =  JSON.parse(atob(response['data']));
      this.Comments = data['comments'];
      this.imageLoader$.next(false);
        }
      })
    }
    }



}
