import { Component, OnInit , ElementRef ,Output, Input , ViewChild , EventEmitter } from '@angular/core';
import { FindTutor } from '../findtutor.service';
import { DatatableService } from '@core/datatable/datatable.service';
import { Model } from '@core/types/models/Model';
import { SubjectTS } from '@auth/subject';
import { LocaleTimeZoneTS } from '@auth/localetimezone';
import { LocaleCurrency } from '@auth/localecurrency';
import { Currencies } from '@auth/currencies';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CurrentUser } from '@auth/current-user';
import { ScheduleComponent } from '../schedule/schedule.component';
import { ToastrService } from 'ngx-toastr';
import { ActiveSubjects } from '@auth/active-subjects';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { Settings } from '@core/config/settings.service';
import { Location } from '@angular/common';
import { WalletAmountTS } from '@auth/walletamount';
import { MessagemodalComponent } from '../messagemodal/messagemodal.component';
declare var $;

@Component({
  selector: 'app-tutor-full-profile',
  templateUrl: './tutor-full-profile.component.html',
  styleUrls: ['./tutor-full-profile.component.css'] ,
  providers: [DatatableService],
})
export class TutorFullProfileComponent implements OnInit {
   
   @ViewChild('openSchedules') openSchedules: ElementRef;
   @ViewChild('successclild') successclild: ElementRef;
   @ViewChild('openLoginModal') openLoginModal: ElementRef;
   @ViewChild(ScheduleComponent) scheduleChild: ScheduleComponent;
   @ViewChild(MessagemodalComponent) messageChild: MessagemodalComponent;
   public sendAsNotification = 'Yes';
   public tutorSubjects;
   public tutor;
   public userDetails;
   public tutorDetails;
   public selectedCurrency;
   public tutorVideo;
   public videoURL;
   public popularCurrencies;
   public tutorSpeaks;
  public offsetTmz=[];
  public availability;
  public packages;
  public monthValues = [10,11,12];
  public packagePrice;
  public discountPrice;
  public packageFinalPrice;
  public name;
  public lessonsDataSeleted;
  public currentDate = new Date(this.localetimezone.date());
  public startDay;
  public FirstDay;
  public SecondDay;
  public ThirdDay;
  public FouthDay;
  public FifthDay;
  public SixthDay;
  public SeventhDay;
  public month1;
  public month2;
  public month = new Array();
  public nextMonthValues = [1,2,3,4,5,6,7];
  public FirstDate;
  public SecondDate;
  public lessonType = '';
  public ThirdDate;
  public FourthDate;
  public removeScheduleData;
  public FifthDate;
  public SixthDate;
  public SeventhDate;
  public FirstDateTimings = new Array();
  public SecondDateTimings;
  public ThirdDateTimings;
  public FourthDateTimings;
  public FifthDateTimings;
  public SixthDateTimings;
  public SeventhDateTimings;
  public selectedLessons=0;
  public hourlyPrice;
  public totalPrice;
  public currentYear =  this.currentDate.getFullYear();
  public monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
  public nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
  public lessonsData = Object();
  public selectedSubject;
  public object = new Array();
  public selectedPackage;
  public bookedTimings;
  public month1BookedTimings = new Array();
  public timezone;
  public revDate;
  public checkOutFinalData;
  public time1;
  public weekFirstDate;
  public weekSecondDate;
  public weekThirdDate;
  public weekFourthDate;
  public weekFifthDate;
  public weekSixthDate;
  public weekSeventhDate;
  public days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public studentBookings;
  public teaches;
  public activateLoader;
  public activeSubjects = new Array();
  public UserProfile = this.datatable.data$;
  public tutorId :any;
  public GBP:any;
  public adminWholeDiscount: any;
  public mainPrice:any;
  public discountedPrice:any;  
  public adminFirstLessonDiscount: any;
  public trailPackageAccess;
  public adminPackageWholeDiscount;
  public adminFirstPackageDiscount;
  public discountedPackagePrice;
  public techingSubjects:any;
  public discountedPercentage:any;
  public showCheck = false;

  public ptype;
  public group_classes;
  public total_batches_selected = 0;
  public selected_batches = [];
  public tutor_subject;
  public packageFinalPriceGroup;

  constructor(public findtutor:FindTutor,
              public datatable: DatatableService<Model>,
              public subjectselected: SubjectTS,
              private localetimezone: LocaleTimeZoneTS,
              public localecurrency: LocaleCurrency,
              public currentuser: CurrentUser,
              public activesubjects: ActiveSubjects,
              private wallet: WalletAmountTS,
              public currencies: Currencies,
              private toastr: ToastrService,
              private elementRef: ElementRef,
              public sanitizer: DomSanitizer,
              public settings: Settings,
              private router: Router,
              private route: ActivatedRoute,
              private _location: Location) { 

  this.timezone = this.localetimezone.timezone();
  this.localecurrency.changelocaleCurrency$.subscribe(changeCurrency => this.changeCurrency(changeCurrency));
  this.localetimezone.changelocaleTime$.subscribe(changeTime => this.time1 = changeTime); 

 }

    ngAfterViewInit(){
         this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#F4F4F4';
    }


  ngOnInit(): void {
    this.adminWholeDiscount = this.settings.get('admin_discount');
    this.adminFirstLessonDiscount = this.settings.get('first_lesson_discount');
    this.adminPackageWholeDiscount = this.settings.get('package_admin_discount');
    this.adminFirstPackageDiscount = this.settings.get('package_first_lesson_discount');

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tutorId = params.get('userName');
        this.datatable.init({
            uri: FindTutor.profile + '/' + this.tutorId
        });
    });
        this.UserProfile.subscribe(response => {
        if(response.length > 0){
         this.tutor = response[0];
         this.availabilityData(this.tutor['availability']);
         this.packagesData(this.tutor['tutor_packages']);
         
         this.tutor['bypass'] = '';
         if ( this.tutor['video_presentation']['video_URL'] ) {
          this.tutor['bypass'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.tutor['video_presentation']['video_URL']);
         }
         this.convertCurrencies();
        }
        });
         this.popularCurrencies = this.currencies.currencies();
          this.GBP = this.popularCurrencies.find(x => {
              if(x.code == 'USD'){
               return x;
               }
          });
  }

  public changeCurrency(currency){
    this.selectedCurrency = currency;
    this.convertCurrencies();
  }

    public backClicked() {
      this._location.back();
    }


    public convertCurrencies(){
      if(this.tutor){
         this.resetSchedules();
         this.popularCurrencies = this.currencies.currencies();
          let tutorMainHourlyRate = this.tutor['hourly_rate_GBP'];
                    
          if ( tutorMainHourlyRate ) {
            let tutorCurrencyInDollars = tutorMainHourlyRate/this.GBP.exchange_rate;
            let hourlyRateInSelectedCurrency = tutorCurrencyInDollars * this.selectedCurrency.exchange_rate;
            this.tutor['hourly_rate'] = (Math.round(hourlyRateInSelectedCurrency * 100) / 100).toFixed(2);
          }
       }
    }

    resetSchedules(){
    this.object = new Array();
    $('.TimeSlotAvailable-CL').removeClass('badge');
    $(".BtnCheckOut").prop("disabled", true);
    this.packagePrice = '';
    this.discountPrice = '';
    this.packageFinalPrice = '';
    this.selectedLessons = 0;
    this.totalPrice = '';
    this.discountedPrice = '';
    this.packages = [];
    this.group_classes = [];
    this.ptype = '';
    (this.selectedSubject) ? this.packageSubject(this.selectedSubject.active_subject_data.subject_name, this.ptype) : '';
    }

  // Init Availability Data
  public availabilityData(data , bookings = ''){
    this.availability = data;
    this.calenderDays();
    this.calenderDates();
  }


    // This Methods gets the calenderDayes
  public calenderDays(){
  this.month[0] = "Jan";
  this.month[1] = "Feb";
  this.month[2] = "Mar";
  this.month[3] = "Apr";
  this.month[4] = "May";
  this.month[5] = "June";
  this.month[6] = "July";
  this.month[7] = "Aug";
  this.month[8] = "Sep";
  this.month[9] = "Oct";
  this.month[10] = "Nov";
  this.month[11] = "Dec";

  this.month1 = this.currentDate.getMonth();
  this.month1 = this.month[this.month1];
  if(this.bookedTimings){
  this.bookedTimings.find(x=>{
   this.month1BookedTimings.push(x);
  });
}
  this.calenderWeeklyDates();
  }
  


  // This method gets the Calender Dates
  public calenderDates(){

 if(this.monthLastDate.getDate() == this.FirstDate){
    this.FirstDate = this.nextMonth.getDate() + 6;
    this.revDate = this.currentDate;
    }else{
    this.FirstDate = this.currentDate.getDate();
    this.weekFirstDate = this.currentDate;
    this.revDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
  }
 
  if(this.monthLastDate.getDate() == this.SecondDate){
    this.SecondDate = this.nextMonth.getDate() + 6;
    this.weekSecondDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
    this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));
      }  
    }

  if(this.monthLastDate.getDate() == this.ThirdDate){
    this.ThirdDate = this.nextMonth.getDate() + 6;
    this.weekThirdDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FourthDate){
   this.FourthDate = this.nextMonth.getDate() + 6;
    this.weekFourthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));
    }
  }

  if(this.monthLastDate.getDate() == this.FifthDate){
    this.FifthDate = this.nextMonth.getDate() + 6;
    this.weekFifthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
  }else{
    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  }
 
  if(this.monthLastDate.getDate() == this.SixthDate){
    this.SixthDate = this.nextMonth.getDate() + 6;
    this.weekSixthDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
   }else{
    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   }

   if(this.monthLastDate.getDate() == this.SeventhDate){
    this.SeventhDate = this.nextMonth.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.nextMonth.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
    }else{
    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
    this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
    }
       // console.log(this.weekFirstDate , this.weekSecondDate , this.weekThirdDate , this.weekFourthDate , this.weekFifthDate , this.weekSixthDate , this.weekSeventhDate);
  }
 

  // Forward Calender Dates
  public forwardDate(){
    // this.scheduleLoader = true;
    this.monthLastDate = new Date(this.currentYear , this.currentDate.getMonth() + 1, 0);
    this.nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.calenderDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }
  

  public availabilityWeeklyData(){
      let availabilityData = Object();
      let pusheditems = {};
      pusheditems[this.days[this.weekFirstDate.getDay()]] = this.currentYear + "-" + this.sliceValue(this.weekFirstDate.getMonth()+1) + "-" + this.sliceValue(this.weekFirstDate.getDate());

      pusheditems[this.days[this.weekSecondDate.getDay()]] = this.weekSecondDate.getFullYear() + "-" + this.sliceValue(this.weekSecondDate.getMonth()+1) + "-" + this.sliceValue(this.weekSecondDate.getDate());

      pusheditems[this.days[this.weekThirdDate.getDay()]] = this.weekThirdDate.getFullYear() + "-" + this.sliceValue(this.weekThirdDate.getMonth()+1) + "-" + this.sliceValue(this.weekThirdDate.getDate());

      pusheditems[this.days[this.weekFourthDate.getDay()]] = this.weekFourthDate.getFullYear() + "-" + this.sliceValue(this.weekFourthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFourthDate.getDate());

      pusheditems[this.days[this.weekFifthDate.getDay()]] = this.weekFifthDate.getFullYear() + "-" + this.sliceValue(this.weekFifthDate.getMonth()+1) + "-" + this.sliceValue(this.weekFifthDate.getDate());

      pusheditems[this.days[this.weekSixthDate.getDay()]] = this.weekSixthDate.getFullYear() + "-" + this.sliceValue(this.weekSixthDate.getMonth()+1) + "-" + this.sliceValue(this.weekSixthDate.getDate());

      pusheditems[this.days[this.weekSeventhDate.getDay()]] = this.weekSeventhDate.getFullYear() + "-" + this.sliceValue(this.weekSeventhDate.getMonth()+1) + "-" + this.sliceValue(this.weekSeventhDate.getDate());

    Object.assign(availabilityData , {availabilityInfo: pusheditems});
    Object.assign(availabilityData , {tutorId: this.tutor.user_id});
    Object.assign(availabilityData , {timezone: this.timezone});
    $('#forwardDate').prop('disabled' , true);
    $('#reverseDate').prop('disabled' , true);
    this.findtutor.getWeeklyAvailability(availabilityData).subscribe(response => {
    let respnose = JSON.parse(atob(response['data']));
    this.availability = respnose;
    this.calenderWeeklyDates();
    $('#forwardDate').prop('disabled' , false);
    $('#reverseDate').prop('disabled' , false);
    // this.scheduleLoader = false;
    });
  }

  public sliceValue(month){
 return ('0' + month).slice(-2)
 }
 

  // Reverse Calender Dated
  public reverseDate(){
    // this.scheduleLoader = true;
    this.currentYear = this.currentDate.getFullYear();
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.calenderReverseDates();
    this.calenderDays();
    this.availabilityWeeklyData();
  }

  public calenderReverseDates(){

 if(this.monthLastDate.getDate() == this.FirstDate){
    this.FirstDate = this.nextMonth.getDate() + 6;
    this.revDate = this.currentDate;
    this.weekFirstDate = this.currentDate;
    }else{
    this.FirstDate = this.currentDate.getDate();
    this.revDate = this.currentDate;
    this.weekFirstDate = this.currentDate;
      if(this.FirstDate > this.monthLastDate.getDate()){
     this.FirstDate = this.FirstDate - this.monthLastDate.getDate();
      }
  }
 

    this.SecondDate = this.currentDate.getDate() + 1;
    this.weekSecondDate = new Date(new Date().setDate(this.currentDate.getDate() + 1));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.currentDate.getMonth()));
         if(this.SecondDate > this.monthLastDate.getDate()){
      this.SecondDate = this.SecondDate - this.monthLastDate.getDate();
    this.weekSecondDate = new Date(new Date().setDate(this.SecondDate));
    this.weekSecondDate = new Date(new Date(this.weekSecondDate).setMonth(this.nextMonth.getMonth()));     
      }  

    this.ThirdDate = this.currentDate.getDate() + 2;
    this.weekThirdDate = new Date(new Date().setDate(this.currentDate.getDate() + 2));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.currentDate.getMonth()));
    if(this.ThirdDate > this.monthLastDate.getDate()){
     this.ThirdDate = this.ThirdDate - this.monthLastDate.getDate();
    this.weekThirdDate = new Date(new Date().setDate(this.ThirdDate));
    this.weekThirdDate = new Date(new Date(this.weekThirdDate).setMonth(this.nextMonth.getMonth())); 
  }


    this.FourthDate = this.currentDate.getDate() + 3;
    this.weekFourthDate = new Date(new Date().setDate(this.currentDate.getDate() + 3));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.currentDate.getMonth()));
   if(this.FourthDate > this.monthLastDate.getDate()){
     this.FourthDate = this.FourthDate - this.monthLastDate.getDate();
    this.weekFourthDate = new Date(new Date().setDate(this.FourthDate));
    this.weekFourthDate = new Date(new Date(this.weekFourthDate).setMonth(this.nextMonth.getMonth()));   
  }


    this.FifthDate = this.currentDate.getDate() + 4;
    this.weekFifthDate = new Date(new Date().setDate(this.currentDate.getDate() + 4));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.currentDate.getMonth()));
    if(this.FifthDate > this.monthLastDate.getDate()){
    this.FifthDate = this.FifthDate - this.monthLastDate.getDate();
    this.weekFifthDate = new Date(new Date().setDate(this.FifthDate));
    this.weekFifthDate = new Date(new Date(this.weekFifthDate).setMonth(this.nextMonth.getMonth()));
    }
  
 

    this.SixthDate = this.currentDate.getDate() + 5;
    this.weekSixthDate = new Date(new Date().setDate(this.currentDate.getDate() + 5));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.currentDate.getMonth()));
    if(this.SixthDate > this.monthLastDate.getDate()){
     this.SixthDate = this.SixthDate - this.monthLastDate.getDate();
    this.weekSixthDate = new Date(new Date().setDate(this.SixthDate));
    this.weekSixthDate = new Date(new Date(this.weekSixthDate).setMonth(this.nextMonth.getMonth()));
    }
   


    this.SeventhDate = this.currentDate.getDate() + 6;
    this.weekSeventhDate = new Date(new Date().setDate(this.currentDate.getDate() + 6));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.currentDate.getMonth()));
    if(this.SeventhDate > this.monthLastDate.getDate()){
     this.SeventhDate = this.SeventhDate - this.monthLastDate.getDate();
    this.weekSeventhDate = new Date(new Date().setDate(this.SeventhDate));
    this.weekSeventhDate = new Date(new Date(this.weekSeventhDate).setMonth(this.nextMonth.getMonth()));
      }
  }

  
  // Reverse Button access
  public reverseButtonAccess(){
    let presentDate = new Date(this.localetimezone.date());
    if(this.revDate){
    if(this.revDate.getTime() > presentDate.getTime()){
      return true;
    }else{
      return false;
    }
   }
}
 
 

  // Time Selection
  public selectDate(date , timeValue , weekDate){
    if(this.currentuser.isLoggedIn()){
    if(timeValue != 'Booked'){
    if(!this.checkstudentBookings(weekDate , timeValue)){
      if(this.lessonType == 'trialLesson'){
      this.dateSelection(date , timeValue , weekDate , this.lessonType);
   }else{ 
      this.dateSelection(date , timeValue , weekDate);
   } 
  }
}
}else{
this.openLoginModal.nativeElement.click();
  }
}

public dateSelection(date , timeValue , weekDate , lessonType = ''){
   let month = weekDate.getMonth();
   let monthName = this.month[month];
   let idBadge = date+'-'+timeValue+'-'+monthName;
  date = ('0' + date).slice(-2)
  if($("[id='" + idBadge + "']").hasClass('badge')){
     $("[id='" + idBadge + "']").removeClass('badge');
     this.object.splice(this.object.findIndex(slot => slot.date === ""+date && slot.time === timeValue && slot.month === monthName),1);
  }else{
    if(lessonType == 'trialLesson'){
     let slotDate = this.currentYear + "-" + (this.getMonth(month+1)) + "-" + date;
     $("[id='" + idBadge + "']").addClass('badge').siblings().removeClass('badge');
     this.object = new Array();
     this.object.push({date:date ,month: monthName ,  time:timeValue,slot_date: slotDate});
     }else{
     let slotDate = this.currentYear + "-" + (this.getMonth(month+1)) + "-" + date;
     $("[id='" + idBadge + "']").addClass('badge');
     this.object.push({date:date ,month: monthName ,  time:timeValue,slot_date: slotDate});
    }
  }
   this.selectedLessons = this.object.length;
   if (this.tutor.hourly_rate) {
    this.mainPrice = this.selectedLessons*this.tutor.hourly_rate;
    let totalPrice = (Math.round((this.selectedLessons*this.tutor.hourly_rate) * 100) / 100).toFixed(2);
    this.calculatePercentage(totalPrice , lessonType);
   }
   if(this.totalPrice!=0){
     $("#BtnCheckOutDetails").prop("disabled", false);
   }else{
     $("#BtnCheckOutDetails").prop("disabled", true);
   }
}

getMonthForDate(date){
 let month = date.getMonth();
 return this.month[month];
}


public calculatePercentage(totalPrice , lessonType){
   if(lessonType == 'trialLesson'){
    if(this.adminFirstLessonDiscount){
        this.discountedPercentage = this.adminFirstLessonDiscount;
        this.discountedPrice = (Math.round(((this.adminFirstLessonDiscount / 100) * totalPrice) * 100) / 100).toFixed(2);
        this.totalPrice = totalPrice - this.discountedPrice;
        this.totalPrice = (Math.round(this.totalPrice * 100) / 100).toFixed(2);
    }else{
      this.totalPrice = totalPrice;
    }
   }else{
   if(this.adminWholeDiscount){
        this.discountedPercentage = this.adminWholeDiscount;
        this.discountedPrice = (Math.round(((this.adminWholeDiscount / 100) * totalPrice) * 100) / 100).toFixed(2);
        this.totalPrice = totalPrice - this.discountedPrice;
        this.totalPrice = (Math.round(this.totalPrice * 100) / 100).toFixed(2);
     }else{
      this.totalPrice = totalPrice;
    }
   }
}


public find(needle, haystack) {
    var results = [];
    var idx = haystack.indexOf(needle);
    while (idx != -1) {
        results.push(idx);
        idx = haystack.indexOf(needle, idx + 1);
    }
    return results;
}


  
  public openCheckoutModal(){
    if(this.currentuser.isLoggedIn()){
        if(this.selectedSubject){
    let details = Object();
    Object.assign(details ,{user:this.tutor.user_data.id });
    Object.assign(details ,{userDetails:this.tutor.user_data});
    Object.assign(details ,{resume:this.tutor});
    Object.assign(details ,{totalPrice:this.totalPrice});
    Object.assign(details ,{mainPrice:this.mainPrice});
    Object.assign(details ,{discountedPrice:this.discountedPrice});
    Object.assign(details ,{discountedPercentage:this.discountedPercentage});
    Object.assign(details ,{selectedLessons:this.selectedLessons});
    Object.assign(details ,{hourlyRate:this.tutor.hourly_rate});
    Object.assign(details ,{lessonsData:this.object});
    Object.assign(details ,{selectedSubject:this.selectedSubject});
    
    Object.assign(details ,{subjectId:this.selectedSubject.subject_id});
    Object.assign(details ,{bookingType:'schedule'});
    Object.assign(details ,{timezone:this.timezone});
    Object.assign(details ,{tutorTimeZone: this.availability.timeZone});
    Object.assign(details ,{lessonType:'schedule'});
    Object.assign(details ,{selectedCurrency:this.selectedCurrency.code});

    Object.assign(details ,{tutor_subject:this.selectedSubject});
    Object.assign(details ,{total_batches_selected:this.total_batches_selected});
    Object.assign(details ,{selected_batches:this.selected_batches});
   
    this.checkOutFinalData = details;
  }else{
    this.toastr.warning('Please Select Subject');
  }
  }else{
    this.openLoginModal.nativeElement.click();
  }
  }

 public bookingCompleted(data){
    this.openThankYouModal();
    this.clearData();
  }

  public openThankYouModal(){
        this.datatable.init({
            uri: FindTutor.profile + '/' + this.tutorId
        });
    this.successclild.nativeElement.click();
    this.getWalletBalance(this.selectedCurrency.code , this.selectedCurrency.symbol);
  }

  public clearData(){
    this.object = new Array();
    this.availability = '';
    $('.TimeSlotAvailable-CL').removeClass('badge');
    $("#BtnCheckOut").prop("disabled", true);
    this.packagePrice = '';
    this.discountPrice = '';
    this.packageFinalPrice = '';
    this.lessonType = '';
    this.selectedLessons = 0;
    this.selectedSubject = '';
    $('#selectSub').prop('selectedIndex',0);
  }


  // Clear All Added Data in closeModal
  public clearModalData(){
    this.object = new Array();
    this.availability = '';
    $('.TimeSlotAvailable-CL').removeClass('badge');
    $("#BtnCheckOutDetails").prop("disabled", true);
  }


  public calenderWeeklyDates(){
   
  this.startDay = this.currentDate.getDay();
     switch(this.startDay){

       case 1:
       this.FirstDay   = 'Mon';
       this.SecondDay  = 'Tue';
       this.ThirdDay   = 'Wed';
       this.FouthDay   = 'Thu';
       this.FifthDay   = 'Fri';
       this.SixthDay   = 'Sat';
       this.SeventhDay = 'Sun';

       this.FirstDateTimings    = this.availability.monday;
       this.SecondDateTimings   = this.availability.tuesday;
       this.ThirdDateTimings    = this.availability.wednesday;
       this.FourthDateTimings   = this.availability.thursday;
       this.FifthDateTimings    = this.availability.friday;
       this.SixthDateTimings    = this.availability.saturday;
       this.SeventhDateTimings  = this.availability.sunday;
       break;

       case 2:
       this.FirstDay   = 'Tue';
       this.SecondDay  = 'Wed';
       this.ThirdDay   = 'Thu';
       this.FouthDay   = 'Fri';
       this.FifthDay   = 'Sat';
       this.SixthDay   = 'Sun';
       this.SeventhDay = 'Mon';

       this.FirstDateTimings    = this.availability.tuesday;
       this.SecondDateTimings   = this.availability.wednesday;
       this.ThirdDateTimings    = this.availability.thursday;
       this.FourthDateTimings   = this.availability.friday;
       this.FifthDateTimings    = this.availability.saturday;
       this.SixthDateTimings    = this.availability.sunday;
       this.SeventhDateTimings  = this.availability.monday;
       break;

       case 3:
       this.FirstDay   = 'Wed';
       this.SecondDay  = 'Thu';
       this.ThirdDay   = 'Fri';
       this.FouthDay   = 'Sat';
       this.FifthDay   = 'Sun';
       this.SixthDay   = 'Mon';
       this.SeventhDay = 'Tue';

       this.FirstDateTimings    = this.availability.wednesday;
       this.SecondDateTimings   = this.availability.thursday;
       this.ThirdDateTimings    = this.availability.friday;
       this.FourthDateTimings   = this.availability.saturday;
       this.FifthDateTimings    = this.availability.sunday;
       this.SixthDateTimings    = this.availability.monday;
       this.SeventhDateTimings  = this.availability.tuesday;
       break;

       case 4:
       this.FirstDay   = 'Thu';
       this.SecondDay  = 'Fri';
       this.ThirdDay   = 'Sat';
       this.FouthDay   = 'Sun';
       this.FifthDay   = 'Mon';
       this.SixthDay   = 'Tue';
       this.SeventhDay = 'Wed';

       this.FirstDateTimings    = this.availability.thursday;
       this.SecondDateTimings   = this.availability.friday;
       this.ThirdDateTimings    = this.availability.saturday;
       this.FourthDateTimings   = this.availability.sunday;
       this.FifthDateTimings    = this.availability.monday;
       this.SixthDateTimings    = this.availability.tuesday;
       this.SeventhDateTimings  = this.availability.wednesday;
       break;

       case 5:
       this.FirstDay   = 'Fri';
       this.SecondDay  = 'Sat';
       this.ThirdDay   = 'Sun';
       this.FouthDay   = 'Mon';
       this.FifthDay   = 'Tue';
       this.SixthDay   = 'Wed';
       this.SeventhDay = 'Thu';

       this.FirstDateTimings    = this.availability.friday;
       this.SecondDateTimings   = this.availability.saturday;
       this.ThirdDateTimings    = this.availability.sunday;
       this.FourthDateTimings   = this.availability.monday;
       this.FifthDateTimings    = this.availability.tuesday;
       this.SixthDateTimings    = this.availability.wednesday;
       this.SeventhDateTimings  = this.availability.thursday;
       break;

       case 6:
       this.FirstDay   = 'Sat';
       this.SecondDay  = 'Sun';
       this.ThirdDay   = 'Mon';
       this.FouthDay   = 'Tue';
       this.FifthDay   = 'Wed';
       this.SixthDay   = 'Thu';
       this.SeventhDay = 'Fri';

       this.FirstDateTimings    = this.availability.saturday;
       this.SecondDateTimings   = this.availability.sunday;
       this.ThirdDateTimings    = this.availability.monday;
       this.FourthDateTimings   = this.availability.tuesday;
       this.FifthDateTimings    = this.availability.wednesday;
       this.SixthDateTimings    = this.availability.thursday;
       this.SeventhDateTimings  = this.availability.friday;
       break;


       case 0:
       this.FirstDay   = 'Sun';
       this.SecondDay  = 'Mon';
       this.ThirdDay   = 'Tue';
       this.FouthDay   = 'Wed';
       this.FifthDay   = 'Thu';
       this.SixthDay   = 'Fri';
       this.SeventhDay = 'Sat';
       
       this.FirstDateTimings    = this.availability.sunday;
       this.SecondDateTimings   = this.availability.monday;
       this.ThirdDateTimings    = this.availability.tuesday;
       this.FourthDateTimings   = this.availability.wednesday;
       this.FifthDateTimings    = this.availability.thursday;
       this.SixthDateTimings    = this.availability.friday;
       this.SeventhDateTimings  = this.availability.saturday;
       break;
     }
  }
    
    // Package Currency Converter
   private convertPackageCurrencies(value){
    let poundExchnageRate = this.GBP.exchange_rate;
    let amount:any = value.replace(/\,/g,'');
    let currencyInDollars = amount/poundExchnageRate;
    let finalselectedCurrency = currencyInDollars * this.selectedCurrency.exchange_rate;
    let priceConverted = (Math.round(finalselectedCurrency * 100) / 100).toFixed(2)
    return priceConverted;
  }


////////////////////////////////////// Package //////////////////////////////////////


  // Package Checkout
  public packageCheckOut(){
    if(this.currentuser.isLoggedIn()){
    let details = Object();
    Object.assign(details ,{userDetails:this.tutor});
    Object.assign(details ,{user:this.tutor.user_id});
    Object.assign(details ,{resume:this.tutor});
    Object.assign(details ,{packageLessons:this.selectedPackage.no_of_classes});
   
    // if (this.ptype == 'group' ) {      
    //   Object.assign(details ,{totalPrice:this.packageFinalPriceGroup});
    // } else {
    Object.assign(details ,{totalPrice:this.packageFinalPrice});
    // }
    Object.assign(details ,{discountPrice:this.discountPrice});
    Object.assign(details ,{packagePrice:this.packagePrice});
    Object.assign(details ,{hourlyRate:this.tutor.hourly_rate});
    Object.assign(details ,{subjectId:this.selectedSubject.subject_id});
    Object.assign(details ,{package:this.selectedPackage.id});
    // Object.assign(details ,{bookingType:'package'});
    Object.assign(details ,{timezone:this.timezone});
    Object.assign(details ,{tutorTimeZone: this.availability.timeZone});
    Object.assign(details ,{lessonType:this.lessonType});
    Object.assign(details ,{mainPrice:this.packagePrice});
    Object.assign(details ,{selectedCurrency:this.selectedCurrency.code});

    Object.assign(details,{lessonsData:this.object});
    Object.assign(details,{selectedSubject:this.selectedSubject});
    Object.assign(details, {tutor_subject: this.selectedSubject});
    Object.assign(details ,{total_batches_selected:this.total_batches_selected});
    Object.assign(details ,{selected_batches:this.selected_batches});
    Object.assign(details ,{bookingType:this.ptype});

    this.checkOutFinalData = details
   
  }else{
    this.openLoginModal.nativeElement.click();
  }
  }


    // Provides Package Data
  public packagesData(data){
     this.packages = data;
  }
  

  // Package Selection Method
  public selectpackage(packageSelected, ptype){
    this.ptype = ptype;
    if ( ptype == 'group' ) {
      this.selectedPackage = this.group_classes.find(x=>(x.no_of_classes===packageSelected));
    } else {
      this.selectedPackage = this.packages.find(x=>(x.no_of_classes===packageSelected));
    }
    
     if(this.selectedPackage){
      if (ptype == 'group') {
        $("#groupBtnCheckOut").prop("disabled", false);
      } else {
        $("#packageBtnCheckOut").prop("disabled", false);
      }
      this.packagePrice = this.convertPackageCurrencies(this.selectedPackage.price);
      this.discountPrice = this.convertPackageCurrencies(this.selectedPackage.discount_price);
      this.packageFinalPrice = this.convertPackageCurrencies(this.selectedPackage.final_price);
     } else{
      if (ptype == 'group') {
        $("#groupBtnCheckOut").prop("disabled", true);
      } else {
        $("#packageBtnCheckOut").prop("disabled", true);
      }
     this.packagePrice='';
     this.discountPrice='';
     this.packageFinalPrice='';
     }
  }



  // This Method gives the exact Month Value
  public getMonth(month){
    if(!this.monthValues.includes(month)){
      month = ('0' + month).slice(-2);
      return month;
    }else{
      return month;
    }
  }


 public checkDate(date , time){

    if(this.month2){
     if(this.nextMonthValues.includes(date)){
     let nextMonth = new Date(this.currentYear , this.currentDate.getMonth()+1);
     let month = nextMonth.getMonth();
     month = this.month[month];
     return this.checkForBadge(date , time , month);
     }
   }else{
     let currentDate = new Date();
     let month = currentDate.getMonth();
     month = this.month[month];
     return this.checkForBadge(date , time , month);
     }
  }

  public checkForBadge(date , time  , month){
        // && Object.keys(this.object).length === 0 && this.object.constructor === Object
    if(this.object){
      let timeAdded = this.object.filter(
          book => {
            if(book.date === ""+date && book.month === ""+month && book.time === time){
             return book;
            }
          });
      if(timeAdded.length){
        return true;
      }
    }else{
      return false;
    }
  }

  public checkstudentBookings(date , time){
    let dateConverted = this.currentYear + "-" + this.sliceValue(date.getMonth()+1) + "-" + this.sliceValue(date.getDate());
    if(this.studentBookings){
      let timeAccess = this.studentBookings.filter(
         book =>{
          if(book.slot_date === dateConverted && book.booked_time === time){
            return book;
          }
         });
      if(timeAccess.length){
        return true;
      }
    }else{
      return false;
    }
  }

  //navigate Route
  public findTutor(){
    this.router.navigate(['student/find-tutor']);
  }
  //Find tutor
  public inputSubject(value){
    if(value){
           $('#findATeacherBtn').prop('disabled', true);
           $('#findATeacherBtn1').prop('disabled', true);
           $('#findATeacherBtn2').prop('disabled', true);
    }
    this.initializeSubjects();
    this.activeSubjects = this.activeSubjects.filter(subject => {
      if (subject.subject_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
        return true;
      }else{
      return false;
    }
  });
  }

  public initializeSubjects(){
    this.activeSubjects = this.activesubjects.activeSubjects$.value;
  }

  public findTeacher(){
  if(this.currentuser.hasRole('Tutor')){
    this.toastr.error('You Have no access to find Tutors');
     }else if(this.currentuser.isAdmin()){
    this.toastr.error('You Have no access to find Tutors');
     }else{
    this.router.navigate(['/student/find-tutor']);
    }
  }

   // SELECT SUBJECT
  public selectSubject(subject){
    
     let subjectSelected = this.tutor['active_subjects'].find(x=>x.active_subject_data.subject_name === subject);
    
     this.selectedSubject = subjectSelected;

     this.tutor.hourly_rate = subjectSelected.hourly_rate_GBP

     if (this.tutor.hourly_rate) {
      this.mainPrice = this.selectedLessons*this.tutor.hourly_rate;
      let totalPrice = (Math.round((this.selectedLessons*this.tutor.hourly_rate) * 100) / 100).toFixed(2);
      this.calculatePercentage(totalPrice , 'schedule');
     }
     
   if(this.totalPrice!=0){
     $("#BtnCheckOutDetails").prop("disabled", false);
   }else{
     $("#BtnCheckOutDetails").prop("disabled", true);
   }
   this.showCheck = true;
  }

  /*
  Get Packages Of Selected Subject
  */
  packageSubject(subject, ptype){
    
    this.ptype = ptype;
    $('#scheduleSubject').val(subject);
    if(subject){
     let subjectSelected = this.tutor['active_subjects'].find(x=>x.active_subject_data.subject_name === subject);
     this.selectedSubject = subjectSelected;
     this.findtutor.getSubjectRelatedPackages(this.selectedSubject.subject_id, this.tutor.user_id, ptype).subscribe(response=>{
     $("#packageBtnCheckOut").prop("disabled", true);
     this.packagePrice='';
     this.discountPrice='';
     this.packageFinalPrice='';
         if(response['status'] == 'success') {          
          if (ptype == 'group') {
            this.group_classes = response['packages']
          } else {
            this.packages = response['packages'];
          }
         } 
     });
     }else{
      this.selectedSubject = '';
      this.resetSchedules();
     }
  }


  public tutorSchedule(userId , bookedDates, packages, user, resume, subjectData , availability, lessonType = ''){
   this.availability = availability;
   
    if(this.availability){
    this.scheduleChild.bookedDates(bookedDates);
    this.scheduleChild.packagesData(packages);
    this.scheduleChild.userData(user);
    this.scheduleChild.userResume(resume);
    this.scheduleChild.subjects(this.tutor['active_subjects']);
    this.scheduleChild.availabilityData(this.availability , this.studentBookings , lessonType);
    this.scheduleChild.tutorSubject(subjectData);
   }
  }

  public getCheckoutDetails(data){
   this.checkOutFinalData = data;
  }
  
  /*
  Message From Tutor Profile
  */
  public messageModal(resume, userData){
    userData.sendAsNotification = this.sendAsNotification;
    this.messageChild.userData(resume.display_name, userData);
  }

    public JSON(languages){
    return JSON.parse(languages);
  }

    //Favourite Tutor 
  public makeFavourite(id){
     if($("#"+id).hasClass('fa-heart-o')){
     this.activateLoader = true;
     $("#"+id).removeClass('fa-heart-o');
     $("#"+id).addClass('fa-heart');
      let favourite_tutors = Object();
      Object.assign(favourite_tutors , {id:id});
      this.findtutor.makeFavourite(favourite_tutors).subscribe(response => {
       let favourite = JSON.parse(atob(response['favourite']));
       this.toastr.success('Added To Favourite');
       this.activateLoader = false;
      })
     }else{
       this.activateLoader = true;
     $("#"+id).addClass('fa-heart-o');
     $("#"+id).removeClass('fa-heart');
      let favourite_tutors = Object();
      Object.assign(favourite_tutors , {id:id});
      this.findtutor.makeFavourite(favourite_tutors).subscribe(response => {
       let favourite = JSON.parse(atob(response['favourite']));
       this.toastr.success('Removed From Favourite');
       this.activateLoader = false;
      })
   }
  }

    public openscheduleModal(lessonData){
    let data = new Array();
    this.lessonsDataSeleted = data.push(lessonData);
    this.openSchedules.nativeElement.click();
  }

    public removeDataFromScheduleModal(value){
      this.scheduleChild.clearModalData();
      this.resetSchedules();
   }
   
      /*get Latest Wallet Balance*/
   getWalletBalance(currency , symbol){
    this.findtutor.userWalletBalance(currency , symbol).subscribe(response =>{ 
      if(response['balance']){
       let balance = response['balance'].replace(/\,/g,'');
       balance = (Math.round(balance * 100) / 100).toFixed(2);
       this.wallet.init(balance);
      }
    });
   }

   // Provides Tutor Subject
  public tutorSubject(tutor_subject){
    this.tutor_subject = tutor_subject;
  }

   public totalSelected(obj) {
    let batch = obj.target.value;
    if ( obj.target.checked ) {
      this.total_batches_selected++
      this.selected_batches.push(batch)
    } else {
      if (this.total_batches_selected > 0) {
        this.total_batches_selected--
      }
      if ( this.selected_batches.indexOf(batch) !== -1 ) {
        this.selected_batches.splice(batch, 1);
      }
    }
    this.packageFinalPriceGroup = this.packageFinalPrice * this.total_batches_selected;

    if (this.total_batches_selected > 0) {
      $("#groupBtnCheckOut").prop("disabled", false);
    } else {
      $("#groupBtnCheckOut").prop("disabled", true);
    }
  }
}