import { Component, OnInit } from '@angular/core';
import { CurrentUser } from '../../auth/current-user';
import { Echo } from 'laravel-echo-ionic';
import { LessonsService } from '../lessons/lessons.service';
import { Bootstrapper } from '../../core/bootstrapper.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-student-alerts',
  templateUrl: './student-alerts.component.html',
  styleUrls: ['./student-alerts.component.css']
})
export class StudentAlertsComponent implements OnInit {

  public host_socket;
  public usernotifications;
  public usernotifications_read;
  public usernotifications_read_count; 
  public usernotifications_unread;
  public usernotifications_unread_count;
  echo: any = null;
  constructor(
  	      public currentuser: CurrentUser,
          public service: LessonsService,
          private router: Router,
          private bootstrapper: Bootstrapper) {

this.host_socket = this.bootstrapper.data.host_socket;

        //    this.echo = new Echo({
        //     broadcaster: 'socket.io',
        //     host: this.host_socket,
        // });

        // this.echo.channel('laravel_database_private-user.'+this.currentuser.userId())
        //     .listen('UserEvent', (data) => {
        //       console.log(data)
        //    if(data['to_type'] != "App\\ChatModels\\Conversation"){
        //         console.log(data)
        //    }
        // });
        }

	  ngOnInit(): void {
	    this.getAllNotifications();

	   }

    public getAllNotifications(){
      this.service.getAllNotifications().subscribe(response=>{
      this.usernotifications = JSON.parse(atob(response['notifications']));
      this.usernotifications = this.usernotifications['notifications'];
      this.usernotifications_read = this.usernotifications.is_read;
      this.usernotifications_read_count = this.usernotifications.is_read.length;
      this.usernotifications_unread = this.usernotifications.is_unread;
      this.usernotifications_unread_count = this.usernotifications.is_unread.length; 
      });
    }



    public viewAlert(notification){
   (notification.redirect_url) ? this.router.navigate([notification.redirect_url]) : '';

      if(notification.is_read == 0){
        this.service.readNotification(notification.id).subscribe(response=>{
      });
      }

  }

  public humanize(str) {
    if ( ! str ) {
      return;
    }
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  public displayTextLimit(text) {
    if ( text.length > 150 ) {
      return text.substring(0, 150) + '...';
    } else {
      return text;
    }
  }
   

}
